import React from 'react'
import './MenuTab.scss'
import axios from 'axios'

class MenuTab extends React.Component {
	state = {
		pdfMeniuLink: undefined
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let eventInfo = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.event_id, auth);

		this.setState({
			pdfMeniuLink: eventInfo.data.menuFile !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${eventInfo.data.menuFile.url}` : ""
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		return <div className="MenuTab-contents">
			<h3 style={{ margin: "20px 0px" }}>Meniu</h3>

			<div className="MenuTab-MenuData">
				{this.state.pdfMeniuLink === undefined
					? <p>Pdf-ul meniului nu a fost incarcat</p>
					: <></>
				}
			</div>
		</div>
	}
}

export default MenuTab;
