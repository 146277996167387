import React from 'react'
import './AddAlbum.scss'
import HeaderWhite from '../components/HeaderWhiteBack'
import { Uploader, Button, Icon } from 'rsuite'
import NavbarColor from '../components/NavbarColor'
import axios from 'axios'
import { toast } from 'react-toastify'
import LoadingModal from './LoadingModal/LoadingModal'
import CustomInput from '../components/custom/CustomInput'
import { textarea_max_length } from '../components/Helper'

class AddAlbum extends React.Component {
	state = {
		numeAlbum: "",
		description: "",
		pozaAlbum: null,
		linkPozeAlbum: "",

		eventId: "",
		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		// let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);
		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWeddingId/' + this.props.match.params.id, auth)
			.then(response => {
				this.setState({
					eventId: response.data,
					loading: false
				})
			})
			.catch(err => {
				localStorage.clear()
				toast.error("Informatiile de autentificare au expirat, va rog reautentificativa")
				this.props.history.push('/login');
			})

		// this.setState({
		// 	eventId: info.data.id,
		// 	loading: false
		// })
	}

	makeid(length) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	handleChange = (value, event) => {
		this.setState({
			[event.target.name]: value
		})
	}

	changeAlbumDescription = (value) => {
		if (this.state.description.length > textarea_max_length - 1 && value.length > textarea_max_length - 1) {
			return
		}

		this.setState({
			description: value
		})
	}

	createAlbum = async () => {
		if (this.state.numeAlbum === "") {
			toast.error("Nu ati completat titlul")
			return;
		}

		this.setState({
			loading: true
		})

		let obj = {
			user: localStorage.getItem('id'),
			event: this.state.eventId,
			numeAlbum: this.state.numeAlbum,
			descriereAlbum: this.state.description,
			linkPozeAlbum: this.state.linkPozeAlbum
		}

		let formData = new FormData()
		formData.append("data", JSON.stringify(obj))

		if (this.state.pozaAlbum !== null) {
			formData.append("files.image", this.state.pozaAlbum)
		}

		let auth = {
			headers: {
				"Content-Type": `multipart/form-data;`,
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'albums', formData, auth)
			.then(async response => {
				toast.success("Album creat cu succes!")

				this.setState({
					loading: false
				})

				this.props.history.push('/viewAlbums/' + this.props.match.params.id)
			})
			.catch(err => {
				console.log(err.message);
				toast.error("Eroare")
			})
	}

	handlePoza = (fileList) => {
		if (fileList.length === 0) {
			this.setState({
				pozaAlbum: null
			})
			return
		}

		this.setState({
			pozaAlbum: fileList[0].blobFile
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading) {
			return <div className="AddAlbum-container">
				<HeaderWhite goBack={this.goBack} />

				<div className="AddAlbum-contents"></div>

				<NavbarColor history={this.props.history} />
			</div>
		}

		return (
			<div className="AddAlbum-container">
				<HeaderWhite goBack={this.goBack} />

				<LoadingModal loading={this.state.loading} />

				<div className="AddAlbum-contents">
					<h4 id="title"> Adauga Album </h4>

					<div className="input-zone">
						<CustomInput value={this.state.numeAlbum} onChange={value => this.setState({ numeAlbum: value })} placeholder={"Nume Album"}
							style={{ margin: "10px 0px" }} icon={<Icon icon="text-height" />} />

						<CustomInput value={this.state.description} onChange={this.changeAlbumDescription} placeholder={`Descriere Album ${this.state.description.length}/${textarea_max_length}`}
							style={{ margin: "10px 0px" }} componentClass={"textarea"} icon={<Icon icon="pencil" />} />

						<div className="Upload-zone">
							<div style={{ textAlign: "left" }}> Adauga poza pt coperta album </div>

							<Uploader onChange={this.handlePoza} accept=".jpg" style={{ borderRadius: "5px" }}
								autoUpload={false} multiple={false} disabled={this.state.pozaAlbum !== null}>
								<div style={{ color: "#221d5d", width: "100%", height: "100%" }} id="text"> Click aici pentru a incarca fotografii </div>
							</Uploader>
						</div>

						<div style={{ textAlign: "left" }}>Albumul va fi vizibil pentru participanti doar daca ati adaugat link de drive</div>
						<CustomInput value={this.state.linkPozeAlbum} onChange={value => this.setState({ linkPozeAlbum: value })} placeholder={"Link catre poze album"}
							style={{ margin: "10px 0px" }} icon={<Icon icon="link" />} />

						<div className="Footer">
							<Button onClick={this.createAlbum} className="add-button" style={{ backgroundColor: "#00afcc", color: "white" }}>Adauga Album</Button>
						</div>
					</div>
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default AddAlbum;