import React from 'react'
import SectionRender from '../sectionRender'
import './template25.scss'

export default function template25Content(templateDetails = false) {

    return (
        <div className={"main-content"} >

            <div className={"background"}>
                <img src={templateDetails.settings[1].value} crossOrigin={"true"}/>
            </div>

            <div className={"photo-template-25 " + (templateDetails.live ? 'live' : '')}>

                <div className={"row-1"}>
                    <div className={"col-left"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[0]}
                            preview={!templateDetails.live}
                        />
                    </div>
                    <div className={"col-right"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[1]}
                            preview={!templateDetails.live}
                        />
                    </div>
                </div>

                <div className={"row-2"}>
                    <div className={"col-left"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[2]}
                            preview={!templateDetails.live}
                        />
                    </div>
                </div>


            </div>

            {templateDetails.settings[8] && templateDetails.settings[8].value && templateDetails.settings[8].value.length > 0 &&
            <div className={"template-25-overlay"}>
                <img src={templateDetails.settings[8].value} crossOrigin={"true"}/>
            </div>}
        </div>
    )
}
