import React from 'react';
import './TemplateRenderLive.scss'
import {getTemplateContent, photoTemplates} from "../../../../components/memorybooth/phototemplates";
import '../../../../components/memorybooth/photoTemplates.scss'

export default function TemplateRenderLive({templateDetails, reff = false, small = false}) {

    return(

        <div className={"template-render-live-cont " + (small ? 'small' : '')} ref={reff}>

            {getTemplateContent(templateDetails)}

        </div>

    )
}

