import React from 'react'
import './HeaderWhite.scss'
import logo from '../assets/logo.png'
import { Icon } from 'rsuite'

class HeaderWhite extends React.Component {
	render() {
		var style = null;
		if (this.props.shadow === false)
			style = { boxShadow: "none" }

		return (
			<div style={style} className="HeaderWhite-container">
				<div className="HeaderWhite-contents">
					<div className="with-back">
						<Icon onClick={this.props.goBack} icon="angle-left" />

						<img src={logo} style={{ width: "100px", height: "50px" }} alt="Logo Ality" />

						<div></div>
					</div>
				</div>
			</div>

		)
	}

}


export default HeaderWhite;