import React from 'react'
import './TemplateSectionEdit.scss'
import {photoTemplatesSectionTypes} from "../../../../components/memorybooth/phototemplates";
import ImageUploadComponent from "../ImageUploadComponent";
export default function TemplateSectionEdit({section, updateSection, weddingId}) {

    return (<div className={"section-edit-item"}>

            <div className={"nr"}>{section.id}</div>

            <div className={"type"}>

                {
                    section.allowed_types.length > 1 ?
                        <select value={section.type} onChange={(e) => updateSection(section.id-1, 'type', parseInt(e.target.value))}>
                            {
                                section.allowed_types.map((type,i) => (
                                    <option key={i} value={type}>{photoTemplatesSectionTypes[type]}</option>
                                ))
                            }
                        </select>
                        :
                        photoTemplatesSectionTypes[section.type]
                }

            </div>

            <div className={"content"}>

                {
                    section.type === 1 ?
                       <ImageUploadComponent
                           weddingId={weddingId}
                        saveImageUrl={(url) => updateSection(section.id-1, 'value', url)}
                       />
                        :
                        null
                }

            </div>

        </div>
    )
}