import React from 'react'
// import Webcam from "react-webcam";
import './FaceRecogTab.scss'
// import LoadingOverlay from 'react-loading-overlay';
// import { toast } from 'react-toastify';
// import Fade from 'react-reveal/Fade';
// import Modal from 'react-responsive-modal'
import axios from 'axios'

class FaceRecogTab extends React.Component {
	state = {
		// webcamRef: React.createRef(),
		imgSrc: null,
		showModal: true,
		showConfirmModal: false,
		showOverlay: null,
		loading: true,
		isPictureSent: false,

		isUsingFaceRecog: false,
		inWebView: false,
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let event_info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.event_id, auth);

		this.setState({
			isUsingFaceRecog: event_info.data.isUsingFaceRecog
		})

		// var response = await axios.get(process.env.REACT_APP_DOMAIN + 'users/' + localStorage.getItem("id"), auth)

		// let obj = {
		// 	email: response.data.email,
		// }

		// await axios.post('https://server.imaflux.com:5006/api/facerecog/checkRequest', obj)
		// 	.then((response) => {
				//check response
				this.setState({
					loading: false,
					// imgSrc: response.data,
					// isPictureSent: true,
					// showModal: false,
				})
			// })
			// .catch(err => {
			// 	this.setState({
			// 		loading: false
			// 	})
			// })

		//check if it's web or flutter_webview
		if (window.flutter_inappwebview) {
			this.setState({
				inWebView: true,
			})
		}
	}

	// capture = () => {
	// 	if (this.state.inWebView) {
	// 		window.flutter_inappwebview.callHandler('faceRecog', 'recog').then(res => {
	// 			if (res === "NULL") {
	// 				return;
	// 			}

	// 			this.setState({
	// 				imgSrc: `data:image/jpeg;base64,${res}`
	// 			})
	// 		})
	// 	} else {
	// 		const imageSrc = this.state.webcamRef.current.getScreenshot();

	// 		this.setState({
	// 			imgSrc: imageSrc
	// 		})
	// 	}
	// }

	// deletePic = () => {
	// 	this.setState({
	// 		imgSrc: null,
	// 	})
	// }

	// savePicture = () => {
	// 	let obj = {
	// 		email: this.props.email,
	// 		tel: "",
	// 		type: "email",
	// 		selfie: this.state.imgSrc,
	// 		eventID: this.props.event_id
	// 	}

	// 	axios.post('https://server.im a flux.com:5006/api/facerecog/saveRequest', obj)
	// 		.then((response) => {
	// 			// this.props.history.push('/finish');
	// 			toast.success("Selfie trimis")
	// 			// console.log(response);
	// 		})
	// 		.catch(err => {
	// 			toast.error("Mail-ul acesta este deja inregistrat")
	// 		})

	// 	this.setState({
	// 		showConfirmModal: false,
	// 		isPictureSent: true
	// 	})
	// }

	render() {
		// const videoConstraints = {
		// 	facingMode: "user"
		// };

		if (this.state.loading) {
			return (
				<div className="FaceRecogTab-container">
					<p>Se verifica daca s-a trimis selfie</p>
				</div>
			)
		}

		return (
			(this.state.isUsingFaceRecog //check if face recog option is opted
				? <div className="FaceRecogTab-container">
					<p>Optiunea de recunoastere faciala este bifata</p>
				</div>
				// <LoadingOverlay
				// 	active={this.state.showOverlay}
				// 	spinner
				// 	text='Se incarca'
				// 	styles={{
				// 		overlay: (base) => ({
				// 			...base,
				// 			background: 'rgba(0, 0, 0, 0.5)'
				// 		})
				// 	}}
				// >
				// 	<Fade >
				// 		<>
				// 			{(this.state.imgSrc === null)
				// 				? !this.state.inWebView && <div style={{ borderRadius: "20px" }} >
				// 					<Webcam
				// 						audio={false}
				// 						ref={this.state.webcamRef}
				// 						screenshotFormat="image/jpeg"
				// 						mirrored={true}
				// 						videoConstraints={videoConstraints}
				// 					/>
				// 					<div className="overlay-pic"></div>
				// 					<div className="circle-pic"></div>
				// 				</div>
				// 				: <img style={{ borderRadius: "20px", width: "80vw" }} className={this.state.inWebView ? "img-webView" : ""}
				// 					src={this.state.imgSrc}
				// 				/>}

				// 			{(this.state.imgSrc === null)
				// 				? <Button style={{ margin: "auto", marginTop: "10px", width: "90vw", maxWidth: '500px' }} block size="lg" appearance="primary" onClick={this.capture}>  Fa un selfie </Button>
				// 				: this.state.isPictureSent
				// 					? <p style={{ color: "green" }}>Poza a fost trimisa </p>
				// 					: <div style={{ marginTop: "10px", flexDirection: "column" }}>
				// 						<Button size="lg" appearance="ghost" onClick={this.deletePic}> Repeta Poza </Button>
				// 						<Button size="lg" appearance="primary" onClick={() => this.setState({ showConfirmModal: true })}> Continua </Button>
				// 					</div>
				// 			}

				// 			<Modal classNames={{
				// 				overlay: 'modal-overlay',
				// 				modal: 'modal-code',
				// 				closeButton: 'modal-close-button',
				// 			}} center open={this.state.showConfirmModal} onClose={() => this.setState({ showConfirmModal: false })}>
				// 				<div style={{ marginTop: "10px", display: "flex", height: "500px", flexDirection: "column" }}>
				// 					<p style={{ color: "red" }}>Poza nu poate fi schimbata odata apasat Confirm</p>
				// 					<Button style={{ marginTop: "20vh" }} size="lg" appearance="primary" onClick={this.savePicture}> Confirm </Button>
				// 					<Button style={{ marginTop: "10px" }} size="lg" appearance="ghost" onClick={() => this.setState({ showConfirmModal: false })}> Cancel </Button>
				// 				</div>
				// 			</Modal>

				// 			<Modal classNames={{
				// 				overlay: 'modal-overlay',
				// 				modal: 'modal-code',
				// 				closeButton: 'modal-close-button',
				// 			}} center open={this.state.showModal} onClose={() => this.setState({ showModal: false })}>

				// 				<h6 style={{ marginTop: "15px" }}>Recomandari pentru a garanta ca te identificam in poze:</h6>

				// 				<div style={{ textAlign: "left", fontFamily: "futura-pt, sans-serif", marginTop: "30px" }}>
				// 					<Icon style={{ color: "red", marginTop: "10px" }} icon='check-circle' size="lg"> Cuprinde cat mai bine fata ta </Icon>
				// 					<Icon style={{ color: "red", marginTop: "10px" }} icon='check-circle' size="lg"> Fara ochelari de soare sau de vedere </Icon>
				// 					<Icon style={{ color: "red", marginTop: "10px" }} icon='check-circle' size="lg"> Fara masca in timp ce faci selfie-ul </Icon>
				// 					<Icon style={{ color: "red", marginTop: "10px" }} icon='check-circle' size="lg"> Fara sapca sau palarie </Icon>
				// 					<Icon style={{ color: "red", marginTop: "10px" }} icon='check-circle' size="lg"> Sa nu vina lumina soarelui din spatele tau </Icon>
				// 				</div>

				// 				<Button style={{ marginTop: "20px" }} size="lg" appearance="primary" onClick={() => this.setState({ showModal: false })}> Continua </Button>
				// 			</Modal>
				// 		</>
				// 	</Fade>
				// </LoadingOverlay>
				: <div className="FaceRecogTab-container">
					<p>Optiunea de recunoastere faciala nu este bifata</p>
				</div>
			)
		)
	}
}

export default FaceRecogTab