import React from 'react'
import { Button } from 'rsuite'
import './FaceRecog.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'
import { toast } from 'react-toastify'
import axios from 'axios'

class FaceRecog extends React.Component {
	state = {
		eventId: "",

		statusFaceRecog: false,

		loading: true,
	}

	componentDidMount = async () => {
		//get faceRecog status from server
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let eventInfo = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

		//save faceRecog status
		this.setState({
			eventId: eventInfo.data.id,
			statusFaceRecog: eventInfo.data.isUsingFaceRecog,
			loading: false
		})
	}

	saveNewStatus = async () => {
		let prevStatus = this.state.statusFaceRecog

		this.setState({
			statusFaceRecog: !prevStatus
		})

		//save new status on server
		let obj = {
			isUsingFaceRecog: !prevStatus
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
			.then(_ => {
				toast.success(prevStatus ? "Face Recognition Dezactivat" : "Face Recognition Activat")
			})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		return <div className="FaceRecog-container">
			<HeaderWhite goBack={this.goBack} />

			<div className="FaceRecog-contents">
				<div className="title">Face Recognition</div>

				{this.state.loading
					? <p></p>
					: <div>Status: <div style={{fontWeight: "bold"}}>{this.state.statusFaceRecog ? "Activ" : "Inactiv"}</div></div>
				}

				{this.state.loading
					? <p>Se verifica statusul optiunii</p>
					: <Button color="green" style={{ height: "70px", width: "150px", margin: "10px auto" }} onClick={this.saveNewStatus}>
						{this.state.statusFaceRecog ? " Anuleaza " : " Porneste "}
					</Button>
				}
			</div>

			<NavbarColor history={this.props.history} />
		</div>
	}
}

export default FaceRecog;