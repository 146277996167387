import React from 'react'
import './MemoryBoothPhotoConfig.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import {Uploader, Button, SelectPicker, Icon, DatePicker, Dropdown} from 'rsuite'
import Modal from "react-responsive-modal";
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';

import NavbarColor from '../../components/NavbarColor'
import {photoTemplates} from "../../components/memorybooth/phototemplates";
import TemplatePicker from "./components/TemplatePicker/TemplatePicker";
import TemplateRender from "./components/TemplateRender/TemplateRender";
import TemplateSectionEdit from "./components/TemplateSectionEdit/TemplateSectionEdit";
import TemplateSettingsEdit from "./components/TemplateSettingsEdit/TemplateSettingsEdit";
import axios from "axios";
import {BackAndConfirmButtons, ConfirmButton} from "../../components/Helper";
import {toast} from "react-toastify";


class MemoryBoothPhotoConfig extends React.Component {
    state = {
        loading: true,

        memorybooth: {},
        selectedTemplate: 0,
        templateDetails: {},

        weddingId: 0

    }

    componentDidMount = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        console.log('here')

        await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.match.params.id, auth)
            .then(response => {
                this.setState({
                    weddingId: response.data.id,
                    memorybooth: response.data.memorybooth,
                    selectedTemplate: response.data.memorybooth && response.data.memorybooth.photo && response.data.memorybooth.photo.templateDetails ? response.data.memorybooth.photo.templateDetails.id : 0,
                    templateDetails: response.data.memorybooth && response.data.memorybooth.photo && response.data.memorybooth.photo.templateDetails ? response.data.memorybooth.photo.templateDetails : {},
                    loading:false
                })

            })
            .catch(err => {
                this.setState({
                    loading: false
                })
                console.log(err);
            })
    }

    saveTemplate = async () => {

        let newMemoryBoothConfig = {...this.state.memorybooth}
        if(!newMemoryBoothConfig.photo) {
            newMemoryBoothConfig.photo = {}
        }

        newMemoryBoothConfig.photo.selectedTemplate = this.state.selectedTemplate
        newMemoryBoothConfig.photo.templateDetails = this.state.templateDetails

        let payload = {
            memorybooth: newMemoryBoothConfig
        }

            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/update/' + this.state.weddingId, payload, auth)
                .then(response => {

                    toast.success("Modificarile au fost salvate!")
                })
                .catch(err => {
                    toast.error("Eroare")
                })


    }


    goBack = () => {
        this.props.history.go(-1)
    }

    changeTemplate = (templateIndex) => {

        let templateDetails = photoTemplates[templateIndex - 1]
        templateDetails.id = templateIndex

        this.setState({
            selectedTemplate: templateIndex,
            templateDetails: templateDetails
        })
    }

    changeSettings = (settingIndex, value) => {
        let newTemplateDetails = {...this.state.templateDetails}

        newTemplateDetails.settings[settingIndex].value = value

        this.setState({templateDetails: newTemplateDetails})
    }

    changeSection = (sectionIndex, param, value) => {
        let newTemplateDetails = {...this.state.templateDetails}

        newTemplateDetails.sections[sectionIndex][param] = value

        this.setState({templateDetails: newTemplateDetails})
    }

    render() {

        return (
            <div className="MemoryBoothPhotoConfig-container">
                <HeaderWhite goBack={this.goBack}/>

                {
                    this.state.loading ?
                        <div>Se incarca ... </div>
                        :
                        <div className="MemoryBoothPhotoConfig-contents">

                            <TemplatePicker
                                changeTemplate={this.changeTemplate}
                                selectedTemplate={this.state.selectedTemplate}
                                resetTemplate={() => this.setState({selectedTemplate: 0, templateDetails: {}})}
                            />

                            <div className={"row"}>

                                <div className={"left"}>
                                    {
                                        this.state.selectedTemplate > 0 ?
                                            <>
                                                <div className={"template-edit-section"}>
                                                    <h3>Personalizeaza</h3>
                                                    {
                                                        this.state.templateDetails && this.state.templateDetails.settings.map((setting, i) => (
                                                            <TemplateSettingsEdit
                                                                key={i}
                                                                setting={setting}
                                                                updateValue={this.changeSettings}
                                                                weddingId={this.state.weddingId}
                                                            />
                                                        ))
                                                    }
                                                </div>

                                                <div className={"template-edit-section"}>
                                                    <h3>Sectiuni</h3>
                                                    <div>
                                                        {
                                                            this.state.templateDetails && this.state.templateDetails.sections.map((section, i) => (
                                                                <TemplateSectionEdit
                                                                    key={i}
                                                                    section={section}
                                                                    weddingId={this.state.weddingId}
                                                                    updateSection={this.changeSection}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </div>

                                            </>
                                            : null
                                    }


                                </div>
                                <div className={"right"}>
                                    {
                                        this.state.selectedTemplate > 0 && this.state.templateDetails ?
                                            <TemplateRender
                                                key={this.state.templateDetails}
                                                templateDetails={this.state.templateDetails}
                                            />
                                            : null
                                    }

                                </div>

                            </div>

                            <div className={"row"} style={{marginBottom: '20px'}}>
                                {
                                    this.state.selectedTemplate > 0 ?
                                        <BackAndConfirmButtons
                                            functionBack={() => this.goBack()}
                                            textBack={"Inapoi"}
                                            functionConfirm={this.saveTemplate}
                                            textConfirm={"Salveaza"}
                                        />
                                        : null
                                }
                            </div>

                        </div>
                }

                <NavbarColor history={this.props.history}/>
            </div>
        )
    }
}

export default MemoryBoothPhotoConfig;