import React, {useState, useEffect} from 'react';
import './MemoryBooth.scss'
import {useHistory} from "react-router-dom";
import axios from "axios";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
const ffmpeg = createFFmpeg({ log: true });



export default function MemoryBoothProcessing() {

    let history = useHistory();

    const [memoryBoothFile, setMemoryBoothFile] = useState(true)
    const [loading, setLoading] = useState(false)



    const loadFFmpeg = async () => {

        if(ffmpeg.isLoaded()) {
            processFile();
            return;
        }

        await ffmpeg.load();
        processFile();
    };

    useEffect(() => {
        loadFFmpeg()


    }, [])

    const processFile = async () => {

        let memoryBoothFiles = await axios.get(process.env.REACT_APP_DOMAIN + 'memory-booth-files/get/toProcess');

        if(memoryBoothFiles.data && memoryBoothFiles.data.length > 0) {

            setLoading(true)

            let memoryBoothFile = memoryBoothFiles.data[0];

            let webmFile = await fetch(memoryBoothFile.url).then(r => r.blob());

            if(memoryBoothFile.wedding.memoryboothWatermark) {

                let watermark = await fetch(process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length-1) + memoryBoothFile.wedding.memoryboothWatermark.url).then(r => r.blob());
                ffmpeg.FS("writeFile", "watermark.png", await fetchFile(watermark));

                // Start conversion
                ffmpeg.FS("writeFile", "video1.webm", await fetchFile(webmFile));
                await ffmpeg.run(
                    "-fflags",
                    "+genpts",
                    "-i",
                    "video1.webm",
                    "-i",
                    "watermark.png",
                    "-filter_complex",
                    "overlay=x=(main_w-overlay_w):y=(main_h-overlay_h)",
                    "-r",
                    "24",
                    "out.mp4"
                );
            } else {

                // Start conversion
                ffmpeg.FS("writeFile", "video1.webm", await fetchFile(webmFile));
                await ffmpeg.run(
                    "-fflags",
                    "+genpts",
                    "-i",
                    "video1.webm",
                    "-r",
                    "24",
                    "out.mp4"
                );

            }


            let data = ffmpeg.FS("readFile", "out.mp4");
            let uploadFile = new Blob([data.buffer], { type: "video/mp4" })


            data = new FormData();
            data.append('file', uploadFile);

            let fetchConfig = {
                header : {
                    'Content-Type' : 'multipart/form-data'
                }
            }


            await axios.put(process.env.REACT_APP_DOMAIN + 'memory-booth-files/update/'+ memoryBoothFile.id, data, fetchConfig)
                .then(response => {
                    console.clear()
                    processFile()
                    setLoading(false)

                })
                .catch(err => {
                    setLoading(false)
                })



        } else {
            setTimeout(function () {
                processFile()
            }, 5000);
        }

        return;
        /*

        let uploadFile = mediaBlob


            ffmpeg.FS("writeFile", "video1.webm", await fetchFile(mediaBlob));

            // Run the FFmpeg command-line tool, converting
            // the .mp4 into .gif file


            await ffmpeg.run(
                "-i",
                "video1.webm",
                "-c:v",
                "copy",
                "-strict",
                "experimental",
                "out.mp4"
            );



                await ffmpeg.run(
                "-fflags",
                "+genpts",
                "-i",
                "video1.webm",
                "-r",
                "24",
                "out.mp4"
            );


            let data = ffmpeg.FS("readFile", "out.mp4");
            uploadFile = new Blob([data.buffer], { type: "video/mp4" })


        data = new FormData();
        data.append('file', uploadFile);

        let fetchConfig = {
            header : {
                'Content-Type' : 'multipart/form-data'
            }
        }

        //process.env.REACT_APP_DOMAIN
        await axios.put(process.env.REACT_APP_DOMAIN + 'memory-booth-files/'+ fileId, data, fetchConfig)
            .then(response => {
                setLoading(false)
                localStorage.removeItem('name')
                localStorage.removeItem('email')
                history.push("/memorybooth/intro/" + id)
            })
            .catch(err => {
                setLoading(false)
                localStorage.removeItem('name')
                localStorage.removeItem('email')
                history.push("/memorybooth/intro/" + id)
            })

*/


    }





    return (<div className={"mb-recording-container"}>

        {
            loading ?
                <div>Se proceseaza ....</div>
                :
                <div>Se astepta clipuri de procesat</div>
        }

        <br/><br/><br/>

    </div>)
}

