import React, {useState, useEffect} from 'react';
import './MemoryBooth.scss'
import CustomInput from "../../components/custom/CustomInput";
import {Icon} from "rsuite";
import {useParams} from 'react-router'
import {useHistory} from "react-router-dom";
import {toast} from 'react-toastify'
import axios from "axios";
import Modal from "react-responsive-modal";
import SendToModal from "./components/SendToModal/SendToModal";
import QRCode from "react-qr-code";
import Loader from "react-spinners/DotLoader";
import {digiCamControlHost} from "../../const";

export default function IntroScreen() {

    const {id} = useParams();
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [wedding, setWedding] = useState(1)
    const [type, setType] = useState(-1)
    const [secondaryRecipient, setSecondaryRecipient] = useState('')

    const [items, setItems] = useState(false)
    const [previewItem, setPreviewItem] = useState(null)
    const [showGallery, setShowGallery] = useState(false)
    const [itemsLoading, setItemsLoading] = useState(true)

    const [typeError, setTypeError] = useState(false)

    const [printNumber, setPrintNumber] = useState(0)
    const [printLoading, setPrintLoading] = useState(false)

    const [showSendToModal, setShowSendToModal] = useState(false)

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [deleteCode, setDeleteCode] = useState('')

    const [tapsForBack, setTapsForBack] = useState(0)

    const [includeIndividuals, setIncludeIndividuals] = useState(false)

    let history = useHistory();

    const date = new Date(localStorage.getItem('memoryBoothDate'))

    const backgroundImageUrl = localStorage.getItem('memoryBoothBackgroundImage')
    const memoryBoothData = localStorage.getItem('memoryBoothData') ? JSON.parse(localStorage.getItem('memoryBoothData')) : false
    const memoryBoothWedding = localStorage.getItem('memoryBoothWedding') ? JSON.parse(localStorage.getItem('memoryBoothWedding')) : false

    const memoryBoothStandardEvent = localStorage.getItem('memoryBoothStandardEvent') ? JSON.parse(localStorage.getItem('memoryBoothStandardEvent')) : {}

    useEffect(() => {
        localStorage.setItem('id_eveniment', id)
        localStorage.setItem('wedding', '1')
        localStorage.setItem('memoryBoothType', type + '')
        localStorage.setItem('memoryBoothSendTo', '')

        checkTimeLimit()

        //getItems()

        const liveFocusInterval = setInterval(() => {
            liveFocus()
        }, 60000);
        return () => {
            //fetch(digiCamControlHost + '?cmd=LiveViewWnd_Hide').catch((e) => {})
            clearInterval(liveFocusInterval);
        };

    }, [])

    useEffect(() => {
        localStorage.setItem('name', name)
        localStorage.setItem('email', email)
        localStorage.setItem('memoryBoothType', type + '')

    }, [name, email, wedding, type])

    const liveFocus = async () => {
        //live focus
        try {
            fetch(digiCamControlHost + '?cmd=livefocus').catch((e) => {})
        } catch (e) {

        }
    }

    const checkTimeLimit = async () => {
        await axios.post(process.env.REACT_APP_DOMAIN + 'memorybooth/checkTimeLimit', {id: id})
            .then(response => {
                if (response.data.status === 0) {
                    toast.error('Perioada maxima de 12 ore a fost depasita!')
                    history.push("/adminEventPage/" + id)
                }
            })
    }

    const setSendTo = (email, name) => {
        localStorage.setItem('memoryBoothSendTo', email)
        setSecondaryRecipient(name)
    }

    const getItems = async () => {

        setItemsLoading(true)

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            id_eveniment: id
        }

        let info = await axios.post(process.env.REACT_APP_DOMAIN + 'memory-booth-files/get-wedding-files', payload, auth);

        if (info.data) {

            // Set items indexes
            let index=1
            let nonIndividualIndex=1
            let reversedItems = info.data.items.reverse()

            let newItems = []

            for(let i=0; i<reversedItems.length; i++) {

                let item = reversedItems[i]
                item.allIndex = index
                item.nonIndividualIndex = nonIndividualIndex

                newItems.push(item)
                index++

                if(!reversedItems[i].individual) {
                    nonIndividualIndex++
                }

            }


            setItems(newItems.reverse())
            setItemsLoading(false)
        }

    }

    const print = async (url) => {

        if (printLoading) {
            return;
        }

        setPrintLoading(true)

        let dataBlob = false

        await fetch(url)
            .then(res => res.blob()) // Gets the response and returns it as a blob
            .then(blob => {
                dataBlob = blob
            });

        if (!dataBlob) {
            setPrintLoading(false)
            return;
        }

        const data = new FormData();
        data.append('image', dataBlob);
        data.append('copies', printNumber)

        let fetchConfig = {
            header: {
                'Content-Type': 'multipart/form-data'
            }
        }

        //process.env.REACT_APP_DOMAIN
        await axios.post('http://localhost:8000/printImage', data, fetchConfig)
            .then(response => {
                setPrintLoading(false)
                setPrintNumber(0)
                toast.success('Fotografia a fost printată!')
            })
            .catch(err => {
                setPrintLoading(false)
                setPrintNumber(0)
                toast.error('A aparut o eroare!')
            })

    }

    const deleteItem = async (id) => {

        if (deleteLoading) {
            return;
        }

        setDeleteLoading(true)

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            id: id,
            code: deleteCode
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'memory-booth-files/deleteItem', payload, auth)
            .then(response => {
                setDeleteLoading(false)
                setShowDeleteModal(false)
                setDeleteCode('')
                toast.success('Înregistrarea a fost ștearsă!')
                setPreviewItem(null)
                getItems()
            })
            .catch(err => {
                setDeleteLoading(false)
                toast.error('A aparut o eroare!')
            })

    }


    const customInputStyle = {
        margin: "15px 0px 10px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    const customInputStyle2 = {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    return (<div className={"mb-intro-container"}>

        <img src={backgroundImageUrl}
             style={{position: 'absolute', width: '100%', height: '100%', zIndex: 5, top: 0, left: 0}}
             crossOrigin={"true"}/>

        <div className={"mb-gallery-button"} onClick={() => {
            getItems()
            setShowGallery(true)
        }}>
            Deschide galeria
        </div>

        {showGallery ?
            <>
                <div className={"mb-gallery-overlay"} onClick={() => setShowGallery(false)}></div>
                <div className={"mb-gallery-list"}>

                    <div className={"mb-gallery-close"} onClick={() => setShowGallery(false)}>
                        <Icon icon="close" className={"mb-gallery-close-icon"}/>
                    </div>

                    {
                        itemsLoading ?
                            <Loader color={'rgb(0,175,242)'}/>
                            :
                            <div className={"mb-gallery-list-inner"}>

                                <div className={"btn-show-individuals " + (includeIndividuals ? 'active' : '')} onClick={() => {
                                    setIncludeIndividuals(!includeIndividuals)
                                }}>{includeIndividuals ? 'Ascunde individuale' : 'Afișează individuale'}</div>

                                {
                                    items && items.length > 0 && items.map((item, i) => (
                                        (includeIndividuals && (item.individual || !item.individual) || !includeIndividuals && (!item.individual)) ?
                                        <div
                                            key={i}
                                            onClick={() => setPreviewItem(item)}
                                            className={"item"}
                                        >
                                            <div className={"nr"}>

                                                {includeIndividuals ? item.allIndex : item.nonIndividualIndex}.
                                            </div>
                                            <div className={"left"}>
                                                {
                                                    item.url.includes('.gif') || item.url.includes('.png') || item.url.includes('.jpg') ?
                                                        <img src={item.url} style={{width: '100%', height: '100%', objectFit:'cover'}}
                                                             crossOrigin={"true"}/>
                                                        :
                                                        <video
                                                            src={item.url}
                                                            width={'100%'}
                                                            crossOrigin={"true"}
                                                            height={'100%'}
                                                            controls={false}
                                                            autoPlay={true}
                                                            loop={true}
                                                            muted={true}
                                                        />
                                                }
                                            </div>
                                            <div className={"right"}>
                                                {item.name}<br/>
                                                {new Date(item.created_at).toLocaleTimeString()}<br/>


                                                {
                                                    item.url.includes('.gif') ?
                                                        <div className={"mb-gallery-tag-gif"}>GIF</div>
                                                        :
                                                        (item.url.includes('.png') || item.url.includes('.jpg')) ?
                                                            <div className={"mb-gallery-tag-photo"}>Fotografie</div>
                                                            :
                                                            <div className={"mb-gallery-tag-video"}>Video</div>
                                                }

                                            </div>
                                        </div>
                                            :null
                                    ))
                                }

                                <Modal
                                    autoFocus={false}
                                    focusTrapped={false}
                                    classNames={{
                                        overlay: 'modal-overlay mb-gallery-modal-overlay',
                                        modal: 'modal-mb-item',
                                        closeButton: 'modal-close-button',
                                    }} open={previewItem !== null}
                                    onClose={() => {
                                        setPreviewItem(null)
                                        setPrintNumber(0)
                                    }}
                                    onEscKeyDown={() => {
                                        setPreviewItem(null)
                                        setPrintNumber(0)
                                    }}
                                    onOverlayClick={() => {
                                        setPreviewItem(null)
                                        setPrintNumber(0)
                                    }}>

                                    {
                                        previewItem !== null ?
                                            <div className={"mb-gallery-modal-inner"}>
                                                <div className={"left"}>
                                                    Nume: {previewItem.name}<br/>
                                                    Email: {previewItem.email}<br/>
                                                    Data: {new Date(previewItem.created_at).toLocaleString()}<br/>

                                                    <br/>

                                                    <div style={{textAlign: 'center'}}>

                                                        <div style={{maxHeight: '200px'}}>
                                                            <QRCode
                                                                size={200}
                                                                value={"https://servicii.ality.ro/memorybooth/previewFile/" + previewItem.uid}/>
                                                        </div>
                                                        {
                                                            (previewItem.url.includes('.png') || previewItem.url.includes('.jpg')) && memoryBoothWedding && memoryBoothWedding.packages && memoryBoothWedding.packages.wedding_participants &&
                                                            memoryBoothWedding.packages.wedding_participants.package > 0 ?

                                                                (memoryBoothData && (typeof (memoryBoothData.photo.disabled) === undefined || memoryBoothData.photo.disabled !== true)) ?
                                                                <>
                                                                    <br/>

                                                                    <div style={{marginTop: '20px'}}>Câte fotografii
                                                                        doriți să printați ?
                                                                    </div>
                                                                    <div className={"print-type-container"}>
                                                                        <div
                                                                            className={"print-type-item active " + (printNumber === 1 ? 'red' : '')}
                                                                            onClick={() => setPrintNumber(1)}>
                                                                            1
                                                                        </div>

                                                                        <div
                                                                            className={"print-type-item active " + (printNumber === 2 ? 'red' : '')}
                                                                            onClick={() => setPrintNumber(2)}>
                                                                            2
                                                                        </div>

                                                                        <div
                                                                            className={"print-type-item active " + (printNumber === 3 ? 'red' : '')}
                                                                            onClick={() => setPrintNumber(3)}>
                                                                            3
                                                                        </div>

                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className={"mb-gallery-print-button"}
                                                                        onClick={() => print(previewItem.url)}
                                                                        disabled={printLoading || printNumber === 0}
                                                                    >
                                                                        {
                                                                            printLoading ?
                                                                                'Se încarcă ...'
                                                                                :
                                                                                <>
                                                                                    <Icon icon="print"
                                                                                          className={"icon"}/>
                                                                                    Printează
                                                                                </>
                                                                        }
                                                                    </button>
                                                                </>
                                                                    :
                                                                    <div style={{marginTop:'20px'}}>
                                                                        Pentru moment printarea fotografiilor a fost oprită! Vă mulțumim pentru întelegere.
                                                                    </div>
                                                                : null
                                                        }
                                                    </div>

                                                    <div className={'delete-container'}>

                                                        <button
                                                            type="button"
                                                            className={"mb-gallery-delete-button-small"}
                                                            onClick={() => setShowDeleteModal(true)}>
                                                            <Icon icon="trash" className={"icon"}/>
                                                        </button>
                                                        <Modal
                                                            autoFocus={false}
                                                            focusTrapped={false}
                                                            classNames={{
                                                                overlay: 'modal-overlay mb-gallery-modal-overlay',
                                                                modal: 'modal-mb-item',
                                                                closeButton: 'modal-close-button',
                                                            }} open={showDeleteModal}
                                                            onClose={() => {
                                                                setShowDeleteModal(false)
                                                            }}
                                                            onEscKeyDown={() => {
                                                                setShowDeleteModal(false)
                                                            }}
                                                            onOverlayClick={() => {
                                                                setShowDeleteModal(false)
                                                            }}>

                                                            <div style={{
                                                                maxWidth: '500px',
                                                                margin: 'auto',
                                                                padding: '4rem 0'
                                                            }}>
                                                                <div style={{marginTop: '20px'}}>Vă rugăm introduceți
                                                                    codul de siguranță pentru a autoriza ștergerea!
                                                                </div>
                                                                <CustomInput value={deleteCode}
                                                                             onChange={setDeleteCode}
                                                                             noFocusItem={true}

                                                                             inputType={'password'}
                                                                             style={customInputStyle2}
                                                                             placeholder={"Cod"}
                                                                />

                                                                <button
                                                                    type="button"
                                                                    className={"mb-gallery-delete-button"}
                                                                    disabled={deleteCode.length === 0 || deleteLoading}
                                                                    onClick={() => deleteItem(previewItem.id)}>
                                                                    <Icon icon="trash" className={"icon"}/>
                                                                    Șterge înregistrarea
                                                                </button>
                                                            </div>
                                                        </Modal>
                                                    </div>
                                                </div>
                                                <div className={"right"}>
                                                    {
                                                        previewItem.url.includes('.gif') || previewItem.url.includes('.png') || previewItem.url.includes('.jpg') ?
                                                            <img src={previewItem.url}
                                                                 style={{width: '100%', height: '100%'}}
                                                                 crossOrigin={"true"}/>
                                                            :
                                                            <video
                                                                src={previewItem.url}
                                                                width={'100%'}
                                                                crossOrigin={"true"}
                                                                height={700}
                                                                controls={true}
                                                                autoPlay={true}
                                                                loop={true}
                                                                muted={false}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }
                                </Modal>
                            </div>
                    }
                </div>
            </>
            : null}

        <div className={"mb-bg-overlay bottom"} style={{position: 'absolute', zIndex: 5}}>

            <div className={"mb-intro-title-cont"}>
                <h1>{localStorage.getItem('memoryBoothTitle')}</h1>
            </div>

            <div className={"mb-intro-date-cont"}>
                <div
                    className={"date"}>{date.getDate()}.{(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}.{date.getFullYear()}</div>
            </div>

            <div className={"mb-intro-form-cont"}>

                <div style={{maxWidth:'511px'}}>
                <div className={"description"}>
                    Vă invităm să vă înregistrați pentru a trimite gândurile tale.
                </div>


                <CustomInput value={name}
                             onChange={setName}
                             style={customInputStyle}
                             placeholder={"Nume și prenume"}
                             icon={<Icon icon="user" className={"mb-input-icon"}/>}/>


                {
                    memoryBoothStandardEvent && memoryBoothStandardEvent.id ?
                        <div className={"mb-send-to"} onClick={() => setShowSendToModal(true)}>


                            <div className={"left"}>
                                {
                                    secondaryRecipient.length > 0 ?
                                        'Trimite catre ' + secondaryRecipient
                                        :
                                        'Trimite unui coleg sau profesor'

                                }
                            </div>
                            <div className={"right"}><Icon className={"icon"} icon="chevron-right"/></div>

                        </div>
                        : null
                }

                {/*
            <div style={{margin:'0'}}>Completați adresa de email dacă doriți să primiți testimonialul pe email.</div>
            <CustomInput value={email}
                         onChange={setEmail}
                         noFocusItem={true}
                         style={customInputStyle2}
                         placeholder={"Email"}
                         icon={<Icon icon="envelope" className={"mb-input-icon"} />} />
                         */}

                <div style={{marginTop: '50px', marginBottom:'10px', textAlign: 'center'}}>Alegeți tipul testimonialului</div>
                </div>
            </div>
            <div className={"mb-intro-form-cont"}>
                <div className={"mb-type-container " + (typeError ? 'error' : '')}>

                    <div className={"mb-type-item " + (type === 0 ? 'active-red' : '')} onClick={() => {
                        setType(0)
                        setTypeError(false)
                    }}>
                        Video
                    </div>

                    <div className={"mb-type-item " + (type === 1 ? 'active-yellow' : '')} onClick={() => {
                        setType(1)
                        setTypeError(false)
                    }}>
                        GIF
                    </div>

                    {
                        memoryBoothWedding.memorybooth && memoryBoothWedding.memorybooth.photo && memoryBoothWedding.memorybooth.photo.templateDetails ?
                            <div className={"mb-type-item " + (type === 2 ? 'active' : '')} onClick={() => {
                                setType(2)
                                setTypeError(false)
                            }}>
                                Fotografie
                            </div>
                            : null
                    }

                </div>


                <button
                    type={"button"}
                    className={"mb-start-button"}
                    onClick={() => {

                        if (type < 0) {
                            toast.error('Vă rugăm să alegeți tipul testimonialului')
                            setTypeError(true)
                            return
                        }

                        if (name.length > 1) {

                            if (type === 2) {
                                history.push("/memorybooth/photo")
                            } else {
                                history.push("/memorybooth/recording")
                            }

                        } else {
                            localStorage.setItem('name', 'Anonim')
                            //toast.error("Vă rugăm introduceți numele dumneavoastră!")
                            if (type === 2) {
                                history.push("/memorybooth/photo")
                            } else {
                                history.push("/memorybooth/recording")
                            }
                        }
                    }}
                >
                    Start
                </button>

            </div>
            {
                memoryBoothStandardEvent && memoryBoothStandardEvent.id ?
                    <SendToModal
                        show={showSendToModal}
                        setShow={setShowSendToModal}
                        memoryBoothStandardEvent={memoryBoothStandardEvent}
                        setSendTo={setSendTo}
                    />
                    : null
            }
            </div>

        <div className={"back-container"} onClick={() => {
            setTapsForBack(tapsForBack + 1)
        }}>

            {
                tapsForBack > 5 ?
                    <div className={"back-btn"} onClick={() => {
                        setTapsForBack(0)
                        history.push("/adminEventPage/" + id)
                    }
                    }>
                    <Icon icon="close" className={"icon"}/>
                    </div>
                    :
                    null
            }
        </div>




    </div>)
}

