import React from 'react'
import './sectionRender.scss'
export default function SectionRender({section, preview=false}) {



    return (<>
            {
                preview ?
                    <div className={"section-nr"}>{section.id}</div>
                    :null
            }

            {
                section.type === 0 ?
                    <img src={"/images/memorybooth/placeholder.png"}/>
                    :
                    null
            }

            {
                section.type === 1 ?
                    <img src={section.value} crossOrigin={"true"}/>
                    :
                    null
            }
        </>
    )
}