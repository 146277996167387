import React from 'react'
import { Icon, Button } from 'rsuite'
import { toast } from 'react-toastify'
import HeaderWhiteBack from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'
import ResetModal from './ResetModal/ResetPass';
import ResetName from './ResetModal/ResetName'
import axios from 'axios'
import { Modal } from 'rsuite';

import './Profile.scss'

class Profile extends React.Component {
	state = {
		showResetModal: false,
		showNameModal: false,
		showGDPR: false,
		showTC: false,

		username: "",
		name: "",
		surname: "",
		email: "",
		isQrChecker: false,

		loading: true
	}

	componentDidMount() {
		if (localStorage.getItem('jwt') === null) {
			toast.error("Trebuie sa va logati pentru a folosi site-ul")
			this.props.history.push('/login');
			return
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
			.then((response) => {
				let isQrChecker = response.data.role.type === "qr_checker"

				if (isQrChecker) {
					localStorage.setItem("QRChecker", "Checker")
				}

				this.setState({
					username: response.data.username.split("|")[0],
					name: response.data.last_name,
					surname: response.data.first_name,
					email: response.data.email,
					isQrChecker: isQrChecker,
					loading: false
				})
			})
			.catch(err => {
				toast.error("Eroare")
			})
	}

	checkTicket = () => {
		this.props.history.push("/checkTicket")
	}

	changePassword = () => {
		this.setState({
			showResetModal: true,
		})
	}

	changeName = () => {
		this.setState({
			showNameModal: true,
		})
	}

	setNameAndSurname = (name, surname) => {
		this.setState({
			name: name,
			surname: surname
		})
	}

	closeResetModal = () => {
		this.setState({
			showResetModal: false,
		})
	}

	closeNameModal = () => {
		this.setState({
			showNameModal: false,
		})
	}

	toggleDrawerGDPR = () => {
		let current = this.state.showGDPR;
		this.setState({ showGDPR: !current });
	}

	toggleDrawerTC = () => {
		let current = this.state.showTC;
		this.setState({ showTC: !current });
	}

	logOut = () => {
		localStorage.clear();

		this.props.history.push('/Login')
	}

	getInfoRow = (icon, name, text) => {
		return <div className="Row">
			<Icon icon={icon} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />

			<div className="Data">
				<div>{name}</div>

				<p style={{ color: "#121213", fontSize: "16px" }}>{text}</p>
			</div>
		</div>
	}

	getOptionRow = (icon, text, func, color) => {
		return <div className="Row" onClick={func}>
			<Icon icon={icon} size="2x" style={{ margin: "0px 10px", color: color }} />

			<div className="Data">
				{text}
			</div>
		</div>
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading) {
			return <div className="Profile-container">
				<HeaderWhiteBack goBack={this.goBack} />

				<h4 className="Profile-TopText">Profil</h4>

				<div className="Profile-modal"></div>

				<div className="Profile-contents">
					Loading...
				</div>

				<NavbarColor history={this.props.history} active={"Profile"} />
			</div>
		}

		let style = { width: "120vw", marginLeft: "-5vw" };

		if (window.innerWidth >= 768) {
			style = { width: "100vw", marginLeft: "0px" };
		}

		return (
			<div className="Profile-container">
				<HeaderWhiteBack goBack={this.goBack} />

				<h4 className="Profile-TopText">Profil</h4>

				<div className="Profile-modal">
					<ResetModal showModal={this.state.showResetModal} closeModal={this.closeResetModal} email={this.state.email} />

					{this.state.showNameModal && <ResetName showModal={this.state.showNameModal} closeModal={this.closeNameModal} name={this.state.name} surname={this.state.surname} setNameAndSurname={this.setNameAndSurname} />}
				</div>

				<div className="Profile-contents">
					<Modal full style={style} backdrop={true} show={this.state.showTC} onHide={this.toggleDrawerTC}>
						<Modal.Header>
							<Modal.Title> Termeni si Conditii - Ality - </Modal.Title>
						</Modal.Header>

						<Modal.Body >
							<div >
								<p>CREATIVE ALITY SRL</p>

								<br /><br />
								<h3> 1. Preambul </h3>
								<p>1.1 Site-ul Ality (www.Ality.ro) și aplicația Ality pentru smartphone-uri, denumite în continuare „Site-ul”/”Aplicația” (folosirea oricăreia dintre aceste denumiri se referă și implică atât site-ul cât și aplicația Ality), sunt deținute și administrate de Creative Ality SRL având următoarele date de identificare: Cod Unic de Înregistrare 39173082, înregistrată la Registrul Comerțului cu nr. J03/837/2021 și cu sediul social în Municipiul Câmpulung, Strada CARPAŢI, Nr. 60, Camera nr.2, Judet Argeş.</p>
								<br /><br />
								<p>1.2 Înainte de a utiliza site-ul www.Ality.ro și serviciile pe care le oferim, este necesar să luați cunoștință de prevederile prezenților termeni și condiții și să le respectați.</p>
								<br /><br />
								<p>1.3 Navigarea site-ului echivalează cu citirea, înțelegerea și acceptarea completă a termenilor de mai jos de către orice Utilizator. Dacă nu ești de acord cu orice aspect al prezenților termeni și condiții atunci nu puteți accesa Aplicația.</p>
								<br /><br />
								<p>1.4 Beneficierea de serviciile noastre prin intermediul site-ului se va putea face doar dacă sunteți de acord cu termenii și condițiile descrise mai jos.</p>
								<br /><br />
								<p>1.5 Creative Ality SRL are dreptul de a efectua orice modificări ale site-ului, termenilor și condițiilor, fără a fi necesară vreo notificare prealabilă față de vizitatorii site-ului.</p>
								<br /><br />
								<p>1.6 Creative Ality SRL își rezervă dreptul de a ceda/autoriza accesul/folosirea site-ului de către alți operatori (Terți autorizați) în vederea oferirea serviciilor către Utilizatori de către Terții autorizați. În acest  caz, cedarea/autorizarea accesului/folosirii site-ului de către Terții autorizați, denumirea și datele lor de contact vor fi anunțate prin intermediul site-ului. În funcție de cedarea sau autorizarea parțială / integrală, Terții autorizați vor fi cei care își asumă oferirea serviciilor către Utilizatori, raportul juridic născându-se între Utilizator și respectivul Terț autorizat, caz în care Creative Ality SRL va avea doar rolul de a oferi în beneficiul Terțului autorizat  servicii de mentenanță pentru asigurarea funcționării site-ului.</p>
								<br /><br />
								<p>1.7 Orice solicitare cu privire la înțelesul și aplicarea prezenților termeni și condiții va fi adresată către adresa de e-mail: ...</p>

								<br /><br />
								<h3> 2.Definiții </h3>
								<div> 2.1 Creative Ality SRL  stabilește că termenii enumerați mai jos vor avea următorul sens:
									<ul>
										<li> Furnizor - Creative Ality S.R.L, societate comercială cu sediul în Municipiul Câmpulung, Strada CARPAŢI, Nr. 60, Camera nr.2, Judet Argeş, înregistrată la Registrul Comerțului cu nr. J03/837/2021 și CUI 44034951.</li>
										<li> Terț autorizat - orice persoană autorizată de Furnizor să controleze și să aibă acces la site și să ofere Servicii în favoarea Utilizatorilor.</li>
										<li> Vizitator - persoană care accesează acest site.</li>
										<li> Utilizator - persoană fizică care vizitează acest site și care a acceptat Termenii și Condițiile de mai jos, îndeplinind în acest sens toate cerințele procesului de înregistrare.</li>
										<li> Conținut - toate programele software, fișierele, datele, imaginile, muzica, grafica sau orice alt conținut al www.Ality.ro sau asociat cu site-ul sau brand-ul Ality.</li>
										<li> Serviciu - acordarea Utilizatorului a accesului la funcționalitățile/datele transmise de Creative Ality SRL sau terțul autorizat cu ajutorul acestui site.</li>
										<li>  Username (nume de utilizator) - pseudonim prin care Utilizatorul poate adăuga Conținut pe Site. Username-ul este asociat informațiilor din Site sub denumirea de “Nume Utilizator”.</li>
										<li> Contract - contract de utilizare prin care  Creative Ality S.R.L, sau Terțul autorizat pune la dispoziție utilizatorului dreptul neexclusiv de a folosi conținutul site-ului  www.Ality.ro.</li>
										<li> Cont Utilizator - secțiunea din Site formată dintr-o adresă de e-mail/număr de telefon și o parolă care permite Utilizatorului încărcarea unei fotografii de tip selfie și care oferă acces la fotografii cu imaginea Utilizatorului realizate în cadrul unor evenimente la care a participat Utilizatorul și organizate de Terțul autorizat.</li>
										<li> Site/Aplicație - site-ul internet  www.Ality.ro și/sau aplicația Ality pentru smartphone-uri.</li>
									</ul>
								</div>

								<br /><br />
								<h3> 3. Obligațiile părților </h3>
								<h5>3.1 Obligațiile  Creative Ality S.R.L </h5>
								<p>3.1.1 În limita legislației în vigoare, a prezentului document și relației contractuale cu Terții autorizați,  Creative Ality SRL se obligă să ofere suport și informații legate de serviciile oferite pe acest Site.</p>
								<br /><br />
								<h5>3.2 Obligațiile Utilizatorului</h5>
								<p>3.2.1 Utilizatorul se obligă să respecte prezentele condiții și termeni de mai jos, legislația existentă în România și să acționeze cu bună-credință în relația cu Creative Ality SRL</p>
								<br /><br />
								<p>3.2.2 Utilizatorul are obligația de a aduce la cunoștința furnizorului prin intermediul adresei puse la dispoziție la art. 1.6  orice situație care afectează modul în care site-ul își desfășoară activitatea și orice tentativă de fraudare identificată.</p>
								<br /><br />
								<p>Utilizatorul se obligă să nu modifice, distribuie, transmită, publice, reproducă, creeze produse derivate, transfere sau vândă orice fel de informații sau servicii obținute de pe sau prin intermediul acestui site.</p>
								<br /><br />
								<p>3.2.4 Utilizatorul se obligă să nu folosească și să nu permită vreunui terț să folosească serviciul oferit de Creative Ality SRL pentru a transmite sau a recepționa materiale care contravin dispozițiilor legale în vigoare sau care au un conținut obscen, abuziv, vulgar, defăimător, ofensator, care încalcă orice alt drept al oricărei persoane sau aduce atingere drepturilor de proprietate intelectuală.</p>
								<br /><br />
								<p>3.2.5 Utilizatorul se obligă să nu desfășoare și să nu permită activitatea de accesare a sistemului informatic. Utilizatorii care violează securitatea sistemelor sau a rețelelor vor fi sancționați în conformitate cu legislația în vigoare și cu oprirea temporară sau definitivă a accesului în Aplicație.</p>

								<br /><br />
								<h3>4. Comunicările electronice</h3>
								<p>4.1 Creative Ality SRL își rezervă dreptul de a comunica Utilizatorilor, prin mijloace electronice, orice informații consideră de cuviință cu privire la activitatea generală a Site-ului.</p>
								<br /><br />
								<p>4.2 Creative Ality SRL poate realiza comunicări către Utilizatori, fie în mod direct, fie prin intermediul partenerilor specializați și agreați pentru asigurarea confidențialității și securității informațiilor.</p>
								<br /><br />
								<p>4.3 Utilizatorul va primi comunicări în modalitate electronică de la Creative Ality SRL numai dacă acesta și-a exprimat în mod prealabil consimțământul în legătură cu primirea unor astfel de notificări / comunicări electronice. Din când în când Furnizorul poate notifica Utilizatorilor modificări semnificative ale Aplicației sau termenilor și condițiilor.</p>
								<br /><br />
								<div>4.4 Utilizatorul își poate modifica opțiunea cu privire la primirea de comunicări electronice din partea Creative Ality SRL în orice moment, prin următoarele mijloace:
									<ul>
										<li> Prin contactarea Site-ului la adresa indicată la art. 1.6.</li>
										<li> Prin modificarea setărilor din contul de Utilizator.</li>
										<li> Prin accesarea link-ului de dezabonare afișat în mesajele electronice primite de la Creative Ality SRL</li>
										<li> Renunțarea Utilizatorului de a mai primi orice fel de comunicare electronică din partea Furnizorului nu îl exonerează de respectarea prezenților termeni și condiții și nici nu este aplicabilă cu privire la comunicarea actualizării termenilor și condițiilor.</li>
									</ul>
								</div>
								<br /><br />
								<p>4.6 Utilizatorul înțelege că va fi informat în mod automat cu privire la modificarea termenilor și condițiilor de utilizare a site-ului sau a apariției unor evenimente majore în funcționarea Aplicației, această comunicare electronică făcându-se în baza acceptării inițiale a acestor termeni și condiții.</p>

								<br /><br />
								<h3>5. Drepturi de proprietate intelectuală și industrială </h3>
								<p>5.1 Întregul conținut al Site-ului, incluzând, orice fel de material purtător de drepturi de proprietate intelectuală aparțin sau se află sub licența Creative Ality SRL și sunt protejate de lege. Folosirea, fără acordul scris al Creative Ality SRL, a oricăror elemente enumerate mai sus va fi sancționată conform legilor în vigoare.</p>
								<br /><br />
								<p>5.2 Accesul la informațiile conținute pe Site garantează Utilizatorului acces limitat, în interes personal, pe acest site și nu îi conferă dreptul de a descărca sau de a modifica parțial sau integral site-ul, de a reproduce parțial sau integral site-ul, de a copia, de a vinde sau revinde, sau de a exploata site-ul în orice altă manieră, în scopuri comerciale sau contrare intereselor Creative Ality SRL, fără acordul scris al acesteia.</p>
								<br /><br />
								<p>5.3 Orice reproducere parțială sau totală a materialelor purtătoare de drepturi de autor ce figurează pe Site se va face doar cu acordul prealabil, scris al Creative Ality SRL</p>
								<br /><br />
								<p>5.4 Orice material purtător de drepturi de proprietate intelectuală transmis către Utilizator nu reprezintă transferul vreunui drept cu privire la respectivele materiale.</p>
								<br /><br />
								<p>5.5 Utilizatorul are dreptul neexclusiv de a utiliza serviciile puse la dispoziție de Creative Ality SRL, iar acesta nu poate transmite dreptul de utilizare unui alte persoane.</p>
								<br /><br />
								<p>5.6 Răspunderea pentru încărcarea pe site de către Utilizator a oricăror fotografii de tip selfie/date/documente îi aparține Utilizatorului, acesta despăgubind Furnizorul pentru orice prejudicii cauzate acestuia prin încălcarea oricăror drepturi de autor și/sau de imagine ce deținute de alte persoane asupra materialelor încărcate de către Utilizator.</p>

								<br /><br />
								<h3>6. Politici de utilizare a Site-ului</h3>
								<h5>6.1 Utilizarea Site-ului</h5>
								<p>6.1.1 Accesul pe Site este permis tuturor persoanelor fără niciun fel de restricții, atâta vreme cât acestea au acceptat prezenții termeni și condiții. Pentru motive justificate,  Creative Ality SRL își rezervă dreptul de a restricționa accesul persoanelor pe site în cazul în care se consideră că în baza conduitei sau a activității Utilizatorului, acesta ar putea prejudicia în vreun fel Creative Ality SRL În cazul în care folosirea site-ului este cedată Terțului autorizat, accesul utilizatorilor la acest Site se va face în funcție de decizia Terțului autorizat. Destinatarul Serviciilor disponibile pe site nu sunt persoane cu vârsta sub 16 ani. Administratorul de date cu caracter personal nu prevede colectarea deliberată a datelor cu privire la persoanele sub 16 ani.</p>
								<br /><br />
								<p>6.1.2 Comunicarea între Creative Ality SRL și Utilizator se va putea face prin orice mijloc de comunicare existent la acest moment sau care va fi inventat. Creative Ality SRL are dreptul de a gestiona oricum consideră de cuviință metodele de comunicare cu Utilizatorii și are libertatea de a gestiona oricum consideră necesar informațiile primite.</p>
								<br /><br />
								<p>6.1.3 În cazul în care se detectează trafic neobișnuit provenit din partea unei rețele de internet, Creative Ality SRL își rezervă dreptul de a introduce orice mecanism de validare a existenței unei persoane reale în spatele fiecărui Utilizator al Site-ului.</p>
								<br /><br />
								<p>6.1.4 Utilizatorii Site-ului pot face comentarii și orice alte comunicări, pot să transmită sugestii, întrebări sau informații, dacă limbajul acestora este civilizat, iar conținutul comunicărilor nu este ilegal, obscen, amenințător, defăimător, nu tulbură în niciun fel viața privată a altor persoane, nu încalcă drepturile de proprietate intelectuală, nu instigă la ură rasială, nu instigă la discriminare de orice fel, nu conține viruși, nu servește unor campanii de promovare care nu au legătură cu Site-ul, nu sunt e-mailuri în masă ori orice altă formă de spam.</p>
								<br /><br />
								<p>6.1.5 Dacă în îndeplinirea procesului de înregistrate pe Site, Creative Ality SRL sau Terțul autorizat constată că Utilizatorii furnizează informații incomplete, inexacte, fotografii de tip selfie inadecvate sau dacă aleg un username/ nume de utilizator neadecvat, Creative Ality SRLRL are dreptul de a suspenda sau închide contul Utilizatorului și de a refuza folosirea curentă sau pe viitor a Site-ului.</p>
								<br /><br />
								<p>6.1.6 Site-ul nu își asumă niciun fel de răspundere față de prejudiciile generate ca urmare a situației descrise la art. 6.1.5.</p>
								<br /><br />
								<h5>6.2 Înregistrarea contului de Utilizator</h5>
								<p>6.2.1 Pentru ca Utilizatorul să poată participa la serviciile oferite prin Site-ul www.Ality.ro, acesta trebuie să își creeze un cont pe Site.  Pentru a vă înregistra Contul de utilizator, va trebui să trimiteți anumite informații personale către Ality. Informații detaliate legate de datele cu caracter personal pe care trebuie să ni le împărtășiți și despre modul în care gestionăm datele dvs. personale, le puteți găsi în cadrul Politicii de prelucrare a datelor cu caracter personal disponibilă la adresa web …. . Sunteți responsabil pentru toate activitățile desfășurate prin intermediul Contului dvs. de utilizator și puteți deschide doar un singur cont Ality.</p>
								<br /><br />
								<p>6.2.2 În vederea înregistrării contului, Utilizatorul este obligat să pună la dispoziție date verificabile și conforme cu realitatea. Creative Ality SRL poate refuza cererea de înregistrate a contului în situațiile în care constată că sunt utilizate date neconforme sau sunt încălcate prevederile prezenților termeni și condiții.</p>
								<br /><br />
								<p>6.2.3 Cu ocazia înregistrării, vă alegeți un username sub care participați la serviciile oferite pe Site, username care nu este obligatoriu să conțină numele dumneavoastră complet sau real.</p>
								<br /><br />
								<p>6.2.4 Dacă se constată că username-ul ales este neadecvat, de exemplu s-a folosit un limbaj obscen, jignitor sau se încalcă un drept de proprietate intelectuală, Creative Ality SRL are dreptul de a refuza cererea de înregistrare a contului. </p>
								<br /><br />
								<p>6.2.5 Contul de utilizator nu se poate înregistra fără furnizarea următoarelor elemente: nume, prenume, username, adresă de e-mail sau număr de telefon.</p>
								<br /><br />
								<p>6.2.6 Utilizatorul răspunde de veridicitatea datelor introduse în timpul înregistrării contului și pentru toate activitățile care survin prin accesarea contului și parolei personale. Creative Ality SRL nu poate fi făcută responsabilă pentru erorile survenite în urma neglijenței utilizatorului privind securitatea și confidențialitatea contului și parolei sale.</p>
								<br /><br />
								<p>6.2.7 Dacă Utilizatorul suspectează că securitatea contului său a fost compromisă acesta trebuie să contacteze Creative Ality SRL la adresa de e-mail indicată la art. 1.6 și să facă demersuri pentru a schimba parola contului său sau să solicite blocarea accesului persoanei care îi accesează Site-ul folosind contul său fără drept.</p>
								<br /><br />
								<p>6.2.8 Site-ul nu poate fi accesat de Utilizator decât în condițiile în care acceptă prezenții termeni și condiții și politica de prelucrare a datelor cu caracter personal și eventualele actualizări ale acestora. Fără a exista acceptul Utilizatorului asupra ultimei variante a termenilor și condițiilor site-ului și a acordului de prelucrare a datelor cu caracter personal, accesul nu va fi permis.</p>
								<br /><br />
								<p>6.2.9 Prin înregistrarea ca Utilizator pe site, este exprimat și acordul expres al persoanei de a fi informat prin mijloace de comunicare electronică pentru orice actualizare a termenilor și condițiilor site-ului și ale politicii de prelucrare a datelor cu caracter personal.</p>
								<br /><br />
								<div>6.2.10 Pentru înregistrarea contului de Utilizator în vederea utilizării aplicației de recunoaștere facială prin intermediul căreia sunt identificate și trimise utilizatorilor fotografiile unde au fost etichetați la diferite evenimente, trebuie urmate următoarele etape concrete:
									<ul>
										<li>Pasul 1 - Utilizatorul scanează un cod QR specific evenimentului la care se află;</li>
										<li>Pasul 2 - Utilizatorul își introduce în aplicație fie numărul de telefon, fie adresa de email validă;</li>
										<li>Pasul 3 - Utilizatorul realizează un selfie, urmând indicațiile din cadrul aplicației. Selfie-ul trece printr-un proces automat de verificare a pozei pentru asigurarea că fotografia este în standarde optime pentru recunoaștere facială.</li>
										<li>Utilizatorul primește un email/SMS de confirmare ce indică înregistrarea în sistem.</li>
									</ul>
								</div>
								<br /><br />
								<p>6.2.11 Utilizatorul poate închide utilizarea contului prin simplul fapt al întreruperii serviciilor Ality.</p>

								<br /><br />
								<h3>7. Serviciile oferite și modul de funcționare</h3>
								<p>7.1. Utilizatorul folosește tehnologia Ality pentru a avea acces la fotografiile în care este identificat în funcție de imaginea facială, realizate la diferite evenimente. Pentru a accesa această funcționalitate, este necesar ca între Furnizor sau Terțul autorizat și organizatorul evenimentului / fotograful care realizează respectivele fotografii să existe o relație contractuală care să permită accesul la fotografiile realizate cu Utilizatorul. Pentru a verifica dacă evenimentul la care participă presupune accesarea gratuită a acestei aplicații, Utilizatorul va verifica codul QR specific evenimentului prin intermediul aplicației.</p>
								<br /><br />
								<p>7.2.  Raportul juridic se creează între Utilizator și organizatorul evenimentului sau persoana care a angajat serviciile fotografului care realizează fotografiile.</p>
								<br /><br />
								<p>7.3. Odată ce fotografii pun la dispoziția Furnizorului fotografiile realizate la un anumit eveniment, algoritmul de recunoaștere facială pe baza căruia funcționează aplicație, va determinarea crearea unei pagini separate cu toate fotografiile în care a fost identificat Utilizatorul, iar Utilizatorul va primi un link pe email/SMS, în funcție de datele furnizate la crearea contului, pentru a putea accesa pagina unde sunt stocate fotografiile respective. Fotografiile vor fi stocate la adresa de URL a paginii respective timp de 7 zile calendaristice.</p>
								<br /><br />
								<p>7.4. Furnizorul nu își asumă răspunderea pentru calitatea fotografiilor realizate de fotografi, aplicația având doar rolul de identificare și centralizare a fotografiilor în care sunt este etichetat facial fiecare Utilizator.</p>
								<br /><br />
								<p>7. 5. Detectarea feței și recunoașterea feței sunt procesate prin algoritmul prin care funcționează aplicația sunt stocate și prelucrate cu ajutorul unor servicii de tip Cloud.</p>

								<br /><br />
								<h3>8. Confidențialitate</h3>
								<p>8.1  Creative Ality S.R.L va păstra confidențialitatea informațiilor de orice natură pe care Utilizatorul le furnizează sau pe care le primește de la organizatorul de evenimente sau Terțul autorizat. Dezvăluirea informațiilor se va putea face doar în condițiile menționate în prezentul Contract.</p>
								<br /><br />
								<p>8.2 Nicio declarație publică, promovare, comunicat sau orice alt mod de comunicare către terțe persoane nu va fi făcută de Utilizator cu privire la prezentul document fără consimțământul prealabil scris al Creative Ality SRL</p>
								<br /><br />
								<p>8.3 Prin transmiterea de materiale sau informații prin intermediului Site-ului, Utilizatorul acordă acces asupra acestora în vederea oferirii Serviciilor, precum și partajarea acestor informații/date cu Terțul autorizat.</p>

								<br /><br />
								<h3>9. Răspundere </h3>
								<p>9.1 Prin crearea Contului și / sau vizitarea Site-ului, Utilizatorul acceptă în mod expres și fără echivoc Termenii și condițiile Site-ului în ultima versiune actualizată care este comunicată în cadrul Site-ului, existentă la data creării Contului și / sau utilizării conținutului.</p>
								<br /><br />
								<p>9.2 Ulterior creării Contului utilizarea sau vizitarea Site-ului echivalează cu acceptarea modificărilor intervenite asupra Termenilor și Condițiilor Site-ului și/sau a versiunilor actualizate ale Termenilor și Condițiilor Site-ului.</p>
								<br /><br />
								<p>9.3 Termenii și Condițiile Site-ului pot fi modificate oricând de către  Creative Ality SRL, aceștia fiind opozabili Utilizatorilor de la data afișării pe Site.</p>
								<br /><br />
								<p>9.4 În cazul în care Utilizatorul încalcă dispozițiile prezentului document  Creative Ality SRL are dreptul de a bloca sau limita temporar accesul acestuia la Site fără vreo notificare prealabilă. Dacă încălcările au un caracter continuu, Furnizorul va bloca permanent accesul Utilizatorului.</p>
								<br /><br />
								<p>9.5 Creative Ality SRL nu este responsabil pentru eventualele întreruperi, blocări, indisponibilități, viruși, erori sau omisiuni apărute în timpul accesării aplicației și care nu sunt generate de serviciile oferite de acesta.</p>
								<br /><br />
								<p>9.6. Furnizorul poate schimba, suspenda sau întrerupe serviciile oferite fără vreo notificare prealabilă.</p>
								<br /><br />
								<p>9.7. Furnizorul nu va fi responsabil pentru relația dintre Utilizator și Terțul autorizat/organizatorul de evenimente/fotograf, raportul juridic născându-se direct între Utilizator și Terțul autorizat, Furnizorul fiind responsabil doar în raport de Terțul autorizat conform contractului încheiat între aceștia.</p>
								<br /><br />
								<p>9.8. Utilizatorul își asumă utilizarea contului Ality cu bună-credință și în conformitate cu toate legile și reglementările aplicabile. Nu va utiliza serviciile noastre pentru a se angaja în activități ilegale.</p>
								<br /><br />
								<p>9.9 Utilizatorul garantează Furnizorul că toate informațiile pe care le furnizați în legătură cu serviciile sunt adevărate, complete, legale și exacte.</p>
								<br /><br />
								<p>9.10. Utilizatorul este de acord să despăgubească Creative Ality S.R.L și cesionarii, directorii, angajații și agenții săi în cazul oricăror creanțe, pierderi, daune și cheltuieli (inclusiv onorariile avocaților) care decurg din sau în legătură cu: (i) utilizarea de către Utilizator a Serviciilor oferite; (ii) nerespectarea sau încălcarea oricăruia dintre acești Termeni sau (iii) încălcarea drepturilor oricărei terțe părți.</p>
								<br /><br />
								<p>9.11. Nu puteți atribui, în totalitate sau parțial, acest acord unei persoane sau entități.</p>
								<br /><br />
								<p>9.12. Trebuie să obțineți sfaturi profesionale sau de specialitate înainte de a lua sau de a vă abține de la orice acțiune pe baza conținutului de pe site-ul nostru, dacă nu sunteți sigur de anumite aspecte. Utilizatorul este responsabil pentru actualizarea datelor din contul de utilizator imediat ce apar modificări. Creative Ality S.R.L nu își asumă nicio responsabilitate pentru orice daune de orice fel cauzate de această încălcare a obligației.</p>
								<br /><br />
								<p>9.13. Având în vedere dependența serviciilor oferite de accesul la Internet, eventualele limitări tehnice și de sistem care pot apărea, Creative Ality S.R.L își asumă doar obligații de diligență cu privire la disponibilitatea serviciilor furnizate. Nicio transmisie de date prin Internet nu poate fi garantată sută la sută.</p>
								<br /><br />
								<p>9.14. Dacă la un moment dat site-ul nostru va conține link-uri către alte site-uri și resurse furnizate de terți, aceste link-uri sunt furnizate doar pentru informarea dvs. Astfel de linkuri nu trebuie interpretate ca aprobarea de către noi a acelor site-uri web conectate sau a informațiilor pe care le puteți obține de la acestea. Nu avem control asupra conținutului acestor site-uri sau resurse.</p>

								<br /><br />
								<h3>10. Soluționare conflictelor. Legea aplicabilă</h3>
								<p>10.1 Prezentul contract este guvernat de legea română.</p>
								<br /><br />
								<p>10.2 În caz de divergență sau neînțelegeri între  LYRA NAPKIN S.R.L și Utilizator, se vor aplica Termenii și Condițiile valabile la momentul utilizării Site-ului.</p>
								<br /><br />
								<p>10.3 În cazul unor eventuale conflicte între  LYRA NAPKIN S.R.L și Utilizatorii săi, se va încerca mai întâi rezolvarea acestora pe cale amiabilă. Dacă rezolvarea pe cale amiabilă nu va fi posibilă, conflictul va fi soluționat în instanță, în conformitate cu legile române în vigoare.</p>

								<br /><br />
								<h3>11. Forța majoră</h3>
								<p>11.1 Niciuna din părți nu va fi răspunzătoare pentru neexecutarea obligațiilor contractuale dacă o astfel de neexecutare este datorată unui eveniment de forță majoră. Forța majoră este un eveniment extern, imprevizibil, în afara controlului părților și care nu poate fi evitat.</p>
								<br /><br />
								<p>11.2 Părțile îți vor aduce la cunoștință de îndată cazul de forță majoră și vor lua toate măsurile necesare în vederea limitării consecințelor evenimentului.</p>
								<br /><br />
								<p>11.3 Dacă în termen de 15 zile evenimentul de forță majoră nu încetează, părțile au dreptul de a denunța unilateral contractul fără a pretinde daune-interese.</p>

								<h3>12. Dispoziții finale</h3>
								<p>12.1 Dacă oricare dintre clauzele de mai sus va fi găsită nulă sau nevalidă, acest lucru nu va afecta valabilitatea celorlalte clauze.</p>
							</div>
						</Modal.Body>

						<Modal.Footer>
							{/* <Button onClick={this.toggleDrawerTC} appearance="primary">
								Accept
							</Button> */}

							<Button onClick={this.toggleDrawerTC} appearance="subtle">
								Inapoi
							</Button>
						</Modal.Footer>
					</Modal>

					<Modal full style={style} backdrop={true} show={this.state.showGDPR} onHide={this.toggleDrawerGDPR}>
						<Modal.Header>
							<Modal.Title> Politica de confidentialitate </Modal.Title>
						</Modal.Header>

						<Modal.Body >
							<div  >
								<p> Ultima modificare: 03 Martie 2020 </p>

								<br /><br />
								<h5> Părţile contractante </h5>
								<p> La Ality.ro si pe aplicatia “Ality”, acordam o importanță foarte mare protejării și confidențialității datelor. Scopul acestei politici de confidențialitate este de a face Ality.ro si aplicatia “Ality”” sigură și potrivită pentru toată lumea. Politica noastră de confidențialitate se aplică utilizării de către dvs. a serviciilor Ality.ro si a aplicatiei “Ality” și descrie tipul de informații pe care le procesăm, modul în care sunt procesate și de ce sunt procesate.
									<br /><br />
									Ality.ro si aplicatia “Ality”” este deținut de către compania Lyra Napkin S.R.L. In cadrul aplicației oferim servicii de management și automatizare al participanților la evenimente. Ca urmare a acestui fapt, procesăm datele personale, ca intermediar, în numele clientului nostru, compania organizatoare a evenimentului.
								</p>

								<h5> <br /><br />1. Definiții </h5>
								<p> 1.1. "Controlor" înseamnă o entitate care determină scopurile și mijloacele de prelucrare a datelor cu caracter personal.
									<br /><br />
									1.2. "Client" înseamnă o persoană sau o entitate care finalizeaza procesul de inregistrare pe Ality.ro si pe aplicatia “Ality” pentru a utiliza Serviciile.
									<br /><br />
									1.3. "Legile privind protecția datelor" înseamnă toate legile și regulamentele privind protecția datelor și confidențialitatea din UE, SEE și statele membre ale acestora, Elveția și Regatul Unit, aplicabile procesării datelor cu caracter personal.
									<br /><br />
									1.4. "GDPR" înseamnă Regulamentul (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și libera circulație a acestor date și de abrogare a Directivei 95 / 46 / CE (Regulamentul general privind protecția datelor).
									<br /><br />
									1.5. "Date personale" înseamnă orice informație referitoare la o persoană fizică identificată sau identificabilă.
									<br /><br />
									1.6. "Procesare" înseamnă orice operațiune sau set de operațiuni care se efectuează asupra datelor cu caracter personal, cu sau fără mijloace automate, cum ar fi colectarea, înregistrarea, organizarea, stocarea, adaptarea sau modificarea, recuperarea, consultarea, utilizarea, dezvăluirea prin transmitere, diseminarea sau punerea la dispoziție în alt mod, alinierea sau combinarea, blocarea, ștergerea sau distrugerea. "Procesul", "Procesele" și "Procesul" vor fi interpretate în mod corespunzător.
									<br /><br />
									1.7. "Procesor" înseamnă o persoană fizică sau juridică, o autoritate publică, o agenție sau orice alt organism care prelucrează date cu caracter personal în numele controlorului.
									<br /><br />
									1.8. "Servicii" înseamnă orice produs sau serviciu furnizat de Ality.ro si pe aplicatia “Ality” în conformitate cu Termenii și condițiile Ality.ro si pe aplicatia “Ality” (Terms of Service)
									<br /><br />
									1.9. "Participant" înseamnă o persoană ce participă la evenimentele Clientului prin intermediul Serviciilor noastre.
									<br /><br />
									1.10. "Noi", "noi" și "nostru" se referă la Ality.ro si la aplicatia “Ality””.
								</p>

								<h5> <br /><br />2. Consimțământul </h5>
								<p> Prin accesarea serviciilor noastre, acceptați Politica noastră de Confidențialitate, Termenii și Condițiile de Utilizare și sunteți de acord cu colectarea, stocarea, utilizarea și dezvăluirea informațiilor dvs. personale așa cum este descris în această politică de confidențialitate. În plus, prin utilizarea serviciilor noastre, acceptați politicile și practicile descrise în această Politică de Confidențialitate. De fiecare dată când vizitați site-ul nostru sau folosiți Serviciul și oricând ne furnizați în mod voluntar sau involuntar informații, sunteți de acord cu colectarea, utilizarea și dezvăluirea informațiilor pe care le furnizați și sunteți de acord să primiți e-mailuri sau sa fiti contactati prin intermediul aplicatiilor de mesagerie sau telefonic, așa cum este descris în această Politică de Confidențialitate. Indiferent dacă vă înregistrați sau nu vă creați un cont, această Politică de confidențialitate se aplică tuturor utilizatorilor site-ului și serviciilor. </p>

								<h5> <br /><br />3. Ce informații procesăm </h5>
								<p> Ality.ro si aplicatia “Ality” colectează atât datele personale cât și datele non-personale despre dvs. și va procesa aceste date pe durata Serviciilor, așa cum este descris în Termenii și Condițiile de Utilizare:
									<br /><br />
									3.1. Date personale:
									<br /><br />
									Informații despre clienți: datele de identificare și de contact (nume, numar de telefon, email, nume de utilizator), informații despre organizație (numele, adresa, locația geografică, zona de responsabilitate, codul TVA), adresele IP de pe care se acceseaza serviciile noastre.
									<br /><br />
									Informații despre participanți: datele de identificare și de contact (numele, ocupația sau alte informații demografice, adresa, datele de contact, inclusiv adresa de e-mail), interesele personale sau preferințele (inclusiv istoricul achizițiilor, informații despre profil); Informații IT (adrese IP, date de utilizare, cookie-uri, date de navigație pe paginile noastre online, date despre locație, date despre browser).
									<br /><br />
									În orice moment, puteți să vă conectați și să modificați sau eliminati datele personale de identificare. Puteți refuza furnizarea de date cu caracter personal serviciilor, însă unele dintre datele personale pe care vi le solicităm să le furnizați, de exemplu, adresa de e-mail,numele,prenumele, sunt obligatorii pentru utilizarea serviciului. Dacă refuzați să le furnizați, ne rezervam dreptul de a nu presta serviciul.
									<br /><br />
									3.2. Date non-personale:
									<br /><br />
									Informații privind browserul și dispozitivul: locația, tipul dispozitivului, tipul de browser sau sistemul de operare, ora și data accesului, activitatea de navigare, rezoluția ecranului, programele de completare și versiunea serviciilor pe care le utilizați , și alte informații care nu vă identifică personal.
									<br /><br />
									Informații din utilizarea de către dvs. a serviciilor și a informațiilor din alte surse: este posibil să primim informații despre cum și când utilizați serviciile, să le stocăm în fișierele jurnal sau în alte tipuri de fișiere asociate contului dvs. și să le conectăm la alte informații pe care le colectăm despre dvs. De asemenea, putem obține informații despre dvs. sau abonații dvs. din surse terțe, cum ar fi bazele de date publice, platformele sociale media, furnizorii de date terță parte și partenerii noștri de marketing în comun. Aceste informații pot include, de exemplu, ora, data, browserul folosit, informațiile demografice, informațiile despre dispozitiv (cum ar fi tipul dispozitivului, tipul de browser sau sistemul de operare, adresele IP) despre folosirea site-urilor de social media, informații despre afișarea paginilor și rezultatele căutării și legăturile), acțiunile pe care le-ați luat, datele de utilizare anonime, paginile de trimitere / ieșire și adresele URL, preferințele pe care le trimiteți și preferințele generate pe baza datelor trimise și numărului de clicuri.
								</p>

								<h5> <br /><br />4. Scopurile procesării </h5>
								<p>
									4.1. Ality.ro si aplicatia “Ality” utilizează informațiile colectate pentru funcționarea Serviciilor, pentru a menține calitatea Serviciului, pentru a furniza statistici generale privind utilizarea Serviciului, pentru a promova și analiza Serviciile, pentru a oferi suport clienților și a trimite informații despre Servicii, pentru a proteja drepturile și siguranța clienților, a abonaților, a terților și a noastră, să trimită conținut informativ sau promoțional personalizat și să furnizeze sugestii în funcție de preferințele de marketing, să asigure respectarea termeniilor și condițiilor și să îndeplinească cerințele legale, să verifice identitatea și informațiile furnizate, plăți, pentru a înțelege datele demografice, interesele și nevoile clienților și alte tendințe în rândul utilizatorilor și pentru alte scopuri de afaceri.
									<br /><br />
									4.2. Putem combina datele personale cu alte informații pe care le procesăm pentru a personaliza conținutul informațional și pentru a spori capacitatea noastră de a furniza servicii în funcție de preferințele, restricțiile de marketing, de publicitate, de direcționare sau de a vă dezvolta și furniza produse și funcții relevante si adaptate la nevoile dvs de utilizare.
								</p>

								<h5> <br /><br />5. Cum colectăm informațiile dvs. </h5>
								<p>
									5.1. Datele furnizate de clienții noștri: Clienții pot importa datele personale ale serviciilor pe care le-au colectat de la participanții lor sau de la alte persoane. Nu avem o relație directă cu participanții la evenimentele clienților sau cu alte persoane decât cu clienții noștri. Prelucrarea datelor noastre personale se bazează pe faptul că persoana vizată acordă consimțământul în mod liber și clienții sunt responsabili pentru a ne asigura că au permisele necesare pentru a procesa date personale despre abonați sau alte persoane.
									<br /><br />
									5.2. Tehnologii de urmărire: în legătură cu performanța serviciilor Ality.ro si pe aplicatia “Ality” utilizează:
									<br /><br />
									Cookie-urile: cookie-urile sunt fișiere de date pe care un site web le transferă pe hard disk-ul computerului pentru a fi ținute în evidență. Majoritatea browserelor web sunt setate să accepte modulele cookie, dar puteți schimba acest lucru în setările browserului. Cu toate acestea, dacă nu acceptați cookie-urile, exista posibilitatea ca unele functionalitati sa nu manifeste comportament corect sau coerent in contextul utilizarii Ality.ro si a aplicatiei “Ality””. Utilizăm cookie-uri pentru a îmbunătăți experiența dvs.pe Ality.ro si pe aplicatia “Ality”” și colectând aceste date, putem stoca informații astfel încât să nu trebuie să le reintroduceți de fiecare dată când vizitați Ality.ro sau aplicatia “Ality”. De asemenea, le utilizăm pentru a monitoriza valorile vizitatorilor către Ality.ro si a aplicatiei “Ality”, cum ar fi vizualizările de pagină, timpul petrecut pe site, numărul de vizitatori etc.
									<br /><br />
									Semnalizatoare web: includem gif-uri cu un singur pixel în e-mailurile pe care le trimitem, ceea ce ne permite să colectăm informații despre momentul deschiderii e-mailului și a adresei dvs. IP, a browserului sau a tipului de client de e-mail și alte detalii similare. Utilizăm datele de pe aceste semnalizatoare web pentru a crea rapoarte despre modul în care au fost receptate comunicările automate trimise de Sistem, pentru a captura timpul petrecut în serviciile, paginile vizitate și performanța Serviciului.
									<br /><br />
									Identificatori unici și tehnologii de urmărire similare: este posibil să folosim alte tehnologii de urmărire pentru a administra Serviciile, pentru a vă urmări mișcările în jurul Serviciilor, pentru a analiza tendințele, pentru a urmări comportamentul, pentru a difuza anunțuri direcționate și pentru a aduna informații demografice, pentru a măsura performanța Serviciilor și pentru a îmbunătăți caracteristicile noastre pentru anumite segmente de clienți.
									<br /><br />
									5.3. Monitorizarea datelor din log-uri: este posibil să colectăm date din log-uri ori de câte ori accesați Serviciul nostru. Aceste date ar putea include adresa IP, tipul și versiunea de browser, paginile pe care le accesați pe Ality.ro si pe aplicatia “Ality” și alte statistici de utilizare. Când accesați Ality.ro si aplicatia “Ality” cu un dispozitiv mobil, datele din jurnal pot include tipul dispozitivului, adresa IP a dispozitivului dvs., sistemul de operare și alte caracteristici.
									<br /><br />
									5.4. Servicii terțe: folosim servicii ale unor terțe părți, cum ar fi Twispay reprezentata de firma Capital Financial Services S.A, Google Analytics și Facebook, care colectează și analizează date din log-uri pentru a ne ajuta să îmbunătățim Ality.ro si aplicatia “Ality” și, ocazional, să le folosim pentru publicitate online. Partenerii noștri terță parte pot utiliza cookie-uri sau alte tehnologii de urmărire pentru a vă oferi publicitate pe alte site-uri în funcție de activitățile și interesele dvs. de navigare. Puteți găsi mai multe informații despre aceste practici, inclusiv cum să renunțați la publicitatea vizată aici: http://www.aboutads.info/choices/.
								</p>

								<h5> <br /><br />6. Transferarea către companii terțe </h5>
								<p>
									6.1. Prin acceptarea prevederilor prezentului act va manifestati acordul asupra posibilitatii vanzarii împrumutarii sau închirii datelor dvs. personale unei terțe părți, respectiv ne rezervam dreptul să împărtășim informațiile colectate în scopurile descrise în această politică de confidențialitate cu terțe părți care ajută Ality.ro si aplicatia “Ality” să furnizeze, să îmbunătățească, să promoveze sau să sprijine serviciile sale sau care ne ajută în operațiunile noastre comerciale și ne oferă asistență în livrarea Serviciilor noastre sau furnizarea de servicii pentru noi, cum ar fi procesoare de plăți, servicii de găzduire, servicii de analiză, servicii de livrare a conținutului, parteneri de publicitate, etc. De asemenea, putem împărtăși informații cu terțe părți, dacă acest lucru este cerut de lege.
									<br /><br />
									6.2. Prin acceptarea prevederilor prezentului act va manifestati acordul asupra posibilitatii de a divulga si de a utiliza date non-personale pentru orice scop, deoarece aceste informații nu pot fi folosite pentru a vă identifica pe dvs. sau pe altă persoană.
								</p>

								<h5> <br /><br />7. Comunicații prin e-mail și oprirea </h5>
								<p>
									7.1. Ne rezervam dreptul de a comunica prin e-mail informatii legate de utilizarea de către dvs. a Ality.ro si pe aplicatia “Ality”. Pe baza datelor personale pe care ni le furnizați, asumam dreptul să comunicăm cu dvs. ca răspuns la solicitările dvs. pentru a furniza Serviciile solicitate și pentru a vă administra contul. De asemenea, putem utiliza datele dvs. personale pentru a vă trimite actualizări și alte comunicări in scop promoțional. Fiecare e-mail va conține un link in vederea dezabonarii de la newsletter-ul si anunturile noastre. Ne asumam drreptul de a trimite mesaje importante cu privire la chestiuni administrative, actualizări, dispute și probleme legate de serviciul clienți care sunt necesare pentru a furniza Serviciile.
									<br /><br />
									7.2. Dacă un Participant nu mai dorește să fie contactat de unul dintre clienții noștri, acesta se va poate dezabona direct din buletinul informativ al clienților sau poate contacta direct Clientul pentru a actualiza sau șterge datele. Dacă un Participant ne contactează, îl vom referi la Clientul respectiv. Vom păstra informațiile personale pe care le procesăm în numele clienților noștri atât timp cât este necesar pentru a furniza serviciile noastre sau pentru a ne conforma obligațiilor legale. Clientul este complet răspunzător pentru a asigura tot suportul necesar Participanților în ce privește datele personale ale acestora.
								</p>

								<h5> <br /><br />8. Siguranța informațiilor dvs. </h5>
								<p>
									8.1. Ality.ro si aplicatia “Ality” ia măsuri de precauție rezonabile, măsuri de securitate tehnice și organizaționale pentru a asigura un nivel de siguranță adecvat riscului, respectă cele mai bune practici din domeniu pentru a vă proteja datele personale de orice încălcare neautorizată sau ilegală a securității care duce la distrugerea accidentală sau ilegală, modificarea, dezvăluirea neautorizată sau accesul la datele cu caracter personal și păstrarea securității și confidențialității datelor. Cu toate acestea, aceste măsuri nu garantează ca nu vor exista cazuri in care informațiile dvs. nu vor fi accesate, dezvăluite, modificate sau distruse prin încălcarea acestor măsuri de precauție. (Aici trebuie ceva formulat legat de atacuri cibernetice) Prin utilizarea Serviciului nostru, declarati pe propria raspundere faptul că înțelegeți și sunteți de acord să vă asumați aceste riscuri.
									<br /><br />
									8.2. Clientul este responsabil de revizuirea informațiilor puse la dispoziție de Ality.ro si de aplicatia “Ality” în legătură cu securitatea datelor sale și de determinarea independentă a faptului dacă serviciile oferite îndeplinesc cerințele și obligațiile legale ce trebuie asigurate de către Client în conformitate cu legile privind protecția datelor. De asemenea, Clientul este responsabil pentru utilizarea securizată a Serviciilor, inclusiv securizarea datelor de autentificare a contului, protejarea securității datelor personale în timpul tranzitului către și de la Servicii și luarea oricăror măsuri corespunzătoare pentru criptarea sau backupul securizat a datelor personale utilizate în cadrul Serviciilor.
								</p>


								<h5> <br /><br />9. Protejarea datelor Participantiilor </h5>
								<p>
									9.1. Ne rezervăm dreptul de a monitoriza constant listele, conținutul și datele dvs. de răspuns pentru a ne asigura că respectă Termenii de utilizare.
									<br /><br />
									9.2. Centrul de date unde sunt stocate datele Serviciului este localizat în Europa – Germania, Frankfurt, în Uniunea Europeană, și este administrat de către o companie specializată in oferirea acestor servicii. Aceasta companie se asigură că funcționarea Serviciilor și securitatea datele sunt permanent asigurate în așa fel încât să fiți siguri că datele abonaților dvs. sunt în siguranță cu noi. Compania ce stocheaza datele Serviciului nu are dreptul de a citi sau folosi datele personale gazduite pe serverele sale.
								</p>

								<h5> <br /><br />10. Legături către alte site-uri web</h5>
								<p>
									Ca parte a Serviciului, putem furniza link-uri sau compatibilitate cu alte site-uri sau aplicații. Cu toate acestea, nu suntem responsabili pentru practicile de confidențialitate utilizate de aceste site-uri web sau pentru informațiile sau conținutul pe care le conțin. Această politică de confidențialitate se aplică numai informațiilor colectate de noi prin intermediul Serviciului. Prin urmare, această politică de confidențialitate nu se aplică utilizării de către dvs. a unui site web al unei terțe părți accesat prin intermediul Serviciului nostru. În măsura în care accesați sau utilizați servicii terte, conectate cu serviciile noastre, atunci politica de confidențialitate a acelui alt site sau aplicație se va aplica accesului sau utilizării site-ului sau aplicației respective. Îi încurajăm pe utilizatorii noștri să citească declarațiile de confidențialitate ale altor site-uri web înainte de a le folosi.
								</p>

								<h5> <br /><br />11. Acordul de prelucrare a datelor</h5>
								<p>
									Am creat contractul nostru de prelucrare a datelor pentru a acoperi cerințele GDPR în ceea ce privește marketingul prin e-mail prin telefon.
								</p>

								<h5> <br /><br />12. Varsta consimtamantului</h5>
								<p>
									Prin utilizarea Serviciului, declarați pe proprie raspundere că aveți cel puțin 14 ani împliniți.
								</p>

								<h5> <br /><br />13. Fuziune sau achiziție</h5>
								<p>
									În cazul în care efectuăm o tranzacție de afaceri, cum ar fi o fuziune, o achiziție de către o altă companie sau o vânzare a tuturor sau a unei părți din activele noastre, datele dvs. personale pot fi printre activele transferate. Recunoașteți și consimțiți că astfel de transferuri pot apărea și sunt permise de această Politică de confidențialitate. Astfel, orice cumpărător al activelor noastre poate continua să proceseze datele dvs. personale așa cum este stabilit în această politică de confidențialitate
								</p>

								<h5> <br /><br />14. Schimbări</h5>
								<p>
									Ality.ro si aplicatia “Ality” își rezervă dreptul de a modifica oricând această politică de confidențialitate. In cazul modificarii Politicii de confidentialitate, toate modificările vor fi mentionate atat pe pagina noastra oficiala ality.ro printr-un comunicat, cat si pe newsletter-ul oferit. Astfel, garantam transparenta catre dvs. despre ce informatii se colecteaza, de modul în care sunt folosite și în ce circumstanțe pot fi dezvaluite. Toate modificările sunt eficiente imediat după înregistrare și vă sugerăm să verificați periodic această politică de confidențialitate dacă sunteți preocupat de modul în care sunt utilizate informațiile dvs.
								</p>

								<h5> <br /><br />15. Contactați Ality</h5>
								<p>
									Aveti dreptul sa ne solicitati partajarea tuturor datelor personale pe care le deținem despre dumneavoastra. Solicitarile se fac doar in format electronic pe adresa de email: contact@ality.ro. Va rugăm să ne acordați un termen de solutionare de 30 de zile calendaristice de la solicitare. Aveți dreptul legal de a accesa, vizualiza aceste informații, să le corectați, modificați, ștergeți sau actualizați
									<br /><br />
									Oricând aveți întrebări privind această politică de confidențialitate sau practicile acestui site, doriți să vă retrageți consimțământul pentru colectarea continuă, doriți să obiectați împotriva utilizarii datelor dvs. personale sau doriti sa adresati întrebări suplimentare, vă rugăm să ne contactați în orice moment printr-un e-mail trimis la adresa contact@ality.ro.
								</p>

								<br /><br />
								<h2>Politica Confidentialitate Face Recognition</h2>

								<br /><br />
								<h3>1. INTRODUCERE</h3>
								<p>Prezenta Politică de confidențialitate respectă dispozițiile Regulamentului (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (denumit în continuare „GDPR”) și prevederile legislației în domeniu, Legii nr. 190/2018 privind măsuri de punere în aplicare a GDPR</p>
								<br /><br />
								<p>Această Politică de confidențialitate conține informații importante și își propune să explice într-un mod simplu și transparent ce tip de date cu caracter personal colectăm despre dvs., cum le prelucrăm și drepturile dvs. cu privire la utilizarea informațiilor dvs. personale.</p>
								<br /><br />
								<div>Politica privind confidențialitatea datelor cu caracter personal vizează modalitatea prin care  LYRA NAPKIN SRL, în calitate de operator de date cu caracter personal, prelucrează datele cu caracter personal, respectiv:
									<ul>
										<li>scopurile pentru care colectăm și utilizăm datele dumneavoastră cu caracter personal</li>
										<br /><br />
										<li>temeiurile de prelucrare ale acestor date</li>
										<br /><br />
										<li>categoriile de date cu caracter personal pe care le colectăm de la dumneavoastră și pe care le prelucrăm</li>
										<br /><br />
										<li>durata procesului de prelucrare și de stocare a acestor date</li>
										<br /><br />
										<li>drepturile dumneavoastră în calitate de persoane vizate</li>
										<br /><br />
										<li>persoanele către care este posibil să dezvăluim datele dumneavoastră cu caracter personal</li>
									</ul>
								</div>
								<br /><br />
								<p>LYRA NAPKIN SRL este doar un furnizor de tehnologie (Furnizor) pentru oferirea de către un Terț autorizat de Furnizor (Terț autorizat) să utilizeze site-ul/aplicația în vederea oferirii, prin intermediul site-ului/aplicației Ality, a unor Servicii de recunoaștere facială prin intermediul căreia sunt identificate și trimise utilizatorilor fotografiile unde au fost etichetați la diferite evenimente (denumite în continuare Servicii).</p>
								<br /><br />
								<p>Relația juridică directă se naște între Utilizatorul care folosește Serviciile și Terțul autorizat care oferă Serviciile. Terțul autorizat poate fi organizatorul de evenimente sau orice persoană care angajează serviciile unui fotograf pentru fotografierea Utilizatorilor la evenimentul respectiv, și care o relație contractuală cu Furnizorul care îi permite Terțului autorizat să ofere Serviciile prin intermediul aplicației Ality.</p>

								<br /><br />
								<h3>2. CINE SUNTEM NOI</h3>
								<div>LYRA NAPKIN SRL (denumită în continuare „controlorul”, “operator asociat”, “furnizor” sau „ALITY”), companie cu răspundere limitată, înregistrată în România, având numărul de înregistrare  J03/741/2018, cod fiscal 39173082, înregistrată în Câmpulung Muscel, jud. Argeș, str Carpați, nr 60, cam 2, în calitate de operator asociat, prelucrează datele dvs. personale atunci când utilizați:
									<ul>
										<li>site-ul nostru de la www.Ality.ro</li>
										<br /><br />
										<li>aplicația pentru smartphone-uri Ality</li>
										<br /><br />
										<li>oricare dintre serviciile la care poți avea acces pe platforma ALITY (produsele și serviciile noastre</li>
									</ul>
								</div>
								<br /><br />
								<p>După cum am menționat mai sus, suntem un furnizor de tehnologie (Furnizor) pentru oferirea de către un Terț autorizat de Furnizor (Terț autorizat) să utilizeze site-ul/aplicația în vederea oferirii, prin intermediul site-ului/aplicației Ality, a unor Servicii de recunoaștere facială prin intermediul căreia sunt identificate și trimise utilizatorilor fotografiile unde au fost etichetați la diferite evenimente. Astfel, vom colecta, procesa, prelucra, stoca date cu caracter personal al Utiliatorului site-ului/aplicației în asociere cu Terțul autorizat care va oferi Serviciile în mod direct către Utilizatori.</p>
								<br /><br />
								<p>Trebuie să aveți vârsta de cel puțin 16 ani pentru a încheia acest acord.</p>
								<br /><br />
								<p>Prin accesarea și utilizarea platformei ALITY și dobândirea calității de Utilizator, se presupune că ați citit Termenii și condițiile Site-ului și ale prezentei Politici de confidențialitate și de protecție a datelor cu caracter personal și că vă dați consimțământul pentru a procesa informațiile dvs. personale necesare pentru ca aceste servicii să fie furnizate.</p>
								<br /><br />
								<p>Pentru evitarea oricăror îndoieli, acceptând această politică de confidențialitate, recunoașteți că Terțul autorizat  oferă prin intermediul platformei ALITY un serviciu care vă ajută sa primiți pozele cu dvs. de la un eveniment prin recunoașterea facială. Datele tale biometrice reprezintă un model matematic al caracteristicilor tale faciale și sunt criptate.</p>
								<br /><br />
								<p>Citiți cu atenție această politică pentru a înțelege practicile noastre de date și modul în care le tratăm. Dacă nu sunteți de acord cu oricare dintre aceste practici, nu accesați serviciile furnizate de această platformă.</p>
								<br /><br />
								<p>Dacă după citirea acestui acord în întregul său nu sunteți încă sigur sau aveți întrebări, nu ezitați să ne contactați la adresa de email contact@ality.ro</p>
								<br /><br />
								<p>Terțul asociat care vă oferă în concret Serviciile prin intermediul site-ului este menționat în cadrul contului dumneavoastră, la secțiunea ... și care va prelucra colecta, procesa, prelucra, stoca date cu caracter personal al Utiliatorului în scopul oferirii Serviciilor.</p>

								<br /><br />
								<h3>3. PRINCIPII DE PROCESARE</h3>
								<p>Protejarea și respectarea vieții private este una dintre preocupările noastre constante.</p>
								<br /><br />
								<p>Prelucrarea datelor dumneavoastră cu caracter personal se va face într-un mod legal, corect și transparent.</p>
								<br /><br />
								<p>Scopul pentru care colectăm datele dvs. personale sunt specificate, explicite și legitime, iar datele dvs. personale nu vor fi prelucrate în continuare într-o manieră care poate fi incompatibilă cu aceste scopuri.</p>
								<br /><br />
								<p>Vom colecta datele dvs. personale într-o manieră adecvată; datele personale colectate sunt relevante și limitate la informațiile necesare în scopul prelucrării, exacte și, dacă este necesar, actualizate.</p>
								<br /><br />
								<p>Ne-am angajat să luăm toate măsurile necesare pentru a asigura ștergerea sau corectarea datelor incorecte.</p>
								<br /><br />
								<p>Datele cu caracter personal vor fi păstrate pentru o perioadă nu mai mare decât cea în care datele cu caracter personal sunt prelucrate, cu excepția circumstanțelor impuse de lege, în cazul în care păstrarea poate fi necesară și ulterior.</p>
								<br /><br />
								<p>Datele cu caracter personal vor fi păstrate confidențiale; stocarea acestora se va face într-o manieră care să asigure securitatea necesară.</p>
								<br /><br />
								<p>Datele cu caracter personal vor fi partajate terților numai dacă sunt necesare în scopul furnizării de servicii în baza unor acorduri.</p>
								<br /><br />
								<p>Persoanele în cauză au dreptul de a solicita accesul la datele cu caracter personal, rectificarea și ștergerea, impedimentul sau restricționarea procesării datelor și dreptul la portabilitatea datelor.</p>
								<br /><br />
								<p>Nu comercializăm date cu caracter personal în scopuri comerciale și nu folosim cookie-uri.</p>

								<br /><br />
								<h3>4. TIP DE DATE PERSONALE PROCESATE</h3>
								<div>Este posibil să prelucrăm date personale precum:
									<ul>
										<li>Detalii de contact (nume, prenume, adresă de e-mail, număr de telefon, adresă poștală)</li>
										<br /><br />
										<li>Șablon biometric (față)</li>
										<br /><br />
										<li>Logare cont (nume de utilizator și parolă)</li>
										<br /><br />
										<li>Fotografii, selfie al utilizatorului</li>
										<br /><br />
										<li>Detalii privind locația - rezultând din denumirea evenimentului la care fotografia a fost realizată</li>
										<br /><br />
										<li>Informații tehnice, cum ar fi adresa protocolului internet (IP) utilizat pentru conectarea computerului la internet, tipul și versiunea browserului, informațiile de conectare, setarea fusului orar, sistemul de operare și platforma, tipul de dispozitiv pe care îl utilizați sau numărul de telefon mobil utilizat de dispozitiv, informațiile despre rețeaua mobilă, sistemul dvs. de operare mobil, tipul de browser mobil pe care îl utilizați și așa mai departe</li>
									</ul>
								</div>

								<br /><br />
								<h3>5. SCOPUL PROCESULUI</h3>
								<div>Scopul nostru principal în colectarea informațiilor personale este să vă oferim o experiență sigură, eficientă și personalizată. Folosim informații personale pentru a crea, dezvolta, opera, livra și îmbunătăți serviciile noastre, în conformitate cu Termenii și condițiile și pentru a crea contul de utilizator al platformei ALITY, pentru a vă identifica ca utilizator al unei platforme și a vă oferi acces la diferitele caracteristici și servicii disponibile pentru dvs. ca utilizator înregistrat:
									<ul>
										<li>Verificarea identității în vederea creării Contului de utilizator</li>
										<br></br>
										<li>Oferirea Serviciilor de către Terțul autorizat  prin intermediul aplicației Ality de recunoaștere facială</li>
										<br></br>
										<li>Detectarea, investigarea, prevenirea sau înlăturarea încălcărilor acordurilor dvs. cu noi</li>
										<br></br>
										<li>Pentru a respecta cerințele legale și de reglementare</li>
									</ul>
								</div>

								<br /><br />
								<h3>6. BAZĂ JURIDICĂ PENTRU PROCESARE</h3>
								<div>ALITY prelucrează informațiile dvs. personale pe bazele prezentate mai jos:
									<ul>
										<li>Respectând acordul cu dvs. - Avem nevoie de anumite informații personale pentru a furniza serviciile noastre și nu le putem furniza fără aceste informații</li>
										<br></br>
										<li>Obligații legale - În unele cazuri, avem responsabilitatea legală de a colecta și stoca informațiile dvs. personale</li>
										<br></br>
										<li>Interese legitime - Uneori colectăm și folosim informațiile dvs. personale, deoarece avem un motiv legitim de a le avea și acest lucru este rezonabil atunci când este echilibrat împotriva dreptului dvs. la confidențialitate</li>
										<br></br>
										<li>Consimțământ - acceptând această politică de confidențialitate, ne acordați acordul dvs. specific asupra prelucrării datelor efectuate în scopurile detaliate mai sus</li>
										<br></br>
										<li>Consimțământul dumneavoastră prealabil, liber, specific, informat, în baza art. 6 alin. (1) lit. a) din GDPR</li>
									</ul>
								</div>

								<br /><br />
								<h3>7. DURATA procesului de prelucrare și de stocare a acestor date</h3>
								<div>În funcție de tipul de date colectate și de momentul prelucrării acestora, ALITY stochează datele pentru următoarele durate
									<ul>
										<li>Nivelul 1 (Aceste date se stochează timp de 7 zile): adresa de email ale utilizatorului, selfie-ul Utilizatorului necesar pentru înregistrare, numele evenimentului la care selfie-ul a fost făcut</li>
										<br></br>
										<li>Nivelul 2 - Aceste date se stochează cât timp algoritmul de recunoaștere facială rulează</li>
										<br></br>
										<li>Fotografiile realizate de fotografi la eveniment sunt stocate în cloud timp de 7 zile</li>
									</ul>
								</div>
								<br /><br />
								<p>Datele cu caracter personal vor fi păstrate pentru o perioadă nu mai mare decât cea în care datele cu caracter personal sunt prelucrate, cu excepția circumstanțelor impuse de lege, în cazul în care păstrarea poate fi necesară și ulterior</p>

								<br /><br />
								<h3>8. Este posibil să dezvăluim și informațiile dvs. personale către terți:</h3>
								<div>
									<ul>
										<li>În cazul în care solicitați sau ne dați permisiunea de a face acest lucru</li>
										<br></br>
										<li>Persoanelor care pot demonstra că au autoritatea legală să acționeze în numele tău</li>
										<br></br>
										<li>Dacă este interesul nostru legitim să facem acest lucru pentru a gestiona, extinde sau dezvolta activitatea comercială. Dacă ALITY (sau o parte substanțială a activelor sale) este achiziționată de un terț, caz în care datele cu caracter personal deținute de ALITY vor fi unul dintre activele transferate</li>
										<br></br>
										<li>Dacă avem obligația de a dezvălui datele dvs. cu caracter personal pentru a respecta o obligație legală, orice solicitare legală din partea autorităților guvernamentale sau executive și, după cum este necesar, pentru a îndeplini anumite cerințe de securitate națională sau de aplicare a legii sau pentru a preveni anumite activități ilegale</li>
										<br></br>
										<li>Pentru a răspunde oricărei revendicări, pentru a ne proteja drepturile sau a unei terțe părți, pentru a proteja siguranța oricărei persoane sau pentru a preveni orice activitate ilegală</li>
										<br></br>
										<li>Pentru a proteja drepturile, proprietatea sau siguranța ALITY, a angajaților săi, a utilizatorilor platformei ALITY sau a altor persoane</li>
										<br></br>
										<li>Terțului autorizat - în calitate de operator asociat</li>
									</ul>
								</div>

								<br /><br />
								<h3>9. SECURITATEA DATELOR PERSONALE</h3>
								<p>Folosim măsuri adecvate pentru a menține confidențialitatea și securitatea datelor dvs. personale și pentru a preveni accesul, utilizarea, dezvăluirea, modificarea sau distrugerea neautorizate</p>
								<br /><br />
								<p>Vă rugăm să rețineți că aceste garanții nu se aplică informațiilor pe care alegeți să le distribuiți în domeniul public, cum ar fi rețelele sociale deținute de terți</p>
								<br /><br />
								<p>Datele dvs. personale vor fi prelucrate de personalul sau agenții noștri autorizați numai în măsura în care li se cere să știe, în funcție de scopurile specifice pentru care au fost colectate datele dvs. personale</p>
								<br /><br />
								<p>Stocăm datele dvs. personale în medii de operare care folosesc măsuri rezonabile de securitate pentru a preveni accesul neautorizat. Respectăm standarde rezonabile pentru protecția datelor cu caracter personal</p>
								<br /><br />
								<div>Informațiile personale pe care le furnizați la crearea unui cont pe platforma ALITY sunt criptate și păstrate în conturile cloud ALITY în AWS Amazon și Azure. Acestea au propriile lor Politici de confidențialitate în ceea ce privește protecția datelor cu caracter personal pe care le puteți găsi aici:
									<ul>
										<li>https://aws.amazon.com/privacy/</li>
										<br></br>
										<li>https://azure.microsoft.com/en-in/overview/trusted-cloud/privacy/</li>
										<br></br>
										<li>https://azure.microsoft.com/es-es/blog/protecting-privacy-in-microsoft-azure-gdpr-azure-policy-updates/</li>
									</ul>
								</div>
								<br /><br />
								<p>Prin exprimarea consimțământului pentru prelucrarea datelor cu caracter personal conform prezentei Politici aferente platformei ALITY, CONFIRMAȚI CĂ SUNTEȚI DE ACORD și CUNOAȘTEȚI  POLITICA DE CONFIDENȚIALITATE aferentă AMAZON ȘI AZURE</p>
								<br /><br />
								<p>Este important pentru dvs. să jucați un rol în menținerea securității și securității datelor dvs. personale. Sunteți responsabil pentru păstrarea confidențialității pentru orice utilizare a contului</p>
								<br /><br />
								<p>Dacă sunteți la curent cu orice utilizare neautorizată a contului dvs. sau orice altă încălcare a securității cu privire la datele cu caracter personal pe care le-ați furnizat către ALITY, vă dați acordul să notificați imediat ALITY</p>

								<br /><br />
								<h3>10. POSIBILE RISCURI PENTRU FURNIZAREA DATELOR BIOMETRICE</h3>
								<div>Chiar dacă luăm toate măsurile pentru securizarea tuturor datelor cu caracter personal furnizate de utilizatori, există totuși anumite riscuri, în special atunci când vine vorba de date sensibile, cum ar fi datele biometrice. Deoarece dorim ca utilizatorii noștri să fie informați pe deplin atunci când vine vorba de datele lor personale, în cele ce urmează menționăm, de exemplu, câteva dintre aceste riscuri:
									<ul>
										<li>Nu este exclus furtul de identitate indiferent de tehnologia utilizată</li>
										<br></br>
										<li>Publicarea acestor date în cazul în care serverele sunt hackate și utilizarea datelor în scopuri neautorizate (de exemplu, marketing, statistici etc.), pierderea acestor date, distrugerea lor, modificarea, dezvăluirea sau colectarea ilegală</li>
										<br></br>
										<li>Acces la alte date cu caracter personal care pot rezulta din datele biometrice, cum ar fi: rasă, sex, etnie, vârstă etc</li>
									</ul>
								</div>
								<br /><br />
								<p>Cu toate acestea, vă rugăm să rețineți că am implementat metode de securizare a datelor cu caracter personal pe care le colectăm, în special datele sensibile prin criptarea și stocarea acestora într-o formă în care aceste informații nu pot fi utilizate pentru a reda date biometrice în forma sa inițială</p>

								<br /><br />
								<h3>11. TRANSFERURI DE DATE PERSONALE</h3>
								<p>Păstrarea și prelucrarea informațiilor dvs. personale, așa cum s-a descris mai sus, poate necesita transferul unei astfel de date și / sau depozitarea către o destinație din afara țării de reședință către țările din Uniunea Europeană („UE”) sau Spațiul Economic European („SEE”), de exemplu, furnizorii de servicii</p>
								<br /><br />
								<p>Înainte de a transfera datele dvs., vom lua măsurile necesare pentru a ne asigura că informațiile dvs. personale vor beneficia de o protecție adecvată, în conformitate cu legile de confidențialitate relevante și politicile interne ale ALITY</p>
								<br /><br />
								<p>Înscriindu-vă ca Utilizator al platformei ALITY deținută de ALITY, vă dați în mod expres și fără echivoc consimțământul pentru transferul și stocarea datelor dvs. personale pe serverele din afara țării dvs. de reședință, inclusiv SUA, care pot avea legi de protecție a datelor diferite de cele din tara ta</p>

								<br /><br />
								<h3>12. DREPTURILE UTILIZATORULUI în calitate de PERSOANĂ VIZATĂ</h3>
								<div>
									<ul>
										<li>Dreptul de a fi informat, acces (persoana vizată are dreptul să obțină de la ALITY o confirmare a faptului că datele cu caracter personal care îl privesc sunt sau nu prelucrate și, în caz afirmativ, are dreptul să acceseze datele). Acest drept poate fi limitat sau refuzat, motivul refuzului sau al limitării fiind comunicat persoanei în cauză</li>
										<br></br>
										<li>Dreptul de rectificare (persoana vizată are dreptul de a obține, fără întârziere nejustificată, rectificarea datelor cu caracter personal inexacte care îl privesc). Ținând cont de scopurile pentru care au fost prelucrate datele, acesta are dreptul să obțină completarea datelor cu caracter personal incomplete, inclusiv prin furnizarea unei declarații suplimentare</li>
										<br></br>
										<li>Dreptul la ștergerea datelor (în situațiile în care (1) datele nu mai sunt necesare pentru îndeplinirea scopurilor, (2) consimțământul a fost retras și nu există niciun alt temei legal pentru prelucrare, (3) persoana se opune prelucrarea și nu există motive legitime (4) datele cu caracter personal au fost prelucrate în mod ilegal, persoana are dreptul să obțină ștergerea datelor referitoare la aceasta fără întârzieri nejustificate). Acest drept poate fi limitat sau refuzat, motivul refuzului sau al limitării fiind comunicat persoanei în cauză</li>
										<br></br>
										<li>Dreptul la restricționarea procesării - persoana vizată are dreptul să restricționeze prelucrarea în următoarele situații
											<p>(i) contesta exactitatea datelor pentru o perioadă care permite operatorului să verifice exactitatea datelor</p>
											<p>(ii) prelucrarea este ilegală și persoana în cauză se opune ștergerii datelor cu caracter personal, dar în schimb solicită restricționarea utilizării acestora</p>
											<p>(iii) nu sunt necesare date personale pentru prelucrare, dar persoana vizată le solicită instituirea, exercitarea sau apărarea unui drept în instanță</p>
											<p>(iv) persoana vizată opusă prelucrării, pentru perioada de timp pentru a verifica dacă interesele legitime ale operatorului prevalează asupra drepturilor persoanei</p>
										</li>
										<br></br>
										<li>Utilizatorul are dreptul de a se opune oricând prelucrării datelor în scop de marketing direct, de a nu fi supus procesului automat de luare a deciziilor, inclusiv de profilare</li>
										<br></br>
										<li>Dreptul la portabilitatea datelor - persoana vizată are dreptul de a primi datele cu caracter personal pe care le consideră și pe care le-a furnizat într-un formular structurat, utilizat în mod obișnuit și care poate fi citit și are dreptul să solicite ALITY să transmită aceste date către o altă persoană operator, fără obstacole din partea ALITY, dacă sunt îndeplinite cumulativ următoarele condiții:
											<p>(i) prelucrarea se bazează pe consimțământ sau contract și</p>
											<p>(ii) prelucrarea se realizează prin mijloace automate, în special dacă acest lucru este fezabil din punct de vedere tehnic</p>
										</li>
										<br></br>
										<li>Dreptul de a depune o reclamație la Terțul autorizat sau Furnizor - persoana vizată poate depune o plângere dacă nu este nemulțumit de prelucrarea datelor sale personale sau de modul de a răspunde la solicitarea sa</li>
										<br></br>
										<li>Dreptul de a depune o reclamație la Autoritatea de Supraveghere - persoana vizată are dreptul să depună o plângere la Autoritatea Națională de Supraveghere pentru Prelucrarea Datelor cu Caracter Personal dacă nu este nemulțumit de prelucrarea datelor sale personale
											<p>Nume: Autoritatea Națională pentru Supravegherea Prelucrării Datelor cu Caracter Personal</p>
											<p>Adresa: B-dul G-ral. Gheorghe Magheru 28-30, Sector 1, cod poștal 010336, București, România</p>
											<p>Telefon: +40.318.059.211 sau +40.318.059.212</p>
											<p>Fax: +40.318.059.602</p>
											<p>E-mail: anspdcp@dataprotection.ro</p>
										</li>
										<br></br>
										<li>Dreptul de a se adresa justiției - persoana vizată are dreptul de a apela la instanțele competente dacă nu este nemulțumit de prelucrarea datelor sale personale</li>
									</ul>
								</div>
								<br /><br />
								<p>Persoana vizată se bucură de aceste drepturi, indiferent de temeiul legal al procesării datelor sale</p>

								<br /><br />
								<h3>13. DIVERSE</h3>
								<p>Este posibil ca site-ul nostru să conțină linkuri către și de pe site-urile rețelelor partenere, agenților de publicitate și afiliaților noștri. Dacă accesați un link către oricare dintre aceste site-uri web, vă rugăm să rețineți că aceste site-uri web au propriile lor politici de confidențialitate și că nu putem fi responsabili pentru aceste politici. Vă rugăm să verificați politicile de confidențialitate ale fiecărui site web înainte de a trimite date personale</p>

								<br /><br />
								<h3>14. MODIFICĂRI LA ACEASTA POLITICĂ</h3>
								<p>Dacă aducem modificări modului în care gestionăm informațiile dvs. personale, vom actualiza această politică de confidențialitate. Ne rezervăm dreptul de a face schimbări în practicile și politicile noastre în orice moment. Vă rugăm să consultați site-ul nostru în mod regulat pentru a vedea actualizări sau modificări ale politicii noastre de confidențialitate. Toate versiunile anterioare ale acestei politici de confidențialitate vor fi disponibile pe site-ul nostru web</p>
								<br /><br />
								<p>SUBSEMNATUL/SUBSEMNATA, DECLAR ÎN MOD EXPRES CĂ AM FOST INFORMAT/Ă DE CĂTRE LYRA NAPKIN S.R.L ASUPRA CONDIȚIILOR DETALIATE PRIVIND GESTIUNEA DATELOR CU CARACTER PERSONAL DE CĂTRE  LYRA NAPKIN S.R.L  ÎMPREUNĂ CU OPERATORUL ASOCIAT - TERȚUL AUTORIZAT, PRIN INTERMEDIUL PREZENTEI POLITICI DE CONFIDENȚIALITATE ȘI DE PROTECȚIE A DATELOR CU CARACTER PERSONAL ȘI PRIN INTERMEDIUL TERMENILOR ȘI CONDIȚIILOR DE UTILIZARE A SITE-ULUI ALITY,
									ȘI,
									SUBSEMNATUL/SUBSEMNATA, DECLAR ÎN MOD EXPRES CĂ SUNT DE ACORD CU CU COLECTAREA, PRELUCRAREA, PROCESAREA, TRANSFERUL DATELOR CU CARACTER PERSONAL ALE SUBSEMNATULUI/SUBSEMNATEI CONFORM PREZENTEI POLITICI DE CONFIDENȚIALITATE ȘI DE PROTECȚIE A DATELOR CU CARACTER PERSONAL
									{/* :   DA _ / NU_ */}
								</p>

							</div>
						</Modal.Body>

						<Modal.Footer>
							{/* <Button onClick={this.toggleDrawerGDPR} appearance="primary">
								Accept
							</Button> */}

							<Button onClick={this.toggleDrawerGDPR} appearance="subtle">
								Inapoi
							</Button>
						</Modal.Footer>
					</Modal>

					<div className="Section-Title">
						<p>Informatii Personale</p>
					</div>

					<div className="Section">
						{this.getInfoRow("avatar", "Username", this.state.username)}

						{this.getInfoRow("at", "Email", this.state.email)}

						{this.getInfoRow("user-info", "Nume", this.state.name)}

						{this.getInfoRow("user-info", "Prenume", this.state.surname)}
					</div>

					<div className="Section-Title">
						<p>Optiuni</p>
					</div>

					<div className="Section">
						{this.getOptionRow("frame", "Schimba Nume/Prenume", this.changeName, "#06aff2")}

						{this.getOptionRow("key", "Schimba Parola", this.changePassword, "#06aff2")}

						{this.getOptionRow("gavel", "Termeni si Conditii", this.toggleDrawerTC, "#06aff2")}

						{this.getOptionRow("gavel", "Politica de Confidentialitate", this.toggleDrawerGDPR, "#06aff2")}

						{this.getOptionRow("off", "Log Out", this.logOut, "#d3163e")}
					</div>
				</div>

				<NavbarColor history={this.props.history} active={"Profile"} />
			</div>
		)
	}
}

export default Profile;