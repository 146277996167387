import React, {useState, useEffect} from 'react';
import './MemoryBooth.scss'
import CustomInput from "../../components/custom/CustomInput";
import {Icon} from "rsuite";
import {Link} from "react-router-dom";
import {useParams} from 'react-router'
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify'
import QRCode from "react-qr-code";
import axios from "axios";

export default function FinishedScreen() {

    const { id, uid } = useParams();
    const [email, setEmail] = useState('')

    let history = useHistory();

    const backgroundImageUrl = localStorage.getItem('memoryBoothBackgroundImage')
    const memoryBoothType = parseInt(localStorage.getItem('memoryBoothType'))

    const saveEmailAndMoveOn = async () => {

        if(email.length > 0) {
            let payload = {
                uid: uid,
                email: email
            }

            await axios.post(process.env.REACT_APP_DOMAIN + 'memory-booth-files/updateFileEmail', payload)
                .then(response => {
                    toast.success("Vă mulțumim că ați folosit serviciile Ality!")
                    history.push("/memorybooth/intro/" + id)
                })
                .catch(err => {
                    toast.error('A aparut o eroare!')
                })

        } else {
            toast.success("Vă mulțumim că ați folosit serviciile Ality!")
            history.push("/memorybooth/intro/" + id)
        }

    }

    useEffect(() => {
        checkTimeLimit()
    }, [])

    const checkTimeLimit = async () => {
        await axios.post(process.env.REACT_APP_DOMAIN + 'memorybooth/checkTimeLimit', {id: id})
            .then(response => {
                if(response.data.status === 0) {

                    toast.error('Perioada maxima de 12 ore a fost depasita!')
                    history.push("/adminEventPage/"+id)
                }
            })
    }

    const customInputStyle = {
        margin: "15px 0px 10px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    const customInputStyle2 = {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    return(<div className={"mb-intro-container"}>

        <img src={backgroundImageUrl} style={{position:'absolute', width:'100%', height:'100%', zIndex:5, top:0, left:0}}  crossOrigin={"true"}/>

        <div className={"mb-bg-overlay"} style={{position:'absolute', zIndex:10}}>


            <div className={"mb-intro-form-cont"}>

                {
                    memoryBoothType === 0 ?
                        <div style={{marginBottom:'10px', color:'red'}}>Clipul video se proceseaza. Va fi disponibil in cateva minute.</div>
                        :null
                }

                <div style={{marginBottom:'20px'}}>Scaneaza codul QR pentru a vizualiza testimonialul pe telefonul tau.</div>

                <QRCode value={"https://servicii.ality.ro/memorybooth/previewFile/" + uid} />

                {/*https://servicii.ality.ro/memorybooth/previewFile/{{var:id}}*/}

            <div style={{marginTop:'20px'}}>Completați adresa de email dacă doriți să primiți testimonialul pe email.</div>
            <CustomInput value={email}
                         onChange={setEmail}
                         noFocusItem={true}
                         style={customInputStyle2}
                         placeholder={"Email"}
                         icon={<Icon icon="envelope" className={"mb-input-icon"} />} />

            <button
                type={"button"}
                className={"mb-start-button"}
                onClick={() => {
                       saveEmailAndMoveOn()
                }}
            >
                Finalizare
            </button>
            </div>

        </div>

    </div>)
}

