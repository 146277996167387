import React, {useRef, useState, useEffect} from "react";
import {Icon} from "rsuite";

export default function StartButton({videoRef}) {

    const [showPlayButton, setShowPlayButton] = useState(true)

    return (<>

            {
                showPlayButton ?

                    <div className={"mb-preview-button"} onClick={() => {
                        setShowPlayButton(false)
                        videoRef.current.play()
                    }}>
                        <Icon icon="play" className={"icon"}/>
                    </div>
                    :null
            }


        </>
    );
}

