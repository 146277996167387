import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import AddEvent from './mainPages/AddEvent';
import AddSaveTheDate from './mainPages/AddSaveTheDate';
import EditSaveTheDate from './mainPages/EditSaveTheDate';


import AddAlbum from './mainPages/AddAlbum';
import EditAlbum from './mainPages/EditAlbum';

import EditEvent from './mainPages/EditEvent';

import EventPage from './secondaryPages/EventPage';
import AdminEventPage from './secondaryPages/AdminEventPage/AdminEventPage';



import HostessPage from './secondaryPages/hostessPage/HostessPage';
import CheckPresence from './secondaryPages/checkPresence/CheckPresence';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ViewEvents from './mainPages/ViewEvents';
import ViewAlbums from './mainPages/ViewAlbums';


import Signup from './SignUp/Signup';
import Login from './Login/Login';
import ConfirmMailPage from './mainPages/ConfirmMailPage/ConfirmMailPage';



import Profile from './mainPages/Profile/Profile';

import Planning from './mainPages/Planning/Planning';
import TasksPage from './mainPages/TasksPage/TasksPage';

import Budget from './mainPages/Budget/Budget';
import Gifts from './mainPages/Gifts/Gifts';


import SaveTheDateResponses from './mainPages/Responses/SaveTheDateResponses';

import InviteResponses from './mainPages/Responses/InviteResponses';
import AddResponse from './mainPages/AddResponse/AddResponse';
import EditResponse from './mainPages/EditResponse/EditResponse';

import AddMenu from './mainPages/MenuPage/AddMenu';
import EditMenu from './mainPages/MenuPage/EditMenu';

import FaceRecog from './secondaryPages/FaceRecog/FaceRecog';
import GiftMoney from './mainPages/GiftMoney/GiftMoney';
import EventInfoPage from './mainPages/EventInfoPage/EventInfoPage';
import GuestInfoPage from './mainPages/GuestInfoPage/GuestInfoPage';

import ContactUs from './mainPages/ContactUs/ContactUs';
import Package from './mainPages/Package/Package';
import IntroScreen from "./mainPages/MemoryBooth/IntroScreen";

import MemoryBoothConfig from "./mainPages/MemoryBoothConfig/MemoryBoothConfig";
import MemoryBoothConfigMain from "./mainPages/MemoryBoothConfig/MemoryBoothConfigMain";
import MemoryBoothListItems from "./mainPages/MemoryBoothConfig/MemoryBoothListItems";
import MemoryBoothPreviewFile from "./mainPages/MemoryBooth/MemoryBoothPreviewFile";
import MemoryBoothProcessing from "./mainPages/MemoryBooth/MemoryBoothProcessing";
import MemoryBoothPreview from "./mainPages/MemoryBooth/MemoryBoothPreview";
import FinishedScreen from "./mainPages/MemoryBooth/FinishedScreen";

import Administrators from "./secondaryPages/AdminEventPage/Administrators";

import PaymentConfirm from "./mainPages/Payment/PaymentConfirm";
import MemoryBoothPhotoConfig from "./mainPages/MemoryBoothPhotoConfig/MemoryBoothPhotoConfig";
import FinishedScreenPhoto from "./mainPages/MemoryBooth/FinishedScreenPhoto";
import PhotoScreenV2 from "./mainPages/MemoryBooth/v2/PhotoScreenV2";
import RecordingScreenV2 from "./mainPages/MemoryBooth/v2/RecordingScreenV2";
import RecordingScreen from "./mainPages/MemoryBooth/RecordingScreen";
import TestScreen from "./mainPages/MemoryBooth/v2/TestScreen";

function App() {
	return (
		<div className="App font-family-poppins-300">
			<Router>
				<ToastContainer />

				<Route exact path="/" component={Login} />
				{/* folosit Helper.js */}
				{/* in feed, adaugat modal showLargeImage */}
				{/* folosit customQuestion */}

				<Route exact path="/adminEventPage/:id" component={AdminEventPage} />
				<Route exact path="/eventPage/:id" component={EventPage} />



				<Route exact path="/hostessMap/:id" component={HostessPage} />
				<Route exact path="/checkPresence/:id" component={CheckPresence} />



				<Route exact path="/addAlbum/:id" component={AddAlbum} />
				<Route exact path="/editAlbum/:id/:idAlbum" component={EditAlbum} />
				<Route exact path="/viewAlbums/:id" component={ViewAlbums} />





				<Route exact path="/addEvent" component={AddEvent} />
				<Route exact path="/editEvent/:id" component={EditEvent} />
				<Route exact path="/viewEvents/:id" component={ViewEvents} />

				<Route exact path="/profile" component={Profile} />

				<Route exact path="/signup" component={Signup} />
				<Route exact path="/login" component={Login} />

				<Route exact path="/confirm-email/:id" component={ConfirmMailPage} />

				<Route exact path="/plan/:id" component={Planning} />
				<Route exact path="/plan/tasks/:id" component={TasksPage} />
				{/* <Route exact path="/plan/expenses/:id" component={Expenses} /> */}
				<Route exact path="/plan/buget/:id" component={Budget} />
				<Route exact path="/plan/gifts/:id" component={Gifts} />
				{/* <Route exact path="/plan/customQuestions/:id" component={CustomQuestions} /> */}

				<Route exact path="/dar/:id" component={GiftMoney} />

				<Route exact path="/info/:id" component={EventInfoPage} />
				<Route exact path="/guests/:id" component={GuestInfoPage} />



				<Route exact path="/raspunsuriSaveTheDate/:id" component={SaveTheDateResponses} />

				<Route exact path="/raspunsuriInvitati/:id" component={InviteResponses} />
				<Route exact path="/adaugaRaspuns/:id" component={AddResponse} />
				<Route exact path="/editeazaRaspuns/:id/:idResponse" component={EditResponse} />

				<Route exact path="/addMenu/:id" component={AddMenu} />
				<Route exact path="/editMenu/:id" component={EditMenu} />

				<Route exact path="/faceRecog/:id" component={FaceRecog} />

				<Route exact path="/contact_us" component={ContactUs} />
				<Route exact path="/package/:id" component={Package} />

				<Route exact path="/administrators/:id" component={Administrators} />



				{/* MemoryBooth */}
				<Route exact path="/memorybooth/intro/:id" component={IntroScreen} />
				<Route exact path="/memorybooth/recording" component={RecordingScreen} />
				<Route exact path="/memorybooth/photo" component={PhotoScreenV2} />
				<Route exact path="/memorybooth/finished/:id/:uid" component={FinishedScreen} />
				<Route exact path="/memorybooth/finishedPhoto/:id/:uid" component={FinishedScreenPhoto} />

				<Route exact path="/memorybooth/main/:id" component={MemoryBoothConfigMain} />
				<Route exact path="/memorybooth/config/:id" component={MemoryBoothConfig} />
				<Route exact path="/memorybooth/listItems/:id" component={MemoryBoothListItems} />
				<Route exact path="/memorybooth/photoConfig/:id" component={MemoryBoothPhotoConfig} />
				<Route exact path="/memorybooth/testScreen/:id" component={TestScreen} />

				<Route exact path="/memorybooth/preview/:id" component={MemoryBoothPreview} />
				<Route exact path="/memorybooth/previewFile/:id" component={MemoryBoothPreviewFile} />

				<Route exact path="/memorybooth/process" component={MemoryBoothProcessing} />

				<Route exact path="/afterPayment" component={PaymentConfirm} />

			</Router>
		</div>
	);
}

export default App;
