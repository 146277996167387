import React from 'react'
import { Icon, Checkbox, Input, InputGroup } from 'rsuite';
import { Button, } from 'rsuite';
import './Login.scss'
import logo from '../assets/logo.png'
import axios from 'axios'
import { toast } from 'react-toastify';
// import { Drawer } from 'rsuite';
// import CustomInput from '../components/custom/CustomInput';
import queryString from 'query-string';

class Login extends React.Component {

	urlParams = queryString.parse(this.props.location.search)

	state = {
		email: "",
		pass: "",
		show: false,
		rememberMEcheck: false,
		passPlaceholder: "Parola",
		value: "",
	}

	componentDidMount() {
		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
			//document.write('<meta name ="viewport" content="width=device-width, height=' + window.innerHeight+'initial-scale=1.0">');
		}

		if (localStorage.getItem('rememberMe') !== "remember") {
			localStorage.clear('jwt');
			localStorage.clear('id');
			localStorage.clear('rememberMe');
		}

		if (localStorage.getItem('jwt') !== null) {
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
				.then((response) => {
					this.props.history.push('/viewEvents/' + localStorage.getItem('id'))
				})
		}
	}

	displayErrors = (data) => {
		for (var key in data) {
			if (data.hasOwnProperty(key)) {
				toast.error(data[key][0]);
			}
		}
	}

	handleEmail = (value) => {
		this.setState({
			email: value
		})
	}

	handlePass = (value) => {
		this.setState({
			pass: value
		})
	}

	changeContext = () => {
		let obj = {
			identifier: this.state.email,
			password: this.state.pass
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'auth/local', obj)
			.then(response => {
				if (response.data.user.confirmed) {
					toast.success("Logare cu succes!")

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

					if (this.urlParams && this.urlParams.redirectBackTo && this.urlParams.redirectBackTo.length > 0) {
						this.props.history.push('/' + this.urlParams.redirectBackTo)
					} else {
						this.props.history.push('/viewEvents/' + response.data.user.id)
					}
				} else {
					toast.error("Va rog confirmati email-ul")
				}
			})
			.catch(err => {
				toast.error("Email sau parola gresita")
			})
	}

	changeContextToSignUp = () => {
		this.props.history.push('/signup')
	}

	recuperareParola = () => {
		var past = this.state.show

		this.setState({
			show: !past
		})
	}

	resetPassword = () => {
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!emailRegex.test(this.state.email.toLowerCase())) {
			toast.error("Adresa de email este invalida")
			return;
		}

		let obj = {
			email: this.state.email,
			sendEmail: true,
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'auth/forgot-password', obj)
			.then((response) => {
				toast.success("Email trimis!");
				this.recuperareParola()
			})
			.catch(err => {
				// this.displayErrors(err.response.data);
				toast.error("Mail-ul nu exista in baza de date")
			})
	}

	passOnFocus = (file) => {
		this.setState({
			passPlaceholder: ""
		})
	}

	passLostFocus = (file) => {
		this.setState({
			passPlaceholder: "Parola"
		})
	}

	render() {
		return (
			<div className="Login-container">
				{/* <Drawer
					placement="top"
					show={this.state.show}
					onHide={this.recuperareParola}
				>
					<Drawer.Header>
						<Drawer.Title> Resetare Parola</Drawer.Title>
					</Drawer.Header>

					<Drawer.Body>
						<div className="Login-drawer-contents">
							<p id="ind"> Daca email-ul este deja inregistrat in baza noastra de date, veti primi un mail cu toate detaliile pentru resetarea parolei</p>

							<InputGroup className="InputGroup-Container" inside>
								<InputGroup.Addon className="InputGroup-Login">
									<Icon icon="envelope" />
								</InputGroup.Addon>

								<Input onChange={this.handleEmail} placeholder="Email" className="InputGroup-Login" />

								<Button onClick={this.resetPassword} className="Login-button"> Resetare </Button>
							</InputGroup>
						</div>
					</Drawer.Body>
				</Drawer> */}

				<div className="Login-one">
					<img style={{ width: "170px", height: "auto", marginBottom: "10px" }} src={logo} alt='' />
					{/* <p id = "ondemand-text"> Smart Event Management </p> */}
				</div>

				<div className="Login-two">
					<InputGroup className="InputGroup-Container" inside>
						<InputGroup.Addon className="InputGroup-Login">
							<Icon icon="envelope" />
						</InputGroup.Addon>

						<Input value={this.state.email} onChange={this.handleEmail} placeholder="Email" className="InputGroup-Login" />
					</InputGroup>

					<InputGroup className="InputGroup-Container" inside>
						<InputGroup.Addon className="InputGroup-Login">
							<Icon icon="key" />
						</InputGroup.Addon>

						<Input value={this.state.pass} onChange={this.handlePass} type="password" onFocus={this.passOnFocus} onBlur={this.passLostFocus} placeholder={this.state.passPlaceholder} className="InputGroup-Login" />
					</InputGroup>

					<Button onClick={this.changeContext} className="Login-button"> Login </Button>

					<div className="checkbox-content" >
						<Checkbox onChange={_ => this.setState({ rememberMeCheck: !this.state.rememberMeCheck })} checked={this.state.rememberMeCheck} />

						<div onClick={_ => this.setState({ rememberMeCheck: !this.state.rememberMeCheck })}> Remember Me </div>
					</div>

					{/* <p onClick={this.recuperareParola} id="recovery-pass"> Recuperare parola </p> */}
				</div>

				<div className="Login-three">
					<p> Inca nu esti inregistrat? </p>

					<p onClick={this.changeContextToSignUp} id="create-account"> Creeaza un cont! </p>
				</div>
			</div>
		)
	}
}

export default Login;