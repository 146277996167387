import React from 'react'
import { Icon } from 'rsuite'
import './GiftMoney.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import Modal from "react-responsive-modal"
import axios from 'axios'
import { toast } from 'react-toastify'
import CustomInput from '../../components/custom/CustomInput'
import { BackAndConfirmButtons, ShowPackageData, usedCurrency } from '../../components/Helper'
import NavbarColor from '../../components/NavbarColor'

class GiftMoney extends React.Component {
	state = {
		participantsData: [],
		displayData: [],

		currentSearch: "",
		showModal: false,
		indexToShow: 0,
		giftMoneyInput: 0,

		packageData: {},

		isSendingData: false,
		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
			.then(async response => {
				//take all data
				let data = response.data.responses.map((invitee, index) => {
					let ans = { ...invitee.response }
					ans.responseIndex = index
					ans.giftMoney = invitee.giftMoney !== null ? invitee.giftMoney : 0
					ans.id = invitee.id

					return ans
				})

				if (data.length === 0) {
					this.setState({
						participantsData: [],
						displayData: [],
						packageData: response.data,
						loading: false
					})

					toast.info("Niciun raspuns in acest moment")

					return
				}

				this.setState({
					participantsData: data,
					displayData: data,
					packageData: response.data,
					loading: false
				})
			})
	}

	onChange = (value, data = null) => {
		let participantsData = this.state.participantsData

		if (data !== null) {
			participantsData = data
		}

		let filteredByName = participantsData.filter(element => {
			let name = element.prenume + " " + element.nume

			return name.toLocaleLowerCase().indexOf(value) !== -1
		})

		this.setState({
			currentSearch: value,
			displayData: filteredByName
		})
	}

	openModal = (index) => {
		this.setState({
			showModal: true,
			indexToShow: index,
			giftMoneyInput: this.state.participantsData[index].giftMoney
		})
	}

	closeModal = () => {
		this.setState({
			showModal: false
		})
	}

	saveGiftMoney = async () => {
		if (this.state.isSendingData) {
			return
		}
		this.setState({
			isSendingData: true
		})

		let index = this.state.indexToShow

		let obj = {
			giftMoney: Number(this.state.giftMoneyInput)
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'responses/' + this.state.participantsData[index].id, obj, auth)
			.then(_ => {
				toast.success("Salvat dar")
				let newParticipantsData = this.state.participantsData

				newParticipantsData[index].giftMoney = Number(this.state.giftMoneyInput)

				this.setState({
					participantsData: newParticipantsData
				})

				this.onChange(this.state.currentSearch)
			})
			.catch(err => {
				console.log(err);
				toast.error("Eroare la setare dar, va rog reintrati si reincercati")
			})


		this.setState({
			isSendingData: false,
			showModal: false
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading === true) {
			return <p> Loading.. </p>
		}

		let widthStyle = {
			width: "33%",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			borderRadius: "0px",
			textAlign: "center"
		}

		let totalMoney = 0

		let empty = this.state.participantsData.map(element => {
			let isSet = element.giftMoney !== 0

			if (isSet) {
				totalMoney += element.giftMoney
			}

			return ""
		})

		let displayInvites = this.state.displayData.map((element, index) => {
			let isSet = element.giftMoney !== 0

			return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
				<div style={widthStyle}> {element.prenume} {element.nume} </div>
				<div style={widthStyle}> {isSet ? element.giftMoney + " " + usedCurrency : "-"} </div>
				<div style={widthStyle} onClick={_ => this.openModal(element.responseIndex)}> {isSet ? "Modifica" : "Introduceti"} </div>
			</div>
		})

		return (
			<div className="GiftMoney-container">
				<HeaderWhite goBack={this.goBack} />

				{this.state.showModal && <Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} center open={this.state.showModal} onClose={this.closeModal}>
					<div>
						<div> Introduceti dar pentru {this.state.participantsData[this.state.indexToShow].prenume} {this.state.participantsData[this.state.indexToShow].nume}</div>

						<CustomInput value={this.state.giftMoneyInput} onChange={value => this.setState({ giftMoneyInput: value })} placeholder={"Dar"} border={true} inputType={"number"} />

						<BackAndConfirmButtons loading={this.state.isSendingData} textBack={"Inapoi"} textConfirm={"Salveaza"} functionBack={this.closeModal} functionConfirm={this.saveGiftMoney} />
					</div>
				</Modal>}

				<div className="GiftMoney-contents">
					<div style={{ fontSize: "30px", fontWeight: "bold" }}>Dar</div>

					<div style={{ fontSize: "20px", fontWeight: "bold" }}>Total dar: {totalMoney} {usedCurrency}</div>

					<CustomInput value={this.state.currentSearch} onChange={value => this.onChange(value, null)} placeholder={"Numele invitatului"} icon={<Icon icon="search" />} />

					<div className='tabel'>
						<div className="data-table" style={{ width: "100%" }}>
							<div className="header">
								<div style={widthStyle}>Nume</div>
								<div style={widthStyle}>Suma</div>
								<div style={widthStyle}>Optiune</div>
							</div>

							{displayInvites}
						</div>
					</div>

					<ShowPackageData packageData={this.state.packageData} history={this.props.history} eventId={this.props.match.params.id} />
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default GiftMoney;