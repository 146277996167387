import React, {useState, useEffect} from 'react'
import './MemoryBoothConfig.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import {Uploader, Button, SelectPicker, Icon, DatePicker, Dropdown} from 'rsuite'
import Modal from "react-responsive-modal";
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';

import NavbarColor from '../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import isBefore from 'date-fns/isBefore'
import CustomInput from '../../components/custom/CustomInput';
import {getUrlFromImage, textarea_max_length} from '../../components/Helper';
import {useHistory, useParams} from "react-router-dom";


export default function MemoryBoothConfig() {

    let history = useHistory()
    let {id} = useParams()

    const [weddingId, setWeddingId] = useState(0)
    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState(null)
    const [imageUrl, setImageUrl] = useState(false)
    const [loading, setLoading] = useState(false)
    const [duration, setDuration] = useState(20)

    useEffect(() => {
        getEvent()
    }, [])

    const getEvent = async () => {

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + id, auth);

        if(info.data.memorybooth && info.data.memorybooth.title) {
            setTitle(info.data.memorybooth.title)
            setDate(info.data.memorybooth.date)
            setDescription(info.data.memorybooth.description)
        } else {
            setDate(info.data.dataEvent)
        }
        setDuration(info.data.memorybooth_duration)
        setWeddingId(info.data.id)
        setImageUrl(info.data.memoryboothBackground ? process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + info.data.memoryboothBackground.url
            : '')

    }

    const goBack = () => {
        history.goBack()
    }

    const handleStartDate = (value) => {
        setDate(value.toISOString())
    }

    const handlePoza = (fileList) => {
        if (fileList.length === 0) {
            setImage(null)
            return
        }

        if (fileList[0].blobFile.size > 7000000) {
            toast.error("Fisier prea mare, maxim 7MB")
        }


        setImage([fileList[0].blobFile])

    }

    const save = async () => {

        if(title.length === 0) {
            return;
        }

        setLoading(true)


        let payload = {
            memorybooth: {
                title: title,
                date: date,
                description: description
            }
        }

        let formData = new FormData()
        formData.append("data", JSON.stringify(payload))

        if (image !== null) {
            formData.append("files.memoryboothBackground", image[0])
        }

        let auth = {
            headers: {
                "Content-Type": `multipart/form-data;`,
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + weddingId, formData, auth)
            .then(response => {
                toast.success("Configuratia a fost salvata!")
            })
            .catch(err => {
                toast.error("Eroare")
                console.log(err)
            })

        setLoading(false)
    }

    const customInputStyle = {
        margin: "15px 0px 10px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    return (
        <div className="mb-config-container">
            <HeaderWhite goBack={goBack}/>

            <div className={"mb-config-inner"}>

                <h4 className="title"> Configurare MemoryBooth </h4>

                <CustomInput value={title}
                             onChange={setTitle}
                             style={customInputStyle}
                             placeholder={"Nume eveniment"}
                             icon={<Icon icon="user" className={"mb-input-icon"}/>}/>

                <div className="AddHours-date-picker">
                    <DatePicker
                        value={date}
                        style={{ width: "100%", borderRadius: "5px", backgroundColor: "#E5F7FE" }}
                        placement="bottomStart"
                        disabledDate={date => isBefore(date, new Date(new Date().setDate(new Date().getDate() - 1)))}
                        defaultOpen={false}
                        oneTap onSelect={handleStartDate}
                        appearance="subtle"
                        placeholder="Data eveniment"
                        locale={{
                            sunday: 'Sa',
                            monday: 'Lu',
                            tuesday: 'Ma',
                            wednesday: 'Mi',
                            thursday: 'Jo',
                            friday: 'Vi',
                            saturday: 'Sa',
                            ok: 'OK',
                            today: 'Azi',
                            yesterday: 'Ieri',
                            hours: 'Ore',
                            minutes: 'Minute',
                            seconds: 'Secunde'
                        }} />
                </div>

                <CustomInput value={description}
                             componentClass={"textarea"}

                             onChange={setDescription}
                             style={customInputStyle}
                             placeholder={"Descriere eveniment"}
                             icon={<Icon icon="pencil" className={"mb-input-icon"}/>}/>


                <Dropdown title={"Durata maximă"} activeKey={duration}
                          className="dropdown_scroll" style={{ marginBottom: "20px", borderRadius: "5px", width: "100%" }}>

                        <Dropdown.Item
                            key={0}
                            eventKey={10}
                            onClick={() => setDuration(10)}>10</Dropdown.Item>

                    <Dropdown.Item
                        key={1}
                        eventKey={15}
                        onClick={() => setDuration(15)}>15</Dropdown.Item>

                    <Dropdown.Item
                        key={2}
                        eventKey={20}
                        onClick={() => setDuration(20)}>20</Dropdown.Item>

                    <Dropdown.Item
                        key={3}
                        eventKey={25}
                        onClick={() => setDuration(25)}>25</Dropdown.Item>

                    <Dropdown.Item
                        key={4}
                        eventKey={30}
                        onClick={() => setDuration(30)}>30</Dropdown.Item>

                </Dropdown>


                {imageUrl ?
                    <img style={{ padding: "5px", width: "300px", height: "300px", alignSelf: "center" }} src={imageUrl} alt="" />
                    : null
                }

                <div style={customInputStyle}>
                    <Uploader onChange={handlePoza} accept=".jpg" style={{ borderRadius: "5px" }} autoUpload={false} multiple={false} disabled={image !== null}>
                        <div style={{ color: "#221d5d", width: "100%", height: "100%" }} id="text">Click aici pentru a incarca fotografie de fundal</div>
                    </Uploader>
                </div>


                <div className="footer">
                    <Button onClick={goBack} className="back-button" style={{ color: "#1E1C59" }}>
                        Inapoi
                    </Button>

                    <Button onClick={save} className="add-button" style={{ backgroundColor: "#00afcc", color: "white" }}>
                        Salveaza
                    </Button>
                </div>

            </div>

            <Modal classNames={{
                overlay: 'modal-overlay',
                modal: 'modal',
                closeButton: 'modal-close-button',
            }} open={loading} onClose={() => { }} onEscKeyDown={() => { }} onOverlayClick={() => { }}>
                <div>
                    <p>Please wait</p>
                </div>
            </Modal>

            <NavbarColor history={history}/>
        </div>
    )

}
