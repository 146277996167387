import React, {useRef, useState, useEffect} from "react";
import {Icon} from "rsuite";
import StartButton from "./StartButton";

export default function Player({srcBlob, audio, videoWidth, videoHeight, status, preparing}) {

    const videoRef = useRef()
    const [currentTime, setCurrentTime] = useState(0)

    useEffect(() => {


/*
        var data = new Blob([srcBlob], {type: 'video/webm'});
        var csvURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'video.webm');
        tempLink.click();

 */

    },[])

    if (!srcBlob) {
        return null;
    }





    const showCurrentTime = (id, { current: videoDom }) => {
        if(videoDom) {
            setCurrentTime((videoDom.currentTime / videoDom.duration) * 100)
            console.log("currentTime" + id, (videoDom.currentTime / videoDom.duration) * 100);
        }
    }

    return (<React.Fragment>

            <StartButton
                videoRef={videoRef}
                />
        <video
            key={1}
            ref={videoRef}
            src={URL.createObjectURL(srcBlob)}
            width={videoWidth}
            height={videoHeight}
            autoPlay={false}
            loop={true}
            muted={false}
            style={{position:'relative'}}
        />


        </React.Fragment>
    );
}

