import React from 'react'
import SectionRender from '../sectionRender'
import './template6.scss'

export default function template6Content(templateDetails = false) {

    return (
        <div className={"main-content"} style={{height:'100%'}} >

            <div className={"background-full"}>
                <SectionRender
                    section={templateDetails.sections[0]}
                    preview={!templateDetails.live}
                />
            </div>

            <div className={"background-full-second"}>
                <img src={templateDetails.settings[6].value} crossOrigin={"true"}/>
            </div>

            <div className={"photo-template-6 " + (templateDetails.live ? 'live' : '')}>

                <div className={"row-header"}>
                    <div className={"col"}>
                        <div className={"title"} style={{fontSize: (templateDetails.live ? templateDetails.settings[1].value*3.2 : templateDetails.settings[1].value)  +'px', color: templateDetails.settings[2].value}}>{templateDetails.settings[0].value}</div>
                        <div className={"subtitle"} style={{fontSize: (templateDetails.live ? templateDetails.settings[4].value*3.2 : templateDetails.settings[4].value)+'px', color: templateDetails.settings[5].value}}>{templateDetails.settings[3].value}</div>
                    </div>
                </div>

                <div className={"row-1"}>

                </div>

            </div>

        </div>
    )
}