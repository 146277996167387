import React from 'react'
import SectionRender from '../sectionRender'
import './template19.scss'

export default function template19Content(templateDetails = false) {

    return (
        <div className={"main-content"} >

            <div className={"background"}>
                <img src={templateDetails.settings[1].value} crossOrigin={"true"}/>
            </div>
            <div className={"photo-template-19 " + (templateDetails.live ? 'live' : '')}>

                <div className={"row-1"}>
                    <div className={"outer-col-first"}>
                    <div className={"col-first"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[0]}
                            preview={!templateDetails.live}
                        />
                    </div>
                    </div>
                    <div className={"outer-col-second"}>
                    <div className={"col-second"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[1]}
                            preview={!templateDetails.live}
                        />
                    </div>
                    </div>
                </div>
            </div>

            <div className={"template-19-overlay"}>
                <img src={templateDetails.settings[2].value} crossOrigin={"true"}/>
            </div>




        </div>
    )
}
