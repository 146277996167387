import React from 'react'
import './BasicTask.scss'
import Task from './Task'
import Modal from "react-responsive-modal";
import axios from 'axios'
import ModalTasks from './ModalTasks';
import { BackAndConfirmButtons } from '../../../components/Helper';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Button } from 'rsuite';

let allTypes = "Toate"

class BasicTask extends React.Component {
	state = {
		weddingTasks: [
			{
				title: `Alegeți un oraș si incepeti documentarea privind locațiile de nunta`,
				detalii: `Ziua nuntii este una dintre cele mai frumoase zile, de aceea fixarea datei este foarte importanta.De ea depind toate detaliile ce vor urma.
			Decizia asupra unui oras si asupra unui anotimp va va ajuta sa incepeti documentarea privind locatiile de nuntă.
			Va recomandam sa fiti flexibili in ceea ce priveste data nuntii pana cand veti gasi locatia perfecta.
			Va trebui sa luati in considerare calendarul religios.Nunta poate fi organizata doar in anumite perioade ale anului, deoarece cununia religioasa nu este oficializata in timpul postului, in unele zile de sărbătoare sau zile libere legale.`,
				categorie: `Planificare`,
				interval: `Cu mai mult de 1 an înainte de eveniment`
			},
			{
				title: `Incepeti cautarea locatiei de nunta`,
				detalii: `Alegeți cateva date si incepeti cautarea locatiei de nunta, stabiliti cateva intalniri pentru a vizita locatiile.
			Aveti in vedere ca locatia in care va avea loc nunta sa fie in armonie cu tema evenimentului.De exemplu, o nunta rustica se potiveste cel mai bine intr - un decor natural, iar o nunta cu tematica medievala poate fi organizata intr - un castel.
			Aveti in vedere ca Restaurantele sau Ballroom - urile de top este posibil sa aiba multe dintre date deja rezervate.
			Nu va ganditi la o locație prea scumpă dacă nu aveți banii disponibili.Nu uitați ca nunta este pentru voi doi.`,
				categorie: `Planificare`,
				interval: `Cu mai mult de 1 an înainte de eveniment`
			},
			{
				title: `Incepeti sa va documentati despre muzica de nunta`,
				detalii: `Muzica are un rol important in crearea atmosferei iar alegerea nu este usoara intrucat trebuie sa satisfaca toti invitatii. Nu puteti sa alegi doar muzica voastra preferata.`,
				categorie: `Muzica & Divertisment`,
				interval: `Cu mai mult de 1 an înainte de eveniment`
			},
			{
				title: `Incepeti documentarea despre ce echipa foto&video va doriti la nunta`,
				detalii: `Fotograful reprezinta o parte importanta din bugetul de nunta, odata acesta stabilit, va trebui sa eliminati din cautarea voastra fotografii care au deja rezervata data pe care voi o doriti.
	Alegeti stilul gandindu - va la ce fel de fotografii va plac, analizati portofoliile apoi discutati, intalniti - va si solicitati oferta de la Fotografi.`,
				categorie: `Foto & Video`,
				interval: `Cu mai mult de 1 an înainte de eveniment`
			},
			{
				title: `Incepti lista de invitati`,
				detalii: `Incearcati sa realizati o lista provizorie de invitati.Acest lucru va va ajuta sa va orientati mai usor catre alegerea unei locatii de nunta.
	Folositi tool - ul nostru de Invitati pentru a avea o evidenta permanenta asupra numarului, status - ului si a meniurilor pentru fiecare invitat, intr - un singur loc.`,
				categorie: `Planificare`,
				interval: `10-12 luni înainte de eveniment`
			},
			{
				title: `Alegerea nasilor`,
				detalii: `Faceti o lista cu prietenii care v - ar placea sa va fie nasi sau intrebati - i pe parinti ce nasi v - ar recomanda.Nasii trebuie sa fie un cuplu de aceeasi religie ca si voi, cununat de un preot.`,
				categorie: `Planificare`,
				interval: `10-12 luni înainte de eveniment`
			},
			{
				title: `Stabiliti bugetul de nunta`,
				Detalii: `Stabiliti cat sunteti dispusi sa cheltuiti, cand, cum si pentru ce.Stabilirea bugetului de nunta trebuie sa fie facuta impreuna cu partenerul.Lasati iubirea sa pluteasca in aer si nu lasati banii, orgoliile sau mofturile sa afecteze traiectoria unui moment atat de frumos.
	Folositi tool - ul nostru de Buget pentru a avea o evidenta permanenta a cheltuielilor.`,
				categorie: `Planificare`,
				interval: `10-12 luni înainte de eveniment`
			},
			{
				title: `Alegeti o tema pentru nunta`,
				detalii: `Daca sunteti persoane care doresc sa iasa din tipare si sa le ofere invitatilor amintiri extraordinare si momente speciale, nunta tematica este alegerea potrivita.
	Tema nuntii trebuie sa tina cont de personalitatile voastre dar si de invitati si de disponibilitatea acestora de a participa la un astfel de eveniment.`,
				categorie: `Planificare`,
				interval: `10-12 luni înainte de eveniment`
			},
			{
				title: `Alegerea domnisoarelor si a cavalerilor de onoare`,
				detalii: `Domnisoarele si cavalerii de onoare se aleg din randul prietenilor sau cunostintelor apropiate. In unele cazuri, mirii isi aleg domnisoare si cavaleri de onoare membrii ai familiei, cum ar fi frati, surori, cumnati.`,
				categorie: `Planificare`,
				interval: `10-12 luni înainte de eveniment`
			},
			{
				title: `Incepeti sa schitati lista de invitați`,
				detalii: `Este timpul sa salvati adresele de e-mail și numerele de telefon ale invitatilor pentru a putea gestiona confirmările și așezarea acestora la mese.`,
				categorie: `Planificare`,
				interval: `10-12 luni înainte de eveniment`
			},
			{
				title: `Rezervati locatia si definitivati data evenimentului`,
				detalii: `Cand faceti rezervarea localului, trebuie sa tineti cont de urmatoarele aspecte:
			capacitatea restaurantului - este important sa cautati un restaurant care sa va puna la dispozitie un spatiu potrivit pentru numarul invitatilor vostri;
			suma care trebuie platita in avans - este important sa stiti de la bun inceput care este modalitatea de a efectua o plata in avans si suma pe care furnizorul o propune;
			decorul - puteti intreba furnizorul daca este asigurat decorul si daca se percep taxe suplimentare,
			daca locatia dispune de incalzire sau aer conditionat - pentru surprize neplacute, intreabati furnizorul despre prezenta in local a aerului conditionat;
			in ce consta meniul si care este pretul acestuia - nu omiteti testarea preparatelor in prealabil;
			ce se intampla in situatia in care numarul invitatilor depaseste numarul stabilit initial sau in cazul nefericit in care numarul de invitati ramane cu mult sub cel al meniurilor cerute;
			daca se poate amplasa un candy bar sau un fruit bar in sala;
			daca se pot folosi artificii sau fum greu in sala - in unele sali acest lucru nu este posibil;daca bautura poate fi adusa din exterior - asta in cazul in care va doriti sa faceti acest lucru;daca este DJ-ul asigurat de local - unele restaurante iti pot asigura si DJ-ul;
			daca localul are o formatie live - unele restaurante au si Muzica live;
			daca exista parcare pentru invitati;
			daca in pretul meniurilor este inclus si pretul personalului;
			daca mai exista si alte cheltuieli suplimentare.`,
				categorie: `Planificare`,
				interval: `10-12 luni înainte de eveniment`
			},
			{
				title: `Rezervați echipa foto & video pentru nunta`,
				detalii: `Solicitati oferte de la Fotografi, negociati, stabiliti clar ce contine pachetul de nunta oferit și stabiliti toate detaliile de la bun început.
			Discutati partea financiara in detaliu. Cat costa, cand ar trebui achitat avansul și cum se va face plata restului de bani pentru pachetul de nunta și dacă mai exista și alte costuri
			Nu uitați sa solicitați un contract pentru serviciile oferite.`,
				categorie: `Foto & Video`,
				interval: `10-12 luni înainte de eveniment`
			},
			{
				title: `Rezervați muzica pentru nunta`,
				detalii: `In cazul in care optati pentru Muzica live trebuie sa aveti in vedere ca o formatie live nu poate canta non-stop, are nevoie de pauza iar un Dj poate intretine atmosfera in aceste pauze.`,
				categorie: `Muzica & Divertisment`,
				interval: `10-12 luni înainte de eveniment`
			},
			{
				title: `Stabiliti Oficiul Stării civile pentru cununia civilă`,
				detalii: `Cununia civila este primul pas in a va uni destinele si va poate aduce momente de neuitat.
			Sfatuiti-va cu cei dragi, verificati programul de la Starea Civilia, verificati ora la care invitatii pot veni la eveniment si stabiliti ziua si ora oficierii casatoriei.
			Atentie la locatia cununiei civile. In cauzul in care sunteti din orase diferite, este obligatoriu ca unul din viitorii miri sa aiba domiciliul in localitatea sau sectorul (pentru Bucuresti) unde vreti sa va casatoriti.`,
				categorie: `Ceremonie`,
				interval: `10-12 luni înainte de eveniment`
			},
			{
				title: `Stabiliti biserica pentru cununia religioasa`,
				detalii: `Cununia religioasa este un moment foarte important, motiv pentru care biserica trebuie aleasa cu grija.
			Ca totul sa iasa perfect, cand faceti alegerea bisericii, va trebui sa luati in considerare urmatoarele aspecte:
			1. Cati invitati vor fi la nunta - daca organizati o nunta mare, atunci si biserica va trebui sa fie una spatioasa, doar nu vreti ca jumatate din persoanele prezente sa stea in curtea biserici pentru ca nu au loc inauntru din cauza aglomeratiei;
			2. Daca exista suficiente locuri de parcare - daca nu sunt suficente riscati sa decalati   programul pentru ca toti invitatii sa gaseasca un loc de parcare;
			3.Daca este aproape de locatia unde va avea loc petrecerea de nunta - este recomandat ca biserica sa nu se afle chiar in cealalta parte a orasului;
			4.Cati preoti vor sluji, daca exista cor si cat costa si care este "donatia recomandata";
			5. daca exista aer conditionat sau sistem de incalzire.`,
				categorie: `Ceremonie`,
				interval: `10-12 luni înainte de eveniment`
			},
			{
				title: `Incepeti sa va documentati in ceea ce priveste tinuta de nunta`,
				detalii: `Fie ca este vorba de Rochii de mireasa sau Costume mire, tinuta trebuie sa fie impecabila.
			Tinuta miresei cuprinde rochia de mireasa si Accesorii mireasa, voalul, lenjeria, bijuteriile, coafura si machiajul.
			Conform traditiei, nasa este cea care ajuta mireasa sa isi aleaga rochia si ii cumpara voalul.
			Pentru mire, nu uita ca o tinuta poate fi scoasa din anonimat cu accesoriile asa ca poti sa alegi piesele din cadrul Accesorii barbati care iti vor completa look-ul
			Va trebui sa luati in calcul si tinuta si accesoriile pentru cununia civila.`,
				categorie: `Planificare`,
				interval: `7-9 luni înainte de eveniment`
			},
			{
				title: `Incepeti sa cautati flori si decoratiuni`,
				detalii: `Alegerea florilor si a decoratiunilor pentru nunta este un proces complex, in cadrul caruia trebuie sa tineti cont de numeroase elemente, de la rochia de mireasa, la tematica nuntii.
			Descoperiti care sunt culorile potrivite pentru anotimpul in care va casatoriti, imaginati-va cum ar trebui sa arate buchetul miresei sau al nasei si decideti ce flori si decoratiuni vi se potrivesc cel mai bine.
			Conform traditiei, cele doua buchete, al miresei si al nasei, voalul miresei, lumanarile si floarea din pieptul mirelui sunt achizitionate de catre nasi.`,
				categorie: `Flori & Decoratiuni`,
				interval: `7-9 luni înainte de eveniment`
			},
			{
				title: `Trimiteti Save the date`,
				detalii: `Este foarte important ca invitatii sa stie din timp de nunta dvs astfel incat ca nu isi planifice un concediu in acea perioada.
	Cu ajutorul aplicatiei noastre puteti creea si trimite Save the date, iar noi vom centraliza toate raspunsurile  primite astfel incat dvs sa aveti o estimare asupra numarului de participanti`,
				categorie: `Planificare`,
				interval: `7-9 luni înainte de eveniment`
			},
			{
				title: `Incepeti sa va documentati despre verighete`,
				detalii: `La prima vedere verighetele sunt doar niste bijuterii dar alegerea lor reprezinta o decizie foarte importanta.Nu numai ca le vei purta toata viata, dar vor simboliza si relatia, de aceea este esential sa simtiti ca verighetele alese va reprezinta.
	In primul rand stabiliti un buget si luati in calcul ca sunt sanse sa mai adaugati diverse diamante sau gravuri la modelul ales.`,
				categorie: `Bijuterii`,
				interval: `7-9 luni înainte de eveniment`
			},
			{
				title: `Incepeti sa va documentati despre destinatiile pentru luna de miere`,
				detalii: `Luna de miere pentru majoritatea cuplurilor este mai mult decat o excursie spontana, planificata in ultimul moment, de aceea este bine sa iti faci planuri din timp.
	Unii miri isi aleg sa - si faca luna de miere imediat dupa nunta, altii in functie de anotimp, pe timp de vara sau iarna.Voi decideti.
	Alocati un Buget pentru luna de miere si incepeti sa va documentati despre destinatii.`,
				categorie: `Planificare`,
				interval: `7-9 luni înainte de eveniment`
			},
			{
				title: `Alege tinuta de nunta pentru mire si programează sedinta de proba`,
				detalii: `Sigur ai probat mai multe Costume mire dar a venit momentul sa alegi.Programeaza sedinta de proba si solicita mici ajustari daca este cazul.`,
				categorie: `Planificare`,
				interval: `7-9 luni înainte de eveniment`
			},
			{
				title: `Alege tinuta de nunta pentru mireasa si programează sedinta de proba`,
				detalii: `Cu siguranta ai probat mai multe Rochii de mireasa dar a venit momentul sa alegi.Programeaza sedinta de proba si solicita mici ajustari daca este cazul.`,
				categorie: `Planificare`,
				interval: `7-9 luni înainte de eveniment`
			},
			{
				title: `Alegeti tinuta domnisoarelor de onoare`,
				detalii: `Daca te-ai hotarat ce prietene iti vor fi alaturi in cea mai importanta zi din viata ta, ar fi bine sa te gandesti serios cu ce se vor imbraca.
			Desi in mod traditional rochiile pentru domnisoare de onoare sunt identice, cel mai bine ar fi ca fiecare sa isi personalizeze rochia de nunta in functie de propriile gusturi, personalitate, stil, corp si bani.`,
				categorie: `Planificare`,
				interval: `7-9 luni înainte de eveniment`
			},
			{
				title: `Intalniti - va cu preotul`,
				detalii: `Este bine sa faceti o vizita preotului sa discutati toate detaliile legate de cununia religioasa, ce trebuie sa aduceti la biserica, care sunt indatoririle voastre si ale nasilor.`,
				categorie: `Ceremonie`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Intalniti - va cu ofițerul stării civile`,
				detalii: `Faceti o vizita la starea civila sau vorbiti telefonic si stabiliti toate detaliile.
	Intrebati de acte si termene pentru depunerea dosarului intrucat acestea variaza de la judet la judet si de la sector la sector.`,
				categorie: `Ceremonie`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Vorbiți cu medicul de familie`,
				detalii: `Luati legatura cu medicul de familie intrucat veti avea nevoie de certificat medical care va declara apți de căsătorie.`,
				categorie: `Ceremonie`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Alegeti - va martorii`,
				detalii: `La incheierea cununiei civile, martori pot fi orice doi cetateni romani, cunoscuti sau necunoscuti, care au cartea de identitate la purtator.
	Obiceiul la noi este ca mirii sa roage nasii sa le fie martori, dar nu este un lucru obligatoriu, oricine poate fi martor.`,
				categorie: `Ceremonie`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Cautati voal și accesorii`,
				detalii: `Poti alege orice fel de voal, dar este important sa iei in considerare modul in care voalul se va asorta cu rochia de mireasa, forma fetei, forma corpului, coafura, celelalte accesorii si nu in ultimul rand locatia.`,
				categorie: `Planificare`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Alegeti tortul de nunta si programati o degustare`,
				detalii: `Alegeti un tort clasic sau un tort cu tematica daca si nunta este cu tematica.
	Vorbiti cu cofetarul, degustati mai multe Torturi de nunta, alegeti glazura, crema, design - ul si decideti marimea si greutatea tortului in functie de la numarul de invitati.`,
				categorie: `Planificare`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Incepeti documentarea privind coafurile de nunta si machiaj`,
				detalii: `Ziua nuntii va fi foarte incarcata si timp de douazeci de ore vei sta la poze, vei dansa, vei merge destul de mult asa ca e important ca machiajul si coafura sa reziste.
	Indiferent ca vorbim despre coafura, machiaj sau orice tine de zona de Servicii beauty mirese, recomandarea noastra este sa apelezi la niste profesionisti.
	Incepe sa studiezi cateva modele de coafuri si machiaje pe care ti - ar placea sa le incerci.Poti merge la un stilist pentru a primi sfaturi de ingrijire a parului, machiaj, tuns, vopsit si multe altele.`,
				categorie: `Planificare`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Creati invitatiile`,
				detalii: `Folositi aplicatia noastra pentru a crea invitatiile digitale.Este recoamandat ca acestea sa fie in aceeasi tema cu nunta pentru a crea o simetri ce va oglindi personalitatea voastra.`,
				categorie: `Invitatii`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Rezervați lautari pentru obiceiuri`,
				detalii: `In functie de zona geografica a tarii intalnim o multime de obiceiuri si traditii legate de nunta, de la petitul miresei, fedeles, brad, apa miresei, barbieritul mirelui, imbracatul miresei, hora miresei, ruperea turtei, samd.

	Daca veti alege sa urmati macar o parte dintre ceste obiceiuri si traditii romanesti veti avea nevoie de un lautar sau un interpret de Muzica live.`,
				categorie: `Muzica & Divertisment`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Rezervati efectele speciale`,
				detalii: `Pentru ca evenimentul sa ramana mereu viu in amintirile voastre si ale participantilor, alegeti sa fie de efect.Puteti opta sa folositi in timpul momentelor cheie, efecte speciale impresionanete: fum greu pentru dansul mirilor, artificii interioare pentru momente speciale, artificii exterioare, gheata carbonica, schele cu lumini si multe altele.

	In general funizorii din categoriile Dj nunta, Muzica live nunta si Divertisment nunta ofera si astfel de servicii.`,
				categorie: `Muzica & Divertisment`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Rezervați transportul pentru voi si invitati`,
				detalii: `Limuzine, autocare, calesti, masini de epoca, motociclete, baloane cu aer cald, barcute sau vaporase, biciclete, carute de la tara sau elicoptere, alegerea este a voastra.Gasiti toate tipurile de mijloace de transport in categoria Masini nunta.`,
				categorie: `Planificare`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Rezervati profesionistii pentru  coafura si machiaj`,
				detalii: `Rezerva profesionistii de Servicii beauty mirese`,
				categorie: `Planificare`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Comandati tortul pentru nunta`,
				detalii: `Tortul de nunta trebuie comandat cu cateva luni înainte de eveniment, mai ales daca nunta are loc in plin sezon.Nu uitati sa intrebati cofetarul cu cat timp înainte de nunta va prepara tortul si cum va decurge livrarea`,
				categorie: `Planificare`,
				interval: `4-6 luni înainte de eveniment`
			},
			{
				title: `Cumparati accesoriile pentru mire si mireasa`,
				detalii: `Este momentul sa completati tinuta cu cateva Accesorii mirese.Este clar ca rochia de mireasa va fi vedeta nuntii insa accesoriile potrivite te pot ajuta sa o pui in evidenta si sa iti completeze look - ul.O tinuta este scoasa din anonimat prin accesorii asa ca este momentul sa alegi cateva accesorii care iti vor completa look - ul.`,
				categorie: `Planificare`,
				interval: `2-3 luni înainte de eveniment`
			},
			{
				title: `Trimiterea invitatiilor`,
				detalii: `Folositi tool - ul nostru de Invitații pentru a avea o evidenta permanenta asupra numarului si status - ului fiecarui invitat.`,
				categorie: `Invitatii`,
				interval: `2-3 luni înainte de eveniment`
			},
			{
				title: `Cumparati verighetele`,
				detalii: `Este foarte important ca in momentul in care cumparati verighetele sa fiti siguri de alegerea facuta, verighetele nu sunt doar simple Bijuterii.Incercati mai multe modele, materiale si dimensiuni pana cand gasiti verighetele perfecte pentru voi.`,
				categorie: `Planificare`,
				interval: `2-3 luni înainte de eveniment`
			},
			{
				title: `Cumpara un cadou de nunta pentru partener`,
				detalii: `Surprinde - ti partenerul cu un cadou unic.`,
				categorie: `Planificare`,
				interval: `2-3 luni înainte de eveniment`
			},
			{
				title: `Ganditi - va la ceva unic pentru nunta voastra`,
				detalii: `Tu si partenerul tau aveti o dragoste unica - ganditi - va la ceva distractiv si special pentru a va face ziua nuntii speciala!Lampioane, porumbei, artificii, faclii, felinarele ?`,
				categorie: `Planificare`,
				interval: `2-3 luni înainte de eveniment`
			},
			{
				title: `Intalniti - va cu echipa foto & video`,
				detalii: `Stabiliti cu fotograful ultimele detalii, povestiti despre asteptarile pe care le aveti de la el si spuneti - i care sunt cele mai importante momente pentru voi: cadrele surprinse in timpul ceremoniei religioase, cadrele din timpul petrecerii in care sa apara toti invitatii, detaliile, decorului samd.Daca aveti posibilitatea, realizati o sedinta foto Save the Date care va va ajuta sa va acomodati unii cu altii si in acelasi timp veti avea si fotografii dragute cu voi pe care sa le impartasiti in mediul online cu prietenii.`,
				categorie: `Foto & Video`,
				interval: `2-3 luni înainte de eveniment`
			},
			{
				title: `Este timpul sa faci programarile pentru coafor`,
				detalii: `Pentru ca in ziua cea mare sa fii pur si simplu stralucitoare fa programari din timp pentru coafor si machiaj dar tine cont si de restul serviciilor: dermatolog, dentist, tratamente faciale, salon de bronzat, epilat, manichiura si pedichiura, Servicii beauty mirese.`,
				categorie: `Planificare`,
				interval: `2-3 luni înainte de eveniment`
			},
			{
				title: `Comandati accesoriile pentru petrecere`,
				detalii: `Ganditi - va la accesorii de nunta distractive pentru petrecere, palarii, esarfe, bratari, samd.`,
				categorie: `Planificare`,
				interval: `2-3 luni înainte de eveniment`
			},
			{
				title: `Verificati transportul pentru voi si invitati`,
				detalii: `Asigurati - va ca comunicati ora, locul, vehiculele si daca exista oaspeti cu nevoi speciale.`,
				categorie: `Planificare`,
				interval: `2-3 luni înainte de eveniment`
			},
			{
				title: `Verificati cu preotul si cu ofiterul starii civile`,
				detalii: `Stabiliti ultimele detalii si definitivati orele exacte.`,
				categorie: `Planificare`,
				interval: `2-3 luni înainte de eveniment`
			},
			{
				title: `Ridicati verighetele`,
				detalii: `WOW chiar se intampla`,
				categorie: `Planificare`,
				interval: `cu o  luna înainte`
			},
			{
				title: `Revizuiti lista de invitati si confirmarile`,
				detalii: `Revizuiti Lista de Invitati si luati legatura cu cei care nu au raspuns inca.`,
				categorie: `Planificare`,
				interval: `cu o  luna înainte`
			},
			{
				title: `Ultimele ajustari pentru tinuta de nunta`,
				detalii: `Fa - ti o programare si mai probeaza odata tinuta de nunta înainte sa o ridici.`,
				categorie: `Planificare`,
				interval: `cu o  luna înainte`
			},
			{
				title: `Finalizeaza lista de invitati si asezarea la mese`,
				detalii: `Este momentul sa utilizati aplicatia noastra ca sa finalizati lista de invitati si sa va ocupati de asezarea lor la mese.`,
				categorie: `Planificare`,
				interval: `cu o  luna înainte`
			},
			{
				title: `Confirmati meniul de nunta`,
				detalii: `Confirmati la local numarul si tipul meniurilor si eventualele nevoi dietetice, restrictii sau alergii daca este cazul.`,
				categorie: `Planificare`,
				interval: `cu o  luna înainte`
			},
			{
				title: `Creati un program pentru seara nuntii`,
				detalii: `Programul pentru ziua nuntii va ajuta sa anticipati desfasurarea evenimentelor si sa depasiti mai usor provocarea organizarii acestui eveniment grandios.
				Ganditi - va la un moment de inceput si de final si lasati mult timp pentru dans!`,
				categorie: `Muzica & Divertisment`,
				interval: `cu o  luna înainte`
			},
			{
				title: `Asigurati - va ca cei din escorta voastra cunosc programul`,
				detalii: `Cei din escorta voastra vor trebui sa stie foarte bine care este programul zilei.Lucrurile se intampla foarte repede si e bine de stiut cine, cand si unde trebuie sa fie.`,
				categorie: `Planificare`,
				interval: `cu 2 saptamani înainte`
			},
			{
				title: `Incepeti sa exersati dansul mirilor`,
				detalii: `Dansul mirilor este unul dintre cele mai emotionante si frumoase momente ale nuntii, iar melodia primului dans ti - o vei aminti cu siguranta toata viata.
				In momentul in care v - ati hotarat la o lista de melodii cel mai bine ar fi sa stabiliti o sedinta de consultanta la o scoala de dans sau la un coregraf.`,
				categorie: `Muzica & Divertisment`,
				interval: `cu 2 saptamani înainte`
			},
			{
				title: `Confirmati ultimele detalii cu cei care fac parte din escorta de nunta`,
				detalii: `Confirmati detaliile cu escorta de nunta.Asigurati - va ca toata lume cunoaste care sunt responsabilitatile si calendarul zilei.`,
				categorie: `Planificare`,
				interval: `cu o saptamana înainte`
			},
			{
				title: `Confirmati ultimele detalii cu furnizorii`,
				detalii: `Confirmati ultimele detalii cu toti furnizorii si nu uitati sa aveti bani de tips.`,
				categorie: `Planificare`,
				interval: `cu o saptamana înainte`
			},
			{
				title: `Verificati ultimele detalii`,
				detalii: `O ultima verificare înainte de marele eveniment.
				Curata - ti inelul de logodna, pune la punct tot ce tine de decor, fa un mic bagaj cu toate lucrurile de care vei avea nevoie in ziua cea mare, vorbeste la locatie, vorbeste cu cofetarul, discuta cu cantaretii, fotograful, videograful si exersati dansul mirilor.`,
				categorie: `Planificare`,
				interval: `cu o saptamana înainte`
			},
			{
				title: `Zambiti ati reusit`,
				detalii: `Felicitati! Casa de piatra!`,
				categorie: `Planificare`,
				interval: `ziua nunții`
			}
		],
		tasks: [],
		dispTasks: [],

		categories: [allTypes, "Planificare", "Muzica & Divertisment", "Foto & Video", "Ceremonie", "Flori & Decoratiuni", "Bijuterii", "Invitatii"],
		intervals: [allTypes, "Cu mai mult de 1 an înainte de eveniment", "10-12 luni înainte de eveniment", "7-9 luni înainte de eveniment", "4-6 luni înainte de eveniment",
			"2-3 luni înainte de eveniment", "cu o  luna înainte", "cu 2 saptamani înainte", "cu o saptamana înainte", "ziua nunții"],

		categoryChoice: allTypes,
		intervalChoice: allTypes,

		isModalOpen: false,
		taskToShow: {
			title: ``,
			detalii: ``,
			categorie: ``,
			interval: ``
		},

		detailsModal: true,
		waitModal: false,

		showModalCategories: false,
		showModalIntervals: false,
	}

	componentDidMount = async () => {
		//get event date
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.event_id_eveniment, auth);

		var newTasks = this.state.weddingTasks.map((elem, index) => {
			elem.done = true
			elem.index = index
			elem.dueDate = this.getDate(info.data.dataEvent, elem.interval);
			elem.cost = 0;
			elem.category = elem.categorie

			return elem
		})

		this.setState({
			tasks: newTasks,
			dispTasks: newTasks
		})
	}

	getDate = (eventDate, interval) => {
		var date = new Date(eventDate)

		if (interval === "Cu mai mult de 1 an înainte de eveniment") {
			date.setFullYear(date.getFullYear() - 1)
		}

		if (interval === "10-12 luni înainte de eveniment") {
			date.setMonth(date.getMonth() - 10)
		}

		if (interval === "7-9 luni înainte de eveniment") {
			date.setMonth(date.getMonth() - 7)
		}

		if (interval === "4-6 luni înainte de eveniment") {
			date.setMonth(date.getMonth() - 4)
		}

		if (interval === "2-3 luni înainte de eveniment") {
			date.setMonth(date.getMonth() - 2)
		}

		if (interval === "cu o  luna înainte") {
			date.setMonth(date.getMonth() - 1)
		}

		if (interval === "cu 2 saptamani înainte") {
			date.setDate(date.getDate() - 14)
		}

		if (interval === "cu o saptamana înainte") {
			date.setDate(date.getDate() - 7)
		}

		if (interval === "ziua nunții") {
			date.setDate(date.getDate() - 0)
		}

		return date
	}

	checkTask = (index) => {
		var newTasks = this.state.tasks
		var taskIndex = this.state.dispTasks[index].index
		var status = this.state.tasks[taskIndex].done

		newTasks[taskIndex].done = !status

		this.setDispTask(0, newTasks)
	}

	changeCategoryChoice = (choice) => {
		this.setState({
			categoryChoice: choice,
			showModalCategories: false
		})

		this.setDispTask(1, choice)
	}

	changeIntervalChoice = (choice) => {
		this.setState({
			intervalChoice: choice,
			showModalIntervals: false
		})

		this.setDispTask(2, choice)
	}

	onTaskClick = (index) => {
		this.setState({
			isModalOpen: true,
			taskToShow: this.state.dispTasks[index]
		})
	}

	toggleModal = () => {
		let status = this.state.isModalOpen

		this.setState({
			isModalOpen: !status
		})
	}

	setDispTask = (step, choice) => {
		let allTasks = this.state.tasks

		if (step === 0) {
			allTasks = choice
		}

		var categoryChoice = this.state.categoryChoice
		if (step === 1) {
			categoryChoice = choice
		}

		var firstFilter = allTasks.filter(elem => categoryChoice === allTypes || elem.categorie === categoryChoice)

		var intervalChoice = this.state.intervalChoice
		if (step === 2) {
			intervalChoice = choice
		}

		var secondFilter = firstFilter.filter(elem => intervalChoice === allTypes || elem.interval === intervalChoice)

		this.setState({
			dispTasks: secondFilter
		})
	}

	saveTask = async () => {
		//get chosen task
		let chosenTasks = this.state.tasks.filter(task => task.done)

		this.setState({
			waitModal: true
		})

		this.setDispTask(-1, null)

		let saveTasks = chosenTasks.map(async (task) => {
			var obj = {
				event: this.props.event_id,
				title: task.title,
				description: task.detalii,
				cost: "0",
				done: false,
				dueDate: new Date(task.dueDate),
				categorie: task.category
			}

			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			await axios.post(process.env.REACT_APP_DOMAIN + 'tasks', obj, auth)
		})

		Promise.all(saveTasks).then(() => {
			this.props.exitBasicTasks()
		})
	}

	closeModalCateories = () => {
		this.setState({
			showModalCategories: false,
		})
	}

	closeModalIntervals = () => {
		this.setState({
			showModalIntervals: false,
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		return (
			<div className="BasicTask-container">
				<div className="BasicTask-filters">
					<div className={"Filter"} onClick={() => { this.setState({ showModalCategories: true }) }}>
						<div className="left"></div>

						<div className="center">Categorie: {this.state.categoryChoice}</div>

						<MdKeyboardArrowDown color={"white"} className="right" size={20} />
					</div>

					<ModalTasks options={this.state.categories} activeOption={this.state.categoryChoice} onClickEvent={this.changeCategoryChoice} isOpen={this.state.showModalCategories} closeModal={this.closeModalCateories} />

					<div className={"Filter"} onClick={() => { this.setState({ showModalIntervals: true }) }}>
						<div className="left"></div>

						<div className="center">Interval: {this.state.intervalChoice}</div>

						<MdKeyboardArrowDown color={"white"} className="right" size={20} />
					</div>

					<ModalTasks options={this.state.intervals} activeOption={this.state.intervalChoice} onClickEvent={this.changeIntervalChoice} isOpen={this.state.showModalIntervals} closeModal={this.closeModalIntervals} />
				</div>

				<div className="BasicTask-contents">
					{this.state.dispTasks.map((task, index) => {
						return <Task
							key={index}
							task={task}
							checkChange={() => this.checkTask(index)}
							onClick={() => this.onTaskClick(index)}
						/>
					})}

					<BackAndConfirmButtons textBack={"Inapoi"} textConfirm={"Confirma"} functionBack={this.goBack} functionConfirm={this.saveTask} />
				</div>

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal-tasks',
					closeButton: 'modal-close-button',
				}} center open={this.state.isModalOpen} onClose={this.toggleModal}>
					<div>
						<div className="title">{this.state.taskToShow.title}</div>

						<div className="description">{this.state.taskToShow.detalii}</div>

						<div className="category">Categorie: {this.state.taskToShow.categorie}</div>
					</div>
				</Modal>

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.waitModal} onClose={() => { }} onEscKeyDown={() => { }} onOverlayClick={() => { }}>
					<div style={{marginTop: "30px"}}>
						<p>Please wait</p>
					</div>
				</Modal>

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.detailsModal} onClose={() => { this.setState({ detailsModal: false }) }}>
					<div style={{ width: "200px", marginTop: "30px", textAlign: "center" }}>
						<p>Pentru usurarea planificarii, am pregatit o lista de task-uri din care puteti alege. Bifati cele de care aveti nevoie si confirmati prin apasarea butonului din josul paginii.</p>

						<Button style={{ backgroundColor: "#00afcc", color: "white", borderRadius: "10px", marginTop: "20px" }} onClick={() => { this.setState({ detailsModal: false }) }}>
							Am inteles
						</Button>
					</div>
				</Modal>
			</div>
		)
	}
}

export default BasicTask;