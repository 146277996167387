import React from 'react'
import { InputGroup, Input, Icon, Button, Dropdown } from 'rsuite'
import './Responses.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'
import Modal from 'react-responsive-modal'
import axios from 'axios'

import DataTable from './ForInviteResponses/DataTable'
import { BackButton, DataCards, ShowPackageData } from '../../components/Helper'

let participa = "yes";
let nuParticipa = "no";
let nuStieDacaParticipa = "don't know";
let all = "toti";

class InviteResponses extends React.Component {
	state = {
		invites: [],
		dispInv: [],
		totalAnswers: 0,
		yesAnswers: 0,
		dontKnowAnswers: 0,
		noAnswers: 0,
		modalRaport: false,

		questions: [],
		questionsFilterOptions: [],

		modalFilter: false,
		participationChoice: all,
		partnerChoice: all,
		kidsChoice: all,

		mentionToDisplay: "",

		currentSearch: "",

		packageData: {},

		packageType: 0,
		needsToUpgrade: false,
		maxReplies: 5,

		loading: true,
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		//get custom questions amount
		let event_data = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
		let questions = event_data.data.questions
		if (!event_data.data.useCustomQuestions) {
			questions = []
		}

		//get answers
		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
			.then(async response => {
				let yesAnswers = 0
				let dontKnowAnswers = 0
				let noAnswers = 0

				let invites = response.data.responses.map(invitee => {
					var data = invitee.response

					//options: "yes", "don't know", "no"
					var ans = data.answer

					var totalPeople = 1

					if (ans === "yes") {
						if (data.insotit) {
							totalPeople++
						}

						if (data.cuCopii) {
							totalPeople += Number(data.nr_copii)
						}

						if (totalPeople > 1) {
							totalPeople += " pers"
						} else {
							totalPeople = "1 pers"
						}

						yesAnswers++;

						let elem = {
							answer: ans,
							name: data.nume,
							prenume: data.prenume,
							insotit: data.insotit,
							nume_insotitor: data.nume_insotitor,
							cuCopii: data.cuCopii,
							nr_copii: data.nr_copii,
							totalPeople: totalPeople,
							responseId: invitee.id
						}

						for (let i = 0; i < questions.length; i++) {
							elem["answer" + i] = invitee.response["answer" + i]
						}

						return elem
					} else {
						if (ans === "no") {
							noAnswers++
						} else {
							dontKnowAnswers++
						}

						let elem = {
							answer: ans,
							name: data.nume,
							prenume: data.prenume,
							insotit: false,
							nume_insotitor: "-",
							cuCopii: false,
							nr_copii: "-",
							totalPeople: "-",
							responseId: invitee.id
						}

						for (let i = 0; i < questions.length; i++) {
							elem["answer" + i] = ""
						}

						return elem
					}
				})

				//-----------
				// if (invites.length > this.state.maxReplies) {
				// 	invites = invites.filter((_, index) => index < 5)

				// 	this.setState({
				// 		needsToUpgrade: true
				// 	})
				// }
				//-----------

				let questionsFilterOptions = []

				for (let i = 0; i < questions.length; i++) {
					questionsFilterOptions.push(all)
				}

				this.setState({
					invites: invites,
					dispInv: invites,
					packageData: response.data,
					totalAnswers: yesAnswers + dontKnowAnswers + noAnswers,
					yesAnswers: yesAnswers,
					dontKnowAnswers: dontKnowAnswers,
					noAnswers: noAnswers,
					loading: false,
					questions: questions,
					questionsFilterOptions: questionsFilterOptions
				})

				let totalResponses = response.data.totalResponses
				let receivedResponses = response.data.responses.length
				let seenResponses = event_data.data.count_seen_invite_responses

				let updateSeenRsponses = false
				let newSeenResponsesCount = seenResponses

				if (totalResponses > seenResponses) {
					if (totalResponses === receivedResponses) {
						//limit not passed
						updateSeenRsponses = true
						newSeenResponsesCount = totalResponses
					} else {
						//limit passed, check 2
						if (receivedResponses > seenResponses) {
							updateSeenRsponses = true
							newSeenResponsesCount = receivedResponses
						}
					}
				}

				if (updateSeenRsponses) {
					let obj = {
						count_seen_invite_responses: newSeenResponsesCount
					}

					axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + event_data.data.id, obj, auth)
				}
			})
	}

	filterInvites = (filterStep, filterChoice) => {
		let allParticipants = [...this.state.invites];

		//filter by participation
		let participationFilterChoice = this.state.participationChoice
		if (filterStep === 1) {
			participationFilterChoice = filterChoice
		}
		let filteredByParticipation = allParticipants.filter(element => participationFilterChoice === all || element.answer === participationFilterChoice)

		//filter by name
		let nameFilterChoice = this.state.currentSearch.toLocaleLowerCase()
		if (filterStep === 2) {
			nameFilterChoice = filterChoice.toLocaleLowerCase()
		}
		let filteredByName = filteredByParticipation.filter(element => {
			return element.name.toLocaleLowerCase().indexOf(nameFilterChoice) !== -1 ||
				element.prenume.toLocaleLowerCase().indexOf(nameFilterChoice) !== -1
		})

		//filter by partner
		let partnerFilterChoice = this.state.partnerChoice
		if (filterStep === 3) {
			partnerFilterChoice = filterChoice.toLocaleLowerCase()
		}
		let filteredByPartner = filteredByName.filter(element => {
			return partnerFilterChoice === all
				|| (element.insotit === true && partnerFilterChoice === "yes")
				|| (element.insotit === false && partnerFilterChoice === "no")
		})

		//filter by kids
		let kidsFilterChoice = this.state.kidsChoice
		if (filterStep === 4) {
			kidsFilterChoice = filterChoice.toLocaleLowerCase()
		}
		let filteredByKids = filteredByPartner.filter(element => {
			return kidsFilterChoice === all
				|| (element.cuCopii === true && kidsFilterChoice === "yes")
				|| (element.cuCopii === false && kidsFilterChoice === "no")
		})

		//filter by custom questions
		let customQuestionsFilters = this.state.questionsFilterOptions
		if (filterStep === 5) {
			customQuestionsFilters = filterChoice
		}
		let finalResult = [...filteredByKids]

		customQuestionsFilters.map((filter, index) => {
			if (filter === all) return null

			let newResult = finalResult.filter(
				element => this.state.questions[index].type === 0
					? (element["answer" + index] !== "" && filter === participa) || (element["answer" + index] === "" && filter === nuParticipa)
					: element["answer" + index] === filter
			)

			finalResult = [...newResult]

			return null
		})

		this.setState({
			dispInv: finalResult
		})
	}

	changeParticipationChoice = (choice, shouldCloseModal = true) => {
		this.setState({
			participationChoice: choice,
			modalFilter: !shouldCloseModal,
		})

		this.filterInvites(1, choice)
	}

	onChange = (value) => {
		this.setState({
			currentSearch: value
		})

		this.filterInvites(2, value)
	}

	changePartnerChoice = (choice, shouldCloseModal = true) => {
		this.setState({
			partnerChoice: choice,
			modalFilter: !shouldCloseModal,
		})

		this.filterInvites(3, choice)
	}

	changeKidsChoice = (choice, shouldCloseModal = true) => {
		this.setState({
			kidsChoice: choice,
			modalFilter: !shouldCloseModal,
		})

		this.filterInvites(4, choice)
	}

	changeQuestionFilterChoice = (choice, index, shouldCloseModal = true) => {
		let oldFilters = [...this.state.questionsFilterOptions]

		oldFilters[index] = choice

		this.setState({
			questionsFilterOptions: oldFilters,
			modalFilter: !shouldCloseModal,
		})

		this.filterInvites(5, oldFilters)
	}

	toggleRaport = () => {
		let current = this.state.modalRaport;

		this.setState({
			modalRaport: !current
		})
	}

	toggleModal = () => {
		let current = this.state.modalFilter;

		this.setState({
			modalFilter: !current
		})
	}

	goToAddResponse = () => {
		this.props.history.push("/adaugaRaspuns/" + this.props.match.params.id)
	}

	goToEditResponse = (index) => {
		this.props.history.push("/editeazaRaspuns/" + this.props.match.params.id + "/" + this.state.dispInv[index].responseId)
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading === true) {
			return <p> Loading.. </p>
		}

		return (
			<div className="Responses-container">
				{/* <Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.modalRaport} onClose={this.toggleRaport}>
					<div>
						<h5> Raport raspunsuri </h5>

						<p style={{ marginTop: "5vh" }}> Participa: {this.state.yesAnswers} </p>
						<p style={{ marginTop: "5vh" }}> Nu participa: {this.state.noAnswers} </p>
						<p style={{ marginTop: "5vh" }}> Nu stie daca participa: {this.state.dontKnowAnswers} </p>
						<p style={{ marginTop: "5vh" }}> Total raspunsuri: {this.state.totalAnswers} </p>
					</div>
				</Modal> */}

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.modalFilter} onClose={this.toggleModal}>
					<div className="Responses-participation-modal">
						<p>Stare Participare</p>
						<Dropdown title={this.state.participationChoice === all ? "Toti" : this.state.participationChoice === "yes" ? "Da" : this.state.participationChoice === "no" ? "Nu" : "Indecis"}
							activeKey={this.state.participationChoice} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
							<Dropdown.Item eventKey={all} onClick={() => this.changeParticipationChoice(all, false)}> Toti participanti </Dropdown.Item>
							<Dropdown.Item eventKey={participa} onClick={() => this.changeParticipationChoice(participa, false)} > Participa </Dropdown.Item>
							<Dropdown.Item eventKey={nuParticipa} onClick={() => this.changeParticipationChoice(nuParticipa, false)}> Nu Participa </Dropdown.Item>
							<Dropdown.Item eventKey={nuStieDacaParticipa} onClick={() => this.changeParticipationChoice(nuStieDacaParticipa, false)}> Indecis </Dropdown.Item>
						</Dropdown>

						<p>Insotit</p>
						<Dropdown title={this.state.partnerChoice === all ? "Toti" : this.state.partnerChoice === "yes" ? "Da" : "Nu"}
							activeKey={this.state.partnerChoice} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
							<Dropdown.Item eventKey={all} onClick={() => this.changePartnerChoice(all, false)}> Toti </Dropdown.Item>
							<Dropdown.Item eventKey={participa} onClick={() => this.changePartnerChoice(participa, false)} > Da </Dropdown.Item>
							<Dropdown.Item eventKey={nuParticipa} onClick={() => this.changePartnerChoice(nuParticipa, false)}> Nu </Dropdown.Item>
						</Dropdown>

						<p>Copii</p>
						<Dropdown title={this.state.kidsChoice === all ? "Toti" : this.state.kidsChoice === "yes" ? "Cu" : "Fara"}
							activeKey={this.state.kidsChoice} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
							<Dropdown.Item eventKey={all} onClick={() => this.changeKidsChoice(all, false)}> Toti </Dropdown.Item>
							<Dropdown.Item eventKey={participa} onClick={() => this.changeKidsChoice(participa, false)} > Cu </Dropdown.Item>
							<Dropdown.Item eventKey={nuParticipa} onClick={() => this.changeKidsChoice(nuParticipa, false)}> Fara </Dropdown.Item>
						</Dropdown>

						{this.state.questions.map((question, index) => {
							return <div key={index} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
								<p>{question.title}</p>

								{question.type === 0
									? <Dropdown title={this.state.questionsFilterOptions[index] === all ? "Toti" : this.state.questionsFilterOptions[index] === participa ? "A raspuns" : "Nu a raspuns"} activeKey={this.state.questionsFilterOptions[index]} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
										<Dropdown.Item eventKey={all} onClick={() => this.changeQuestionFilterChoice(all, index, false)}> Toti </Dropdown.Item>
										<Dropdown.Item eventKey={participa} onClick={() => this.changeQuestionFilterChoice(participa, index, false)} > A Raspuns </Dropdown.Item>
										<Dropdown.Item eventKey={nuParticipa} onClick={() => this.changeQuestionFilterChoice(nuParticipa, index, false)}> Nu a raspuns </Dropdown.Item>
									</Dropdown>
									: <Dropdown title={this.state.questionsFilterOptions[index]} activeKey={this.state.questionsFilterOptions[index]} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
										<Dropdown.Item eventKey={all} onClick={() => this.changeQuestionFilterChoice(all, index, false)}> Toti </Dropdown.Item>
										{question.options.map((option, optionIndex) => {
											return <Dropdown.Item key={optionIndex} eventKey={option} onClick={() => this.changeQuestionFilterChoice(option, index, false)}> {option} </Dropdown.Item>
										})}
									</Dropdown>
								}
							</div>
						})}
					</div>
				</Modal>

				<HeaderWhite goBack={this.goBack} />

				<div className='Responses-content'>
					<div className='title'>Raspunsuri invitatii</div>

					<DataCards cards={[
						{
							data: `Total invitati: ${this.state.totalAnswers}`,
							color: "#05AFF2"
						},
						{
							data: `Participa: ${this.state.yesAnswers}`,
							color: "#19B899"
						},
						{
							data: `Nu participa: ${this.state.noAnswers}`,
							color: "#F26157"
						},
						{
							data: `Indecisi: ${this.state.dontKnowAnswers}`,
							color: "#E3B505"
						}
					]} />

					<div className='add-response-button'>
						<Icon icon="plus-circle" size="lg" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={this.goToAddResponse} />
						<div onClick={this.goToAddResponse}>Adauga raspuns</div>
					</div>

					<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", border: "1px solid blue", margin: "10px 5px" }}>
						<p style={{ color: "blue", padding: "10px 20px" }} onClick={() => this.toggleModal("Filters")}> Filtre participanti </p>
					</div>

					<InputGroup style={{ margin: "10px auto" }}>
						<InputGroup.Addon>
							<Icon icon="search" />
						</InputGroup.Addon>

						<Input onChange={this.onChange} placeholder="Numele invitatului" />
					</InputGroup>

					<DataTable dispInv={this.state.dispInv} goToEditResponse={this.goToEditResponse} questions={this.state.questions} />

					<BackButton text={"Inapoi"} function={this.goBack} />
				</div>

				<ShowPackageData packageData={this.state.packageData} history={this.props.history} eventId={this.props.match.params.id} />

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default InviteResponses;