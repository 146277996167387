import React from 'react'
import HeaderWhite from '../../../components/HeaderWhiteBack'
import {Uploader, Button, SelectPicker, Icon, DatePicker, Dropdown} from 'rsuite'
import Modal from "react-responsive-modal";
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';

import NavbarColor from '../../../components/NavbarColor'
import {photoTemplates} from "../../../components/memorybooth/phototemplates";
import axios from "axios";
import {BackAndConfirmButtons, ConfirmButton} from "../../../components/Helper";
import {toast} from "react-toastify";
import TemplateRenderLive from "../../MemoryBoothPhotoConfig/components/TemplateRenderLive/TemplateRenderLive";
import {toPng} from "html-to-image";


class MemoryBoothPhotoConfig extends React.Component {
    state = {
        loading: true,

        memorybooth: {},
        selectedTemplate: 0,
        templateDetails: {},

        weddingId: 0,
        previewDataUrl: false



    }

    constructor() {
        super();

        this.printRef = React.createRef();
    }

    componentDidMount = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }


        await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.match.params.id, auth)
            .then(response => {

                let newTemplateDetails = response.data.memorybooth && response.data.memorybooth.photo && response.data.memorybooth.photo.templateDetails ? response.data.memorybooth.photo.templateDetails : {}
                newTemplateDetails.live = 'live'

                this.setState({
                    weddingId: response.data.id,
                    memorybooth: response.data.memorybooth,
                    selectedTemplate: response.data.memorybooth && response.data.memorybooth.photo && response.data.memorybooth.photo.templateDetails ? response.data.memorybooth.photo.templateDetails.id : 0,
                    templateDetails: newTemplateDetails,
                    loading:false
                }, () => {

                    if(newTemplateDetails) {
                        this.populateTemplate()
                    }
                })

            })
            .catch(err => {
                this.setState({
                    loading: false
                })
                console.log(err);
            })
    }

    populateTemplate = async () => {
        let newTemplateDetails = {...this.state.templateDetails}


        await this.toDataURL('/images/memorybooth/esantion_poza_dslr.jpg')
            .then(dataUrl => {
                for (let i = 0; i < newTemplateDetails.sections.length; i++) {
                    if (newTemplateDetails.sections[i].type === 0) {
                        newTemplateDetails.sections[i].type = 1
                        newTemplateDetails.sections[i].value = dataUrl
                        newTemplateDetails.sections[i].base64 = true
                        break;
                    }
                }

                this.setState({
                    templateDetails: newTemplateDetails
                }, async () => {
                    await this.timeout(1000)
                    const element = this.printRef.current;


                    await toPng(element, { cacheBust: false, })
                        .then((dataUrl) => {
                            this.setState({previewDataUrl: dataUrl})
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
            })





    }

    timeout = (delay) => {
        return new Promise( res => setTimeout(res, delay) );
    }

    toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))

    goBack = () => {
        this.props.history.go(-1)
    }

    render() {

        return (
            <div className="MemoryBoothPhotoConfig-container">
                <HeaderWhite goBack={this.goBack}/>

                {
                    this.state.loading ?
                        <div>Se incarca ... </div>
                        :
                        <div className="MemoryBoothPhotoConfig-contents">

                            {
                                this.state.previewDataUrl ?
                                    <img src={this.state.previewDataUrl}  style={{width:'80vw'}}/>
                                    :null
                            }

                            {
                                this.state.templateDetails &&
                                <div style={{position:"absolute", top: "-200vh"}}>
                                <TemplateRenderLive
                                    reff={this.printRef}
                                    key={123}
                                    templateDetails={this.state.templateDetails}
                                />
                                </div>
                            }

                        </div>
                }

                <NavbarColor history={this.props.history}/>
            </div>
        )
    }
}

export default MemoryBoothPhotoConfig;
