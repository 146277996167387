import React, {useState, useEffect} from 'react';
import './MemoryBooth.scss'
import CustomInput from "../../components/custom/CustomInput";
import {Icon} from "rsuite";
import {Link} from "react-router-dom";
import {useParams} from 'react-router'
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify'
import axios from "axios";

export default function MemoryBoothPreview() {

    const { id } = useParams();

    const [data,setData] = useState(false)


    const getFile = async () => {

        let info = await axios.get(process.env.REACT_APP_DOMAIN + 'memory-booth-files/' + id);

        setData(info.data)

    }

    useEffect(() => {
        getFile()
    }, [])


    return(<div >
        <div>
            {
                data && data.url.includes('.gif') ?
                    <img src={data.url} style={{width:'100%', maxWidth:'750px', height:'550px'}} crossOrigin={"true"} />
                    :
                    <video
                        src={data.url}
                        width={'100%'}
                        style={{maxWidth:'900px'}}
                        crossOrigin={"true"}
                        height={700}
                        controls={true}
                    />
            }

        </div>

    </div>)
}

