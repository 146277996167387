import React from 'react'
import { toast } from 'react-toastify'
import { Button } from 'rsuite'
import CustomInput from '../../../components/custom/CustomInput'
import { BackAndConfirmButtons, usedCurrency } from '../../../components/Helper'
import './Expense.scss'

class ChangeExpense extends React.Component {
	state = {
		title: this.props.expense.title,
		cost: this.props.expense.cost,
		advance: this.props.expense.advance,

		sendingData: false,
		confirmDelete: false,
	}

	changeData = (key, value) => {
		if ((key === "cost" || key === "advance") && "" + Number(value) === "NaN") {
			return
		}

		this.setState({
			[key]: value
		})
	}

	changeExpense = () => {
		if (this.state.title === "") {
			toast.error("Trebuie sa introduci un titlu");
			return
		}

		if (this.state.cost === "") {
			toast.error("Trebuie sa introduci un pret");
			return
		}

		var expense = {
			title: this.state.title,
			cost: this.state.cost,
			advance: this.state.advance,
			eventID: this.props.expense.eventID,
			id: this.props.expense.id
		}

		this.setState({
			sendingData: true
		})

		this.props.changeExpense(expense)
	}

	deleteExpense = () => {
		this.setState({
			sendingData: true
		})

		this.props.deleteExpense()
	}

	render() {
		var boldStyle = {
			fontWeight: "bold",
			fontSize: 20
		}

		var inputStyle = {
			marginTop: 10,
			marginBottom: 10,
			textAlign: 'center',
			width: '90%',
			maxWidth: "var(--container-max-width)",
			alignSelf: 'center',
			backgroundColor: "#E5F7FE",
			borderRadius: "10px"
		}

		return (
			<div className="Expense-contents">
				<p style={boldStyle}>Adauga Cheltuieli</p>

				<CustomInput style={inputStyle} value={this.state.title} onChange={(value) => this.changeData("title", value)} placeholder={"Tilu"} />

				<CustomInput style={inputStyle} value={this.state.cost} onChange={(value) => this.changeData("cost", value)} placeholder={"Cost - " + usedCurrency} />

				<CustomInput style={inputStyle} value={this.state.advance} onChange={(value) => this.changeData("advance", value)} placeholder={"Avans (" + usedCurrency + ")"} />

				<BackAndConfirmButtons loading={this.state.sendingData} textBack={"Inchide"} textConfirm={"Salveaza"} functionBack={this.props.back} functionConfirm={this.changeExpense} />

				<Button color="red" style={{ marginTop: "10px", borderRadius: "20px", width: "150px" }} onClick={() => this.setState({ confirmDelete: true })}>Sterge</Button>

				{this.state.confirmDelete && <Button loading={this.state.sendingData} style={{ marginTop: 30, borderRadius: "20px", width: "250px" }} color="red" onClick={this.deleteExpense}>Confirma stergerea</Button>}
			</div>
		)
	}
}

export default ChangeExpense;