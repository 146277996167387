import React, { Component } from 'react';

class AudioVisualiser extends Component {
    constructor(props) {
        super(props);
        this.canvas = React.createRef();
    }

    componentDidUpdate() {
        this.draw();
    }

    draw() {
        const { audioData } = this.props;
        const canvas = this.canvas.current;
        const height = canvas.height;
        const width = canvas.width;
        const context = canvas.getContext('2d');
        let x = 0;
        const sliceWidth = (width * 1.0) / audioData.length;

        context.lineWidth = 2;
        context.strokeStyle = 'rgb(239,99,91)';
        context.clearRect(0, 0, width, height);

        context.beginPath();
        context.moveTo(0, height / 2);
        for (const item of audioData) {
            const y = (item / 255.0) * height;
            context.lineTo(x, y);
            x += sliceWidth;
        }
        context.lineTo(x, height / 2);
        context.stroke();
    }
    
    /*
         <div style={{position:'absolute', top:0, right:0}}>
                Nivel microfon
                <br/>

                <div style={{width:'30px', height:'200px', border:'1px solid #000', display:'flex', alignItems:'flex-end', overflow:'hidden'}}>
                    <div style={{width:'100%', height:this.props.levelData + '%', backgroundColor:'red'}}></div>
                </div>
            </div>
     */

    render() {
        return <canvas width="1200" height="60" ref={this.canvas} className={"waveform"} />
    }
}

export default AudioVisualiser;