import React from 'react'
import { Icon } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'
import Modal from "react-responsive-modal";

import HeaderWhite from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'

import './Budget.scss'
import CustomInput from '../../components/custom/CustomInput'
import AddExpense from '../Expenses/Components/AddExpense'
import ChangeExpense from '../Expenses/Components/ChangeExpense'
import { BackAndConfirmButtons, BackButton, ConfirmButton, DataCards, DataTable, LoadingScreen, usedCurrency } from '../../components/Helper'

import SVG_Budget_Edit from '../../assets/BudgetSVG/edit-budget-icon.svg'

class Budget extends React.Component {
	state = {
		tasks: [],
		expenses: [],

		choice: "BUDGET",
		expenseInChange: -1,
		taskClicked: -1,

		eventId: "",

		budget: "",
		newBudget: "",
		totalSpending: 0,
		totalGiftMoney: 0,

		showModal: true,
		isLoadingRequest: false,
		loading: true
	}

	componentDidMount = async () => {
		let uniqueId = this.props.match.params.id

		//get budget from server
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + uniqueId, auth);

		if (info.data.budget !== 0) {
			this.setState({
				showModal: false,
				budget: info.data.budget,
				newBudget: info.data.budget
			})
		}

		this.setState({
			eventId: info.data.id
		})

		//get tasks from server
		let tasksResponse = await axios.get(process.env.REACT_APP_DOMAIN + 'tasks/list/byEvent/' + uniqueId, auth);

		//get tasks that cost more than 0
		let tasksWithCost = tasksResponse.data.filter(elem => elem.cost !== "0")

		//set tasks in state
		this.setState({
			tasks: tasksWithCost
		})

		//get expenses from server
		let expensesResponse = await axios.get(process.env.REACT_APP_DOMAIN + 'expenses/list/byEvent/' + uniqueId, auth);

		//get expenses that cost more than 0
		let expensesWithCost = expensesResponse.data.filter(elem => elem.cost !== "0")

		//set tasks in state
		this.setState({
			expenses: expensesWithCost
		})

		let total = 0

		//get total cost from tasks
		for (let i = 0; i < tasksWithCost.length; i++) {
			total += Number(tasksWithCost[i].cost)
		}

		//get total cost from expenses
		for (let i = 0; i < expensesWithCost.length; i++) {
			total += Number(expensesWithCost[i].cost)
		}

		//get gift money
		let responsesData = await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)

		let totalGiftMoney = 0

		responsesData.data.responses.map(elem => {
			if (elem.giftMoney) {
				totalGiftMoney += elem.giftMoney
			}

			return null
		})

		this.setState({
			totalSpending: total,
			totalGiftMoney: totalGiftMoney,
			loading: false
		})
	}

	setBudget = async () => {
		if (this.state.newBudget === "") {
			toast.error("Campul bugetului nu a fost completat")
			return
		}

		if (this.state.isLoadingRequest) {
			return
		}

		this.setState({
			isLoadingRequest: true
		})

		//send budget to server
		let obj = {
			budget: Number(this.state.newBudget)
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
			.then(response => {
				toast.success("Budgetul Setat")

				this.setState({
					budget: this.state.newBudget,
					isLoadingRequest: false,
					showModal: false
				})
			})
			.catch(err => {
				toast.error("Eroare, va rog reincercati")
			})
	}

	selectedTaskData = () => {
		let selectedTask = this.state.tasks[this.state.taskClicked]

		let dueDateParts = selectedTask.dueDate.split("T")[0].split("-")

		return <div className="task-container">
			<div className="title">{selectedTask.title}</div>

			<div className="description">{selectedTask.description}</div>

			<div className="category">Categorie: {selectedTask.categorie}</div>

			<div className="cost">Cost: {selectedTask.cost} {usedCurrency}</div>

			<div className="status">Status: {selectedTask.done ? "Terminat" : "Neterminat"}</div>

			<div className="dueDate">Due date: {dueDateParts[2]}-{dueDateParts[1]}-{dueDateParts[0]}</div>

			<ConfirmButton text={"Edit"} function={this.moveToEditTask} />
		</div>
	}

	moveToEditTask = () => {
		localStorage.setItem('taskToEditId', this.state.tasks[this.state.taskClicked].id);

		this.props.history.push('/plan/tasks/' + this.props.match.params.id)
	}

	saveExpense = async (expense) => {
		//send new expense to server
		let obj = {
			event: this.state.eventId,
			title: expense.title,
			cost: expense.cost,
			advance: expense.advance,
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'expenses', obj, auth)
			.then(response => {
				toast.success("Cheltuiala salvata cu succes")

				obj.id = response.data.id

				let newExpenses = [...this.state.expenses, obj]
				let newTotalSpending = Number(this.state.totalSpending) + Number(obj.cost)

				this.setState({
					expenses: newExpenses,
					totalSpending: newTotalSpending,
					choice: "",
					showModal: false
				})
			})
			.catch(err => {
				console.log(err)
				toast.error("Nu s-a putut finaliza, va rog reincercati")

				this.setState({
					choice: "",
					showModal: false
				})
			})
	}

	changeExpense = async (expense) => {
		let newTotalSpending = this.state.totalSpending + Number(expense.cost) - Number(this.state.expenses[this.state.expenseInChange].cost)

		let newExpenses = this.state.expenses
		newExpenses.splice(this.state.expenseInChange, 1, expense)

		var obj = {
			title: expense.title,
			cost: expense.cost,
			advance: expense.advance,
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'expenses/' + expense.id, obj, auth)
			.then(_ => {
				toast.success("Modificari efectuate cu succes")

				this.setState({
					expenses: newExpenses,
					totalSpending: newTotalSpending,
					choice: "",
					showModal: false
				})
			})
			.catch(err => {
				console.log(err)
				toast.error("Nu s-a putut finaliza, va rog reincercati")

				this.setState({
					choice: "",
					showModal: false
				})
			})
	}

	deleteExpense = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.delete(process.env.REACT_APP_DOMAIN + 'expenses/' + this.state.expenses[this.state.expenseInChange].id, auth)
			.then(_ => {
				toast.success("Stergere finalizata")

				let newTotalSpending = Number(this.state.totalSpending) - Number(this.state.expenses[this.state.expenseInChange].cost)

				var newExpenses = this.state.expenses
				newExpenses.splice(this.state.expenseInChange, 1)

				this.setState({
					expenses: newExpenses,
					totalSpending: newTotalSpending,
					choice: "",
					showModal: false
				})
			})
			.catch(err => {
				console.log(err)
				toast.error("Nu s-a putut finaliza, va rog reincercati")

				this.setState({
					choice: "",
					showModal: false
				})
			})
	}

	modalChangeBudget = () => {
		this.setState({
			choice: "BUDGET",
			showModal: true
		})
	}

	modalAddExpense = () => {
		this.setState({
			choice: "ADD",
			showModal: true
		})
	}

	modalChangeExpense = (index) => {
		this.setState({
			choice: "CHANGE",
			expenseInChange: index,
			showModal: true
		})
	}

	modalTaskClicked = (index) => {
		this.setState({
			choice: "TASK",
			taskClicked: index,
			showModal: true
		})
	}

	closeModal = () => {
		this.setState({
			showModal: false
		})
	}

	moveToGiftMoney = () => {
		this.props.history.push('/dar/' + this.props.match.params.id)
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading) {
			return <div className="Budget-container">
				<HeaderWhite shadow={true} goBack={this.goBack} />

				<div className="Budget-contents">
					<LoadingScreen />
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		}

		return (
			<div className="Budget-container">
				<HeaderWhite shadow={true} goBack={this.goBack} />

				<div className="Budget-contents">
					<div style={{ fontWeight: "bold", fontSize: "30px" }}>Buget</div>

					<DataCards cards={[
						{
							data: `Buget Total: ${this.state.budget} ${usedCurrency}`,
							color: "#19B899",
							onClickEvent: this.modalChangeBudget,
							svgIcon: SVG_Budget_Edit
						},
						{
							data: `Total dar: ${this.state.totalGiftMoney} ${usedCurrency}`,
							color: "#731DD8"
						},
						{
							data: `Cheltuieli totale: ${this.state.totalSpending} ${usedCurrency}`,
							color: "#F26157"
						},
						{
							data: `Buget ramas: ${this.state.budget - this.state.totalSpending + this.state.totalGiftMoney} ${usedCurrency}`,
							color: "#E3B505"
						}
					]} />

					<div style={{ fontWeight: "bold", fontSize: "15px", width: "90%", display: "flex", alignItems: "center", margin: "10px 0px" }}>
						<Icon icon="plus-circle" size="lg" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={this.modalAddExpense} />
						<div onClick={this.modalAddExpense}>Adauga cheltuiala</div>
					</div>

					<DataTable
						columnNames={[{ name: "Cheltuieli", style: { width: "75%" } }, { name: "Cost", style: { width: "25%", textAlign: "right" } }]}
						onRowClick={this.modalChangeExpense}
						dataArray={this.state.expenses}
						dataToTakeFromArray={["title", "cost"]}
					/>

					<DataTable
						columnNames={[{ name: "Cheltuieli din task-uri", style: { width: "75%" } }, { name: "Cost", style: { width: "25%", textAlign: "right" } }]}
						onRowClick={this.modalTaskClicked}
						dataArray={this.state.tasks}
						dataToTakeFromArray={["title", "cost"]}
					/>

					<BackButton style={{ marginTop: "20px" }} text={"Inapoi"} function={this.goBack} />
				</div>

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal-budget',
					closeButton: 'modal-close-button',
				}} open={this.state.showModal} onClose={this.closeModal} center>
					{this.state.choice === "BUDGET" && <div>
						<h3 style={{ textAlign: "center" }}>Va rog specificati bugetul</h3>

						<CustomInput value={"" + this.state.newBudget} onChange={value => { this.setState({ newBudget: value }) }} placeholder="Buget" eventType={"number"} border={true} />

						<BackAndConfirmButtons loading={this.state.isLoadingRequest} textBack={"Inchide"} textConfirm={"Confirm"} functionBack={this.closeModal} functionConfirm={this.setBudget} />
					</div>}

					{this.state.choice === "ADD" && <div className="Budget-contents">
						<AddExpense saveExpense={this.saveExpense} back={this.closeModal} />
					</div>}

					{this.state.choice === "CHANGE" && <div className="Budget-contents">
						<ChangeExpense expense={this.state.expenses[this.state.expenseInChange]} changeExpense={this.changeExpense} deleteExpense={this.deleteExpense} back={this.closeModal} />
					</div>}

					{this.state.choice === "TASK" && <div className="Budget-contents">
						{this.selectedTaskData()}
					</div>}
				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default Budget;
