import React from 'react'
import './ViewEvents.scss'
import NavbarColor from '../components/NavbarColor'
import HeaderWhiteBack from '../components/HeaderWhiteBack'
import EventCard from './ViewEvents/EventCard'
import axios from 'axios'
import { toast } from 'react-toastify'
import HeaderWhite from "../components/HeaderWhite";
import Loader from "react-spinners/DotLoader";

class ViewEvents extends React.Component {
	state = {
		events: [],
		dispEvents: [],

		showFinishedEvents: false,
		eventsTypes: ["Toate", "MemoryBooth"],
		eventTypeChoice: "Toate",

		loading: true
	}

	componentDidMount() {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.get(process.env.REACT_APP_DOMAIN + 'weddings/list/myWeddings?onlyServices=1', auth)
			.then(response => {
				let newEvents = response.data.map((event, index) => {
					event.eventIndex = response.data.length - index - 1

					return event
				})

				newEvents.reverse()

				this.setState({
					events: newEvents,
					dispEvents: newEvents.filter(event => event.isFinished === this.state.showFinishedEvents),
					loading: false
				})
			})
			.catch(err => {
				console.log(err);
				localStorage.clear()
				toast.error("Informatiile de autentificare au expirat, va rog reautentificativa")
				this.props.history.push('/login');
			})
	}

	shouldShowFinishedEvents = (shouldShow) => {
		let newDipsEvents = [...this.state.events]

		newDipsEvents = newDipsEvents.filter(event => event.isFinished === shouldShow)

		if (this.state.eventTypeChoice !== "Toate") {
			newDipsEvents = newDipsEvents.filter(event => event.eventType === this.state.eventTypeChoice)
		}

		this.setState({
			showFinishedEvents: shouldShow,
			dispEvents: newDipsEvents
		})
	}

	changeFilterType = (type) => {
		let newDipsEvents = [...this.state.events]

		newDipsEvents = newDipsEvents.filter(event => event.isFinished === this.state.showFinishedEvents)

		if (type !== "Toate") {
			newDipsEvents = newDipsEvents.filter(event => event.eventType === type)
		}

		this.setState({
			eventTypeChoice: type,
			dispEvents: newDipsEvents
		})
	}

	changeFinishedStatus = (index) => {
		let eventIndex = this.state.dispEvents[index].eventIndex
		let currentStatus = this.state.events[eventIndex].isFinished

		//change server status
		let obj = {
			isFinished: !currentStatus
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.dispEvents[index].id, obj, auth)
			.then(_ => {
				if (!currentStatus) {
					toast.success("Eveniment trecut la Terminate")
				} else {
					toast.success("Eveniment trecut la Active")
				}

				//change local status
				let newEvents = [...this.state.events]
				newEvents[eventIndex].isFinished = !currentStatus

				this.setState({
					events: newEvents
				})

				this.changeFilterType(this.state.eventTypeChoice)

			})
			.catch(err => {
				toast.error("Eroare, va rog reincercati")
			})
	}

	moveToEvent = (id) => {
		this.props.history.push('/adminEventPage/' + id)
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading === true)
			return (
				<div className="ViewEvents-container">
					<HeaderWhite />

					<h4 className="title"> Evenimentele Mele </h4>

					<div className="ViewEvents-contents">
						<div style={{margin:"auto"}}>
							<Loader color={'rgb(0,175,242)'}/>
						</div>
					</div>

					<NavbarColor history={this.props.history} active={"Events"} />
				</div>
			)

		let cards = this.state.dispEvents.map((event, index) => {
			return <EventCard moveToEvent={this.moveToEvent} key={index} event={event} changeFinishedStatus={() => this.changeFinishedStatus(index)} />
		})

		return (
			<div className="ViewEvents-container">
				<HeaderWhite />

				<h4 className="title"> Evenimentele Mele </h4>

				<div className="filters">
					<div className={!this.state.showFinishedEvents ? "Events-Status-Choice" : "Events-Status-Option"} onClick={() => this.shouldShowFinishedEvents(false)}>
						Active
					</div>

					<div className={this.state.showFinishedEvents ? "Events-Status-Choice" : "Events-Status-Option"} onClick={() => this.shouldShowFinishedEvents(true)}>
						Terminate
					</div>
				</div>



				<div className="ViewEvents-contents">
					<div>
						{cards}
					</div>
				</div>

				<NavbarColor history={this.props.history} active={"Events"} />
			</div>
		)
	}
}

export default ViewEvents;
