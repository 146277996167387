import React from 'react';
import './TemplateRender.scss'
import {getTemplateContent, photoTemplates} from "../../../../components/memorybooth/phototemplates";
import '../../../../components/memorybooth/photoTemplates.scss'

export default function TemplateRender({templateDetails}) {

    return(

            <div className={"template-render-cont"}>

                    {getTemplateContent(templateDetails)}

            </div>

    )
}

