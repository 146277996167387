import React from 'react'
import { Button } from 'rsuite'
import axios from 'axios'
import './Gift.scss'
import CustomInput from '../../../components/custom/CustomInput'
import { ShowPackageData } from '../../../components/Helper'

class ChoseBuyer extends React.Component {
	state = {
		responses: [],
		dispResponses: [],

		searchName: "",

		packageData: {},

		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.eventId, auth)
			.then(response => {
				var invites = response.data.responses.map(invitee => {
					return { nume: invitee.response.nume, prenume: invitee.response.prenume, id: invitee.user }
				})

				this.setState({
					responses: invites,
					dispResponses: invites,
					packageData: response.data,
					loading: false
				})
			})
	}

	changeSearch = (value) => {
		var newDispResponses = this.state.responses.filter(element => {
			return element.nume.toLocaleLowerCase().indexOf(value) !== -1 ||
				element.prenume.toLocaleLowerCase().indexOf(value) !== -1
		})

		this.setState({
			dispResponses: newDispResponses,
			searchName: value
		})
	}

	render() {
		if (this.state.loading) {
			return <div className="Gift-contents">
				<p>Loading...</p>
			</div>
		}

		return (
			<div className="Chose-Container">
				<div className="Title">
					<h3 style={{fontWeight: "bold"}}>Atribuie Cadou</h3>

					<h5>Nume cadou: {this.props.gift.title}</h5>

					{this.props.gift.boughtBy !== "" && this.props.gift.boughtBy !== null && <div style={{ margin: "10px 0px" }}>
						<p>Cumparat de: {this.props.gift.boughtBy}</p>
						<div style={{ color: "red" }} onClick={this.props.deleteBoughtBy}>Anulati atribuirea?</div>
					</div>}

					<CustomInput placeholder="Nume invitat" value={this.state.searchName} onChange={this.changeSearch} />

					<div className="Table-Head">
						<p>Prenume</p>
						<div>Nume</div>
						<p>Optiune</p>
					</div>
				</div>

				<div className="Content">
					{this.state.dispResponses.map((response, index) => {
						let isChosen = this.props.gift.boughtBy !== "" && this.props.gift.boughtBy !== null && Number(this.props.gift.idBoughtBy) === response.id
						return <div key={index} className={index % 2 === 0 ? "even-line" : "uneven-line"}>
							<p style={{ width: "33%", textAlign: "left" }}>{response.prenume}</p>
							<div style={{ width: "33%" }}>{response.nume}</div>
							<div style={{ width: "33%", display: "flex", justifyContent: "flex-end" }}>
								<Button appearance="ghost" color={isChosen ? "green" : "blue"} onClick={() => this.props.setBoughtBy(response)}>
									{isChosen
										? "Ales"
										: "Alege"}
								</Button>
							</div>
						</div>
					})}
				</div>

				<ShowPackageData packageData={this.state.packageData} history={this.props.history} eventId={this.props.eventId} />
			</div>
		)
	}
}

export default ChoseBuyer;