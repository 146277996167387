import React from 'react'
import MenuBlock from './MenuBlock'
import SettingCard from "../../../../../mainPages/Profile/components/SettingCard";
import {toast} from "react-toastify";
import {withRouter} from 'react-router';

class MenuBlocks extends React.Component {
    state = {
        tabs: [
            {id: "1", details: "Click pentru a vedea detalii", title: "Informatii generale"},
            {id: "2", details: "Click pentru a vedea detalii", title: "Pachete"},
        ],
        showMore: false
    }

    render() {
        let tabs = this.state.tabs.map(element => {
            return <MenuBlock event={this.props.event} moveTo={this.props.moveTo} key={element.id} id={element.id}
                              title={element.title}
                              details={element.details} eventID={this.props.eventID}
                              hasNewResponses={this.props.hasNewResponses}/>
        })

        return (
            <div className="MenuBlocks-container">
                <div className="MenuBlocks-content">

                    {
                        this.props.event.isAdmin ?
                            <>
                                <SettingCard icon="" onClick={() => {
                                    this.setState({showMore: true})
                                }} name="Configurare"/>

                                {
                                    this.state.showMore ?
                                            <>
                                                <SettingCard icon="" isSmall={true} onClick={() => {
                                                    this.props.history.push('/editEvent/' + this.props.eventID)
                                                }} name="Setari generale"/>


                                                        <SettingCard icon="" isSmall={true} onClick={() => {
                                                            this.props.history.push('/memorybooth/photoConfig/' + this.props.eventID)
                                                        }} name="Setari sablon poza"/>


                                                <SettingCard icon="" isSmall={true} onClick={() => {

                                                    localStorage.setItem('memoryBoothType', '0')
                                                    localStorage.setItem('memoryBoothTest', '1')
                                                    localStorage.setItem('id_eveniment', this.props.eventID)

                                                    this.props.history.push('/memorybooth/testScreen/' + this.props.eventID)
                                                }} name="Previzualizeaza"/>
                                            </>
                                        : null
                                }

                                <SettingCard icon="" onClick={() => {
                                    this.props.history.push('/package/' + this.props.eventID)
                                }} name="Cumpara pachet"/>

                                <SettingCard icon="" onClick={() => {
                                    this.props.history.push('/administrators/' + this.props.eventID)
                                }} name="Organizatori"/>
                            </>
                            : null
                    }

                    <SettingCard icon="" onClick={() => {
                        this.props.history.push('/memorybooth/listItems/' + this.props.eventID)
                    }} name="Mesaje primite"/>

                    <div style={{marginTop: '30px'}}></div>
                    {
                        this.props.event.isAdmin ?
                            <SettingCard icon="" onClick={() => {

                                if (this.props.event.currentTimeSpent >= 720) {
                                    toast.error('Perioada maxima de 12 ore a fost depasita!')
                                    return;
                                }

                                if (!this.props.event.packages) {
                                    toast.error('Trebuie sa achizitionati un pachet!')
                                } else {
                                    localStorage.setItem('memoryBoothTest', '0')
                                    localStorage.setItem('memoryBoothWedding', JSON.stringify(this.props.event))
                                    this.props.history.push('/memorybooth/intro/' + this.props.eventID)
                                }

                            }} name="Porneste MemoryBooth"/>
                            : null
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(MenuBlocks);
