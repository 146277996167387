import React from 'react'
import './ModalTasks.scss'
import Modal from "react-responsive-modal"

//params: options, active option, on click event, modal status (isOpen), close modal event
class ModalTasks extends React.Component {
	render() {
		return (
			<div>
				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal-tasks',
					closeButton: 'modal-close-button',
				}} center open={this.props.isOpen} onClose={this.props.closeModal}>
					<div style={{marginTop: "30px"}}>
						{this.props.options.map((option, index) => {
							return <div className={this.props.activeOption === option ? "active-elem" : "elem"} key={index} onClick={() => {this.props.onClickEvent(option)}}>{option}</div>
						})}
					</div>
				</Modal>
			</div>
		)
	}
}

export default ModalTasks