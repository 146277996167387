import React from 'react'
import './EventCard.scss'

class EventCard extends React.Component {
	render() {
		let event = this.props.event
		var data = event.dataEvent.split("T")[0].split("-")
		let hasImage = event.backgroundImage !== null && event.backgroundImage !== undefined

		let backgroundImageStyle = {
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			backgroundImage: hasImage ? `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${event.backgroundImage.url})` : ''
		}

		return (
			<div className="EventCard-container-ViewEvents">
				<div className="EventCard-content" onClick={() => this.props.moveToEvent(this.props.event.id_eveniment)}>

					{hasImage && <div className="Picture">
						<img src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${event.backgroundImage.url}`} crossOrigin="true"/>
					</div> }

					<div className="Title font-family-poppins-700-bold">
						{
							event.eventType === "Nunta"
								? "Nunta " + event.prenumeMire + " si " + event.prenumeMireasa
								: event.eventType + " " + event.prenumeMire
						}
					</div>

					<div className="Data">{data[2]}-{data[1]}-{data[0]}</div>

					<div className="Full-Description">
						{event.eventDescription}
					</div>
				</div>

				<p style={{ color: "blue" }} onClick={this.props.changeFinishedStatus}>{event.isFinished ? "Seteaza ca Eveniment Activ" : "Seteaza ca Eveniment Terminat"}</p>
			</div>
		)
	}
}

export default EventCard;
