import React from 'react'
import './ViewAlbums.scss'
import NavbarColor from '../components/NavbarColor'
import HeaderWhite from '../components/HeaderWhiteBack'
import EventCard from './AddAlbums/EventCard'
import axios from 'axios'
import { Button, Icon } from 'rsuite'
import { toast } from 'react-toastify'

class ViewAlbums extends React.Component {
	state = {
		isPublished: false,
		eventId: -1,
		albums: [],
		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.match.params.id)
			.then(response => {
				this.setState({
					isPublished: response.data.areAlbumsPublished,
					eventId: response.data.id
				})
			})
			.catch(err => {
				console.log(err);
			})

		await axios.get(process.env.REACT_APP_DOMAIN + 'albums/list/byEvent/' + this.props.match.params.id, auth)
			.then(response => {
				this.setState({
					albums: response.data,
					loading: false
				})
			})
	}

	changeStatus = () => {
		let newStatus = !this.state.isPublished

		let obj = {
			areAlbumsPublished: newStatus
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		toast.info("Se trimit modificarile, va rog asteptati")

		axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
			.then(_ => {
				toast.success("Modificari trimise")
				this.setState({
					isPublished: newStatus
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("Eroare")
			})
	}

	moveToAddAlbum = () => {
		this.props.history.push('/addAlbum/' + this.props.match.params.id);
	}

	moveToEdit = (id) => {
		this.props.history.push('/editAlbum/' + this.props.match.params.id + "/" + id)
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading === true)
			return (
				<div>
					<p>Loading...</p>
				</div>
			)

		let cards = this.state.albums.map((album, index) => {
			return <EventCard key={index} moveToEdit={this.moveToEdit} albumData={album} />
		})

		return (
			<div className="ViewAlbums-container">
				<HeaderWhite goBack={this.goBack} />

				<div className="ViewAlbums-contents">
					<h4 id="title">Albumele Mele</h4>

					<div className="Status">
						<div className="Details">Status: {this.state.isPublished ? "Publicat" : "Nepublicat"}</div>

						<div className={!this.state.isPublished ? "Published" : "Unpublished"} onClick={this.changeStatus}>
							{!this.state.isPublished ? "Publica" : "Anuleaza publicare"}
						</div>
					</div>

					<div className='add-button'>
						<Icon icon="plus-circle" size="lg" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={this.moveToAddAlbum} />
						<div onClick={this.moveToAddAlbum}>Adauga album</div>
					</div>

					<div className="ViewAlbums-cards">
						{cards}
					</div>
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default ViewAlbums;