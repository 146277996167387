import React from 'react'
import './HeaderWhite.scss'
import logo from '../assets/logo.png'

class HeaderWhite extends React.Component {
	render() {
		var style = null;
		if (this.props.shadow === false)
			style = { boxShadow: "none" }

		return (
			<div style={style} className="HeaderWhite-container">
				<div className="HeaderWhite-contents">
					<div>
						<img src={logo} style={{ width: "100px", height: "auto" }} alt="Logo Ality" />
					</div>
				</div>
			</div>
		)
	}
}

export default HeaderWhite;