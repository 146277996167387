import React from 'react'
import SectionRender from '../sectionRender'
import './template12.scss'

export default function template12Content(templateDetails = false) {

    return (
        <div className={"main-content"} >

            {templateDetails.settings[1].value && templateDetails.settings[1].value.length > 0 &&
            <div className={"background"}>
                <img src={templateDetails.settings[1].value} crossOrigin={"true"}/>
            </div>
            }

            <div className={"photo-template-12 " + (templateDetails.live ? 'live' : '')}>
                <div className={"row-1"}>
                    <div className={"col-left"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[0]}
                            preview={!templateDetails.live}
                        />
                    </div>
                    <div className={"col-right"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[1]}
                            preview={!templateDetails.live}
                        />
                    </div>
                </div>
            </div>

            {templateDetails.settings[8] && templateDetails.settings[8].value && templateDetails.settings[8].value.length > 0 &&
                <div className={"template-overlay-image"}>
                    <img src={templateDetails.settings[8].value} crossOrigin={"true"}/>
                </div>}

        </div>
    )
}
