import React, {useState, useEffect} from 'react'
import './MemoryBoothConfig.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import {Uploader, Button, SelectPicker, Icon, DatePicker} from 'rsuite'
import Modal from "react-responsive-modal";
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';

import NavbarColor from '../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import isBefore from 'date-fns/isBefore'
import CustomInput from '../../components/custom/CustomInput';
import {getUrlFromImage, textarea_max_length} from '../../components/Helper';
import {useHistory, useParams} from "react-router-dom";
import SettingCard from "../Profile/components/SettingCard";


export default function MemoryBoothListItems() {

    let history = useHistory()
    let {id} = useParams()

    const [items, setItems] = useState(false)

    const [state, setState] = useState(3)
    const [filesCount, setFilesCount] = useState(0)
    const [processedFilesCount, setProcessedFilesCount] = useState(0)
    const [archiveLink, setArchiveLink] = useState('')
    const [wedding, setWedding] = useState(null)

    const [previewItem, setPreviewItem] = useState(null)

    useEffect(() => {
        getItems()
        getArchiveLink()

        const interval = setInterval(() => {
            getArchiveLink()
        }, 6000);
        return () => clearInterval(interval);
    }, [])

    const getItems = async () => {

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            id_eveniment: id
        }

        let info = await axios.post(process.env.REACT_APP_DOMAIN + 'memory-booth-files/get-wedding-files',payload, auth);

        if(info.data && info.data.items.length > 0) {
            setItems(info.data.items)
            setFilesCount(info.data.items.length)
            setWedding(info.data.wedding)
        }

    }

    const createArchive = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            id_eveniment: id
        }

        let res = await axios.post(process.env.REACT_APP_DOMAIN + 'memory-booth-files/createArchive',payload, auth);

    }

    const getArchiveLink = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            id_eveniment: id
        }

        let res = await axios.post(process.env.REACT_APP_DOMAIN + 'memory-booth-files/getArchiveLink',payload, auth);

        if(res.data) {

            // MemoryBooth has not ended
            if(res.data.state === 3) {
                setState(3)
            }

            // Archive is ready
            if(res.data.state === 2) {
                setState(2)
                setArchiveLink(res.data.link)
            }

            // Memorybooth archive in progress
            if(res.data.state === 1) {
                setState(1)
                setFilesCount(res.data.files)
                setProcessedFilesCount(res.data.processedFiles)
            }

            // Memorybooth has ended - > show archive button
            if(res.data.state === 0) {
                setState(0)
            }

        }
    }

    const goBack = () => {
        history.goBack()
    }

    const customInputStyle = {
        margin: "15px 0px 10px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    return (
        <div className="mb-config-container">
            <HeaderWhite goBack={goBack}/>

            <div className={"mb-config-inner"}>

                {
                    state === 0 ?
                        <SettingCard
                            key={'d1'}
                            icon=""
                            onClick={() => {
                                createArchive()
                                setState(1)
                            }}
                            name={'Creează arhivă'}
                        /> : null
                }

                {
                    state === 1 ?
                        <div>
                            Se pregătește arhiva ({processedFilesCount + '/'+  filesCount})
                            <br/>
                            <br/>
                            Vă rugăm așteptați ...
                        </div> : null
                }

                {
                    state === 2 ?
                        <a href={process.env.REACT_APP_DOMAIN + archiveLink}>
                        <SettingCard
                            key={'d1'}
                            icon=""
                            onClick={() => {

                            }}
                            name={'Descarcă arhiva'}
                        /> </a>: null
                }

                {
                    wedding && wedding.others && wedding.others.memorybooth_archive_downloaded && wedding.others.memorybooth_archive_downloaded === true ?
                        <p>Arhiva a fost deja descărcată!</p>:null
                }


                {
                    items && items.length > 0 && items.map((item,i) => (
                        <div
                            key={i}
                            onClick={() => setPreviewItem(item)}
                            className={"mb-preview-list-item"}
                        >
                            <div className={"nr"}>
                                {items.length - i}.
                            </div>
                            <div className={"left"}>
                                {
                                    item.url.includes('.gif') || item.url.includes('.png') || item.url.includes('.jpg') ?
                                        <img src={item.url} style={{width: '100%', height: '100%'}}
                                             crossOrigin={"true"}/>
                                        :
                                        <video
                                            src={item.url}
                                            width={'100%'}
                                            crossOrigin={"true"}
                                            height={'100%'}
                                            controls={false}
                                            autoPlay={true}
                                            loop={true}
                                            muted={true}
                                        />
                                }
                            </div>
                            <div className={"right"}>
                                {item.name}<br/>
                                {item.email}<br/>
                                {new Date(item.created_at).toLocaleString()}<br/>

                                {
                                    item.url.includes('.gif') ?
                                        <div className={"tag-gif"}>GIF</div>
                                        :
                                        (item.url.includes('.png') || item.url.includes('.jpg')) ?
                                            <div className={"tag-photo"}>Fotografie</div>
                                            :
                                            <div className={"tag-video"}>Video</div>
                                }

                            </div>
                        </div>
                    ))
                }

            </div>

            <Modal classNames={{
                overlay: 'modal-overlay',
                modal: 'modal-mb-item',
                closeButton: 'modal-close-button',
            }} open={previewItem !== null}
                   onClose={() => {setPreviewItem(null)}}
                   onEscKeyDown={() => {setPreviewItem(null)}}
                   onOverlayClick={() => {setPreviewItem(null)}}>

                {
                    previewItem !== null ?
                        <div className={"mb-modal-container"}>

                            Nume: {previewItem.name}<br/>
                            Email: {previewItem.email}<br/>

                            <a style={{color:'#221d5d', cursor:'pointer'}} download target="_blank" href={process.env.REACT_APP_DOMAIN + "memorybooth/handleFileDownload?url=" + previewItem.url}>Download</a>

                            <br/>

                            {
                                previewItem.url.includes('.gif') || previewItem.url.includes('.png') || previewItem.url.includes('.jpg') ?
                                    <img src={previewItem.url} style={{width:'100%', height:'100%'}} crossOrigin={"true"} />
                                    :
                                    <video
                                        src={previewItem.url}
                                        width={'100%'}
                                        crossOrigin={"true"}
                                        height={700}
                                        controls={true}
                                        autoPlay={true}
                                        loop={true}
                                        muted={false}
                                    />
                            }
                        </div>
                        : null
                }
            </Modal>

            <NavbarColor history={history}/>
        </div>
    )

}
