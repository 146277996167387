import React from 'react'
import { Checkbox } from 'rsuite'
import { usedCurrency } from '../../../components/Helper'
import './Task.scss'

class Task extends React.Component {
	changeDateFormat = (date) => {
		var parts = new Date(date).toDateString().split(" ")

		if (parts[1] === "Jan") parts[1] = "Ianuarie"  //"01"
		if (parts[1] === "Feb") parts[1] = "Februarie" //"02"
		if (parts[1] === "Mar") parts[1] = "Martie"    //"03"
		if (parts[1] === "Apr") parts[1] = "Aprilie"   //"04"
		if (parts[1] === "May") parts[1] = "Mai"       //"05"
		if (parts[1] === "Jun") parts[1] = "Iunie"     //"06"
		if (parts[1] === "Jul") parts[1] = "Iulie"     //"07"
		if (parts[1] === "Aug") parts[1] = "August"    //"08"
		if (parts[1] === "Sep") parts[1] = "Septembrie"//"09"
		if (parts[1] === "Oct") parts[1] = "Octombrie" //"10"
		if (parts[1] === "Nov") parts[1] = "Novembrie" //"11"
		if (parts[1] === "Dec") parts[1] = "Decembrie" //"12"

		return parts[2] + " " + parts[1] + " " + parts[3]
	}

	isDateOverdue = (date) => {
		return new Date(date).getTime() <= new Date().getTime()
	}

	render() {
		return (
			<div className="task">
				<div className="top-row">
					<div style={{alignSelf: "center"}}>
						<Checkbox checked={this.props.task.done} onChange={this.props.checkChange} style={{ alignSelf: 'center' }} />
					</div>

					<div style={{ fontWeight: "bold", alignSelf: "center" }} onClick={this.props.onClick}>{this.props.task.title}</div>
				</div>

				<div className="bottom-row">
					{this.props.task.cost !== 0 && this.props.task.cost !== "0" && <div className={"yellow-button"}>Cost: {this.props.task.cost} {usedCurrency}</div>}
{/* 
					{this.props.task.advance !== 0 && this.props.task.advance !== "0" && <div className={"yellow-button"}>Avans: {this.props.task.advance} {usedCurrency}</div>} */}

					<div className={this.isDateOverdue(this.props.task.dueDate) ? "red-button" : "green-button" }>{this.changeDateFormat(this.props.task.dueDate)}</div>
				</div>
			</div>
		)
	}
}

export default Task;