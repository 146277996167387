import React from 'react'
import Modal from 'react-responsive-modal'
import { Divider, Button } from 'rsuite'

class terms2 extends React.Component {
    render() {
        return (
            <Modal classNames={{
                overlay: 'modal-overlay',
                modal: 'terms-modal',
                closeButton: 'modal-close-button',
            }} focusTrapped={false} open={this.props.showModal} onClose={this.props.toggleModal} center>

                <h5>Termeni si Conditii Ality</h5>
                <p> Ultima modificare: 20 August 2021 </p>
                <Divider />


                <div>
                    <p style={{margin: '0cm', lineHeight: '27pt', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '24.0pt', fontFamily: 'inherit, serif', color: 'black'}}><br />Termeni și condiții aplicabile organizatorilor</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><em><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>în conformitate cu prevederile Regulamentul (UE) 2019/1150 al Parlamentului European și al Consiliului din 20 iunie 2019 privind promovarea echității și a transparenței pentru întreprinderile utilizatoare de servicii de intermediere online</span></em></p>
                    <p style={{margin: '15pt 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>1. Termeni generali</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>1.1. Prezentul document conținând termenii și condițiile aplicabile organizatorilor de evenimente (denumit în continuare "Termeni și Condiții") reprezintă o completare a contractului de prestări servicii care a fost încheiat cu Administratorul Platformei sau a contractului de prestări servicii care ar urma să se încheie, și face parte integrantă din respectivul contract. În caz de contradicții sau neconcordanțe, prevalează prevederile respectivului contract.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>1.2. Vă rugăm să citiți integral acest document înainte de a utiliza serviciile furnizate de societatea noastră sau de a continua utilizarea acestora.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>1.3. Organizatorii înțeleg și acceptă că utilizarea oricărui serviciu furnizat prin intermediul Platformei de Ality necesită o acceptare în întregime și necondiționată a Termenilor și Condițiilor.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>1.4. Dacă aveți nelămuriri cu privire la conținutul Termenilor și Condițiilor, obligațiile prevăzute de acest document sau orice alte întrebări referitoare la serviciile furnizate de Ality, le puteți comunica pe adresa de mail contact@ality.ro.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>1.5. În înțelesul Termenilor și Condițiilor, termenii următori înseamnă:</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>1.6. „Administratorul Platformei” sau “Ality” înseamnă Ality SRL, cu sediul în București, Sos. Calea Vitan nr. 6-6A, clădirea B, etaj 4, camera 4, nr. 21, sector 3.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>1.7. "Site" înseamnă website-ul www.ality.ro.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>1.8. ”Utilizator Profesionist” înseamnă orice persoană fizică care acționează în capacitate comercială sau profesională sau orice persoană juridică care oferă bunuri sau servicii consumatorilor, prin intermediul Platformei, în scopuri legate de activitatea sa comercială, de afaceri, meșteșugărească sau profesională.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>1.9. ”Organizator” înseamnă Utilizatorul Profesionist care utilizează sau care intenţionează să utilizeze serviciile furnizate de Ality prin intermediul Platformei.</span></p>
                    <p style={{margin: '15pt 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>2. Modificarea Termenilor și Condițiilor</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>2.1. Administratorul Platformei are dreptul de a modifica oricând și în orice mod oricare dintre prevederile din Termeni şi Condiţii sau Termenii și Condițiile în întregime.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>2.2. Organizatorii vor fi notificați cu privire la aceste modificări pe cale electronică.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>2.3. Modificările (care pot fi descărcate în format pdf) intră în vigoare în termen de 15 zile de la data la care Administratorul Platformei a notificat Organizatorii cu privire la modificările propuse.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>2.4. Orice modificare va fi considerată ca fiind acceptată deplin și necondiționat de către oricare dintre Organizatori printr-o declarație scrisă furnizată de acesta, prin bifarea în mod corespunzător a acordului pentru Termenii și Condițiile modificate sau printr-o manifestare univocă de voință, precum utilizarea în continuare a serviciilor furnizate de Administratorul Platformei pentru punerea la vânzare a unor bilete pentru evenimente noi, intervenite oricând după data notificării comunicate de Administratorul Platformei sau la expirarea termenului de 15 zile menționat mai sus, oricare dintre acestea survine mai târziu, iar neacceptarea oricărei modificări atrage obligația respectivului Organizator de a înceta utilizarea serviciilor furnizate prin intermediul Platformei de către Administratorul Platformei.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>2.5. Organizatorul are dreptul de a rezilia prezentele Termeni și Condiții înainte de expirarea perioadei de preaviz menționate mai sus. Rezilierea operează în termen de 15 zile de la momentul recepționării de către Organizator a informațiilor despre modificări, cu excepția cazului în care Organizatorul renunță la această notificare, prezintă o declarație în acest scop sau desfășoară activități exprese pe Site de natură să confirme acceptarea unor astfel de condiții modificate (exemplu: punerea în vânzare a biletelor pentru un eveniment nou).</span></p>
                    <p style={{margin: '15pt 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>3. Conținutul Platformei. Drepturi de proprietate intelectuală</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>3.1. Toate drepturile de proprietate intelectuală asupra conținutului de orice fel al Platformei (“Conținutul Platformei”), inclusiv design, imagini, texte, baze de date, programe pentru calculator, dar fără a se limita la acestea, aparțin Administratorului Platformei.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>3.2. Prin derogare de la prevederile de mai sus, nu aparțin Administratorului Platformei drepturile asupra acelor materiale pentru care pe Site s-a indicat în mod expres sau implicit un alt titular de drepturi, precum afișele de prezentare a concertelor, fotografiile artiștilor, materialele publicitare ale terților, dar fără a se limita la acestea.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>3.3. Organizatorii se obligă să respecte toate drepturile de proprietate intelectuală pe care Administratorul Platformei și partenerii săi contractuali le dețin asupra Conținutului Platformei. Este interzisă preluarea, reproducerea, distribuirea și/sau comunicarea publică în orice formă a Conținutului de orice fel al Platformei, integral sau parțial, modificat sau nu, fără acordul prealabil, expres și scris al Administratorului Platformei.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>3.4. Administratorul Platformei își rezervă dreptul de a acționa în instanță orice persoană care încalcă în orice mod prevederile de mai sus.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>3.5. Organizatorii care publică materiale în Site sau care expediază în orice modalitate materiale către Administratorul Platformei pentru a fi publicate în Site, acordă acestuia din urmă o licență neexclusivă, transferabilă și perpetuă, pe orice teritoriu, de a utiliza materialele respective prin publicare (reproducere și comunicare publică) pe internet, pe Site sau pe website-urile partenerilor contractuali ai Administratorului Platformei, precum și de a le modifica / adapta în vederea utilizării conform celor de mai sus.</span></p>
                    <p style={{margin: '15pt 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>4. Identitatea profesioniștilor</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>4.1. Datele de identificare și contact ale Organizatorului care comercializează bilete prin intermediul Platformei sunt afișate în mod clar în pagina evenimentului. În calitate de organizator de evenimente, sunteți de acord cu afișarea datelor dvs. de identificare și contact pe Site, în pagina destinată vânzării biletelor. Vor fi publicate date precum denumirea societății, codul unic de înregistrare, numărul de înregistrare în registrul comerțului şi adresa de e-mail a Organizatorului.</span></p>
                    <p style={{margin: '15pt 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>5. Sancțiuni</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>5.1. Ality nu dispune sancțiuni precum restricționarea, suspendarea sau sistarea serviciilor furnizate Organizatorilor, cu excepția cazului în care acestea au fost stabilite de către părți de comun acord prin contract semnat de acestea (precum suspendarea serviciilor pentru neplată).</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>5.2. Cu toate acestea, în cazul în care Organizatorul nu acceptă integral și necondiționat prezentele Termeni și Condiții, precum și orice modificări la acestea conform procedurii menționate la art. 2.4. de mai sus, contul Organizatorului va fi suspendat până la momentul la care va interveni acceptarea acestora sau până la momentul încetării contractului încheiat între Ality și Organizator.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>5.3. Rezilierea contractului încheiat între Organizator și Administratorul Platformei, care are ca efect sistarea în totalitate a serviciilor şi desființarea contractului, operează la solicitarea oricărei părți cu respectarea prevederilor contractului semnat de acestea şi a prevederilor corespunzătoare din Codul Civil.</span></p>
                    <p style={{margin: '15pt 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>6. Ierarhia</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>6.1. Evenimentele sunt afișate pe Site în funcție de anumite criterii considerate de Administratorul Platformei relevante pentru consumatori, precum dimensiunea evenimentului (raportat la numărul total de bilete puse în vânzare), data la care acestea urmează să aibă loc şi locul de desfășurare (oraș / local).</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>6.2. În secțiunea aferentă categoriei, evenimentele sunt afișate în ordine cronologică, în funcție de data evenimentului.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>6.3. Pe prima pagină a Platformei sunt mai multe secțiuni, printre care următoarele: evenimente top (primele două rânduri), evenimentele recomandate, top vânzări, categorii de evenimente (teatru, stand up etc) și locații recomandate.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>6.4. Evenimentele mari sau medii (raportat la numărul total de bilete puse în vânzare) care au loc la data curentă sau în viitorul apropiat sunt afișate în primul ecran vizibil, în primele două rânduri din prima pagină a Platformei.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>6.5. La secțiunea evenimente recomandate sunt afișate prin rotație toate evenimentele publicate pe Site (evenimente mari, medii și mici).</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>6.6. În secțiunea top vânzări / top sales sunt afișate cele mai vândute evenimente ale momentului.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>6.7. La secțiunile de locații recomandate sunt afișate în prima pagina din Site evenimentele organizate de locațiile care au minim 3 evenimente simultan la vânzare pe Site .</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>6.8. Administratorul Platformei poate promova un eveniment cu potențial mare de vânzări, prin poziționare preferențială (prioritară), pe baza unei înțelegeri separate cu Organizatorul acestuia.</span></p>
                    <p style={{margin: '15pt 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>7. Tratament diferenţiat. Canale de distribuţie</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>7.1. Ality nu are un tratament diferențiat față de anumiți Organizatori la vânzarea biletelor aferente evenimentelor acestora prin intermediul Platformei.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>7.2. Toți Organizatorii au acces la aceleași servicii, condiții sau funcționalități ale Platformei.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>7.3. Organizatorii au acces la întreaga rețea de distribuție a biletelor, așa cum este prevăzută în contract. „Reţeaua Ality” include Site-ul şi punctele de vânzare fizice care pot fi modificate de Ality potrivit propriei sale opțiuni (precum localuri, magazine specializate de muzică, automat self service etc). În plus, în urma integrării în propriul site a soluției tehnice a Ality, Organizatorul poate comercializa bilete la evenimentele sale prin intermediul platformei propriu.</span></p>
                    <p style={{margin: '15pt 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>8. Accesul la date</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>8.1. Ality poate partaja datele dvs. cu colaboratori și parteneri, în condiții contractuale foarte stricte în ceea ce privește confidențialitatea și securitatea datelor. Astfel de categorii de destinatari pot fi: furnizori de servicii de contabilitate, asistență juridică, bănci și orice alți parteneri de afaceri, atunci când sunt necesare pentru executarea unui contract sau când există un interes legitim al Ality, fără însă a afecta drepturile Organizatorilor și ale persoanelor vizate (persoane de contact, reprezentanți legali etc) și cu respectarea măsurilor de securitate necesare.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>8.2. La achiziția unui bilet de pe Site, datele de contact ale cumpărătorului pot fi accesibile Organizatorului evenimentului respectiv, în condițiile acordurilor GDPR încheiate de Administratorul Platformei cu Organizatorul. Ality nu își asumă răspunderea cu privire la datele cu caracter personal pe care cumpărătorul consimte să le dezvăluie Organizatorului. În această situație, întreaga responsabilitate cu privire la prelucrarea datelor cu caracter personal ale cumpărătorului revine Organizatorului. Un Organizator nu are acces la datele de contact ale cumpărătorilor de bilet la evenimentele altui Organizator. Administratorul Platformei are acces la datele de contact ale tuturor cumpărătorilor de bilet.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>8.3. Niciun Organizator nu are acces la datele nepublice privind vânzările altui Organizator. Fiecare Organizator poate consulta în contul propriu statistici privind vânzările efectuate la evenimentele sale. Administratorul Platformei are acces la statistici privind vânzările tuturor Organizatorilor.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>8.4. Administratorul Platformei are acces tehnic și contractual atât la informațiile furnizate sau generate de Organizator în Site, cât şi la informaţiile furnizate de consumatori (cumpărători de bilet). După încetarea contractului, Administratorul Platformei păstrează datele privind Organizatorii și activitatea acestora pe Site, inclusiv pentru analizarea acestora, în vederea îmbunătățirii serviciilor oferite.</span></p>
                    <p style={{margin: '15pt 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>9. Soluționarea reclamațiilor</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>9.1. Organizatorii au dreptul de a formula reclamații care privesc serviciile furnizate de Ality, de îndată ce evenimentul reclamat s-a produs sau când nu este posibil astfel, într-un termen rezonabil.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>9.2. Pentru a înlătura orice îndoială, Organizatorul are dreptul de a adresa Administratorului Platformei reclamații cu privire la oricare dintre următoarele aspecte:</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>(i) presupusa nerespectare de către Administratorului Platformei a obligațiilor prevăzute în Regulamentul (UE) 2019/1150 al Parlamentului European și al Consiliului din 20 iunie 2019 privind promovarea echității și a transparenței pentru întreprinderile utilizatoare de servicii de intermediere online, care afectează Utilizatorul Profesionist reclamant (Organizatorul);</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>(ii) aspectele tehnologice care se referă direct la furnizarea serviciilor și care îl afectează pe reclamant;</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>(iii) măsurile luate de Administratorul Platformei sau comportamentul acestuia care se referă direct la furnizarea serviciilor și care îl afectează pe reclamant;</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>9.3. Reclamațiile vor fi făcute telefonic la nr. 0314 326 888 în cazurile urgente sau comunicate în scris Administratorului Platformei pe adresa de e-mail: contact@ality.ro.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>9.4. Organizatorii au obligația de a comunica sau include în fiecare reclamație toate datele și informațiile relevante cu privire la aceasta, pentru a-i permite Ality să analizeze complet și corect plângerea, inclusiv să poată soluționa plângerea într-un termen cât mai scurt, ținând seama de importanța și de complexitatea problemei sesizate. În situația în care reclamantul nu a transmis Administratorului Platformei toate datele și informațiile necesare soluționării reclamației respective, acestuia îi vor fi solicitate informațiile lipsă.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>9.5. Reclamațiile care nu sunt urgente vor fi analizate și vor primi răspuns în termen de maxim 5 zile lucrătoare de la primirea acestora de către Administratorul Platformei, în cazul în care au fost însoțite de toate datele și informațiile necesare pentru soluționarea acestora. Reclamațiile urgente se vor soluționa de îndată acolo unde este posibil sau într-un termen cât mai scurt.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>9.6. Procedura de mai sus de soluționare a reclamațiilor este pusă în mod gratuit la dispoziția Organizatorilor.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>9.7. Soluționarea reclamațiilor va respecta principiile transparenței și egalității de tratament în situații echivalente și al tratării plângerilor proporțional cu importanța și complexitatea lor.</span></p>
                    <p style={{margin: '15pt 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>10. Medierea</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>10.1. Organizatorul poate solicita medierea pentru soluționarea conflictelor cu Administratorul Platformei legate de furnizarea serviciilor de către Ality, inclusiv cele care pot deriva din soluționarea reclamațiilor conform celor de mai sus. Părțile pot încerca soluționarea pe cale amiabilă a oricărui astfel de conflict, inclusiv prin intermediul mediatorilor autorizați de Consiliul de Mediere, lista acestora fiind disponibilă aici:&nbsp;</span><span style={{color: 'black'}}><a style={{color: 'blue', textDecoration: 'underline'}} href="https://www.cmediere.ro/mediatori" aria-invalid="true"><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: '#006aca'}}>https://www.cmediere.ro/mediatori</span></a></span><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>10.2. Medierea nu este gratuită.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>10.3. Medierea este voluntară și facultativă. Administratorul Platformei își rezervă dreptul de a refuza participarea la mediere sau de a refuza mediatorul propus de Organizator.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>10.4. Administratorul Platformei va suporta o parte rezonabilă din totalul costurilor de mediere, în conformitate cu dispozițiile Regulamentului (UE) 2019/1150 al Parlamentului European și al Consiliului din 20 iunie 2019 privind promovarea echității și a transparenței pentru întreprinderile utilizatoare de servicii de intermediere online.</span></p>
                    <p style={{margin: '15pt 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><strong><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>11. Cesiunea</span></strong></p>
                    <p style={{margin: '0cm 0cm 7.5pt', lineHeight: 'normal', background: 'white', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{fontSize: '12.0pt', fontFamily: '"Times New Roman", serif', color: 'black'}}>11.1. Administratorul Platformei poate cesiona oricând către un terț drepturile și obligațiile izvorâte din prezentele Termeni și Condiții, precum și din contractele încheiate cu Organizatorii, inclusiv prin cesionarea către un terț a contractului, iar Organizatorul consimte anticipat la această cesiune, conform art. 1315 și următoarele din Codul Civil. Pentru a înlătura orice îndoială, Organizatorii consimt anticipat la substituirea Administratorul Platformei cu un terț în raporturile născute între acesta și Organizatori în temeiul prezentelor Termeni și Condiții, precum și în temeiul contractelor încheiate cu Administratorul Platformei în legătură cu utilizarea Platformei și / sau a serviciilor furnizate prin intermediul acestuia.</span></p>
                    <p style={{margin: '0cm', textAlign: 'center', lineHeight: 'normal', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><a style={{color: 'blue', textDecoration: 'underline'}} title="Întrebări frecvente" href="https://www.iabilet.ro/faq/"><span style={{fontSize: '9.0pt', fontFamily: '"Times New Roman", serif', color: '#006aca'}}>FAQ</span></a><span style={{fontSize: '9.0pt', fontFamily: '"Times New Roman", serif'}}>&nbsp;|&nbsp;</span><a style={{color: 'blue', textDecoration: 'underline'}} title="Termeni și condiții" href="https://www.iabilet.ro/terms/ro/"><span style={{fontSize: '9.0pt', fontFamily: '"Times New Roman", serif', color: '#006aca'}}>Termeni și condiții</span></a><span style={{fontSize: '9.0pt', fontFamily: '"Times New Roman", serif'}}>&nbsp;|&nbsp;</span><a style={{color: 'blue', textDecoration: 'underline'}} title="Termeni și condiții organizatori" href="https://www.iabilet.ro/terms/organizers/"><span style={{fontSize: '9.0pt', fontFamily: '"Times New Roman", serif', color: '#006aca'}}>Termeni și condiții organizatori</span></a><span style={{fontSize: '9.0pt', fontFamily: '"Times New Roman", serif'}}>&nbsp;|&nbsp;</span><a style={{color: 'blue', textDecoration: 'underline'}} title="Politică de confidențialitate" href="https://www.iabilet.ro/privacy/ro/"><span style={{fontSize: '9.0pt', fontFamily: '"Times New Roman", serif', color: '#006aca'}}>Politică de confidențialitate</span></a><span style={{fontSize: '9.0pt', fontFamily: '"Times New Roman", serif'}}>&nbsp;|&nbsp;</span><a style={{color: 'blue', textDecoration: 'underline'}} title="Contact" href="https://www.iabilet.ro/contact/"><span style={{fontSize: '9.0pt', fontFamily: '"Times New Roman", serif', color: '#006aca'}}>Contact</span></a></p>
                    <p style={{margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}>&nbsp;</p>
                    <h1 style={{margin: '0cm', lineHeight: '27pt', background: 'white', fontSize: '24pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontFamily: 'inherit, serif', color: 'black'}}><br />Termeni și condiții clienti</span></h1>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>TERMENI ŞI CONDIŢII DE UTILIZARE A SERVICIILOR FURNIZATE PRIN INTERMEDIUL PLATFORMEI WWW.ALITY.RO</span></p>
                    <div style={{margin: '15pt 0cm', textAlign: 'center', background: 'white', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}} align="center"><hr align="center" size={0} width="100%" /></div>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Dragi clienti,</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Pe perioada stării de alertă și până la noi informații din partea autorităților, biletele pentru evenimente de pe ality.ro se achiziționează conform legislaţiei aplicabile privind măsurile pentru prevenirea contaminării cu noul coronavirus SARS-CoV-2 și pentru asigurarea desfășurării activităților în condiții de siguranță sanitară în domeniul culturii.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>În contextul măsurilor luate de autorităţi pentru prevenirea răspândirii noului coronavirus, o mare parte din evenimentele ce urmau să aibă loc în anii 2020 şi 2021 au fost anulate sau reprogramate.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Organizatorii de evenimente sunt obligaţi să se supună restricţiilor dispuse în principal prin hotărârile emise de Departamentul pentru Situaţii de Urgenţă, dar şi de alte autorităţi cu atribuţii în domeniu.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><strong><span style={{color: 'black'}}>Decizia de reprogramare sau de anulare a evenimentului este luată exclusiv de organizator, fără implicarea CREATIVE ALITY.</span></strong><span style={{color: 'black'}}>&nbsp;Alegerea între reprogramare sau anulare este inevitabilă în contextul măsurilor luate de autorităţi pentru combaterea răspândirii COVID-19, aceasta nu este imputabilă organizatorului evenimentului, nici societăţii Creative Ality. Organizatorii evenimentelor respective sunt sigurii care au puterea sa decidă cu privire la returnarea banilor pe biletele achiziţionate şi condiţiile în care această operaţiune de returnare se desfăşoară. Societatea noastră este un intermediar care acţionează doar în limitele mandatului acordat de organizatorii de evenimente, nu ia decizii cu privire la anulare / reprogramare evenimente, nici cu privire la returnarea preţului biletelor.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>În situaţia în care nu sunteţi de-acord cu decizia organizatorului (comunicată de Ality pe seama acestuia), vă rugăm să vă adresaţi pentru soluţionarea situaţiei direct organizatorului respectiv (menţionat pe bilet).</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><strong><span style={{color: 'black'}}>În conformitate cu Ordonanţa de urgenţă nr. 174/2020 pentru modificarea şi completarea unor acte normative care reglementează activitatea de sprijin financiar din fonduri externe nerambursabile, în contextul crizei provocate de COVID-19</span></strong><span style={{color: 'black'}}>, modificată prin Legea nr. 131/2021, pentru evenimentele ce urmau a fi susţinute în perioada în care se suspendă dreptul de organizare a evenimentelor,&nbsp;<strong>prin decizia autorităţilor publice şi a organizatorilor (nu a CREATIVE ALITY)</strong>, urmează să se aplice următoarele reguli:</span></p>
                    <ol style={{marginTop: '0cm', marginBottom: '7.5pt'}}>
                        <li style={{margin: '0cm 0cm 7.5pt 0px', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>În cazul reprogramării evenimentului, biletul de acces achiziţionat va putea fi folosit pentru noua dată, cumpărătorul beneficiind de toate drepturile conferite de biletul de acces la data reprogramării evenimentului. Termenul de aducere la cunoştinţa publicului a datei reprogramării evenimentului este de maximum 6 luni de la data încetării perioadei de suspendare a dreptului de organizare a evenimentelor.</span></li>
                        <li style={{margin: '0cm 0cm 7.5pt 0px', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>În cazul anulării evenimentului sau în cazul imposibilităţii folosirii biletului de acces la data reprogramării evenimentului, participantul va primi din partea organizatorului un voucher pentru întreaga sumă plătită organizatorului, voucher ce va fi folosit pentru achiziţionarea de produse sau servicii din gama oferită de către organizator, inclusiv pentru accesul la alte evenimente organizate de același organizator, conform unui regulament comunicat de organizator.</span></li>
                        <li style={{margin: '0cm 0cm 7.5pt 0px', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>În cazul în care voucherul nu este folosit pentru achiziţionarea de produse sau servicii până la epuizarea termenului prevăzut la punctul 1 de mai sus, organizatorul va rambursa toate sumele pe care le-a primit din partea consumatorului în termen de 90 de zile calculat din ultima zi a termenului de reprogramare.</span></li>
                        <li style={{margin: '0cm 0cm 7.5pt 0px', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Organizatorul are obligativitatea de a oferi un termen de cel puţin 30 zile cumpărătorului de bilete pentru a opta între păstrarea valabilităţii biletului pentru evenimentul reprogramat sau transformarea lui în voucher. După expirarea celor 30 de zile biletul devine automat valabil pentru ediţia reprogramată a evenimentului.</span></li>
                    </ol>
                    <div style={{margin: '15pt 0cm', textAlign: 'center', background: 'white', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}} align="center"><hr align="center" size={0} width="100%" /></div>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>1. Termeni generali</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>1.1. Prezentul document conținând termenii și condițiile aplicabile cumpărătorilor de bilet (denumit în continuare "Termeni și Condiții") stabilește care sunt condițiile în care orice persoană poate accesa Site-ul, ori poate utiliza în orice mod serviciile oferite prin intermediul acestuia. Termenii și Condițiile reprezintă contractul încheiat de dumneavoastră cu administratorul platformei, vă rugăm să citiți integral acest document înainte de a utiliza serviciile furnizate de societatea noastră.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>1.2. Utilizatorii înțeleg și acceptă că utilizarea Serviciului, cumpărarea unui bilet pe Site, precum și accesarea sau vizitarea în continuare a Platformei constituie o acceptare în întregime și necondiționată a Termenilor și Condițiilor.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>1.3. Dacă aveți nelămuriri cu privire la conținutul Termenilor și Condițiilor, obligațiile prevăzute de acest document sau orice alte întrebări referitoare la Servicii, le puteți comunica pe adresa de mail contact@ality.ro.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>1.4. În înțelesul Termenilor și Condițiilor, termenii următori înseamnă:</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>a) „Administratorul Platformei” înseamnă Creative Ality, cu sediul în Bd. Pierre de Coubertin, Nr. 3-5, cladirea Office Building, etaj 5, spatiu S5.21, Sector 2 , Bucureşti;</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>b) "Site" sau „ality.ro” înseamnă website-ul www.ality.ro şi aplicaţiile pentru mobil iaBilet distribuite prin platformele GooglePlay (versiunea Android) şi AppStore (versiunea iOS;</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>c) "Utilizatorul" înseamnă orice persoană care accesează Site-ul sau care utilizează în orice modalitate orice Serviciu furnizat prin intermediul Platformei;</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>2. Mod funcționare</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>2.1. ality.ro este o platformă de ticketing online (oferind utilizatorilor servicii de intermediere independente). Comenzile efectuate pe site-ul www.ality.ro sunt confirmate printr-un e-mail de înregistrare primit după finalizarea comenzii. Biletele comandate sunt trimise în format PDF prin e-mail după finalizarea comenzii.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>2.2. În urma finalizării cu succes a unei comenzi online, utilizatorul cumpărător primește pe adresa de mail furnizată biletele cumpărate (de exemplu dacă a cumpărat 4 bilete, primește un fișier.pdf cu 4 bilete), pe care apoi le poate printa pentru a le prezenta la intrarea în locul de desfășurare a evenimentului. Alternativ, utilizatorul cumpărător poate prezenta biletele direct pe ecranul smartphone-ului, variantă pe care o recomandăm. Accesul la evenimentul pentru care s-au achiziționat bilete online se face în baza scanării codului QR de pe bilet. Codurile QR sunt unice și fiecare permite accesul unei singure persoane la eveniment. O dată scanat un cod QR, o altă persoană nu mai poate intra cu acesta, nici măcar pe la o altă intrare pentru că toate scannerele ality.ro funcționeză online și sunt sincronizate în timp real.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>2.3. În cazul în care v-aţi abonat la recomandările noastre, Administratorul Platformei vă poate trimite ulterior prin SMS sau e-mail oferte promoționale pentru alte evenimente organizate în orașul dumneavoastră sau care ar putea să vă intereseze. Scopul acestor mesaje este de fidelizare a clienților. Daca nu doriti sa mai primiti aceste informari, va puteti dezabona oricând sau puteti sa ne scrieti la dpo [at] ality.ro pentru a va anonimiza pe ality.ro.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>2.4. Concomitent cu achiziția online de bilete, Administratorul Platformei vă comunică și individualizează prețul aferent serviciilor de intermediere prestate separat de prețul biletelor procurate.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>3. Relații cu clienții</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>3.1. E-mail-ul contact@ality.ro, la care ne puteti contacta, este conectat la un software special de tickets similar cu cel al companiilor de telefonie mobila. Colegii nostri iau ticketele pe rand pe masura ce le sunt alocate si va raspund in ordinea acestora. Uneori, datorita numarului mare de cereri, primirea unui raspuns poate dura pana la 48 de ore sau mai mult, in functie de complexitatea solicitarii. Va asiguram ca toti clientii sunt in mod egal la fel de importanti pentru noi si ca depunem toate eforturile pentru a va raspunde cat mai repede si a va oferi servicii de calitate.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>3.2. Toata comunicarea cu clientii nostri se realizeaza doar in scris pentru a putea urmari discutiile si a putea rezolva cat mai eficient, rapid si cu atentie solicitarile voastre. ality.ro foloseste un software de tickets performant prin intermediul caruia fiecare solicitare primita de la clientii nostri este alocata unui account manager si urmarita de catre colegii si superiorii sai ierarhici. Va incurajam sa folositi adresa de mai sus si va multumim pentru intelegere.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>4. Modalitati de plata</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Pentru cumpararea de bilete, Administratorul Platformei accepta urmatoarele modalitati de plata:</span></p>
                    <h3 style={{margin: '15pt 0cm 7.5pt', background: 'white', lineHeight: '107%', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Calibri Light", sans-serif', color: '#1f3763', fontWeight: 'normal'}}><span style={{fontSize: '10.5pt', lineHeight: '107%', fontFamily: 'inherit, serif'}}>4.1. Plata cu cardul</span></h3>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Plățile prin intermediul cardurilor de debit sau credit sunt procesate de către&nbsp;<a style={{color: 'blue', textDecoration: 'underline'}} href="http://www.librabank.ro/"><span style={{color: '#006aca'}}>https://netopia-payments.com/</span></a>, partenerul Administratorului Platformei în procesarea acestor plăţi. Comunicaţia cu procesatorul se face prin intermediul unei conexiuni securizate SSL, datele transmise fiind criptate. Toate informaţiile privind cardul vor fi prelucrate exclusiv de catre procesatorul de plăţi.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Prin menținerea activă a opțiunii Activează opțiunea plăți rapide, prezentă în formularul de plată online pus la dispoziția Administratorului Platformei de către procesatorul de plăți, utilizatorul își dă acceptul pentru activarea serviciului prin care se vor putea efectua ulterior plăți online doar prin introducerea codului de securitate CVC2/CVV. <br/> Clienti vor intra în posesia bunurilor achiziționate imediat după confirmarea plății.</span></p>
                    <h3 style={{margin: '15pt 0cm 7.5pt', background: 'white', lineHeight: '107%', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Calibri Light", sans-serif', color: '#1f3763', fontWeight: 'normal'}}><span style={{fontSize: '10.5pt', lineHeight: '107%', fontFamily: 'inherit, serif'}}>4.2. Plata prin transfer bancar</span></h3>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Este necesara formularea unei solicitari scrise pe adresa contact@ality.ro pentru transmiterea datelor bancare.</span></p>
                    <h3 style={{margin: '15pt 0cm 7.5pt', background: 'white', lineHeight: '107%', breakAfter: 'avoid', fontSize: '12pt', fontFamily: '"Calibri Light", sans-serif', color: '#1f3763', fontWeight: 'normal'}}><span style={{fontSize: '10.5pt', lineHeight: '107%', fontFamily: 'inherit, serif'}}>5. Returnarea contravalorii biletelor</span></h3>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>5.1.&nbsp;<strong>ATENTIE: Administratorul Platformei va putea returna in caz de anulare a comenzii sau a evenimentului doar pretul biletului nu si pretul serviciilor de intermediere aferente achiziției făcute.</strong></span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Anularea comenzilor si returnarea contravalorii biletelor se poate face, daca organizatorul premite acest lucru, pe www.ality.ro/anulare.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>In caz ca optati pentru returnarea contravalorii biletelor in cont bancar, va rugam sa ne oferiti un IBAN din Romania (RO .. ). Administratorul Platformei nu va returna contravaloarea biletelor in conturi inregistrate la banci din alte tari sau in conturi de Revolut.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>5.2.&nbsp;<strong>ATENTIE:</strong>&nbsp;Acest tool anuleaza toate biletele dintr-o comanda. Nu puteti anula doar o parte din bilete.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>5.3.&nbsp;<strong>ATENTIE:</strong>&nbsp;Clientii care au comandat bilete si au platit cu cardul cu mai mult de 6 luni inainte de momentul in care doresc restituirea banilor, trebuie sa foloseasca tool-ul de anulare de mai sus pentru a ne oferi un IBAN in care sa primeasca banii. Netopia Pay, procesatorul de plati cu care lucreaza Administratorul Platformei, poate returna automat doar comenzile facute cu maxim 6 luni inainte.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>5.4.&nbsp;<strong>ATENTIE:</strong>&nbsp;Pentru anumite evenimente organizatorul este liber să nu permită renunţarea la bilet. Achiziţionarea de biletele la spectacole este exceptată de la aplicarea dreptului de retragere reglementat de OUG 34/2014, în conformitate cu art. 16 lit. l) din actul normativ menţionat mai sus. Exemple: Sublime Events, Artmania, Emagic.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>5.5.&nbsp;<strong>Biletul achiziţionat poate fi returnat sau schimbat numai dacă organizatorul evenimentului acceptă şi numai cu respectarea condiţiilor de la pct. A de mai jos, cu excepţia cazului în care evenimentul respectiv este anulat (nu mai are loc), caz în care returnarea biletului şi rambursarea sumelor achitate va fi efectuată potrivit lit. B de mai jos sau prevederilor legale aplicabile*, după caz. Sub nicio forma nu se mai iau in considerare cererile de rambursare primite dupa data si ora de incepere a evenimentului.</strong></span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>A. Returnarea biletului poate fi efectuată în termenele şi cu respectarea condiţiilor de mai jos, prin transmiterea unei solicitări în acest sens către contact@ality.ro:</span></p>
                    <ol style={{marginTop: '0cm', marginBottom: '7.5pt'}}>
                        <li style={{margin: '0cm 0cm 7.5pt 0px', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>dacă solicitarea de returnare este formulată cu cel puţin 14 de zile înainte de data de desfăşurare a evenimentului (sau de prima zi a acestuia, în cazul unui festival), cumpărătorul de bilet primeşte ca urmare a anulării biletului un voucher pentru întreaga sumă plătită cu titlu de preţ al biletului (fără alte taxe, tarife, costuri, cheltuieli suportate de cumpărător cu ocazia cumpărării acestuia, inclusiv, dar fără a se limita la tarif curier, taxă de rezervare, dacă e cazul etc). Voucherul poate fi utilizat pentru cumpărarea de bilete la alte evenimente prin intermediul platformei www.ality.ro.</span></li>
                        <li style={{margin: '0cm 0cm 7.5pt 0px', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>dacă solicitarea de returnare este formulată cu cel puţin 7 zile (dar mai puţin de 14) înainte de data de desfăşurare a evenimentului (sau de prima zi a acestuia, în cazul unui festival), cumpărătorul de bilet primeşte ca urmare a anulării biletului, fie 70% din sumă plătită cu titlu de preţ al biletului (fără alte taxe, tarife, costuri, cheltuieli suportate de cumpărător cu ocazia cumpărării acestuia, inclusiv, dar fără a se limita la tarif curier, taxă de rezervare, dacă e cazul etc), fie un voucher pentru aceeaşi sumă (determinată conform prevederilor de mai sus). Voucherul poate fi utilizat pentru cumpărarea de bilete la alte evenimente prin intermediul platformei www.ality.ro.</span></li>
                        <li style={{margin: '0cm 0cm 7.5pt 0px', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>dacă solicitarea de returnare este formulată cu cel putin o zi înainte (dar mai puţin de 7) de data de desfăşurare a evenimentului (sau de prima zi a acestuia, în cazul unui festival), cumpărătorul de bilet primeşte ca urmare a anulării biletului, fie 50% din sumă plătită cu titlu de preţ al biletului (fără alte taxe, tarife, costuri, cheltuieli suportate de cumpărător cu ocazia cumpărării acestuia, inclusiv, dar fără a se limita la tarif curier, taxă de rezervare, dacă e cazul etc), fie un voucher pentru 70% din sumă plătită cu titlu de preţ al biletului (fără alte taxe, tarife, costuri, cheltuieli suportate de cumpărător cu ocazia cumpărării acestuia, inclusiv, dar fără a se limita la tarif curier, taxă de rezervare, dacă e cazul etc). Voucherul poate fi utilizat pentru cumpărarea de bilete la alte evenimente prin intermediul platformei www.ality.ro.</span></li>
                    </ol>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>În toate cazurile de mai sus, prețul serviciilor furnizate de Administratorul Platformei nu se returnează şi nu se ia în calcul la determinarea valorii voucherului.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>În funcţie de momentul formulării solicitării, cumpărătorul se va afla într-una din situaţiile menţionate la pct. 1-3 de mai sus, fără a le putea combina sau cumula. Cu alte cuvinte, dacă, solicitarea este formulată cu 14 zile înainte de data de desfăşurare a evenimentului, operează exclusiv pct. 2 de mai sus (fără a dobândi cumulativ şi drepturile menţionate la pct. 3 de mai sus).</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>B. În eventualitatea anulării unui eveniment, vă rugăm să trimiteţi solicitarea dvs. în acest sens pe adresa contact@ality.ro, precum şi către adresa de contact a organizatorului evenimentului anulat. Administratorul Platformei va colabora cu organizatorul evenimentului în cauză în vederea rambursării sumelor achitate cu titlu de preţ pentru biletele achiziţionate de dvs, dar nu oferă nicio garanţie în acest sens şi nu are nicio obligaţie de a despăgubi cumpărătorul de bilet în lipsa unei înţelegeri prealabile cu organizatorul evenimentului. În principiu, în funcţie de momentul la care este formulată solicitarea, sumele vor fi rambursate fie de organizatorul de spectacole, dacă Administratorul Platformei a transferat deja la momentul solicitării sumele încasate pe seama acestuia din vânzarea de bilete, fie de Administratorul Platformei, dacă are acordul organizatorului pentru restituirea acestor sume, în termen de maxim 15 zile lucrătoare, doar prin transfer bancar. Pentru a înlătura orice îndoială, Administratorul Platformei şi organizatorul evenimentului precizează că nu va fi returnat decât preţul biletului (fără alte taxe, tarife, costuri, cheltuieli suportate de cumpărător cu ocazia cumpărării acestuia, inclusiv, dar fără a se limita la tarif curier, taxă de rezervare, dacă e cazul etc).</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>*) prin derogare de la cele de mai sus, pentru evenimentele ce urmau a fi susţinute în perioada în care se suspendă dreptul de organizare a evenimentelor în contextul crizei provocate de COVID-19 sunt aplicabile prevederile speciale de la începutul prezentelor Termeni și Condiții, precum și legislația adoptată de autorități în acest scop.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>5.6.&nbsp;<strong>Modalitati de rambursare a contravalorii biletelor in functie de modalitatea de plata cu care s-au achizitionat bilete, in urma solicitarii scrise primite pe adresa: contact@ality.ro:</strong></span></p>
                    <ul style={{marginBottom: '0cm', marginTop: '0px'}} type="disc">
                        <li style={{lineHeight: 'normal', background: 'white', marginTop: '0cm', marginRight: '0cm', marginBottom: '8pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{color: 'black'}}>daca biletele au fost achizitionate cu plata prin card sau paypal rambursarea sumei ce reprezinta contravaloarea biletelor se va efectua in contul din care s-a facut plata initiala;</span></li>
                        <li style={{lineHeight: 'normal', background: 'white', marginTop: '0cm', marginRight: '0cm', marginBottom: '8pt', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}><span style={{color: 'black'}}>daca biletele au fost achizitionate cu plata prin Vodafone sau Orange rambursarea sumei ce reprezinta contravaloarea biletelor va fi returnata din contul operatorului de telefonie prin ajustarea facturii lunare;</span></li>
                    </ul>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>5.7. In caz de&nbsp;<strong>pierdere sau de furt</strong>&nbsp;al biletelor online, utilizatorul cumparator este obligat sa notifice acest fapt catre Administratorul Platformei, pe adresa contact@ality.ro, in vederea inlocuirii biletelor. In cazul in care utilizatorul nu procedeaza la notificarea Administratorului Platformei in timp util, acesta nu isi asuma nicio raspundere pentru utilizarea biletelor respective de o alta persoana, nici pentru imposibilitatea cumparatorului initial de a mai participa la evenimentul respectiv pe baza biletelor pierdute / furate.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>5.8. Sub nicio forma biletele nu se mai pot returna dupa ce evenimentul a avut loc.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>5.9.&nbsp;<strong>Comisioane puse peste pretul biletelor pe site si in puncte fizice</strong>: Anumiti organizatori cu care lucreaza Administratorul Platformei aleg sa adauge anumite taxe aferente biletelor peste pretul acestora. Asa ca in anumite cazuri veti plati o suma mai mare decat cea comunicata pe anumite canale media atat pe site cat si in punctele fizice de vanzare. Administratorul Platformei mereu va afisa suma finala platita de client inainte ce acesta sa efectueze plata. Mentionam ca aceste sume nu reprezinta intotdeauna venituri ale Administratorului Platformei si ca acesta este pretul final platit de catre toti clientii care merg la spectacol indiferent de locul de achizitie al biletelor.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>6. Relocări</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>La dorinta organizatorilor evenimentelor, Administratorul Platformei poate efectua oricand relocari cu biletele cumparate de dumneavoastra de pe locurile pe care le aveti pe alte locuri dar va va informa in prealabil si va va trimite pe mail noile locuri. Acese relocari sunt motivate de masuri care tin de productia evenimentelor si pot aparea spontan chiar cu cateva zile sau chiar o zi inainte de eveniment. Locurile cumparate de dumneavoastra pot fi ocupate de echipamente tehnice sau de butaforii sau pot fi relocate la cerinta artistului interpret. Aceste cazuri sunt totusi foarte rare. In caz ca doriti returnarea contravalorii biletelor daca ati fost relocat/a, puteti cere acest lucru si veti primi suma platita pe bilete.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>7. Revânzarea biletelor</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>7.1. Revanzarea biletelor cumparate prin intermediul ality.ro este interzisa sau restrictionata de anumiti organizatori. Daca doriti sa renuntati la bilete si sa va recuperati banii pe biletele cumparate va recomandam sa scrieti la adresa de e-mail contact@ality.ro si sa solicitati acest lucru. Atentie, trebuie sa respectati termenii si conditiile de la capitolul Returnarea contravalorii biletelor din Termenii si conditiile ality.ro.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>7.2. Organizatorii care au interzis sau restrictionat in orice mod revanzarea biletelor isi rezerva dreptul de a anula toate si orice bilete oferite spre vanzare de cumparatorii acestora, inclusiv pe siteuri de comert electronic / marketplace, urmand sa va comunice masura dispusa si sa va returneze sumele platite pe biletele anulate. Va rugam sa aveti in vedere ca in anumite situatii, anularea biletelor va fi dispusa doar daca pretul de revanzare al acestora este diferit de cel inscris pe acestea (pretul platit de dumneavoastra). In toate cazurile, este recomandat sa contactati organizatorul evenimentului pentru a fi informat complet si corect cu privire la restrictiile aplicabile revanzarii biletului.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>8. Traficul de date</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Traficul de date asociat utilizării serviciului se taxează conform planului tarifar al operatorului dumneavoastră de telefonie mobila.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>9. Vouchere</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>In cazul in care se achita o comanda prin voucher si clientul nu a cheltuit toata suma disponibila, atunci diferența nu este rambursabilă.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Voucherul poate fi folosit pentru o singura comanda.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Voucherul este valabil 365 de zile de la data emiterii acestuia.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Administratorul Platformei isi rezerva dreptul de a anula voucherele pentru care exista suspiciunea ca au fost obtinute prin fraudarea sistemului, precum si biletele achizitionate folosind aceste vouchere.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>10. Protecție la supraîncărcarea rețelei</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Administratorul Platformei poate limita in timp real numarul de bilete disponibile la un anumit eveniment atunci cand cererea (globala sau locala) este foarte mare pentru a proteja reteaua / platforma de supraincarcare si a va asigura o experienta de cumparare cat mai placuta. Pe masura ce traficul scade, biletele se vor repune in vanzare, treptat. Aceste practici sunt comune asa ca uneori s-ar putea sa observati categorii de bilete pe cale de epuizare urmand ca in scurt timp alte bilete sa apara la vanzare.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>11. Locuri cu vizibilitate limitată</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Anumiti organizatori pot pune in vanzare prin ality.ro locuri cu vizibilitatea limitata. Acestea vor avea o denumire care specifica acest lucru clar. Locurile cu vizibilitatea limitata nu garanteaza cea mai buna experienta de concert. S-ar putea sa fiti nevoiti sa va ridicati in picioare pentru a urmari concertul sau o parte din vizibilitatea dumneavoastra sa fie afectata de balustrade sau de umbrele de baruri, corturi de sunet dar fara a se limita la acestea. Odata cumparate, aceste bilete nu mai pot fi returnate nici inainte, nici dupa eveniment. Cumparatorul isi asuma vizibilitatea limitata in momentul cumpararii biletelor.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>12. Nota de informare privind protecția datelor personale</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>12.1. Conform cerintelor Regulamentului (UE) 2016/679 privind protecţia persoanelor fizice în ceea ce priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date şi de abrogare a Directivei 95/46/CE (Regulamentul general privind protecţia datelor), si ale Legii nr. 506/2004 privind prelucrarea datelor cu caracter personal si protectia vietii private in sectorul comunicatiilor electronice, Administratorul Platformei va administra in conditii de siguranta si numai pentru scopurile specificate, datele personale furnizate de utilizatori. Va informam ca datele personale pe care utilizatorii le furnizeaza administratorului platformei sunt prelucrate in scopul oferirii in conditii optime a serviciilor de intermediere a vânzării de bilete la evenimente, de administrare a comenzilor formulate de utilizatori, de emitere bilet şi a serviciilor conexe acestora, precum şi în scopuri de marketing direct.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>12.2. Fiecare utilizator, la optiunea sa proprie, furnizeaza unele dintre datele sale personale pentru a beneficia de serviciul oferit de catre Administratorul Platformei. Refuzul furnizarii anumitor date poate conduce la imposibilitatea accesarii anumitor servicii prin intermediul Platformei.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>12.3.&nbsp;<strong>Datele dvs. cu caracter personal pot fi dezvăluite partenerilor contractuali ai Creative Ality S.R.L. implicaţi în furnizarea serviciilor noastre, precum furnizori de servicii de curierat, de contabilitate, procesatori de plăţi.</strong></span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>12.4. Conform Regulamentului general privind protecţia datelor, fiecare utilizator beneficiaza de dreptul de acces, dreptul la rectificare, dreptul la ştergerea datelor, dreptul la restricţionarea prelucrării, dreptul la opoziţie, dreptul la portabilitatea datelor, dreptul de a nu fi supus unei decizii individuale si dreptul de a se adresa justitiei.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>12.5. Pentru exercitarea acestor drepturi, fiecare utilizator poate face o cerere in scris in acest sens, pe care sa o trimita apoi prin posta la adresa administratorul platformei: Creative Ality S.R.L. cu sediul in Sat Albesti, comuna Albesti de Muscel, Nr. 178, jud Arges sau prin e-mail la adresa: contact@ality.ro.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>12.6. Administratorul Platformei prelucrează datele cu caracter personal ale Utilizatorilor cu respectarea Politicii de confidențialitate, care face parte integrantă din prezentele Termene şi condiţii.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>13. Limitarea răspunderii</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>13.1. ality.ro este o platformă online prin intermediul căreia utilizatorii platformei pot achiziţiona de la organizatorii de spectacole bilete de acces la evenimentele organizate de aceştia. Pe cale de consecinţă, întreaga răspundere faţă de utilizatori în legătură cu evenimentele respective revine organizatorilor de spectacole. Aceştia îşi asumă integral răspunderea faţă de cumpărătorii de bilet pentru eventualele întarzieri, amânari, anulări sau pentru orice alte probleme în legătură cu evenimentele ale căror bilete au fost comercializate prin intermediul platformei Ality.ro, pentru derularea acestora în condiții necorespunzătoare sau in alte condiții decât cele comunicate public de organizator sau cele prevăzute de legislația in materie, precum şi pentru orice restricții impuse cu privire la evenimente de terțe persoane implicate in organizarea lor sau de autorități. Organizatorul are dreptul sa modifice datele de desfășurare a evenimentului, noile informații fiind anunțate prin mijloace de comunicare specifice.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>13.2. Prin urmare, Administratorul Platformei nu răspunde pentru nicio pretenţie în legătură cu: intensitatea sau calitatea sunetului, vizibilitatea, aranjarea meselor în local, setlistul ales de artist, serviciile de curăţenie, alimentaţie, pază, garderobă, baruri, dar fără a se limita la acestea. Pentru orice plângere referitoare la aspectele menţionate mai sau la orice alte aspecte care ţin de organizarea evenimentului, vă rugăm să vă adresaţi organizatorului acestuia.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>13.3. Administratorul Platformei nu răspunde sub nicio formă, în nicio masură și nu poartă niciun fel de responsabilitate pentru serviciul de procesare a plăților cu cardul sau pentru serviciul de plată prin Vodafone. Administratorul Platformei nu este responsabil pentru niciun fel de eroare și/sau fraudă decurgând din, sau în legatură cu acest serviciu. Totuși, deşi serviciile de plată sunt oferite și dirijate in totalitate și in mod independent de catre furnizorii nostru, Administratorul Platformei va întreprinde toate măsurile rezonabile pentru a limita pe cât posibil riscul apariției acestor erori și/sau fraude.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>13.4. In masura maxima permisa de lege, Administratorul Platformei nu isi asuma obligatia si nu garanteaza implicit sau expres pentru continutul de orice fel al Platformei, indiferent daca acesta este creat de Administratorul Platformei, este publicat in Site de partenerii sai contractuali sau la cererea acestora sau este oferit de catre organizatorii de evenimente pentru a fi publicat pe Site (denumit in continuare “Continutul Platformei”). Administratorul Platformei va face toate eforturile rezonabile pentru a asigura acuratetea si increderea in Site si va incerca sa corecteze erorile si omisiunile cat mai repede posibil.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>13.5. In masura maxima permisa de lege, Administratorul Platformei nu ofera niciun fel de garantii sau raspundere pentru Continutul Platformei si, in masura maxima permisa de lege, nu poate fi facut raspunzator pentru nicio pierdere sau prejudiciu ce ar putea rezulta din utilizarea oricarei parti, secvente, modul al Continutului Platformei sau din imposibilitatea utilizarii acestuia, indiferent de cauza acesteia ori din interpretarea eronata a oricaror prevederi ale Continutului Platformei.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>13.6. Utilizatorii inteleg si accepta ca furnizarea serviciului Ia Bilet poate fi afectata de anumite conditii obiective, precum si ca orice servicii oferite prin intermediul Platformei sunt oferite potrivit principiului "asa cum sunt", "asa cum sunt disponibile", iar Utilizatorii folosesc aceste servicii pe riscul lor. Utilizatorii inteleg si accepta ca Administratorul Platformei este absolvit de orice raspundere in eventualitatea producerii oricarei opriri, intreruperi, ingreunari, disfunctionalitati sau erori in functionarea Platformei, in situatia unei erori tehnice de orice fel sau oricaror erori in furnizarea serviciului, precum si in orice situatie in care nu ar dovedi cert ca orice erori sau probleme de natura tehnica din cele mentionate mai sus se datoreaza in mod direct si exclusiv culpei grave a Administratorului Platformei.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>13.7. In cazurile de forta majora si caz fortuit, Administratorul Platformei si/sau operatorii, directorii, angajatii, sucursalele, filialele si reprezentantii sai, sunt exonerati total de raspundere. Cazurile de forta majora includ, dar nu se limiteaza la acestea: erori de functionare ale echipamentului tehnic al Administratorului Platformei, lipsa conexiunii la internet, virusii informatici, atacurile informatice de orice fel, accesul neautorizat in sistemele Platformei, erorile de operare, greva, pandemii, epidemii etc.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>13.8. In mod expres, Utilizatorii inteleg si accepta ca Administratorul Platformei este absolvit in masura maxima permisa de lege de orice raspundere pentru orice fel de daune directe, indirecte, daune morale, incluzand dar fara a se limita la pierderi de profit, atingeri aduse onoarei si reputatiei, vad comercial sau alte pierderi intangibile, rezultate din utilizarea serviciului sau orice alt aspect in legatura cu serviciul precum si din utilizarea Continutului Platformei in orice mod sau orice efecte juridice derivand din aceasta.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>14. Factura fiscală persoane juridice</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>14.1. Pentru comenzile efectuate in numele unei entitati juridice se pot emite facturi fiscale in urma unei solicitari scrise primite prin completarea formularului de aici: www.ality.ro/facturi</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>14.2. Solicitarea trebuie sa contina toate datele complete si corecte: numarul de comanda sau numele pe care s-a efectuat comanda si datele de facturare complete (nume societate, CUI, Nr. Reg Comert, Sediu Social, Cont bancar, banca la care este deschis contul, delegat).</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>14.3. Factura pentru prețul biletului nu este emisa de ality.ro, ci de catre organizatorul evenimentului in numele caruia noi vindem bilete. Cererea ta ajunge pe mail direct la organizator.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>14.4. Dacă prețul aferent serviciilor de intermediere este încasat de la cumpărător, atunci factura pentru acesta nu este emisă de către organizatorul evenimentului ci de către Administratorul Platformei.</span></p>
                    <h2 style={{margin: '15pt 0cm 7.5pt', background: 'white', fontSize: '18pt', fontFamily: '"Times New Roman", serif', fontWeight: 'bold'}}><span style={{fontSize: '13.5pt', fontFamily: 'inherit, serif', color: 'black'}}>15. Dreptul de retragere acordat de operatorii economici care comercializează produse și servicii prin intermediul platformei Ality.ro</span></h2>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.1. Achizitionarea de servicii privind activitatile de agrement, in cazul in care este prevazuta o data de executare specifica, este exceptata de la aplicarea dreptului de retragere reglementat de OUG 34/2014, în conformitate cu art. 16 lit. l) din actul normativ mentionat mai sus. Prin urmare, nu veti putea solicita returnarea contravalorii codurilor de acces sau biletelor achizitionate prin exercitarea dreptului de retragere.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.2. În conformitate cu Ordonanța de urgență nr. 34/2014 („OUG 34/2014”) privind drepturile consumatorilor în cadrul contractelor încheiate cu profesioniștii, precum și pentru modificarea și completarea unor acte normative, consumatorul beneficiază de o perioadă de 14 zile pentru a se retrage dintr-un contract la distanță sau dintr-un contract în afara spațiilor comerciale, fără a fi nevoit să justifice decizia de retragere și fără a suporta alte costuri decât costurile directe legate de returnarea produselor și eventualele prejudicii provocate de diminuarea valorii produselor rezultată din manipularea acestora.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>De la regula generală instituită potrivit celor de mai sus, OUG 34/2014 reglementează următoarele excepții relevante pentru utilizatorii Ality.ro:</span></p>
                    <ol style={{marginTop: '0cm', marginBottom: '7.5pt'}}>
                        <li style={{margin: '0cm 0cm 7.5pt 0px', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Achiziționarea de biletele la spectacole este exceptată de la aplicarea dreptului de retragere reglementat de OUG 34/2014, în conformitate cu art. 16 lit. l) din acest act normativ.</span></li>
                        <li style={{margin: '0cm 0cm 7.5pt 0px', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Contractele de prestări de servicii sunt exceptate de la dreptul de retragere, după prestarea completă a serviciilor, dacă executarea a început cu acordul prealabil expres al consumatorului și după ce acesta a confirmat că a luat cunoștință de faptul că își va pierde dreptul la retragere după executarea completă a contractului de către profesionist, în conformitate cu art. 16 lit. a) din OUG 34/2014.</span></li>
                    </ol>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.3. Dacă produsul achiziționat prin intermediul platformei nu este exceptat de la dreptul de retragere în conformitate cu prevederile OUG 34/2014, consumatorul beneficiază de o perioadă de 14 zile pentru a se retrage dintr-un contract la distanță, fără a fi nevoit să justifice decizia de retragere, în condițiile reglementate de actul normativ menționat mai sus și cu respectarea prezentelor prevederi.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.4. În situația exercitării dreptului de retragere, consumatorul nu este obligat să precizeze motivele pentru care returnează produsul.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.5. Termenul de 14 zile curge de la data la care consumatorul sau o terță parte indicată de acesta intră în posesia fizică a produselor. În cazul în care consumatorul comandă printr-o singură comandă produse multiple care vor fi livrate separat, termenul de 14 zile curge din ziua în care consumatorul sau o parte terță indicată de acesta, intră în posesia ultimului produs. Serviciile de intermediere, administrare comandă, cele conexe acestora și cele de emitere bilete furnizate de administratorul platformei prin intermediul Ality.ro sunt exceptate de la dreptul de retragere. Cumpărătorul de bilet este expres de acord cu începerea executării serviciilor de intermediere, de emitere bilet și serviciile conexe acestora de la finalizarea comenzii. Cumpărătorul de bilet declară că înțelege că prin finalizarea comenzii își va pierde dreptul de retragere, ca urmare a executării complete a contractului de către Administratorul Platformei.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.6. Pentru a vă exercita dreptul de retragere dintr-un anumit contract, trebuie să contactați operatorul economic care comercializează produsele achiziționate, la adresa pusă la dispoziție de administratorul platformei, și să îl informați cu privire la decizia dvs. de a vă retrage din contract.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.7. În situația în care operatorul economic nu poate fi contact pentru exercitarea dreptului de retragere, vă rugăm să comunicați cererea pe adresa contact@ality.ro. Administratorul platformei o va înainta respectivului operator economic și va furniza asistența rezonabil necesară pentru soluționarea cererii de retragere.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.8. Consumatorul poate exercita dreptul de retragere prin utilizarea formularului de retragere de mai jos sau prin orice altă declarație neechivocă în care își exprimă decizia de retragere din contract. Formularul sau declarația de retragere pot fi comunicate operatorului economic prin poștă tradițională sau electronică (e-mail). Folosirea formularului propus mai jos nu este obligatorie.</span></p>
                    <div style={{margin: '15pt 0cm', textAlign: 'center', background: 'white', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}} align="center"><hr align="center" size={0} width="100%" /></div>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>FORMULAR DE RETRAGERE</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>pe baza Ordonanţei de urgenţă nr. 34/2014 privind drepturile consumatorilor în cadrul contractelor încheiate cu profesioniştii, precum şi pentru modificarea şi completarea unor acte normative (publicată în Monitorul Oficial, Partea I nr. 427 din 11.06.2014)</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>Stimați consumatori, prezentul formular se trimite înapoi completat doar dacă doriţi să vă retrageţi din contract.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>– Către [aici se introduce numele, adresa poştală şi, dacă este cazul, numărul de telefon, numărul de fax şi adresa de e‐mail ale profesionistului]:<br />– Vă informez/Vă informăm (*) prin prezenta cu privire la retragerea mea/noastră (*) din contractul referitor la vânzarea următoarelor produse (*)/prestarea următoarelor servicii *<br />– Comandate la data (*)/primite la data (*)<br />– Numele consumatorului (consumatorilor)<br />– Adresa consumatorului (consumatorilor)<br />– Semnătura consumatorului (consumatorilor) (doar în cazul în care acest formular este notificat pe hârtie)<br />– Data (*) A se elimina menţiunea inutilă, după caz</span></p>
                    <div style={{margin: '15pt 0cm', textAlign: 'center', background: 'white', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}} align="center"><hr align="center" size={0} width="100%" /></div>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.9. Notificarea/declarația trebuie să includă datele consumatorului: numele, adresa poștală, numărul de telefon și adresa de e-mail, numărul de comanda, precum și o formulare precisă care să exprime decizia de a se retrage din contract.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.10. Pentru a respecta termenul limită de retragere este suficient să trimiteți comunicarea privind exercitarea dreptului de retragere înainte de expirarea perioadei de retragere.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.11. Ulterior trimiterii notificării de retragere, consumatorul trebuie să expedieze produsele către operatorul economic de la care le-a achiziționat, pe adresa indicată de acesta, cât mai curând posibil și fără întârzieri nejustificate, în cel mult 14 zile de la comunicarea notificării de retragere.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.12. Produsele pe care le returnați trebuie să fie în aceeași stare în care au fost primite. Sunteți responsabil pentru diminuarea valorii produselor care rezulta din manipulări, altele decât cele necesare pentru determinarea naturii, calităților și funcționarii produselor. Nu se acceptă returul produselor asupra cărora au fost efectuate intervenții neautorizate, cele care prezinta urme de uzură sau folosire excesivă, zgârieturi, lovituri, șocuri sau produsele cărora le lipsesc accesorii. Returul se face în ambalajul original, cu etichetele inițiale intacte, certificatul de garanție în original (daca a fost emis de producător/ distribuitor) și toate documentele cu care a fost livrat produsul.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.13. În situația exercitării valabile a dreptului de retragere, operatorul economic va rambursa orice sumă pe care a primit-o de la dumneavoastră, inclusiv costurile livrării, cu excepția costurilor suplimentare determinate de faptul că ați ales altă modalitate de livrare decât cel mai ieftin tip de livrare standard oferit, fără întârzieri nejustificate și, în orice caz, nu mai târziu de 14 zile de la data la care suntem informați cu privire la decizia dumneavoastră de a vă retrage din contract.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.14. Costurile directe legate de returnarea produselor sunt suportate de consumator.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.15. Comerciantul va efectua rambursarea folosind aceeași modalitate de plată ca și cea folosită pentru tranzacția inițială, cu excepția cazului în care consumatorul a fost de-acord cu altă modalitate de rambursare.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.16. Operatorul economic va putea amâna rambursarea sumei până la primirea produselor sau până la primirea unei dovezi conform căreia acestea au fost expediate.</span></p>
                    <p style={{margin: '0cm 0cm 7.5pt', background: 'white', fontSize: '12pt', fontFamily: '"Times New Roman", serif'}}><span style={{color: 'black'}}>15.17. Pentru mai multe detalii, vă rugăm să verificați politica de retur proprie a operatorului economic de la care ați achiziționat produsul.</span></p>
                    <p style={{margin: '0cm 0cm 8pt', lineHeight: '107%', fontSize: '11pt', fontFamily: 'Calibri, sans-serif'}}>&nbsp;</p>
                </div>

            </Modal>
        )
    }

}

export default terms2;