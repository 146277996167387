import React, {useEffect, useState} from "react";
import AudioAnalyser from "../Spectrum/AudioAnalyser";

export default function ProgressBar({
                                        startRecording,
                                        stopRecording,
                                        setPreparing,
                                        preparing,
                                        status,
                                        startTimout,
                                        maxDuration,
    mediaBlob,
    makeAGif
                                    }) {

    const [timer, setTimer] = useState(startTimout);
    const [duration, setDuration] = useState(null)
    const [audio, setAudio] = useState(null)

    const [currentQuestion, setCurrentQuestion] = useState(null)

    const memoryBoothData = JSON.parse(localStorage.getItem('memoryBoothData'))

    useEffect(() => {
        if (timer === 0) {
            setPreparing(false)
            setTimer(null)
        }

        // exit early when we reach 0
        if (!timer) {
            setDuration(maxDuration)
            return
        }

        // save intervalId to clear the interval when the
        // component re-renders

        const intervalId = setInterval(() => {

            setTimer(timer - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timer]);

    useEffect(() => {

        if (status === 'stopped') {
            setDuration(null)
            stopMicrophone()
            return
        }

        if (duration === 0) {
            setDuration(null)
        }

        let showQuestions = false
        let questionsCount, currentQuestionNr = 0

        if(memoryBoothData && memoryBoothData.questions && memoryBoothData.questions.length > 0) {
            questionsCount = memoryBoothData.questions.length
            currentQuestionNr = 0
            showQuestions = true
        }

        let questionChangeStep = parseInt((maxDuration/questionsCount) + '')
        currentQuestionNr = Math.floor((maxDuration - duration) / questionChangeStep)

        if(showQuestions) {

           if(currentQuestion != currentQuestionNr) {
               setCurrentQuestion(currentQuestionNr)
           }
        }

        // exit early when we reach 0
        if (!duration) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {

            setDuration(duration - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [duration]);

    useState(() => {
        if (preparing) {
            setTimer(startTimout)
        }
    }, [preparing])

    useEffect(() => {
        toggleMicrophone()

        return () => {
            //console.log(audio)
            //audio.getTracks().forEach(track => track.stop());
        }
    }, [])

    const getMicrophone = async () => {
        const audio = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        });
        setAudio(audio)
    }

    const stopMicrophone = () => {
        if(audio)
        audio.getTracks().forEach(track => track.stop());
        setAudio(null)
    }

    const toggleMicrophone = () => {
        if (audio) {
            stopMicrophone();
        } else {
            getMicrophone();
        }
    }


    const getProgress = () => {

        if (duration === null) {
            return 0
        }
        return 100 - ((duration - 1) / (maxDuration)) * 100
    }

    return (
        <div style={{flexDirection:'column'}}>
        <div className={"mb-progressbar"}>
            {
                (status === 'stopped' || status === 'ready' || status === 'acquiring_media') && !preparing && mediaBlob ?
                    null:
                    audio ?
                    <AudioAnalyser audio={audio}/> : ''}

            <div className={"progress-line"} style={{width: getProgress() + '%'}}></div>
        </div>
            {
                status === 'recording' && makeAGif !== 1 ?
                    <div className={"mb-questions-container"}>
                        {
                            currentQuestion !== null ?
                                <div className={"mb-question-preview animate__animated animate__zoomIn "} key={currentQuestion}>
                                    {memoryBoothData.questions[currentQuestion]}
                                </div>
                                :null
                        }
                    </div>
                    :null
            }
        </div>
    );
}

