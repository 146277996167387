import React from 'react'
import { Button } from 'rsuite'
import './ResetName.scss'
import { toast } from 'react-toastify'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import CustomInput from '../../../components/custom/CustomInput'

class ResetName extends React.Component {
	state = {
		name: this.props.name,
		surname: this.props.surname
	}

	setName = (value) => {
		this.setState({ name: value });
	}

	setSurname = (value) => {
		this.setState({ surname: value });
	}

	confirm = async () => {
		if (this.state.name === "") {
			toast.error("Campul cu nume nu poate fi necompletat")
			return
		}

		if (this.state.surname === "") {
			toast.error("Campul cu prenume nu poate fi necompletat")
			return
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			first_name: this.state.surname,
			last_name: this.state.name
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'user/updateProfile', obj, auth)
			.then((response) => {
				toast.success("Schimbare efectuata")
				this.props.setNameAndSurname(this.state.name, this.state.surname)
			})
			.catch(_ => {
				toast.error("S-a produs o erorare va rog reincercati")
			})

		this.cancel()
	}

	cancel = () => {
		this.props.closeModal()
	}

	render() {
		const styles = {
			fontFamily: "sans-serif",
			textAlign: "center",
			marginTop: "20px",
			width: "90vw",
			height: "100%",
		};

		return (
			<div style={styles}>
				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal',
						closeButton: 'modal-close-button',
					}}
					center
					open={this.props.showModal}
					onClose={this.props.closeModal}
				>
					<div style={{marginTop: "20px"}}>
						<h4>Schimba Nume/Prenume</h4>

						<CustomInput style={{ margin: "10px 0px" }} value={this.state.name} onChange={this.setName} placeholder={'Nume'} border={true} />

						<CustomInput style={{ margin: "10px 0px" }} value={this.state.surname} onChange={this.setSurname} placeholder='Prenume' border={true} />

						<Button onClick={this.confirm} color="green" style={{ backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%" }}>Confirm</Button>
					</div>
				</Modal>
			</div>
		)
	}
}

export default ResetName