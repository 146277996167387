import React from 'react'
import './LoadingModal.scss'
import Modal from "react-responsive-modal"
import { BeatLoader } from "react-spinners"
import { css } from "@emotion/core"

class LoadingModal extends React.Component {
	componentDidMount() {
		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]")
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
		}
	}

	render() {
		const styles = {
			fontFamily: "sans-serif",
			textAlign: "center",
		};

		const override = css`
        display: block;
        margin: 25px auto;
        border-color: #221d5d;
        `;

		let toRender = (<BeatLoader
			css={override}
			size={15}
			color={"#00afcc"}
		/>)

		return (
			<div style={styles}>
				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal-loading',
					closeButton: 'modal-close-button',
				}} center open={this.props.loading} onClose={() => console.log("Cannot close modal")}>
					<h5> Se proceseaza informatiile </h5>

					{toRender}
				</Modal>
			</div>
		)
	}
}

export default LoadingModal