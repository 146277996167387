import React from 'react'
import './NavbarColor.scss'
import { Icon } from 'rsuite'

import SVG_Create_Event_Active from '../assets/NavBarSVGs/create-event-active-icon.svg'
import SVG_Create_Event from '../assets/NavBarSVGs/create-event-icon.svg'
import SVG_Participants from '../assets/NavBarSVGs/participant-icon.svg'
import SVG_My_Events_Active from '../assets/NavBarSVGs/my-events-active-icon.svg'
import SVG_My_Events from '../assets/NavBarSVGs/my-events-icon.svg'
import SVG_Settings_Active from '../assets/NavBarSVGs/settings-active-icon.svg'
import SVG_Settings from '../assets/NavBarSVGs/settings-icon.svg'

class NavbarColor extends React.Component {
	moveToAddEvents = () => {
		this.props.history.push('/addEvent/');
	}

	moveToEventsSite = () => {
		window.open(process.env.REACT_APP_PAGE, "_self");
	}

	moveToMyEvents = () => {
		this.props.history.push('/viewEvents/' + localStorage.getItem('id'));
	}

	moveToSettings = () => {
		this.props.history.push('/profile')
	}

	render() {
		let svgStyle = { height: "35px"}

		return (
			<div className="NavbarColor-container">
				<div className="NavbarColor-contents">
					<div onClick={this.moveToAddEvents} className={this.props.active === "Add" ? "active-element" : "menu-element"} >
						<img src={this.props.active === "Add" ? SVG_Create_Event_Active : SVG_Create_Event} alt="Participanti" style={svgStyle} />
						<p> Creaza eveniment </p>
					</div>

					<div onClick={this.moveToMyEvents} className={this.props.active === "Events" ? "active-element" : "menu-element"} >
						<img src={this.props.active === "Events" ? SVG_My_Events_Active : SVG_My_Events} alt="Participanti" style={svgStyle} />
						<p> Evenimentele mele </p>
					</div>

					<div onClick={this.moveToSettings} className={this.props.active === "Profile" ? "active-element" : "menu-element"} >
						<img src={this.props.active === "Profile" ? SVG_Settings_Active : SVG_Settings} alt="Participanti" style={svgStyle} />
						<p> Setari </p>
					</div>

				</div>
			</div>
		)
	}
}

export default NavbarColor;