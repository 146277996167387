import React, {useState, useEffect} from 'react';
import './MemoryBooth.scss'
import CustomInput from "../../components/custom/CustomInput";
import {Icon} from "rsuite";
import {useParams} from 'react-router'
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify'
import QRCode from "react-qr-code";
import axios from "axios";

export default function FinishedScreenPhoto() {

    const { id, uid } = useParams();
    const [email, setEmail] = useState('')

    const [printLoading, setPrintLoading] = useState(false)

    let history = useHistory();

    const backgroundImageUrl = localStorage.getItem('memoryBoothBackgroundImage')
    const memoryBoothType = parseInt(localStorage.getItem('memoryBoothType'))
    const memoryBoothWedding = localStorage.getItem('memoryBoothWedding') ? JSON.parse(localStorage.getItem('memoryBoothWedding')) : false
    const memoryBoothData = localStorage.getItem('memoryBoothData') ? JSON.parse(localStorage.getItem('memoryBoothData')): false
    const [activePhotosButton, setActivePhotosButton] = useState(0)

    const saveEmailAndMoveOn = async () => {

        if(email.length > 0) {
            let payload = {
                uid: uid,
                email: email
            }

            await axios.post(process.env.REACT_APP_DOMAIN + 'memory-booth-files/updateFileEmail', payload)
                .then(response => {
                    toast.success("Vă mulțumim că ați folosit serviciile Ality!")
                    history.push("/memorybooth/intro/" + id)
                })
                .catch(err => {
                    toast.error('A aparut o eroare!')
                })

        } else {
            toast.success("Vă mulțumim că ați folosit serviciile Ality!")
            history.push("/memorybooth/intro/" + id)
        }

    }

    const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))

    const print = async (nr) => {

        if(printLoading) {
            return;
        }


        setPrintLoading(true)

        let dataUri = false

        await toDataURL(localStorage.getItem('memoryBoothPrintPhoto'))
            .then(dataUrl => {
                dataUri = dataUrl
            })

        if(!dataUri) {
            return;
        }

        const image = convertBase64ToBlob(dataUri)

        const data = new FormData();
        data.append('image', image);
        data.append('copies', activePhotosButton)

        let fetchConfig = {
            header: {
                'Content-Type': 'multipart/form-data'
            }
        }

        //process.env.REACT_APP_DOMAIN
        await axios.post('http://localhost:8000/printImage', data, fetchConfig)
            .then(response => {
                setPrintLoading(false)
                setActivePhotosButton(0)
            })
            .catch(err => {
                setPrintLoading(false)
                setActivePhotosButton(0)
                toast.error('A aparut o eroare!')
            })

    }

    const convertBase64ToBlob = (base64Image) => {
        // Split into two parts
        const parts = base64Image.split(';base64,');

        // Hold the content type
        const imageType = parts[0].split(':')[1];

        // Decode Base64 string
        const decodedData = window.atob(parts[1]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        return new Blob([uInt8Array], { type: imageType });
    }


    useEffect(() => {
        checkTimeLimit()
    }, [])

    const checkTimeLimit = async () => {
        await axios.post(process.env.REACT_APP_DOMAIN + 'memorybooth/checkTimeLimit', {id: id})
            .then(response => {
                if(response.data.status === 0) {

                    toast.error('Perioada maxima de 12 ore a fost depasita!')
                    history.push("/adminEventPage/"+id)
                }
            })
    }

    const customInputStyle = {
        margin: "15px 0px 10px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    const customInputStyle2 = {
        margin: "5px 0px 10px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    return(<div className={"mb-intro-container-photo"}>

        <img src={backgroundImageUrl} style={{position:'absolute', width:'100%', height:'100%', zIndex:5, top:0, left:0}}  crossOrigin={"true"}/>

        <div className={"mb-bg-overlay"} style={{position:'absolute', zIndex:10}}>


            <div className={"mb-intro-form-cont"}>

                {
                    memoryBoothType === 0 ?
                        <div style={{marginBottom:'10px', color:'red'}}>Clipul video se proceseaza. Va fi disponibil in cateva minute.</div>
                        :null
                }

                <div style={{marginBottom:'20px'}}>Scaneaza codul QR pentru a vizualiza testimonialul pe telefonul tau.</div>

                <QRCode value={"https://servicii.ality.ro/memorybooth/previewFile/" + uid} />

                {/*https://servicii.ality.ro/memorybooth/previewFile/{{var:id}}*/}

                <div style={{marginTop:'20px'}}>Completați adresa de email dacă doriți să primiți testimonialul pe email.</div>
                <CustomInput value={email}
                             onChange={setEmail}
                             noFocusItem={true}
                             style={customInputStyle2}
                             placeholder={"Email"}
                             icon={<Icon icon="envelope" className={"mb-input-icon"} />} />


                {
                    memoryBoothWedding && memoryBoothWedding.packages && memoryBoothWedding.packages.wedding_participants &&
                    memoryBoothWedding.packages.wedding_participants.package > 0 ?

                        (memoryBoothData && (typeof (memoryBoothData.photo.disabled) === undefined || memoryBoothData.photo.disabled !== true)) ?
                        <><div style={{position:'relative'}}>
                            <div style={{marginTop: '20px'}}>Câte fotografii doriți să printați ?</div>
                            <div className={"mb-type-container"}>
                                <div className={"mb-type-item active " + (activePhotosButton === 1 ? 'red' : '')}
                                     onClick={() => setActivePhotosButton(1)}>
                                    1
                                </div>

                                <div className={"mb-type-item active " + (activePhotosButton === 2 ? 'red' : '')}
                                     onClick={() => setActivePhotosButton(2)}>
                                    2
                                </div>

                                <div className={"mb-type-item active " + (activePhotosButton === 3 ? 'red' : '')}
                                     onClick={() => setActivePhotosButton(3)}>
                                    3
                                </div>

                            </div>
                        </div>
                            <button
                                type="button"
                                className={"mb-gallery-print-button finished-screen"}
                                onClick={() => print()}
                                disabled={printLoading || activePhotosButton === 0}
                            >
                                {
                                    printLoading ?
                                        'Se încarcă ...'
                                        :
                                        <>
                                            <Icon icon="print" className={"icon"}/>
                                            Printează
                                        </>
                                }
                            </button>
                        </>
                            :
                            <div style={{marginTop:'20px'}}>
                                Pentru moment printarea fotografiilor a fost oprită! Vă mulțumim pentru întelegere.
                            </div>
                        : null
                }



                    <button
                        type={"button"}
                        className={"mb-start-button"}
                        onClick={() => {
                            saveEmailAndMoveOn()
                        }}
                    >
                        Finalizare
                    </button>

            </div>



        </div>

    </div>)
}

