import React, {useState, useEffect} from 'react'
import './MemoryBoothConfig.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import {Uploader, Button, SelectPicker, Icon, DatePicker} from 'rsuite'
import Modal from "react-responsive-modal";
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';

import NavbarColor from '../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import isBefore from 'date-fns/isBefore'
import CustomInput from '../../components/custom/CustomInput';
import {getUrlFromImage, textarea_max_length} from '../../components/Helper';
import {useHistory, useParams} from "react-router-dom";
import SettingCard from "../Profile/components/SettingCard";


export default function MemoryBoothConfigMain() {

    let history = useHistory()
    let {id} = useParams()

    const [configured, setConfigured] = useState(false)

    useEffect(() => {
        getEvent()
    }, [])

    const getEvent = async () => {

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + id, auth);

        if(info.data.memorybooth_duration) {
            setConfigured(true)

            localStorage.setItem('memoryBoothTitle', info.data.prenumeMire);
            localStorage.setItem('memoryBoothDate', info.data.DataEvent);
            localStorage.setItem('memoryBoothDuration', info.data.memorybooth_duration);
            localStorage.setItem('memoryBoothBackgroundImage',
                info.data.memoryboothBackground ? process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + info.data.memoryboothBackground.url
                    :
                    '/images/bg-memory-booth-transparent.jpg')
        } else {
            localStorage.removeItem('memoryBoothBackgroundImage')
        }


    }

    const goBack = () => {
        history.goBack()
    }

    const customInputStyle = {
        margin: "15px 0px 10px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    return (
        <div className="mb-config-container">
            <HeaderWhite goBack={goBack}/>

            <div className={"mb-config-inner"}>

                <SettingCard icon="" onClick={() => history.push('/memorybooth/config/' + id)} name="Configurare" />

                <SettingCard icon="" onClick={() => {
                    if(configured) {
                        history.push('/memorybooth/listItems/' + id)
                    } else {
                        toast.error("Va rugam sa setati configurarea!")
                    }
                }} name="Mesaje primite" />

                <div style={{marginTop:'30px'}}></div>

                <SettingCard icon="" onClick={() => {
                    if(configured) {
                        history.push('/memorybooth/intro/' + id)
                    } else {
                        toast.error("Va rugam sa setati configurarea!")
                    }
                }} name="Porneste MemoryBooth" />


            </div>
            <NavbarColor history={history}/>
        </div>
    )

}
