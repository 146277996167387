import React from 'react'
import Modal from 'react-responsive-modal'
import { Divider, Button } from 'rsuite'

class terms extends React.Component {
	render() {
		return (
			<Modal classNames={{
				overlay: 'modal-overlay',
				modal: 'terms-modal',
				closeButton: 'modal-close-button',
			}} focusTrapped={false} open={this.props.showModal} onClose={this.props.toggleModal} center>

				<h5>Termeni si Conditii Ality</h5>
				<p> Ultima modificare: 20 August 2021 </p>
				<Divider />

				{/* <h5> 1. Părţile contractante </h5>
				<p> 1.1. S.C. LYRA NAPKIN S.R.L. reprezintă o societate cu răspundere limitată, ce are sediul în Campulung Muscel,jud. Arges, str. Carpati, nr 60, cam.2, Codul Unic de Înregistrare: 39173082 și Nr. de ordine în registrul comerțului: J03/741/2018,operator al platformei www.ality.ro si a tuturor subdomeniilor aferente cat si a aplicatiei “Ality” in calitate de Prestator. </p>
				<p> și </p>
				<p><br />Clientul, cu datele de identificare din platforma www.ality.ro sau a aplicatiei mobile “Ality” au agreat următoarele: </p>
				<p><br /> Acesta reprezintă contractul propus de S.C. LYRA NAPKIN S.R.L. și trebuie acceptat înainte de comandarea oricărui serviciu de pe site-ul www.ality.ro și a subdomeniilor acestuia sau a aplicatiei mobile “Ality”. Orice comandă confirmată de client, prin bifarea căsuței – "Am citit și am luat la cunoștiință termenii și condițiile de utilizare", reprezintă o acceptare din partea clientului a ofertei S.C. LYRA NAPKIN S.R.L. în condițiile stipulate de art. 9 din Legea comerțului electronic 365/2002. Bifarea căsuței reprezintă o semnătură electronică, în sensul art. 4 pct. 3 din Legea semnăturii electronice nr. 455/2001, având aceeași valoare cu o semnatură olografă. </p>

				<h5><br /><br /> 2. Definitii </h5>
				<p> <br /><br /> www.ality.ro: site-ul de internet, precum și ansamblul serviciilor accesibile pe acest site web și pe subdomeniile acestuia sau a aplicatiei mobile “Ality”.
					<br /><br />
					Serviciu: serviciile puse la dispoziție de S.C. LYRA NAPKIN S.R.L. prin intermediul platformei ality.ro sau a aplicatiei mobile “Ality”, ce include, fără a se limita la, servicii de management și administrare a participanților la evenimente, servicii de management a încasărilor, de notificare a participanților etc.
					<br /><br />
					Client: persoana fizică sau juridică care achiziționează un serviciu de pe site-ul ality.ro sau a aplicatiei mobile “Ality” prin înregistrearea unui cont de utilizator, în scopul creerii unor evenimente si/sau vânzării de pachete de participare la evenimente.
					<br /><br />
					Utilizator Final: persoana fizică sau juridică ce vizitează site-ul ality.ro cu subdomeniile sale sau a aplicatiei mobile “Ality” în scopul folosirii unui serviciu oferit de către Client.
					<br /><br />
					Tranzacție: comandă de achiziționare a unuia sau mai multor servicii realizate de către Client pe site-ul ality.ro și subdomeniile sale sau a aplicatiei mobile “Ality”, realizată de către un Utilizator Final ce dorește să beneficieze de acel/acele servicii.
					<br /><br />
					Pachet de participare: o tranzacție pentru un eveniment realizat de către Client, poate conține unul sau mai multe pachete de participare la eveniment, pachete achiziționate de către Utilizator Final ce îi oferă permisiunea acestuia să participe la evenimentul Clientului.
				</p>

				<h5><br /><br /> 3. Obiectul contractului </h5>
				<p>
					3.1. Prezentul Contract de prestări servicii este aplicabil tuturor clienților ce utilizează www.ality.ro sau a aplicatiei mobile “Ality”. Clientul se obligă să ia la cunoștință de aceste condiții înainte de a-și deschide un cont. Deschiderea contului implică acceptarea de către Client a tuturor acestor condiții de prestări servicii. Acceptarea prezentului contract de către client se face prin bifarea căsuței "Am citit și am luat la cunoștiință termenii și condițiile de utilizare".
					<br /><br />
					3.2. LYRA NAPKIN S.R.L. își rezervă dreptul de a modifica prezentele condiții de prestări servicii în orice moment. Versiunea aplicabilă unui client este aceea în vigoare pe www.ality.ro sau a aplicatiei mobile “Ality” la momentul creerii contului.
					<br /><br />
					3.3. În calitate de operator al site-ului www.ality.ro cu subdomeniile sale sau a aplicatiei mobile “Ality”, S.C. LYRA NAPKIN S.R.L.. prestează pentru Client, fără a se limita la, servicii de management și administrare a participanților la evenimente, servicii de management a încasărilor, de notificare a participanților etc. - de oriunde prin accesul la Internet - prin oferirea serviciilor sub forma unui serviciu cu cost fix per eveniment sau abonament lunar și/sau a unui comision din comenzile realizate de către Participanți în beneficiul clientului.
					<br /><br />
					3.4. Serviciile pot fi accesate de pe o serie de interfețe precum: browser web, aplicații iOS și Android.
					<br /><br />
					3.5. Lista de servicii oferite este disponibilă pe www.ality.ro sau pe aplicatia mobila “Ality”. Prețul per eveniment/lunar/anual și/sau comisionul aferent comenzilor pentru ality.ro sau a aplicatiei mobile “Ality”, se va stabili de catre S.C. LYRA NAPKIN S.R.L. în functie de politica sa comercială și se pot modifica fără nicio notificare.
					<br /><br />
					3.6 Prestatorul nu va acționa ca distribuitor al Clientului și se va limita strict la prestarea serviciilor în condițiile prezentului contract. Dacă sunt necesare, contractele cu Utilizatorii Finali (persoanele fizice sau juridice ce se înregistrează în sistemul ality.ro sau pe aplicatia mobila “Ality”) se încheie exclusiv între Client si aceștia și nu creează nici o obligație suplimentară Prestatorului.
					<br /><br />
					3.7. Prezentul contract de prestări servicii este însoțit și trebuie interpretat împreună cu Termenii și Condițiile portalului www.ality.ro cu subdomeniile sale, la www.ality.ro/politica-de-confidentialitate sau pe aplicatia mobila “Ality” in sectiunea politica-de-confidentialitate
				</p>


				<h5><br /><br /> 4. Durata contractului </h5>
				<p>
					4.1. Prezentul contract se încheie pe o perioada nedeterminată începând de la data creării contului, iar obligațiile părților intră în vigoare de la această dată.
					<br /><br />
					4.2. Imediat după crearea contului și efectuării plății pentru eveniment dorit sau a tipului de abonament lunar ales - unde este cazul, Clientul va avea acces la serviciile incluse în pachetul tipului de serviciu ales sau a abonamentului. Abonamentul este valabil pentru perioade succesive de 30 de zile calendaristice. Daca s-a ales plata per eveniment acesta este valabil de la momentul achizitionarii serviciului si 30 de zile de la terminarea evenimentului.
					<br /><br />
					4.3. În cazul în care Clientul nu notifică renunțarea la abonamentul ales cu minim 30 de zile calendaristice înainte de expirare termenului de valabilitate a abonamentului, în fiecare lună abonamentul se va prelungi automat fără nicio formalitate.
					<br /><br />
					4.4. În cazul în care Clientul dorește modificarea tipului de abonament ales pe parcursul perioadei în care este activ un abonament anterior ales, acest lucru este posibil – schimbarea intrând în vigoare în termen de 2 zile lucrătoare de la efectuarea plății diferenței, în cazul upgrade-ului unui pachet, iar în cazul downgrade-ului unui pachet, intra în vigoare după ce expira perioada pentru care clientul a plătit deja și efectuează o noua plata, pentru un pachet mai mic.
					<br /><br />
					4.5. S.C. LYRA NAPKIN S.R.L. oferă oricărui Client posibilitatea de a testa gratuit aplicația ality.ro si poate trimite pana invitatii catre 5 prieteni pentru a se familiariza si pentru vedea cum functioneaza aplicatia pe o perioada de 14 zile. După expirarea acestei perioade, Clientul poate să opteze pentru a achita taxa oranizarii unui abonament sau un abonament lunar. În cazul în care nu mai dorește continuarea serviciilor, accesul va fi întrerupt definitiv, iar datele sale vor fi șterse în mod automat în termen de 60 de zile. Clientul are posibilitatea de a cere ștergerea imediată a datelor încărcate, iar acest lucru se va realiza în maxim 2 zile lucrătoare.
				</p>

				<h5><br /><br /> 5. Valoarea contractului și efectuarea plății </h5>
				<p>
					5.1. Pentru serviciile prestate, Clientul va plăti catre S.C. LYRA NAPKIN S.R.L. o sumă sub forma de taxa per eveniment sau o taxa lunară, de tipul unui abonament, prevăzută în momentul alegerii tipului de pachet și finalizării comenzii online și/sau un comision din fiecare comandă realizată de către Utilizatori Finali, în funcție de tipul pachetului ales la înregistrare.
					<br /><br />
					5.2. Tarifele pentru deschiderea contului și a pachetului per eveniment sau abonamentul lunar se vor achita pe baza unei facturi emise de către Prestator în avans pentru luna următoare. Plata se va realiza prin card online sau prin ordin de plată în cel mult 5 zile de la data emiterii facturii proforme.
					<br /><br />
					5.3. Comisioanele aferente comenzilor realizate de către Utilizatori Finali în contul Clientului se vor opri după cum urmează: comisionele pentru tranzacțiile procesate prin platforma de plăți TwisPay vor fi oprite de proprietarul acestuia. Din suma rămasă, LYRA NAPKIN S.R.L. va opri comisionul aferent tipului de pachet ales și va restitui în contul Clientului valoarea rămasă. Pentru aceste comisioane, Prestatorul va emite o factura fiscală.
					<br /><br />
					5.4. LYRA NAPKIN S.R.L. va trimite notificări de plată regulate în conformitate cu tipul de pachetului ales, iar pe baza acestora Clientul va efectua plata online sau prin transfer bancar.
				</p>

				<h5><br /><br /> 6. Disponibilitatea serviciilor afișate </h5>
				<p>
					6.1 Oferta LYRA NAPKIN S.R.L. cu privire la servicii și prețul acestora este valabilă atâta vreme cât este afișată pe www.ality.ro sau pe aplicatia mobila “Ality”. LYRA NAPKIN S.R.L. își va îndeplini obligațiile contractuale imediat ce clientul va efectua plata aferentă serviciului selectat.
					<br /><br />
					6.2. Orice modificări ale tarifelor sau cu privire la disponibilitatea serviciilor vor fi comunicate prin email la adresa de contact a clientului din contul de utilizator sau prin intermediul aplicației cu cel puțin 30 de zile înaintea oricărei modificări. Serviciile de mentenanța ale aplicației sunt anunțate prin intermediul aplicației sau prin email.
					<br /><br />
					6.3. Clientul este responsabil cu actualizarea datelor din contul de utilizator imediat ce intervin schimbări. LYRA NAPKIN S.R.L. nu își asumă responsabilitatea în cazul în care intervin prejudicii sau pagube de orice natură din cauza imposibilității de a accesa adresa de e-mail declarată în contul de utilizator.
					<br /><br />
					6.4. Accesul la platforma ality.ro sau pe aplicatia mobila “Ality” este disponibil până la renunțarea la serviciu și/sau ștergerea contului de Client, indiferent de progresul tehnologic sau modificări, cu excepția cazului în care Clientul nu își mai plătește abonamentul. Acest acces cuprinde și actualizări legislative pentru serviciile disponibile, cât și asistența prin email pe întreaga perioadă de desfășurare a prezentului contract.
					<br /><br />
					6.5. LYRA NAPKIN S.R.L. depune toate eforturile pentru a oferi permanent accesul la serviciile disponibile la ality.ro și subdomeniile sale sau pe aplicatia mobila “Ality”, dar nu poate garanta disponibilitatea serviciului decât în măsură de 99.8%. Perioada de disponibilitatea nu include perioadele de mentenanță anunțate în prealabil prin email sau în cadrul serviciului, însă include acele perioade de mentenanță neanunțate. Ca atare, având în vedere dependența serviciilor oferite de accesul la Internet, posibilele limitări tehnice și de sistem ce pot apărea, LYRA NAPKIN S.R.L. își asumă o obligație de diligență în ceea ce privește disponibilitatea serviciilor conform procentului de mai sus. Dacă Clientul nu a putut accesa serviciul din motive imputabile LYRA NAPKIN S.R.L.pentru o perioadă mai mare decât ceva menționată mai sus, acesta poate cere rambursarea proporțională a pachetului plătit pentru perioada în care serviciul nu a fost disponibil.
					<br /><br />
					6.6. În condițiile în care serviciul dorit nu mai este disponibil, din motive imputabile LYRA NAPKIN S.R.L., Clientul își poate exporta toate trazacțiile în format tabelar. În condițiile în care serviciul dorit nu mai este disponibil, din motive imputabile Clientului (neplata, încalcara acestui contract etc), LYRA NAPKIN S.R.L. se va strădui, fără a garanta însă, să ofere Clientului acces pentru a își exporta datele existente în format tabelar. LYRA NAPKIN S.R.L. nu își poate asuma vreo obligație legate de modul cum aceste date pot fi încărcate în alte sisteme informatice.
					<br /><br />
					6.7. LYRA NAPKIN S.R.L. depune toate eforturile rezonabile pentru asigurarea confidențialității datelor primite de la Client și introduse în contul de utilizator sau folosite în aplicațiile LYRA NAPKIN S.R.L.
				</p>

				<h5><br /><br /> 7. Drepturile și obligațiile părților </h5>
				<p>
					7.1. Drepturile și obligațiile Clientului
					<br /><br />
					7.1.1. Clientul este unicul responsabil pentru întreg conținutul existent în contul său sau pentru orice încălcare a legii sau a drepturilor unei terțe persoane.
					<br /><br />
					7.1.2. Clientul se obligă să folosească serviciile LYRA NAPKIN S.R.L. în deplină legalitate și cunoscând dispozițiile legale în vigoare cu privire la activitatea pe care o desfășoară și să aibă dreptul de proprietate sau comercializare și să vândă produsele/serviciile Participanților prin folosirea ality.ro sau a aplicatiei mobile “Ality”potrivit valorii precizate în cadrul serviciilor acestuia și cu respectarea tuturor condițiilor legale.
					<br /><br />
					7.1.3. Se obligă să respecte prevederile Legii Nr. 677/2001 pentru protecția persoanelor cu privire la prelucrarea datelor cu caracter personal și libera circulație a acestor date și prevederile Legii Nr. 506/2004 privind prelucrarea datelor cu caracter personal și protecția vieții private în sectorul comunicațiilor electronice.
					<br /><br />
					7.1.4. Își asumă întreaga răspundere pentru crearea, personalizarea, administrarea, gestionarea, acuratețea și legalitatea documentelor emise cu ajutorul serviciilor LYRA NAPKIN S.R.L.
					<br /><br />
					7.1.5. Să pună la dispoziția Participanților un serviciu de Relații cu Clienții, și de a face cunoscut Participanților acest serviciu, precum și modalitatea de tarifare a acestuia, dacă este cazul. Prestatorul nu are nici un fel de obligație în relația cu Participanților, aceasta revenind in întregime Clientului.
					<br /><br />
					7.1.6. Clientul va procesa datele cu caracter personal conform prevederilor legale în vigoare și are obligația de a respecta legislația în acest domeniu, iar LYRA NAPKIN S.R.L. nu poate fi făcut responsabil pentru niciun fel prejudicii sau încălcări ale drepturilor terțelor persoane.
					<br /><br />
					7.1.7. Are dreptul de a adăuga în sistemul informatic mai mulți utilizatori ai contului cu diverse drepturi de acces – în totalitate sau în parte – în contul său. De asemenea poate delega atribuțiile sale unuia sau mai multor utilizatori.
					<br /><br />
					7.1.8. Să informeze Utilizatorii Finali cu privire la tarifele cerute pentru serviciile ce vor fi prestate și să le pună la dispoziție într-un mod care să asigure înțelegerea neechivocă a acestora, precum și a celorlalte obligații pe care Utilizatorii Final și le asumă în momentul în care utilizează serviciile Beneficiarului.
					<br /><br />
					7.1.9. Să ia toate măsurile în vederea livrării produselor și furnizării serviciilor achiziționate de către Utilizatorii Finali prin intermediul aplicației ality.ro sau a aplicatiei mobile “Ality”, precum și în vederea existenței și comunicării documentelor justificative pentru aceste operațiuni în conformitate cu termenii și condițiile legale și cele afișate în cadrul serviciilor, Prestatorul neavând nicio responsabilitate în acest sens.
					<br /><br />
					7.2.0. Înțelege și este de acord cu Termenii și Condițiile portalului www.ality.ro www.ality.ro/politica-de-confidentialitate sau pe aplicatia mobila “Ality” in sectiunea politica-de-confidentialitate.
					<br /><br />
					7.3 Drepturile și obligațiile LYRA NAPKIN S.R.L.
					<br /><br />
					7.3.1. LYRA NAPKIN S.R.L. asigură:
					- accesul la platforma tehnică necesară pentru crearea și administrarea de evenimente și a participanților la acestea.
					- oferă posibilitatea testării gratuite a serviciilor pe perioada a 14 de zile si trimiterea la un numar maxim de 5 utilizatori invitatii. După expirarea acestei perioade, serviciul devine inactiv, iar datele și contul de utilizator vor fi șterse în mod automat în termen de 60 de zile. Clientul are posibilitatea de a cererea ștergerea imediată a datelor încărcate, iar acest lucru se va realiza în maxim 2 zile lucrătoare de la confirmarea cererii.
					- asistență de luni până vineri, în funție de pachetul ales, între orele 09:00 – 17:00 pentru probleme legate de aplicație.
					- backup lunar al datelor
					- conectarea la sistem printr-un protocol sigur de comunicare (https)
					- sincronizarea datelor din contul de utilizator în așa fel încât să fie disponibile de pe orice tip de dispozitiv, de oriunde, prin accesul la Internet. Serviciile LYRA NAPKIN S.R.L. nu funcționează în varianta offline (fără acces la Internet).
					- păstrarea confidențialității tuturor informațiilor primite de la Beneficiar cât și cele primite de la Utilizatorii Finali ai Beneficiarului ca urmare a derulării prezentului Contract.
					<br /><br />
					7.3.2. www.ality.ro sau a aplicatiei mobili “Ality” ca un găzduitor pentru conținutul adăugat de Client, în condițiile Legii 365/2002 privind comerțul electronic. În același timp, o parte limitată de date sunt stocate și pe calculatoarele Clientului. În schimb, datele nu sunt păstrate în browser sau pe mobil. LYRA NAPKIN S.R.L. nu își asumă responsabilitatea pentru pierderea parolelor de acces la contul de utilizator sau pentru activități ce pot compromite contul Clientului. În cazul în care LYRA NAPKIN S.R.L. primește o notificare cu privire la existența unor servicii cu o aparență nelegală realizată de către clienți, își rezervă dreptul de a suspenda contul de utilizator sau bloca accesul la acesta.
					<br /><br />
					7.3.3. Fiecare Client va avea un sistem de utilizatori cărora li se vor putea configura un anumit set de drepturi conform funcționalităților din site. Clientul poartă întreaga responsabilitate în cazul în care utilizatorii aprobați de către Client încalcă dispozițiile documentelor precizate la punctul 2.6 al prezentului contract sau se angajează în activități ilegale sau imorale.
					<br /><br />
					7.3.4. Backup-ul datelor se realizează lunar.
					<br /><br />
					7.3.6. LYRA NAPKIN S.R.L. nu monitorizează și nu exercită nici un control asupra datelor și documentelor Clientului.
					<br /><br />
					7.3.7. LYRA NAPKIN S.R.L. oferă un drept de utilizare (licență) neexclusivă și nelimitată în timp și spațiu pentru folosirea aplicațiilor de pe site-ul www.ality.ro și a subdomeniilor aferente sau a aplicatiei mobile “Ality”.
					<br /><br />
					7.3.8. În cazul în care uptime-ul este sub 90% vom oferi despăgubiri procentuale din valoarea pachetului ales conform articolului 5.5. Orice altă pretenție, de orice natură este considurată nulă.
					<br /><br />
					7.3.9 LYRA NAPKIN S.R.L. are dreptul de a colecta date anonime despre modul de utilizare a serviciilor sale si de a le face publice în mod agregat.
				</p>

				<h5><br /><br /> 8. Tranzactii bancare </h5>
				<p>
					8.1 Prestatorul este autorizat de către Client să administreze și să încaseze, direct sau cu ajutorul partenerilor săi, în condițiile Contractului și a Anexelor sale, părți integrante din acesta, orice plăți efectuate de către Utilizatori Finali în moneda națională (RON), cu orice titlu, în legătură cu Comenziile efectuate prin ality.ro sau a aplicatiei mobile “Ality” și să efectueze plata acestor sume în contul Beneficiarului, mai puțin comisioanele stabilite conform pachetului ales de Client.
					<br /><br />
					8.2. Să reprezinte Clientul, direct sau cu ajutorul partenerilor săi, în soluționarea reclamațiilor și contestațiilor făcute Băncii sau băncilor emitente de Instrumente de Plată Electronică utilizate în Comenzi de către Utilizatori Finali privind plățile legate de Tranzacții sau de utilizarea frauduloasă a Instrumentelor de Plată Electronică în Comenzi.
					<br /><br />
					8.3. Denunțarea unilaterală de către Utilizatori Finali pentru plata prin card (returnare/refund):
					- în caz de denunțare unilaterală, Clientul este acela care va avea obligația de a verifica îndeplinirea condițiilor denunțării unilaterale a Contractului cu Utilizatorii Finali efectuată de către Prestator, precum și de a verifica îndeplinirea condițiilor de rambursare a prețului plătit către Utilizatorul Final;
					- cheltuielile aferente denunțări unilaterale a contractului de către Utilizatorul Final vor fi în sarcina Clientului, Prestatorul neavând niciun fel de răspundere în legătură cu acestea;
					- în cazul denunțări unilaterale a contractului și în urma verificării condițiilor de temeinicie ale acesteia, Clientul este îndreptățit a notifica Prestatorul cu privire la detaliile pentru efectuarea plății sumelor cuvenite Utilizatorului Final, de către Prestator în numele Clientului;
					- în cazul restituirii contravalorii Tranzacției către Utilizatorul Final, dacă în urma efectuării plăților menționate mai sus patrimoniul Prestatorului suferă micșorări, acesta va fi îndreptățit la recuperarea întregii sume de la Client. Această recuperare va putea fi efectuată în două modalități, la alegerea Prestatorului:
					a) prin reținerea sumelor de la Client din contravaloarea Tranzacțiilor lunare
					b) în termen de 5 zile de la data notificării Prestatorului transmisă Clientului, prin virament direct din conturile acestuia.
					<br /><br />
					8.4. Nerecunoașterea tranzacției de către Utilizatorul Final (posesorul cardului) pentru plata prin card (refuz de plată/chargeback).
					- întregul risc al Refuzurilor de Plată aferent tranzacțiilor revine în sarcina Clientului;
					- orice Refuz de Plată primit de către Prestator va fi notificat Clientul, în ziua imediat următoare datei recepționării lui. În termen de 5 zile de la data primirii notificării Prestatorului, Clientul este obligat să răspundă acestei notificări, precum și să pună la dispoziția acestuia documentația corespunzătoare Tranzacției. În caz contrar, Prestatorul va reține de la Client, respectiv restitui Utilizatorului Final, suma refuzată la plată;
					- ori de câte ori se constată de către autoritățile competente că Refuzul de Plată a fost cauzat de Client, Prestatorul va avea următoarele drepturi, respectiv posibilități:
					a) să restituie imediat Utilizatorului Final contravaloarea cererii de restituire;
					b) să încaseze Comisionul în legătură cu debitarea contului său;
					c) să încaseze contravaloarea oricăror cheltuieli în legătură cu suma restituită;
					- Toate informațiile și documentele legate de Tranzacții vor fi păstrate de către Client o perioadă de minim 12 luni de la data efectuării lor. Ori de câte ori Prestatorul solicită, prin cerere scrisă sau electronică, Clientul va fi obligat a pune la dispoziția acestea, copii ale Tranzacțiilor, precum și ale oricăror informații sau documente în legătură cu acestea. În acest sens, Clientul autorizează pe Prestator să le pună, la rândul său, la dispoziția Băncii, în vederea efectuării operațiunilor de decontare Plăti, Refund sau Chargeback;
					- în toate situațiile de restituire de plăți, achitări de comisioane, încasări de cheltuieli ori alte restituiri de sume cuvenite Prestatorului, acesta va fi îndreptățit la:
					a) reținerea de la Client din contravaloarea Tranzacțiilor lunare, ori
					b) achitare directă de către Client, prin virament bancar, în termen de maxim 5 zile de la data primirii notificării Prestatorului în acest sens;
					- Prestatorul va reține pentru fiecare chargeback pierdut de către Client pe motivul “nelivrării de bunuri/servicii” o taxă de 20 euro + TVA. Taxa este percepută de către VISA și MASTERCARD.
				</p>

				<h5><br /><br /> 10. Răspunderea contractuală </h5>
				<p>
					10.1. Clientul garantează datele introduse și poartă întreaga responsabilitate pentru felul și scopul în care utilizează serviciile puse la dispoziție de LYRA NAPKIN S.R.L. precum și pentru felul în care configurează sistemul de utilizatori ce au acces la contul Clientului și comportamentul acestora.
					<br /><br />
					10.2. Clientul garantează informația pe care o furnizează către www.ality.ro sau catre aplicatia mobile “Ality” este singurul responsabil cu privire la prezentarea informațiilor într-un mod corect și complet, precum și cu păstrarea acurateții informațiilor sau a actualizării lor.
					<br /><br />
					10.3. LYRA NAPKIN S.R.L. nu poate fi ținut răspunzător în cazul în care Clientul se folosește de aplicațiile și serviciile puse la dispoziție în scopuri ilegale sau imorale.
					<br /><br />
					10.4. Clientul este de acord că este singurul responsabil și va despăgubi LYRA NAPKIN S.R.L. pentru orice pagubă, costuri sau limitări de profit care apar ca urmare a oricăror acțiuni frauduloase din partea acestuia. Prin prezentul document Clientul înțelege și acceptă faptul că LYRA NAPKIN S.R.L. va transmite datele acestuia către organele de anchetă, în cazul în care primește o cerere motivată de la o instituție publică autorizată.
					<br /><br />
					10.5. Deși în fiecare moment face eforturi în vederea asigurării calității și corectitudinii mesajelor publicate în site sau pe aplicatia mobila, LYRA NAPKIN S.R.L. nu poate garanta, expres sau implicit, în privința conținutului, a software-ului ori a produselor și serviciilor publicate sub egida sa. LYRA NAPKIN S.R.L. nu își asumă răspunderea, în nicio situație, pentru nicio daună sau eroare pricinuită direct sau indirect, pentru nicio lipsă de profit directă sau indirectă (incluzând, dar fără a se limita la această enumerare: daune pentru pierderea profitului, întreruperea unor afaceri, sau alte daune pecuniare), suferite ca urmare a utilizării sau a întreruperii utilizării ori a lipsei regularității informațiilor și serviciilor furnizate de site.
					<br /><br />
					10.6. LYRA NAPKIN S.R.L. nu garantează acuratețea, corectitudinea sau caracterul actual al informațiilor ori serviciilor furnizate de site.
				</p>

				<h5><br /><br /> 11. Încetarea contractului </h5>
				<p>
					11.1. Prezentul contract încetează în următoarele cazuri:
					<br /><br />
					a) părțile convin de comun acord încetarea contractului;
					b) neîndeplinirea sau îndeplinirea defectuoasă repetată a obligațiilor contractuale de către una din părți;
					c) decizia unilaterală a uneia dintre părți, transmisă în scris celeilalte părți; recepția notificării de încetare trebuie să aibă loc cu minimum 30 zile calendaristice înainte de data stabilită pentru încetarea colaborării;
					d) în caz de dizolvare, lichidare, faliment, retragerea autorizației de funcționare a unuia din contractanți, caz în care părțile vor fi ținute a îndestula datoriile uneia către alta, datorii rezultate până la momentul intervenției cauzei de dispariție.

				</p>

				<h5><br /><br /> 12. Exonerarea de răspundere </h5>
				<p>
					12.1. LYRA NAPKIN S.R.L. nu va răspunde pentru niciun prejudiciu material sau moral, orice pagubă sau costuri ce pot interveni din întârzierea plăților ce sunt în sarcina Clientului, precum și încălcarea de către acesta a oricăror obligații legale dacă nu sunt din vina LYRA NAPKIN S.R.L.
				</p>

				<h5><br /><br /> 13. Confidențialitate </h5>
				<p>
					13.1. Nici una din Părţile contractante nu are dreptul, fără acordul scris prealabil al celeilalte Părţi:
					a) de a face cunoscută orice informație confidențială unei terţe părţi, în afara acelor persoane implicate în derularea şi executarea contractului;
					b) de a utiliza orice informaţie confidențială sau la care are acces în perioada de derulare a contractului, în alt scop decât acela de a-şi executa obligaţiile asumate. Toate informațiile confidențiale trebuie marcate ca atare de partea care le invocă a fi confidențiale.
					<br /><br />
					13.2. Restricţia prevăzută anterior nu va fi aplicabilă dacă:
					a) informaţia era cunoscută părţii contractante înainte ca ea să fi fost primită în executarea prezentului contract;
					b) informaţia era accesibilă publicului;
					c) partea în cauză a fost obligată în conformitate cu dispoziţiile legale să dezvăluie informaţiile în cauză.
				</p>

				<h5><br /><br /> 14. Forța majoră </h5>
				<p>
					14.1. Forța majoră exonerează de răspundere părțile, în cazul neexecutării parțiale sau totale a obligațiilor asumate prin prezentul contract. Prin forța majoră se înțelege un eveniment independent de voința părților, imprevizibil și insurmontabil, apărut după încheierea contractului și care împiedică părțile să execute total sau parțial obligațiile asumate.
					<br /><br />
					14.2. Partea care invocă forța majoră are obligația să o aducă la cunoștința celeilalte părti, în scris, în maximum 5 zile de la apariție.
					<br /><br />
					14.3. Partea care invocă forța majoră are obligația să aducă la cunostință celeilalte părți încetarea cauzei acesteia în maximum 15 zile de la încetare.
					<br /><br />
					14.4. Dacă aceste împrejurări și consecințele lor durează mai mult de 2 luni, fiecare partener poate renunța la executarea contractului pe mai departe. În acest caz, nici una din părți nu are dreptul de a cere despăgubiri de la cealaltă parte, dar ele au îndatorirea de a-și onora toate obligațiile până la această dată.
				</p>


				<h5><br /><br /> 15. Legea guvernantă </h5>
				<p>
					15.1. Drepturile și obligațiile părților impuse de prezentul contract, precum și toate efectele juridice pe care acesta le produce vor fi interpretate și guvernate de legea română în vigoare.
					<br /><br />
					15.2. Orice litigiu care are ca obiect acest acord va fi soluționat pe cale amiabilă, iar în cazul în care soluționarea amiabilă nu este posibilă va fi adus spre soluționare în fața unui arbitru. În condițiile în care părțile nu cad de acord asupra numirii unui arbitru în termen de 15 zile de la notificarea litigiului, competența va reveni instanțelor românești de la sediul LYRA NAPKIN S.R.L.
					<br /><br />
				</p>

				<h5><br /><br /> 16. Dispoziții finale </h5>
				<p>
					16.1. Părțile declară că au negociat toate clauzele prezentului contract și acestea sunt acceptate în mod expres prin semnarea contractului, orice înțelegere anterioară neproducând efecte juridice între acestea.
					<br /><br />
					16.2. Prezentul contract poate fi modificat numai prin acordul părților prin încheierea unui act adițional la prezentul contract.
					<br /><br />
					16.3. Orice notificare către LYRA NAPKIN S.R.L. trebuie trimisă electronic la adresa de email contact@ality.ro
					<br /><br />
					Prezentul contract este disponibil la adresa www.ality.ro/termeni-si-conditii sau pe aplicatia mobila “Ality” in sectiunea termini si conditii
				</p>

				<h5><br /><br /> Anexa la termenii și condițiile - acordul de utilizare ality.ro si a aplicatiei mobile “Ality” </h5>
				<p>
					1. Definiții
					<br /><br />
					1.1. Prin tranzacție se înțelege orice comandă efectuată prin sistemul ality.ro efectuată pentru unul sau mai mulți participanți. Valoarea comisionului, și a valorii minime de plată, se calculează pentru fiecare participant în parte. Astfel, daca valoarea minimă pe participant este mai mică decât procentul specificat la art. II, cu subpunctele sale, se va aplica comisionul minim specificat la art. II, cu subpunctele sale, pentru fiecare participant în parte.
					<br /><br />
					1.2. Participantul este fiecare persoană pentru care se emite un pachet de participare sau invitație printr-o tranzacție efectuată în sistemul ality.ro sau a aplicatiei mobile “Ality”
					<br /><br />
					1.3. Comisionul este definit ca procent din valoarea tranzacției, dar nu mai puțin de o sumă minimă aferentă fiecărui participant, așa cum este definită la art. 2. din prezenta anexa.
					<br /><br />
					2. Tariful contractului
					<br /><br />
					2.1. Pentru tranzacțiile efectuate prin card bancar, comisionul este de 5% din valoarea tranzacției, dar nu mai puțin de 5 lei (sau echivalentul în lei la cursul valutar din ziua tranzacției).
					<br /><br />
					2.2. Pentru tranzacțiile efectuate prin transfer bancar, comisionul este de 5% din valoarea tranzacției, dar nu mai puțin de 5 lei (sau echivalentul în lei la cursul valutar din ziua tranzacției).
					<br /><br />
					2.3. Pentru tranzacțiile efectuate prin depunerea personală la ghișeele sau bancomate, comisionul este de 5% din valoarea tranzacției, dar nu mai puțin de 5 lei (sau echivalentul în lei la cursul valutar din ziua tranzacției)
					<br /><br />
					2.4. Pentru biletele emise pentru evenimente fără taxă de participare sau emise pentru parteneri și invitați, comisionul este de 2 lei (sau echivalentul în lei la cursul valutar din ziua tranzacției).
					<br /><br />
					2.5. Toate comisioanele de mai sus, pentru tranzacțiile prin ality.ro nu includ și costurile de procesare al tranzacțiilor perceput de către Capital Financial Services S.A., prin platformat TwisPay, in valoare de 0.99%+TVA, precum si comisionul pentru transferul bancar către Client.
					<br /><br />
					3. Facturare și plată
					<br /><br />
					3.1. Plata comisioanelor datorate de către Client Prestatorului, ca urmare a tranzacțiilor efectuate de către Utilizatorul Final, se face prin oprirea comisionului datorat din valoarea plătită de către Utilizatorul Final.
					<br /><br />
					3.2. Decontarea sumelor rezultate în urma tranzacțiilor efectuate prin intermediul ality.ro sau a aplicatiei “Ality”se face lunar, în fiecare zi de luni a săptămânii sau la alt interval dacă acesta a fost mofificat în scris de către părți.


				</p> */}

				<h3> 1. Preambul </h3>
				<p>1.1 Site-ul Ality (www.Ality.ro) și aplicația Ality pentru smartphone-uri, denumite în continuare „Site-ul”/”Aplicația” (folosirea oricăreia dintre aceste denumiri se referă și implică atât site-ul cât și aplicația Ality), sunt deținute și administrate de Creative Ality SRL având următoarele date de identificare: Cod Unic de Înregistrare 39173082, înregistrată la Registrul Comerțului cu nr. J03/837/2021 și cu sediul social în Municipiul Câmpulung, Strada CARPAŢI, Nr. 60, Camera nr.2, Judet Argeş.</p>
				<br /><br />
				<p>1.2 Înainte de a utiliza site-ul www.Ality.ro și serviciile pe care le oferim, este necesar să luați cunoștință de prevederile prezenților termeni și condiții și să le respectați.</p>
				<br /><br />
				<p>1.3 Navigarea site-ului echivalează cu citirea, înțelegerea și acceptarea completă a termenilor de mai jos de către orice Utilizator. Dacă nu ești de acord cu orice aspect al prezenților termeni și condiții atunci nu puteți accesa Aplicația.</p>
				<br /><br />
				<p>1.4 Beneficierea de serviciile noastre prin intermediul site-ului se va putea face doar dacă sunteți de acord cu termenii și condițiile descrise mai jos.</p>
				<br /><br />
				<p>1.5 Creative Ality SRL are dreptul de a efectua orice modificări ale site-ului, termenilor și condițiilor, fără a fi necesară vreo notificare prealabilă față de vizitatorii site-ului.</p>
				<br /><br />
				<p>1.6 Creative Ality SRL își rezervă dreptul de a ceda/autoriza accesul/folosirea site-ului de către alți operatori (Terți autorizați) în vederea oferirea serviciilor către Utilizatori de către Terții autorizați. În acest  caz, cedarea/autorizarea accesului/folosirii site-ului de către Terții autorizați, denumirea și datele lor de contact vor fi anunțate prin intermediul site-ului. În funcție de cedarea sau autorizarea parțială / integrală, Terții autorizați vor fi cei care își asumă oferirea serviciilor către Utilizatori, raportul juridic născându-se între Utilizator și respectivul Terț autorizat, caz în care Creative Ality SRL va avea doar rolul de a oferi în beneficiul Terțului autorizat  servicii de mentenanță pentru asigurarea funcționării site-ului.</p>
				<br /><br />
				<p>1.7 Orice solicitare cu privire la înțelesul și aplicarea prezenților termeni și condiții va fi adresată către adresa de e-mail: ...</p>

				<br /><br />
				<h3> 2.Definiții </h3>
				<div> 2.1 Creative Ality SRL  stabilește că termenii enumerați mai jos vor avea următorul sens:
					<ul>
						<li> Furnizor - Creative Ality S.R.L, societate comercială cu sediul în Municipiul Câmpulung, Strada CARPAŢI, Nr. 60, Camera nr.2, Judet Argeş, înregistrată la Registrul Comerțului cu nr. J03/837/2021 și CUI 44034951.</li>
						<li> Terț autorizat - orice persoană autorizată de Furnizor să controleze și să aibă acces la site și să ofere Servicii în favoarea Utilizatorilor.</li>
						<li> Vizitator - persoană care accesează acest site.</li>
						<li> Utilizator - persoană fizică care vizitează acest site și care a acceptat Termenii și Condițiile de mai jos, îndeplinind în acest sens toate cerințele procesului de înregistrare.</li>
						<li> Conținut - toate programele software, fișierele, datele, imaginile, muzica, grafica sau orice alt conținut al www.Ality.ro sau asociat cu site-ul sau brand-ul Ality.</li>
						<li> Serviciu - acordarea Utilizatorului a accesului la funcționalitățile/datele transmise de Creative Ality SRL sau terțul autorizat cu ajutorul acestui site.</li>
						<li>  Username (nume de utilizator) - pseudonim prin care Utilizatorul poate adăuga Conținut pe Site. Username-ul este asociat informațiilor din Site sub denumirea de “Nume Utilizator”.</li>
						<li> Contract - contract de utilizare prin care  Creative Ality S.R.L, sau Terțul autorizat pune la dispoziție utilizatorului dreptul neexclusiv de a folosi conținutul site-ului  www.Ality.ro.</li>
						<li> Cont Utilizator - secțiunea din Site formată dintr-o adresă de e-mail/număr de telefon și o parolă care permite Utilizatorului încărcarea unei fotografii de tip selfie și care oferă acces la fotografii cu imaginea Utilizatorului realizate în cadrul unor evenimente la care a participat Utilizatorul și organizate de Terțul autorizat.</li>
						<li> Site/Aplicație - site-ul internet  www.Ality.ro și/sau aplicația Ality pentru smartphone-uri.</li>
					</ul>
				</div>

				<br /><br />
				<h3> 3. Obligațiile părților </h3>
				<h5>3.1 Obligațiile  Creative Ality S.R.L </h5>
				<p>3.1.1 În limita legislației în vigoare, a prezentului document și relației contractuale cu Terții autorizați,  Creative Ality SRL se obligă să ofere suport și informații legate de serviciile oferite pe acest Site.</p>
				<br /><br />
				<h5>3.2 Obligațiile Utilizatorului</h5>
				<p>3.2.1 Utilizatorul se obligă să respecte prezentele condiții și termeni de mai jos, legislația existentă în România și să acționeze cu bună-credință în relația cu Creative Ality SRL</p>
				<br /><br />
				<p>3.2.2 Utilizatorul are obligația de a aduce la cunoștința furnizorului prin intermediul adresei puse la dispoziție la art. 1.6  orice situație care afectează modul în care site-ul își desfășoară activitatea și orice tentativă de fraudare identificată.</p>
				<br /><br />
				<p>Utilizatorul se obligă să nu modifice, distribuie, transmită, publice, reproducă, creeze produse derivate, transfere sau vândă orice fel de informații sau servicii obținute de pe sau prin intermediul acestui site.</p>
				<br /><br />
				<p>3.2.4 Utilizatorul se obligă să nu folosească și să nu permită vreunui terț să folosească serviciul oferit de Creative Ality SRL pentru a transmite sau a recepționa materiale care contravin dispozițiilor legale în vigoare sau care au un conținut obscen, abuziv, vulgar, defăimător, ofensator, care încalcă orice alt drept al oricărei persoane sau aduce atingere drepturilor de proprietate intelectuală.</p>
				<br /><br />
				<p>3.2.5 Utilizatorul se obligă să nu desfășoare și să nu permită activitatea de accesare a sistemului informatic. Utilizatorii care violează securitatea sistemelor sau a rețelelor vor fi sancționați în conformitate cu legislația în vigoare și cu oprirea temporară sau definitivă a accesului în Aplicație.</p>

				<br /><br />
				<h3>4. Comunicările electronice</h3>
				<p>4.1 Creative Ality SRL își rezervă dreptul de a comunica Utilizatorilor, prin mijloace electronice, orice informații consideră de cuviință cu privire la activitatea generală a Site-ului.</p>
				<br /><br />
				<p>4.2 Creative Ality SRL poate realiza comunicări către Utilizatori, fie în mod direct, fie prin intermediul partenerilor specializați și agreați pentru asigurarea confidențialității și securității informațiilor.</p>
				<br /><br />
				<p>4.3 Utilizatorul va primi comunicări în modalitate electronică de la Creative Ality SRL numai dacă acesta și-a exprimat în mod prealabil consimțământul în legătură cu primirea unor astfel de notificări / comunicări electronice. Din când în când Furnizorul poate notifica Utilizatorilor modificări semnificative ale Aplicației sau termenilor și condițiilor.</p>
				<br /><br />
				<div>4.4 Utilizatorul își poate modifica opțiunea cu privire la primirea de comunicări electronice din partea Creative Ality SRL în orice moment, prin următoarele mijloace:
					<ul>
						<li> Prin contactarea Site-ului la adresa indicată la art. 1.6.</li>
						<li> Prin modificarea setărilor din contul de Utilizator.</li>
						<li> Prin accesarea link-ului de dezabonare afișat în mesajele electronice primite de la Creative Ality SRL</li>
						<li> Renunțarea Utilizatorului de a mai primi orice fel de comunicare electronică din partea Furnizorului nu îl exonerează de respectarea prezenților termeni și condiții și nici nu este aplicabilă cu privire la comunicarea actualizării termenilor și condițiilor.</li>
					</ul>
				</div>
				<br /><br />
				<p>4.6 Utilizatorul înțelege că va fi informat în mod automat cu privire la modificarea termenilor și condițiilor de utilizare a site-ului sau a apariției unor evenimente majore în funcționarea Aplicației, această comunicare electronică făcându-se în baza acceptării inițiale a acestor termeni și condiții.</p>

				<br /><br />
				<h3>5. Drepturi de proprietate intelectuală și industrială </h3>
				<p>5.1 Întregul conținut al Site-ului, incluzând, orice fel de material purtător de drepturi de proprietate intelectuală aparțin sau se află sub licența Creative Ality SRL și sunt protejate de lege. Folosirea, fără acordul scris al Creative Ality SRL, a oricăror elemente enumerate mai sus va fi sancționată conform legilor în vigoare.</p>
				<br /><br />
				<p>5.2 Accesul la informațiile conținute pe Site garantează Utilizatorului acces limitat, în interes personal, pe acest site și nu îi conferă dreptul de a descărca sau de a modifica parțial sau integral site-ul, de a reproduce parțial sau integral site-ul, de a copia, de a vinde sau revinde, sau de a exploata site-ul în orice altă manieră, în scopuri comerciale sau contrare intereselor Creative Ality SRL, fără acordul scris al acesteia.</p>
				<br /><br />
				<p>5.3 Orice reproducere parțială sau totală a materialelor purtătoare de drepturi de autor ce figurează pe Site se va face doar cu acordul prealabil, scris al Creative Ality SRL</p>
				<br /><br />
				<p>5.4 Orice material purtător de drepturi de proprietate intelectuală transmis către Utilizator nu reprezintă transferul vreunui drept cu privire la respectivele materiale.</p>
				<br /><br />
				<p>5.5 Utilizatorul are dreptul neexclusiv de a utiliza serviciile puse la dispoziție de Creative Ality SRL, iar acesta nu poate transmite dreptul de utilizare unui alte persoane.</p>
				<br /><br />
				<p>5.6 Răspunderea pentru încărcarea pe site de către Utilizator a oricăror fotografii de tip selfie/date/documente îi aparține Utilizatorului, acesta despăgubind Furnizorul pentru orice prejudicii cauzate acestuia prin încălcarea oricăror drepturi de autor și/sau de imagine ce deținute de alte persoane asupra materialelor încărcate de către Utilizator.</p>

				<br /><br />
				<h3>6. Politici de utilizare a Site-ului</h3>
				<h5>6.1 Utilizarea Site-ului</h5>
				<p>6.1.1 Accesul pe Site este permis tuturor persoanelor fără niciun fel de restricții, atâta vreme cât acestea au acceptat prezenții termeni și condiții. Pentru motive justificate,  Creative Ality SRL își rezervă dreptul de a restricționa accesul persoanelor pe site în cazul în care se consideră că în baza conduitei sau a activității Utilizatorului, acesta ar putea prejudicia în vreun fel Creative Ality SRL În cazul în care folosirea site-ului este cedată Terțului autorizat, accesul utilizatorilor la acest Site se va face în funcție de decizia Terțului autorizat. Destinatarul Serviciilor disponibile pe site nu sunt persoane cu vârsta sub 16 ani. Administratorul de date cu caracter personal nu prevede colectarea deliberată a datelor cu privire la persoanele sub 16 ani.</p>
				<br /><br />
				<p>6.1.2 Comunicarea între Creative Ality SRL și Utilizator se va putea face prin orice mijloc de comunicare existent la acest moment sau care va fi inventat. Creative Ality SRL are dreptul de a gestiona oricum consideră de cuviință metodele de comunicare cu Utilizatorii și are libertatea de a gestiona oricum consideră necesar informațiile primite.</p>
				<br /><br />
				<p>6.1.3 În cazul în care se detectează trafic neobișnuit provenit din partea unei rețele de internet, Creative Ality SRL își rezervă dreptul de a introduce orice mecanism de validare a existenței unei persoane reale în spatele fiecărui Utilizator al Site-ului.</p>
				<br /><br />
				<p>6.1.4 Utilizatorii Site-ului pot face comentarii și orice alte comunicări, pot să transmită sugestii, întrebări sau informații, dacă limbajul acestora este civilizat, iar conținutul comunicărilor nu este ilegal, obscen, amenințător, defăimător, nu tulbură în niciun fel viața privată a altor persoane, nu încalcă drepturile de proprietate intelectuală, nu instigă la ură rasială, nu instigă la discriminare de orice fel, nu conține viruși, nu servește unor campanii de promovare care nu au legătură cu Site-ul, nu sunt e-mailuri în masă ori orice altă formă de spam.</p>
				<br /><br />
				<p>6.1.5 Dacă în îndeplinirea procesului de înregistrate pe Site, Creative Ality SRL sau Terțul autorizat constată că Utilizatorii furnizează informații incomplete, inexacte, fotografii de tip selfie inadecvate sau dacă aleg un username/ nume de utilizator neadecvat, Creative Ality SRLRL are dreptul de a suspenda sau închide contul Utilizatorului și de a refuza folosirea curentă sau pe viitor a Site-ului.</p>
				<br /><br />
				<p>6.1.6 Site-ul nu își asumă niciun fel de răspundere față de prejudiciile generate ca urmare a situației descrise la art. 6.1.5.</p>
				<br /><br />
				<h5>6.2 Înregistrarea contului de Utilizator</h5>
				<p>6.2.1 Pentru ca Utilizatorul să poată participa la serviciile oferite prin Site-ul www.Ality.ro, acesta trebuie să își creeze un cont pe Site.  Pentru a vă înregistra Contul de utilizator, va trebui să trimiteți anumite informații personale către Ality. Informații detaliate legate de datele cu caracter personal pe care trebuie să ni le împărtășiți și despre modul în care gestionăm datele dvs. personale, le puteți găsi în cadrul Politicii de prelucrare a datelor cu caracter personal disponibilă la adresa web …. . Sunteți responsabil pentru toate activitățile desfășurate prin intermediul Contului dvs. de utilizator și puteți deschide doar un singur cont Ality.</p>
				<br /><br />
				<p>6.2.2 În vederea înregistrării contului, Utilizatorul este obligat să pună la dispoziție date verificabile și conforme cu realitatea. Creative Ality SRL poate refuza cererea de înregistrate a contului în situațiile în care constată că sunt utilizate date neconforme sau sunt încălcate prevederile prezenților termeni și condiții.</p>
				<br /><br />
				<p>6.2.3 Cu ocazia înregistrării, vă alegeți un username sub care participați la serviciile oferite pe Site, username care nu este obligatoriu să conțină numele dumneavoastră complet sau real.</p>
				<br /><br />
				<p>6.2.4 Dacă se constată că username-ul ales este neadecvat, de exemplu s-a folosit un limbaj obscen, jignitor sau se încalcă un drept de proprietate intelectuală, Creative Ality SRL are dreptul de a refuza cererea de înregistrare a contului. </p>
				<br /><br />
				<p>6.2.5 Contul de utilizator nu se poate înregistra fără furnizarea următoarelor elemente: nume, prenume, username, adresă de e-mail sau număr de telefon.</p>
				<br /><br />
				<p>6.2.6 Utilizatorul răspunde de veridicitatea datelor introduse în timpul înregistrării contului și pentru toate activitățile care survin prin accesarea contului și parolei personale. Creative Ality SRL nu poate fi făcută responsabilă pentru erorile survenite în urma neglijenței utilizatorului privind securitatea și confidențialitatea contului și parolei sale.</p>
				<br /><br />
				<p>6.2.7 Dacă Utilizatorul suspectează că securitatea contului său a fost compromisă acesta trebuie să contacteze Creative Ality SRL la adresa de e-mail indicată la art. 1.6 și să facă demersuri pentru a schimba parola contului său sau să solicite blocarea accesului persoanei care îi accesează Site-ul folosind contul său fără drept.</p>
				<br /><br />
				<p>6.2.8 Site-ul nu poate fi accesat de Utilizator decât în condițiile în care acceptă prezenții termeni și condiții și politica de prelucrare a datelor cu caracter personal și eventualele actualizări ale acestora. Fără a exista acceptul Utilizatorului asupra ultimei variante a termenilor și condițiilor site-ului și a acordului de prelucrare a datelor cu caracter personal, accesul nu va fi permis.</p>
				<br /><br />
				<p>6.2.9 Prin înregistrarea ca Utilizator pe site, este exprimat și acordul expres al persoanei de a fi informat prin mijloace de comunicare electronică pentru orice actualizare a termenilor și condițiilor site-ului și ale politicii de prelucrare a datelor cu caracter personal.</p>
				<br /><br />
				<div>6.2.10 Pentru înregistrarea contului de Utilizator în vederea utilizării aplicației de recunoaștere facială prin intermediul căreia sunt identificate și trimise utilizatorilor fotografiile unde au fost etichetați la diferite evenimente, trebuie urmate următoarele etape concrete:
					<ul>
						<li>Pasul 1 - Utilizatorul scanează un cod QR specific evenimentului la care se află;</li>
						<li>Pasul 2 - Utilizatorul își introduce în aplicație fie numărul de telefon, fie adresa de email validă;</li>
						<li>Pasul 3 - Utilizatorul realizează un selfie, urmând indicațiile din cadrul aplicației. Selfie-ul trece printr-un proces automat de verificare a pozei pentru asigurarea că fotografia este în standarde optime pentru recunoaștere facială.</li>
						<li>Utilizatorul primește un email/SMS de confirmare ce indică înregistrarea în sistem.</li>
					</ul>
				</div>
				<br /><br />
				<p>6.2.11 Utilizatorul poate închide utilizarea contului prin simplul fapt al întreruperii serviciilor Ality.</p>

				<br /><br />
				<h3>7. Serviciile oferite și modul de funcționare</h3>
				<p>7.1. Utilizatorul folosește tehnologia Ality pentru a avea acces la fotografiile în care este identificat în funcție de imaginea facială, realizate la diferite evenimente. Pentru a accesa această funcționalitate, este necesar ca între Furnizor sau Terțul autorizat și organizatorul evenimentului / fotograful care realizează respectivele fotografii să existe o relație contractuală care să permită accesul la fotografiile realizate cu Utilizatorul. Pentru a verifica dacă evenimentul la care participă presupune accesarea gratuită a acestei aplicații, Utilizatorul va verifica codul QR specific evenimentului prin intermediul aplicației.</p>
				<br /><br />
				<p>7.2.  Raportul juridic se creează între Utilizator și organizatorul evenimentului sau persoana care a angajat serviciile fotografului care realizează fotografiile.</p>
				<br /><br />
				<p>7.3. Odată ce fotografii pun la dispoziția Furnizorului fotografiile realizate la un anumit eveniment, algoritmul de recunoaștere facială pe baza căruia funcționează aplicație, va determinarea crearea unei pagini separate cu toate fotografiile în care a fost identificat Utilizatorul, iar Utilizatorul va primi un link pe email/SMS, în funcție de datele furnizate la crearea contului, pentru a putea accesa pagina unde sunt stocate fotografiile respective. Fotografiile vor fi stocate la adresa de URL a paginii respective timp de 7 zile calendaristice.</p>
				<br /><br />
				<p>7.4. Furnizorul nu își asumă răspunderea pentru calitatea fotografiilor realizate de fotografi, aplicația având doar rolul de identificare și centralizare a fotografiilor în care sunt este etichetat facial fiecare Utilizator.</p>
				<br /><br />
				<p>7. 5. Detectarea feței și recunoașterea feței sunt procesate prin algoritmul prin care funcționează aplicația sunt stocate și prelucrate cu ajutorul unor servicii de tip Cloud.</p>

				<br /><br />
				<h3>8. Confidențialitate</h3>
				<p>8.1  Creative Ality S.R.L va păstra confidențialitatea informațiilor de orice natură pe care Utilizatorul le furnizează sau pe care le primește de la organizatorul de evenimente sau Terțul autorizat. Dezvăluirea informațiilor se va putea face doar în condițiile menționate în prezentul Contract.</p>
				<br /><br />
				<p>8.2 Nicio declarație publică, promovare, comunicat sau orice alt mod de comunicare către terțe persoane nu va fi făcută de Utilizator cu privire la prezentul document fără consimțământul prealabil scris al Creative Ality SRL</p>
				<br /><br />
				<p>8.3 Prin transmiterea de materiale sau informații prin intermediului Site-ului, Utilizatorul acordă acces asupra acestora în vederea oferirii Serviciilor, precum și partajarea acestor informații/date cu Terțul autorizat.</p>

				<br /><br />
				<h3>9. Răspundere </h3>
				<p>9.1 Prin crearea Contului și / sau vizitarea Site-ului, Utilizatorul acceptă în mod expres și fără echivoc Termenii și condițiile Site-ului în ultima versiune actualizată care este comunicată în cadrul Site-ului, existentă la data creării Contului și / sau utilizării conținutului.</p>
				<br /><br />
				<p>9.2 Ulterior creării Contului utilizarea sau vizitarea Site-ului echivalează cu acceptarea modificărilor intervenite asupra Termenilor și Condițiilor Site-ului și/sau a versiunilor actualizate ale Termenilor și Condițiilor Site-ului.</p>
				<br /><br />
				<p>9.3 Termenii și Condițiile Site-ului pot fi modificate oricând de către  Creative Ality SRL, aceștia fiind opozabili Utilizatorilor de la data afișării pe Site.</p>
				<br /><br />
				<p>9.4 În cazul în care Utilizatorul încalcă dispozițiile prezentului document  Creative Ality SRL are dreptul de a bloca sau limita temporar accesul acestuia la Site fără vreo notificare prealabilă. Dacă încălcările au un caracter continuu, Furnizorul va bloca permanent accesul Utilizatorului.</p>
				<br /><br />
				<p>9.5 Creative Ality SRL nu este responsabil pentru eventualele întreruperi, blocări, indisponibilități, viruși, erori sau omisiuni apărute în timpul accesării aplicației și care nu sunt generate de serviciile oferite de acesta.</p>
				<br /><br />
				<p>9.6. Furnizorul poate schimba, suspenda sau întrerupe serviciile oferite fără vreo notificare prealabilă.</p>
				<br /><br />
				<p>9.7. Furnizorul nu va fi responsabil pentru relația dintre Utilizator și Terțul autorizat/organizatorul de evenimente/fotograf, raportul juridic născându-se direct între Utilizator și Terțul autorizat, Furnizorul fiind responsabil doar în raport de Terțul autorizat conform contractului încheiat între aceștia.</p>
				<br /><br />
				<p>9.8. Utilizatorul își asumă utilizarea contului Ality cu bună-credință și în conformitate cu toate legile și reglementările aplicabile. Nu va utiliza serviciile noastre pentru a se angaja în activități ilegale.</p>
				<br /><br />
				<p>9.9 Utilizatorul garantează Furnizorul că toate informațiile pe care le furnizați în legătură cu serviciile sunt adevărate, complete, legale și exacte.</p>
				<br /><br />
				<p>9.10. Utilizatorul este de acord să despăgubească Creative Ality S.R.L și cesionarii, directorii, angajații și agenții săi în cazul oricăror creanțe, pierderi, daune și cheltuieli (inclusiv onorariile avocaților) care decurg din sau în legătură cu: (i) utilizarea de către Utilizator a Serviciilor oferite; (ii) nerespectarea sau încălcarea oricăruia dintre acești Termeni sau (iii) încălcarea drepturilor oricărei terțe părți.</p>
				<br /><br />
				<p>9.11. Nu puteți atribui, în totalitate sau parțial, acest acord unei persoane sau entități.</p>
				<br /><br />
				<p>9.12. Trebuie să obțineți sfaturi profesionale sau de specialitate înainte de a lua sau de a vă abține de la orice acțiune pe baza conținutului de pe site-ul nostru, dacă nu sunteți sigur de anumite aspecte. Utilizatorul este responsabil pentru actualizarea datelor din contul de utilizator imediat ce apar modificări. Creative Ality S.R.L nu își asumă nicio responsabilitate pentru orice daune de orice fel cauzate de această încălcare a obligației.</p>
				<br /><br />
				<p>9.13. Având în vedere dependența serviciilor oferite de accesul la Internet, eventualele limitări tehnice și de sistem care pot apărea, Creative Ality S.R.L își asumă doar obligații de diligență cu privire la disponibilitatea serviciilor furnizate. Nicio transmisie de date prin Internet nu poate fi garantată sută la sută.</p>
				<br /><br />
				<p>9.14. Dacă la un moment dat site-ul nostru va conține link-uri către alte site-uri și resurse furnizate de terți, aceste link-uri sunt furnizate doar pentru informarea dvs. Astfel de linkuri nu trebuie interpretate ca aprobarea de către noi a acelor site-uri web conectate sau a informațiilor pe care le puteți obține de la acestea. Nu avem control asupra conținutului acestor site-uri sau resurse.</p>

				<br /><br />
				<h3>10. Soluționare conflictelor. Legea aplicabilă</h3>
				<p>10.1 Prezentul contract este guvernat de legea română.</p>
				<br /><br />
				<p>10.2 În caz de divergență sau neînțelegeri între  LYRA NAPKIN S.R.L și Utilizator, se vor aplica Termenii și Condițiile valabile la momentul utilizării Site-ului.</p>
				<br /><br />
				<p>10.3 În cazul unor eventuale conflicte între  LYRA NAPKIN S.R.L și Utilizatorii săi, se va încerca mai întâi rezolvarea acestora pe cale amiabilă. Dacă rezolvarea pe cale amiabilă nu va fi posibilă, conflictul va fi soluționat în instanță, în conformitate cu legile române în vigoare.</p>

				<br /><br />
				<h3>11. Forța majoră</h3>
				<p>11.1 Niciuna din părți nu va fi răspunzătoare pentru neexecutarea obligațiilor contractuale dacă o astfel de neexecutare este datorată unui eveniment de forță majoră. Forța majoră este un eveniment extern, imprevizibil, în afara controlului părților și care nu poate fi evitat.</p>
				<br /><br />
				<p>11.2 Părțile îți vor aduce la cunoștință de îndată cazul de forță majoră și vor lua toate măsurile necesare în vederea limitării consecințelor evenimentului.</p>
				<br /><br />
				<p>11.3 Dacă în termen de 15 zile evenimentul de forță majoră nu încetează, părțile au dreptul de a denunța unilateral contractul fără a pretinde daune-interese.</p>

				<h3>12. Dispoziții finale</h3>
				<p>12.1 Dacă oricare dintre clauzele de mai sus va fi găsită nulă sau nevalidă, acest lucru nu va afecta valabilitatea celorlalte clauze.</p>

				<Button onClick={this.props.toggleModal} appearance="primary" > Am luat la cunostinta </Button>

			</Modal>
		)
	}

}

export default terms;