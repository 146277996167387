import React from 'react'
import './MenuAdmin.scss'
import MenuBlocks from './components/MenuBlocks'

class MenuAdmin extends React.Component {
	state = {
		current_event: this.props.event
	}

	componentDidMount() {
		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
			//document.write('<meta name ="viewport" content="width=device-width, height=' + window.innerHeight+'initial-scale=1.0">');
		}
	}

	render() {
		let date = new Date(this.state.current_event.dataEvent).toString()

		date = date.split(" ")

		return (
			<div className="MenuAdmin-container">
				<div className="Date"> {date[2] + " " + date[1] + " " + date[3]} </div>


				{this.state.current_event.eventType === "MemoryBooth" && <p className="Name"> MemoryBooth: {this.state.current_event.prenumeMire} </p>}

				<div className="MenuAdmin-contents">
					<MenuBlocks event={this.props.event} moveTo={this.props.moveTo} eventID={this.props.id}
						eventType={this.state.current_event.eventType} hasNewResponses={this.props.hasNewResponses} />
				</div>
			</div>
		)
	}
}

export default MenuAdmin;