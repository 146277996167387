import template1Content from "./phototemplates/template1";
import template2Content from "./phototemplates/template2";
import template3Content from "./phototemplates/template3";
import template4Content from "./phototemplates/template4";
import template5Content from "./phototemplates/template5";
import template6Content from "./phototemplates/template6";
import template7Content from "./phototemplates/template7";
import template8Content from "./phototemplates/template8";
import template9Content from "./phototemplates/template9";
import template10Content from "./phototemplates/template10";
import template11Content from "./phototemplates/template11";
import template12Content from "./phototemplates/template12";
import template13Content from "./phototemplates/template13";
import template14Content from "./phototemplates/template14";
import template15Content from "./phototemplates/template15";
import template16Content from "./phototemplates/template16";
import template17Content from "./phototemplates/template17";
import template18Content from "./phototemplates/template18";
import template19Content from "./phototemplates/template19";
import template20Content from "./phototemplates/template20";
import template21Content from "./phototemplates/template21";
import template22Content from "./phototemplates/template22";
import template23Content from "./phototemplates/template23";
import template24Content from "./phototemplates/template24";
import template25Content from "./phototemplates/template25";

/*
Section types
0 - image taken from memorybooth
1 - static image
2 - fixed text area
 */

export const photoTemplates = [
    {
        "id": 1,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 4,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#E04DB0"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/1/background.webp"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Cristina & Andrei"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "23"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#E04DB0"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "8 Septembrie 2018"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#503147"
            },

        ]
    },
    {
        "id": 2,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#eec174"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/2/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Cristina & Andrei"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "23"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#FFFFFF"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "8 Septembrie 2018"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#FFFFFF"
            },

        ]
    },
    {
        "id":3,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#eec174"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/3/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Cristina & Andrei"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "23"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#FFFFFF"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "8 Septembrie 2018"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#FFFFFF"
            },

        ]
    },
    {
        "id": 4,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#eec174"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/2/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Cristina & Andrei"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "23"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#FFFFFF"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "8 Septembrie 2018"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#FFFFFF"
            },

        ]
    },
    {
        "id": 5,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Titlu",
                type: 'text',
                value: "Cristina & Andrei"
            },
            {
                id: 1,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "23"
            },
            {
                id: 2,
                title: "Culoare titlu",
                type: 'color',
                value: "#FFFFFF"
            },
            {
                id: 3,
                title: "Subtitlu",
                type: 'text',
                value: "8 Septembrie 2018"
            },
            {
                id: 4,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 5,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#FFFFFF"
            },

        ]
    },
    {
        "id": 6,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Titlu",
                type: 'text',
                value: "Cristina & Andrei"
            },
            {
                id: 1,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "23"
            },
            {
                id: 2,
                title: "Culoare titlu",
                type: 'color',
                value: "#FFFFFF"
            },
            {
                id: 3,
                title: "Subtitlu",
                type: 'text',
                value: "8 Septembrie 2018"
            },
            {
                id: 4,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 5,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#FFFFFF"
            },
            {
                id: 6,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/6/background.png"
            },

        ]
    },
    {
        "id": 7,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 4,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#B38F42"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/7/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: ""
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "21"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#000000"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: ""
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#000000"
            },

        ]
    },
    {
        "id": 8,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#B38F42"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/8/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: ""
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "21"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#000000"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: ""
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#000000"
            },

        ]
    },
    {
        "id": 9,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#694535"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/9/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#603e3e"
            },

        ]
    },
    {
        "id": 10,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/10/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#603e3e"
            },

        ]
    },
    {
        "id": 11,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#929a49"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/11/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: ""
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "21"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#000000"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: ""
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#000000"
            },

        ]
    },
    {
        "id": 12,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#ac9152"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/12/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#ac9152"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#ac9152"
            },
            {
                id: 8,
                title: "Imagine overlay",
                type: 'image',
                value: ""
            },

        ]
    },
    {
        "id": 13,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#ac9152"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/13/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#ac9152"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#ac9152"
            },

        ]
    },
    {
        "id": 14,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#ac9152"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/13/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#ac9152"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#ac9152"
            },

        ]
    },
    {
        "id": 15,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#ac9152"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/13/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#ac9152"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#ac9152"
            },

        ]
    },
    {
        "id": 16,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/16/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#603e3e"
            },

        ]
    },
    {
        "id": 17,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#694535"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/17/background.png"
            }

        ]
    },
    {
        "id": 18,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#eec174"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/18/background.png"
            }

        ]
    },
    {
        "id": 19,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#B38F42"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/19/background.png"
            },
            {
                id: 2,
                title: "Imagine overlay",
                type: 'image',
                value: "/images/memorybooth/phototemplates/19/overlay.png"
            },
        ]
    },
    {
        "id": 20,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#694535"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/20/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: ""
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: ""
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#603e3e"
            },

        ]
    },
    {
        "id": 21,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/21/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#603e3e"
            },
            {
                id: 8,
                title: "Imagine overlay",
                type: 'image',
                value: ""
            },

        ]
    },
    {
        "id": 22,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/22/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#603e3e"
            },
            {
                id: 8,
                title: "Imagine overlay",
                type: 'image',
                value: ""
            },

        ]
    },
    {
        "id": 23,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/23/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#603e3e"
            },
            {
                id: 8,
                title: "Imagine overlay",
                type: 'image',
                value: ""
            },

        ]
    },
    {
        "id": 24,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/24/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#603e3e"
            },
            {
                id: 8,
                title: "Imagine overlay",
                type: 'image',
                value: ""
            },

        ]
    },
    {
        "id": 25,
        "sections": [
            {
                "id": 1,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 2,
                "type": 0,
                "allowed_types": [0, 1]
            },
            {
                "id": 3,
                "type": 0,
                "allowed_types": [0, 1]
            }
        ],
        "settings" : [
            {
                id: 0,
                title: "Culoare borduri imagini",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 1,
                title: "Imagine de fundal",
                type: 'image',
                value: "/images/memorybooth/phototemplates/25/background.png"
            },
            {
                id: 2,
                title: "Titlu",
                type: 'text',
                value: "Claudia și Cosmin"
            },
            {
                id: 3,
                title: "Dimensiune titlu",
                type: 'integer',
                value: "24"
            },
            {
                id: 4,
                title: "Culoare titlu",
                type: 'color',
                value: "#d11919"
            },
            {
                id: 5,
                title: "Subtitlu",
                type: 'text',
                value: "21 Septembrie 2022"
            },
            {
                id: 6,
                title: "Dimensiune subtitlu",
                type: 'integer',
                value: "14"
            },
            {
                id: 7,
                title: "Culoare subtitlu",
                type: 'color',
                value: "#603e3e"
            },
            {
                id: 8,
                title: "Imagine overlay",
                type: 'image',
                value: ""
            },

        ]
    },

]

export function getTemplateContent(templateDetails) {

    switch (templateDetails.id) {
        case 1: return template1Content(templateDetails)
        case 2: return template2Content(templateDetails)
        case 3: return template3Content(templateDetails)
        case 4: return template4Content(templateDetails)
        case 5: return template5Content(templateDetails)
        case 6: return template6Content(templateDetails)
        case 7: return template7Content(templateDetails)
        case 8: return template8Content(templateDetails)
        case 9: return template9Content(templateDetails)
        case 10: return template10Content(templateDetails)
        case 11: return template11Content(templateDetails)
        case 12: return template12Content(templateDetails)
        case 13: return template13Content(templateDetails)
        case 14: return template14Content(templateDetails)
        case 15: return template15Content(templateDetails)
        case 16: return template16Content(templateDetails)
        case 17: return template17Content(templateDetails)
        case 18: return template18Content(templateDetails)
        case 19: return template19Content(templateDetails)
        case 20: return template20Content(templateDetails)
        case 21: return template21Content(templateDetails)
        case 22: return template22Content(templateDetails)
        case 23: return template23Content(templateDetails)
        case 24: return template24Content(templateDetails)
        case 25: return template25Content(templateDetails)
    }

}

export const photoTemplatesSectionTypes = [
    'Poză din memorybooth',
    'Imagine statică',
    'Câmp text'
]

