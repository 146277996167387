import React from 'react'
import './DataTable.scss'

class DataTable extends React.Component {
	render() {
		let widthStyle = {
			width: '33%',
			overflow: "hidden",
			// whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			borderRadius: "0px",
			textAlign: "center"
		}

		let displayInvites = this.props.dispInv.map((element, index) => {
			return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
				{/* <p style={{ fontWeight: "bold", marginLeft: "10px" }}> {index + 1} </p> */}

				<div  style={{...widthStyle, fontWeight: "bold"}} onClick={() => this.props.goToEditResponse(index)}> {element.prenume} {element.name} </div>

				<p style={widthStyle}> {element.totalPeople} </p>

				<div style={widthStyle}> {element.answer === "yes" ? "Participa" : element.answer === "no" ? "Nu participa" : "Indecis"} </div>
			</div>
		})

		//if on big screen
		if (window.innerWidth > 800) {
			widthStyle = {
				width: Math.round(100 / (this.props.questions.length + 7)) + "%",//+7 from: 5 fixed questions, 1 for total people, 1 for #
				overflow: "hidden",
				whiteSpace: "nowrap",
				textOverflow: "ellipsis",
				borderRadius: "0px",
				textAlign: "center"
			}

			displayInvites = this.props.dispInv.map((element, index) => {
				return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
					<div style={{ fontWeight: "bold", marginLeft: "10px" }}> {index + 1} </div>

					<p style={{ fontWeight: "bold", ...widthStyle, whiteSpace: "pre-line", wordBreak: "break-word" }} onClick={() => this.props.goToEditResponse(index)}> {element.prenume} {element.name} </p>

					<div style={widthStyle}> {element.insotit ? element.nume_insotitor : "-"} </div>

					<p style={widthStyle}> {element.cuCopii ? element.nr_copii : "-"} </p>

					{this.props.questions.map((question, index) => {
						return <div style={widthStyle} key={index}> {element.answer === "yes" ? element["answer" + index] : "-"} </div>
					})}

					<p style={widthStyle}> {element.totalPeople} </p>

					<div style={widthStyle}> {element.answer === "yes" ? "Participa" : element.answer === "no" ? "Nu participa" : "Indecis"} </div>
				</div>
			})

			return (
				<div className="data-table">
					<div className="header">
						<div style={{ fontWeight: "bold", marginLeft: "10px" }}> Nr </div>

						<div style={widthStyle}> Participant </div>

						<div style={widthStyle}> Insotitor </div>

						<div style={widthStyle}> Copii </div>

						{this.props.questions.map((question, index) => {
							return <div style={widthStyle} key={index}> {question.title} </div>
						})}

						<p style={widthStyle}> Total Pers </p>

						<div style={widthStyle} onClick={this.props.toggleModalParticipation}> Stare participare </div>
					</div>

					{/* <div className="Responses-contents"> */}
					{displayInvites}
					{/* </div> */}
				</div>
			)
		}

		return (
			<div className="data-table">
				<div className="header">
					{/* <div> Nr </div> */}

					<p style={widthStyle}> Nume Participant </p>

					<div style={widthStyle}> Total pers </div>

					<div style={widthStyle} onClick={this.props.toggleModalParticipation}> Stare participare </div>
				</div>

				{/* <div className="Responses-contents"> */}
				{displayInvites}
				{/* </div> */}
			</div>
		)
	}
}

export default DataTable;