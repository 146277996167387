import React from 'react'
import SettingCard from '../Profile/components/SettingCard'
import './EventInfoPage.scss'

import HeaderWhite from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'
import { Button } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'

import SVG_Invite from '../../assets/EventInfoSVG/invitatii-icon.svg'
import SVG_Albume from '../../assets/EventInfoSVG/albume-icon.svg'
import SVG_Meniu from '../../assets/EventInfoSVG/meniu-icon.svg'
import SVG_Feed from '../../assets/EventInfoSVG/feed.svg'
import SVG_Face_Recog from '../../assets/EventInfoSVG/face-recog-icon.svg'

import SVG_Informatii from '../../assets/EventPageSVGs/informatii-icon.svg'

let options = {
	event: "Event",
	saveTheDate: "SaveTheDate",
	invite: "Invite"
}

class EventInfoPage extends React.Component {
	state = {
		current_event: {},
		isInviteCreated: false,
		isSaveTheDateCreated: false,

		showOptionsFor: "",

		isMenuAdded: false,

		std_modal: false,
		inv_modal: false,

		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
			.then(response => {
				this.setState({
					current_event: response.data,
					isInviteCreated: response.data.invitatieInfo,
					isSaveTheDateCreated: response.data.saveTheDateInfo,
					isMenuAdded: response.data.menuFile !== null,
					loading: false
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("A aparut o eroare, va rog reincercati")
				this.props.history.push("/viewEvents/" + localStorage.getItem("id"))
			})
	}

	showEventOptions = () => {
		let newOptions = this.state.showOptionsFor === options.event ? "" : options.event

		this.setState({
			showOptionsFor: newOptions
		})
	}

	goToPreviewEvent = () => {
		this.props.history.push('/eventPage/' + this.props.match.params.id)
	}

	goToEditEvent = () => {
		this.props.history.push('/editEvent/' + this.props.match.params.id)
	}

	goToSaveTheDate = () => {
		if (this.state.isSaveTheDateCreated) {
			let newOptions = this.state.showOptionsFor === options.saveTheDate ? "" : options.saveTheDate

			this.setState({
				showOptionsFor: newOptions
			})
		} else {
			this.props.history.push('/addSaveTheDate/' + this.props.match.params.id)
		}
	}

	goToEditSaveTheDate = () => {
		this.props.history.push('/editSaveTheDate/' + this.props.match.params.id)
	}

	goToPreviewSaveTheDate = () => {
		this.props.history.push('/previewSaveTheDate/' + this.props.match.params.id)
	}

	toggleSTDModal = () => {
		if (window.flutter_inappwebview) {
			let msg = this.state.current_event.prenumeMireasa + " & " + this.state.current_event.prenumeMire + ", va roaga sa salvati aceasta data!"
			window.flutter_inappwebview.callHandler('shareEvent', `${msg} ${process.env.REACT_APP_PAGE}saveTheDate/${this.state.current_event.id_eveniment}`)
			return
		}

		let prevState = this.state.std_modal

		this.setState({
			std_modal: !prevState
		})
	}

	goToInvite = () => {
		if (this.state.isInviteCreated) {
			let newOptions = this.state.showOptionsFor === options.invite ? "" : options.invite

			this.setState({
				showOptionsFor: newOptions
			})
		} else {
			this.props.history.push('/addInvite/' + this.props.match.params.id)
		}
	}

	goToEditInvite = () => {
		this.props.history.push('/editInvite/' + this.props.match.params.id)
	}

	goToPreviewInvite = () => {
		this.props.history.push('/invitationPreview/' + this.props.match.params.id)
	}

	toggleInvModal = () => {
		if (window.flutter_inappwebview) {
			let msg = this.state.current_event.prenumeMireasa + " & " + this.state.current_event.prenumeMire + ", va invita la nunta lor!"
			if (this.state.current_event === "Botez") {
				msg = `Va invit la botezul lui ${this.state.current_event.prenumeMire}`
			}
			window.flutter_inappwebview.callHandler('shareEvent', `${msg} ${process.env.REACT_APP_PAGE}invitation/${this.state.current_event.id_eveniment}`)
			return
		}

		let prevState = this.state.inv_modal

		this.setState({
			inv_modal: !prevState
		})
	}

	goToAlbume = () => {
		this.props.history.push('/viewAlbums/' + this.props.match.params.id)
	}

	goToMenu = () => {
		if (this.state.isMenuAdded) {
			this.props.history.push('/editMenu/' + this.props.match.params.id)
		} else {
			this.props.history.push('/addMenu/' + this.props.match.params.id)
		}
	}

	goToFeed = () => {
		this.props.history.push('/adminFeed/' + this.props.match.params.id)
	}

	goToFaceRecog = () => {
		this.props.history.push('/faceRecog/' + this.props.match.params.id)
	}

	goBack = () => {
		this.props.history.push("/adminEventPage/" + this.props.match.params.id)
	}

	render() {
		if (this.state.loading) {
			return <div>Loading...</div>
		}

		return (
			<div className="EventInfoPage-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<div className="Title">Informatii Eveniment</div>



				<div className="EventInfoPage-contents">
					<SettingCard svg={SVG_Informatii} onClick={this.showEventOptions} name="Eveniment" dropDown={this.state.showOptionsFor === options.event} />

					{this.state.showOptionsFor === options.event && <div>
						<SettingCard icon="" onClick={this.goToEditEvent} name="Editeaza" isSmall={true} />

						<SettingCard icon="" onClick={this.goToPreviewEvent} name="Previzualizeaza" isSmall={true} />
					</div>}

					{this.state.current_event.eventType === "Nunta" && <SettingCard svg={SVG_Invite} onClick={this.goToSaveTheDate} name="Save the date" dropDown={this.state.showOptionsFor === options.saveTheDate} />}

					{this.state.showOptionsFor === options.saveTheDate && <div>
						<SettingCard icon="" onClick={this.goToEditSaveTheDate} name="Editeaza" isSmall={true} />

						<SettingCard icon="" onClick={this.goToPreviewSaveTheDate} name="Previzualizeaza" isSmall={true} />

						<SettingCard icon="" onClick={this.toggleSTDModal} name="Trimite" isSmall={true} />
					</div>}

					<SettingCard svg={SVG_Invite} onClick={this.goToInvite} name="Invitatie" dropDown={this.state.showOptionsFor === options.invite} />

					{this.state.showOptionsFor === options.invite && <div>
						<SettingCard icon="" onClick={this.goToEditInvite} name="Editeaza" isSmall={true} />

						<SettingCard icon="" onClick={this.goToPreviewInvite} name="Previzualizeaza" isSmall={true} />

						<SettingCard icon="" onClick={this.toggleInvModal} name="Trimite" isSmall={true} />
					</div>}

					<SettingCard svg={SVG_Albume} onClick={this.goToAlbume} name="Albume" />

					<SettingCard svg={SVG_Meniu} onClick={this.goToMenu} name="Meniu" />

					<SettingCard svg={SVG_Feed} onClick={this.goToFeed} name="Feed" />

					<SettingCard svg={SVG_Face_Recog} onClick={this.goToFaceRecog} name="Face recognition" />

					<div>
						<Button style={{ margin: "20px 20px 20px 0px", color: "#1E1C59", border: "1px solid #1E1C59", borderRadius: "80px", width: "200px" }} onClick={this.goBack}>
							Inapoi
						</Button>
					</div>
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default EventInfoPage;
