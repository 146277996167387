import React from 'react'
import { InputGroup, Input, Icon, SelectPicker } from 'rsuite'
import './Responses.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import CustomInput from '../../components/custom/CustomInput'
import { toast } from 'react-toastify'
import { BackAndConfirmButtons, BackButton, DataCards, ShowPackageData } from '../../components/Helper'
import { MdKeyboardArrowDown } from 'react-icons/md';

let participa = "yes";
let nuParticipa = "no";
let nuStieDacaParticipa = "don't know";
let all = "toti";

const ansOptions = [{ label: 'Participa', value: 'yes' }, { label: 'Nu știe daca participa', value: "don't know" },
{ label: 'Nu Participa', value: 'no' }]

class SaveTheDateResponses extends React.Component {
	state = {
		eventId: "",
		screen: "Tabel",

		invites: [],
		dispInv: [],

		totalAnswers: 0,
		yesAnswers: 0,
		dontKnowAnswers: 0,
		noAnswers: 0,
		modalRaport: false,

		currentSearch: "",
		participationChoice: all,

		packageData: {},

		raspuns: "",
		nume: "",
		prenume: "",
		modifIndex: -1,

		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let event_data = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)

		this.setState({
			eventId: event_data.data.id
		})

		//get answers
		await axios.get(process.env.REACT_APP_DOMAIN + 'response-save-the-dates/list/byEvent/' + this.props.match.params.id, auth)
			.then(async response => {
				let yesAnswers = 0
				let dontKnowAnswers = 0
				let noAnswers = 0

				let invites = response.data.responses.map((invitee, index) => {
					if (invitee.raspuns === "yes") {
						yesAnswers++
					} else if (invitee.raspuns === "no") {
						noAnswers++
					} else {
						dontKnowAnswers++
					}

					invitee.index = index

					return invitee
				})

				this.setState({
					invites: invites,
					dispInv: invites,
					packageData: response.data,
					totalAnswers: invites.length,
					yesAnswers: yesAnswers,
					dontKnowAnswers: dontKnowAnswers,
					noAnswers: noAnswers,
					loading: false
				})

				let totalResponses = response.data.totalResponses
				let receivedResponses = response.data.responses.length
				let seenResponses = event_data.data.count_seen_save_the_date_responses

				let updateSeenRsponses = false
				let newSeenResponsesCount = seenResponses

				if (totalResponses > seenResponses) {
					if (totalResponses === receivedResponses) {
						//limit not passed
						updateSeenRsponses = true
						newSeenResponsesCount = totalResponses
					} else {
						//limit passed, check 2
						if (receivedResponses > seenResponses) {
							updateSeenRsponses = true
							newSeenResponsesCount = receivedResponses
						}
					}
				}

				if (updateSeenRsponses) {
					let obj = {
						count_seen_save_the_date_responses: newSeenResponsesCount
					}

					let auth = {
						headers: {
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						}
					}

					axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + event_data.data.id, obj, auth)
				}
			})
	}

	filterInvites = (filterStep, filterChoice) => {
		let allParticipants = [...this.state.invites];

		//filter by participation
		let participationFilterChoice = this.state.participationChoice
		if (filterStep === 1) {
			participationFilterChoice = filterChoice
		}
		let filteredByParticipation = allParticipants.filter(element => participationFilterChoice === all || element.raspuns === participationFilterChoice)

		//filter by name
		let nameFilterChoice = this.state.currentSearch.toLocaleLowerCase()
		if (filterStep === 2) {
			nameFilterChoice = filterChoice.toLocaleLowerCase()
		}
		let filteredByName = filteredByParticipation.filter(element => {
			return element.nume.toLocaleLowerCase().indexOf(nameFilterChoice) !== -1 ||
				element.prenume.toLocaleLowerCase().indexOf(nameFilterChoice) !== -1
		})

		this.setState({
			dispInv: filteredByName
		})
	}

	changeParticipationChoice = (choice) => {
		this.setState({
			participationChoice: choice,
			modalRaport: false
		})

		this.filterInvites(1, choice)
	}

	onChange = (value) => {
		this.setState({
			currentSearch: value
		})

		this.filterInvites(2, value)
	}

	toggleRaport = () => {
		let current = this.state.modalRaport;

		this.setState({
			modalRaport: !current
		})
	}

	goToAddResponse = () => {
		this.setState({
			screen: "Add",
			modalRaport: true
		})
	}

	goToEditResponse = (index) => {
		this.setState({
			screen: "Edit",
			modifIndex: index,
			raspuns: this.state.invites[index].raspuns,
			prenume: this.state.invites[index].prenume,
			nume: this.state.invites[index].nume,
			modalRaport: true
		})
	}

	showTable = () => {
		this.setState({
			screen: "Table",
			nume: "",
			prenume: "",
			raspuns: "",
			modifIndex: -1,
			modalRaport: false
		})
	}

	createInvite = () => {
		if (this.state.raspuns === "") {
			toast.error("Va rog alegeti raspunsul participantului")
			return
		}

		if (this.state.prenume === "") {
			toast.error("Va rog introduceti prenumele invitatului")
			return
		}

		var obj = {
			raspuns: this.state.raspuns,
			nume: this.state.nume,
			prenume: this.state.prenume,
			event: this.state.eventId
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'response-save-the-dates', obj, auth)
			.then(response => {
				toast.success("Raspuns trimis")

				obj.index = this.state.invites.length

				let newInvites = [...this.state.invites, obj]

				this.setState({
					invites: newInvites,
					dispInv: newInvites
				})

				this.showTable()
			})
	}

	saveChange = () => {
		if (this.state.raspuns === "") {
			toast.error("Va rog alegeti raspunsul participantului")
			return
		}

		if (this.state.prenume === "") {
			toast.error("Va rog introduceti prenumele invitatului")
			return
		}

		let index = this.state.modifIndex

		var obj = {
			raspuns: this.state.raspuns,
			nume: this.state.nume,
			prenume: this.state.prenume
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.put(process.env.REACT_APP_DOMAIN + 'response-save-the-dates/' + this.state.invites[index].id, obj, auth)
			.then(response => {
				toast.success("Raspuns modificat")

				let elem = this.state.invites[index]
				elem.nume = obj.nume
				elem.prenume = obj.prenume
				elem.raspuns = obj.raspuns

				let newInvites = [...this.state.invites]
				newInvites.splice(index, 1, elem)

				this.setState({
					invites: newInvites,
					dispInv: newInvites
				})

				this.showTable()
			})
	}

	toggleModalParticipation = () => {
		this.setState({
			screen: "Participation",
			modalRaport: true
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading === true) {
			return <p> Loading.. </p>
		}
		let rowStyle = {
			overflow: "hidden",
			textOverflow: "ellipsis",
			borderRadius: "0px",
			textAlign: "center"
		}

		return (
			<div className="Responses-container">
				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal-response',
					closeButton: 'modal-close-button',
				}} open={this.state.modalRaport} onClose={this.toggleRaport}>
					{this.state.screen === "Participation" && <div className="Participation-Filter">
						<div>Sorteaza dupa raspunsuri: </div>

						<div className="Participation-Options">
							<div className={this.state.participationChoice === all ? "Active-Option" : "Option"} onClick={() => this.changeParticipationChoice(all)}>Toate</div>
							<div className={this.state.participationChoice === participa ? "Active-Option" : "Option"} onClick={() => this.changeParticipationChoice(participa)}>Participa</div>
							<div className={this.state.participationChoice === nuParticipa ? "Active-Option" : "Option"} onClick={() => this.changeParticipationChoice(nuParticipa)}>Nu participa</div>
							<div className={this.state.participationChoice === nuStieDacaParticipa ? "Active-Option" : "Option"} onClick={() => this.changeParticipationChoice(nuStieDacaParticipa)}>Indecis</div>
						</div>
					</div>}

					{this.state.screen === "Add" && <div style={{ display: "flex", flexDirection: "column", margin: "10px auto" }}>
						<SelectPicker
							searchable={false}
							style={{ marginBottom: 20, marginTop: "30px" }}
							data={ansOptions}
							appearance="default"
							onChange={(value) => this.setState({ raspuns: value })}
							value={this.state.raspuns}
							placeholder="Raspuns"
						/>

						<CustomInput value={this.state.prenume} onChange={value => this.setState({ prenume: value })} placeholder={"Prenume, Nume invitat"} />

						<BackAndConfirmButtons textBack={"Inchide"} textConfirm={"Adauga"} functionBack={this.showTable} functionConfirm={this.createInvite} />
					</div>}

					{this.state.screen === "Edit" && <div style={{ display: "flex", flexDirection: "column", margin: "10px auto" }}>
						<SelectPicker
							searchable={false}
							style={{ marginBottom: 20, marginTop: "30px" }}
							data={ansOptions}
							appearance="default"
							onChange={(value) => this.setState({ raspuns: value })}
							value={this.state.raspuns}
							placeholder="Raspuns"
						/>

						<CustomInput value={this.state.prenume} onChange={value => this.setState({ prenume: value })} placeholder={"Prenume, Nume invitat"} />

						<BackAndConfirmButtons textBack={"Inchide"} textConfirm={"Salveaza"} functionBack={this.showTable} functionConfirm={this.saveChange} />
					</div>}
				</Modal>

				<HeaderWhite goBack={this.goBack} />

				<div className='Responses-content'>
					<div className='title'>Raspunsuri Save the Date</div>

					<DataCards cards={[
						{
							data: `Total invitati: ${this.state.totalAnswers}`,
							color: "#05AFF2"
						},
						{
							data: `Participa: ${this.state.yesAnswers}`,
							color: "#19B899"
						},
						{
							data: `Nu participa: ${this.state.noAnswers}`,
							color: "#F26157"
						},
						{
							data: `Indecisi: ${this.state.dontKnowAnswers}`,
							color: "#E3B505"
						}
					]} />

					<div className='add-response-button'>
						<Icon icon="plus-circle" size="lg" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={this.goToAddResponse} />
						<div onClick={this.goToAddResponse}>Adauga raspuns</div>
					</div>

					<InputGroup style={{ margin: "10px auto" }}>
						<InputGroup.Addon>
							<Icon icon="search" />
						</InputGroup.Addon>

						<Input onChange={this.onChange} placeholder="Numele invitatului" />
					</InputGroup>

					<div className="data-table">
						<div className="header">
							<div style={{ ...rowStyle, marginLeft: "5px" }}> Nr </div>

							<div style={{ ...rowStyle, width: '45%' }}> Nume Participant </div>

							<div style={{ ...rowStyle, width: "45%" }} className='clickable-elem' onClick={this.toggleModalParticipation}>
								Stare participare <MdKeyboardArrowDown />
							</div>
						</div>

						{this.state.dispInv.map((elem, index) => {
							return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
								<div style={{ ...rowStyle, marginLeft: "5px" }}>
									{index + 1}
								</div>

								<div style={{ ...rowStyle, width: "45%" }} onClick={() => this.goToEditResponse(elem.index)}>
									{elem.prenume} {elem.nume}
								</div>

								<div style={{ ...rowStyle, width: "45%", textAlign: "right" }}>
									{elem.raspuns === "yes" ? "Participa" : elem.raspuns === "no" ? "Nu participa" : "Indecis"}
								</div>
							</div>
						})}
					</div>

					<BackButton text={"Inapoi"} function={this.goBack} />
				</div>

				<ShowPackageData packageData={this.state.packageData} history={this.props.history} eventId={this.props.match.params.id} />

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default SaveTheDateResponses;