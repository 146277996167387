import React from 'react'
import { toast } from 'react-toastify';
import { Icon } from 'rsuite';
import CustomInput from '../../components/custom/CustomInput';
import HeaderWhiteBack from '../../components/HeaderWhiteBack';
import { BackAndConfirmButtons, LoadingScreen } from '../../components/Helper';
import NavbarColor from '../../components/NavbarColor';
import axios from 'axios'
import './ContactUs.scss'

class ContactUs extends React.Component {
	state = {
		username: "",
		email: "",

		nume: "",
		phone: "",
		title: "",
		text: "",

		is_sending: false,
		loading: true
	}

	componentDidMount = () => {
		if (localStorage.getItem('jwt') === null) {
			toast.error("Trebuie sa va logati pentru a folosi site-ul")
			this.props.history.push('/login');
			return
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
			.then(response => {
				this.setState({
					username: response.data.username.split("|")[0],
					email: response.data.email,
					loading: false
				})
			})
			.catch(err => {
				localStorage.clear()
				toast.error("Informatiile de autentificare au expirat, va rog reautentificativa")
				this.props.history.push('/login');
			})
	}

	setter = (key, value) => {
		this.setState({ [key]: value })
	}

	changePhoneNumber = (value) => {
		if (value !== "") {
			if (!Number(value)) {
				return
			}
		}

		this.setter("phone", value)
	}

	changeText = (value) => {
		this.setState({
			text: value
		})
	}

	sendMessage = async () => {
		//check validity
		if (this.state.title === "") {
			toast.error("Va rog adaugati titlu")
			return
		}

		if (this.state.text === "") {
			toast.error("Va rog adaugati continut")
			return
		}

		if (this.state.is_sending) {
			return
		}

		this.setState({
			is_sending: true
		})

		//send data
		var obj = {
			nume: this.state.username,
			email: this.state.email,
			facultate: "Mesaj nunta",
			prenume: this.state.nume,
			telefon: this.state.phone,
			titlu: this.state.title,
			mesaj: this.state.text,
			id_eveniment: "sXTo"
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		//show success/fail message
		await axios.post(process.env.REACT_APP_DOMAIN + "contacts", obj, auth)
			.then((response) => {
				toast.success("Mesaj trimis")
				this.setState({
					nume: "",
					phone: "",
					title: "",
					text: ""
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("Eroare")
			})

		//empty fields
		this.setState({
			is_sending: false
		})
	}

	getInfoRow = (icon, name, text) => {
		return <div className="Row">
			<Icon icon={icon} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />

			<div className="Data">
				<div>{name}</div>

				<p style={{ color: "#121213", fontSize: "16px" }}>{text}</p>
			</div>
		</div>
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading) {
			return <LoadingScreen />
		}

		return (
			<div className="ContactUs-container">
				<HeaderWhiteBack goBack={this.goBack} />

				<div className="ContactUs-contents">
					<div className="Section">
						{this.getInfoRow("avatar", "Username", this.state.username)}

						{this.getInfoRow("at", "Email", this.state.email)}
					</div>

					<CustomInput style={{ margin: "10px 0px" }} value={this.state.nume} onChange={value => this.setter("nume", value)} placeholder={"Nume"}
						icon={<Icon icon={"avatar"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />

					<CustomInput style={{ margin: "10px 0px" }} value={this.state.phone} onChange={this.changePhoneNumber} placeholder={"Telefon (Optional)"}
						icon={<Icon icon={"phone"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />

					<CustomInput style={{ margin: "10px 0px" }} value={this.state.title} onChange={value => this.setter("title", value)} placeholder={'Titlu'}
						icon={<Icon icon={"pencil"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />

					<CustomInput style={{ margin: "10px 0px" }} value={this.state.text} onChange={this.changeText} placeholder={`Scrie un mesaj!`}
						componentClass={"textarea"} icon={<Icon icon={"pencil"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />

					<BackAndConfirmButtons loading={this.state.is_sending} textBack={"Inapoi"} textConfirm={"Trimite"} functionBack={this.goBack} functionConfirm={this.sendMessage} />
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default ContactUs;