import React, {useState} from 'react';
import axios from "axios";
import {toast} from "react-toastify";

export default function ImageUploadComponent({saveImageUrl, weddingId}) {


    const uploadFile = async (file) => {

        const formData = new FormData();

        // Update the formData object
        formData.append("files.memorybooth_images", file);
        formData.append("data", JSON.stringify({}))

        let auth = {
            headers: {
                "Content-Type": `multipart/form-data;`,
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/update/' + weddingId, formData, auth)
            .then(response => {
                saveImageUrl(process.env.REACT_APP_DOMAIN.slice(0,-1)+ '' +response.data.memorybooth_images[response.data.memorybooth_images.length-1]['url'])
                toast.success("Imaginea a fost incarcata!")
            })
            .catch(err => {
                toast.error("Eroare")
            })

    }

    return(
            <input type={"file"} value={''} onChange={(e) => uploadFile(e.target.files[0])} />
    )
}

