import React from 'react'
import SectionRender from '../sectionRender'
import './template15.scss'

export default function template15Content(templateDetails = false) {

    return (
        <div className={"main-content"} >

            <div className={"background"}>
                <img src={templateDetails.settings[1].value} crossOrigin={"true"}/>
            </div>

            <div className={"photo-template-15 " + (templateDetails.live ? 'live' : '')}>


                <div className={"row-1"}>
                    <div className={"col-left"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[0]}
                            preview={!templateDetails.live}
                        />
                    </div>
                    <div className={"col-right"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[1]}
                            preview={!templateDetails.live}
                        />
                    </div>
                </div>


            </div>

        </div>
    )
}