import React, {useState, useRef, useEffect} from 'react';
import './MemoryBooth.scss'
import useMediaRecorder from '@wmik/use-media-recorder';
import {Icon} from "rsuite";
import Player from "./components/Recording/Player";
import LiveStreamPlayer from "./components/Recording/LiveStreamPlayer";
import Timer from "./components/Recording/Timer";
import {Link, useHistory} from "react-router-dom";
import {useParams} from 'react-router'
import ProgressBar from "./components/Recording/ProgressBar";
import axios from "axios";
import {createFFmpeg, fetchFile} from "@ffmpeg/ffmpeg";
import {toast} from 'react-toastify'
import Loader from "react-spinners/DotLoader";
import Webcam from "react-webcam";

const ffmpeg = createFFmpeg({log: false});

const videoWidth = 690
const videoHeight = 518
const startTimeout = 5

const videoConstraints = {
    width: 1280,
    height: 720
};

export default function RecordingScreen() {

    let history = useHistory();

    const id = localStorage.getItem('id_eveniment')
    const name = localStorage.getItem('name')
    const email = localStorage.getItem('email')
    const wedding = localStorage.getItem('wedding')
    const testMode = localStorage.getItem('memoryBoothTest')

    const backgroundImageUrl = localStorage.getItem('memoryBoothBackgroundImage')
    const makeAGif = parseInt(localStorage.getItem('memoryBoothType')) === 1 ? 1 : 0
    const type = parseInt(localStorage.getItem('memoryBoothType'))
    let maxDuration = (makeAGif == 1 ? 5 : parseInt(localStorage.getItem('memoryBoothDuration')))


    const [preparing, setPreparing] = useState(true) // it means that we are preparing last steps to recording (starting timers)
    const [loading, setLoading] = useState(false)

    const [started, setStarted] = useState(false)


    const [ready, setReady] = useState(false);
    const [video, setVideo] = useState();
    const [gif, setGif] = useState();
    const [gifFile, setGifFile] = useState();

    let {
        error,
        status,
        mediaBlob,
        liveStream,
        stopRecording,
        getMediaStream,
        startRecording,
        clearMediaStream,
        clearMediaBlob,

    } = useMediaRecorder({
        recordScreen: false,
        blobOptions: {type: 'video/webm'},
        mediaStreamConstraints: {
            audio: true,
            video: true
        }
    });

    const loadFFmpeg = async () => {

        if (ffmpeg.isLoaded()) {
            setReady(true);
            return;
        }

        try {
            await ffmpeg.load();
        } catch (e) {

        }
        setReady(true);
    };

    useEffect(() => {
        start()
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        loadFFmpeg()

        checkTimeLimit()

        return async () => {
            stopRecording()
            clearMediaBlob()
            clearMediaStream()
        }

    }, [])

    const checkTimeLimit = async () => {
        if(testMode !== '1') {
            await axios.post(process.env.REACT_APP_DOMAIN + 'memorybooth/checkTimeLimit', {id: id})
                .then(response => {
                    if (response.data.status === 0) {

                        toast.error('Perioada maxima de 12 ore a fost depasita!')
                        history.push("/adminEventPage/" + id)
                    }
                })
        } else {
            toast.success('Modul de previzualizare este activ!')
        }
    }

    const start = async () => {
        setGif(false)
        await getMediaStream()
        setPreparing(false)
    }

    const uploadFile = async () => {

        clearMediaBlob()
        clearMediaStream()

        if(testMode === '1') {
            history.push("/adminEventPage/"+id)
            return;
        }

        setLoading(true)

        let uploadFile = mediaBlob

        /*
            if(!makeAGif) {

                ffmpeg.FS("writeFile", "video1.webm", await fetchFile(mediaBlob));

                // Run the FFmpeg command-line tool, converting
                // the .mp4 into .gif file


                await ffmpeg.run(
                    "-i",
                    "video1.webm",
                    "-c:v",
                    "copy",
                    "-strict",
                    "experimental",
                    "out.mp4"
                );



                    await ffmpeg.run(
                    "-fflags",
                    "+genpts",
                    "-i",
                    "video1.webm",
                    "-r",
                    "24",
                    "out.mp4"
                );


                const data = ffmpeg.FS("readFile", "out.mp4");
                uploadFile = new Blob([data.buffer], { type: "video/mp4" })
            }

         */


        const data = new FormData();
        data.append('file', makeAGif && gif ? gifFile : uploadFile);
        data.append('gif', makeAGif && gif ? 1 : 0)
        data.append('wedding', parseInt(wedding));
        data.append('id_eveniment', id);
        data.append('name', name);
        data.append('email', email)

        data.append('secondaryRecipient', localStorage.getItem('memoryBoothSendTo'))

        let fetchConfig = {
            header: {
                'Content-Type': 'multipart/form-data'
            }
        }

        //process.env.REACT_APP_DOMAIN
        await axios.post(process.env.REACT_APP_DOMAIN + 'memory-booth-files/upload-file', data, fetchConfig)
            .then(response => {
                setLoading(false)
                localStorage.removeItem('name')
                history.push("/memorybooth/finished/" + id + "/" + response.data.uid)
            })
            .catch(err => {
                setLoading(false)
                toast.error('A aparut o eroare!')
            })


    }

    const convertToGif = async () => {

        while (!mediaBlob) {
            console.log('waiting for blob')
        }

        // Write the .mp4 to the FFmpeg file system
        ffmpeg.FS("writeFile", "video1.mp4", await fetchFile(mediaBlob));

        // Run the FFmpeg command-line tool, converting
        // the .mp4 into .gif file
        await ffmpeg.run(
            "-i",
            "video1.mp4",
            "-t",
            "5.0",
            "-ss",
            "0.5",
            "-vf",
            "fps=3,scale=500:-1:flags=lanczos,split[s0][s1];[s0]palettegen[p];[s1][p]paletteuse",
            "-f",
            "gif",
            "out.gif"
        );
        // Read the .gif file back from the FFmpeg file system
        const data = ffmpeg.FS("readFile", "out.gif");
        const blob = new Blob([data.buffer], {type: "image/gif"})
        const url = URL.createObjectURL(blob);
        setGif(url);
        setGifFile(blob)
    };

    useEffect(() => {

        if (mediaBlob && makeAGif) {
            convertToGif()
        }

    }, [mediaBlob])

    return (<div className={"mb-recording-container"}>
        <img src={backgroundImageUrl}
             style={{position: 'absolute', width: '100%', height: '100%', zIndex: 5, top: 0, left: 0}}
             crossOrigin={"true"}/>

        <div className={"mb-bg-overlay"} style={{position: 'absolute', zIndex: 10}}>

            <div>
                <div key={1} className={"mb-video-container"}
                     style={{minHeight: videoHeight + 'px', minWidth: videoWidth, height: videoHeight + 'px'}}>

                    {
                        status === 'stopped' && mediaBlob && !preparing ?

                            makeAGif === 1 ?
                                gif ?
                                    <img style={{
                                        minHeight: videoHeight + 'px',
                                        minWidth: videoWidth,
                                        height: videoHeight + 'px'
                                    }} src={gif}/>
                                    : null
                                :
                                <Player key={2} videoWidth={videoWidth} videoHeight={videoHeight} status={status}
                                        srcBlob={mediaBlob} preparing={preparing}/>
                            :
                            <>
                            <div style={{
                                height: videoHeight + 'px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Loader color={'rgb(0,175,242)'}/>
                            </div>

                        <div style={{position:'absolute', top: 0, left:0, width:'100%', height:'100%'}}>
                            <div style={{}}>

                                <LiveStreamPlayer key={3} videoWidth={videoWidth} videoHeight={videoHeight}
                                      stream={liveStream}/>
                                {/*<Webcam
                                    audio={false}
                                    height={videoHeight}
                                    controlhttps
                                    //ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    width={videoWidth}
                                    videoConstraints={videoConstraints}
                                    style={{position:'relative'}}
                                />*/}
                            </div>
                        </div></>
                    }
                    {
                        started ?
                            <Timer
                                key={preparing}
                                preparing={preparing}
                                startRecording={startRecording}
                                setPreparing={setPreparing}
                                stopRecording={() => {
                                    stopRecording()
                                }
                                }
                                status={status}
                                startTimout={startTimeout}
                                maxDuration={maxDuration}
                            />
                            : null
                    }

                </div>
            </div>

            <div className={"mb-progressbar-container"}>

                <div className={"row"}>
                <div className={"left"}></div>

                <div className={"center"}>
                    {
                        started && (status !== 'stopped') ?
                            <ProgressBar
                                key={preparing}
                                preparing={preparing}
                                setPreparing={setPreparing}
                                status={status}
                                mediaBlob={mediaBlob}
                                startTimout={startTimeout}
                                maxDuration={maxDuration}
                                makeAGif={makeAGif}
                            />
                            : null
                    }
                </div>

                <div className={"right"}>
                    {
                        status === 'recording' ?
                            <button
                                type="button"
                                className={"mb-recording-button mb-stop-button"}
                                onClick={() => {
                                    stopRecording()
                                }
                                }
                                disabled={status !== 'recording'}
                            >
                                <Icon icon="stop" className={"icon"}/>
                                Oprește
                            </button>
                            : null
                    }
                </div>

                </div>

            </div>
            <div className={"mb-buttons-container"}>
            {
                status === 'recording' ?
                    null
                    :

                            (status === 'stopped' || status === 'ready' || status === 'acquiring_media') && !preparing ?
                                <>
                                    {
                                        mediaBlob && started ?
                                            <>

                                                <button
                                                    type="button"
                                                    className={"mb-recording-button mb-stop-button"}
                                                    disabled={status === 'recording'}
                                                    onClick={() => {

                                                        if(testMode === '1') {
                                                            start()
                                                            return;
                                                        }

                                                        localStorage.removeItem('name')
                                                        localStorage.removeItem('email')
                                                        history.push("/memorybooth/intro/" + id)
                                                    }
                                                    }>

                                                    <Icon icon="trash" className={"icon"}/>
                                                    Șterge înregistrarea
                                                </button>


                                                <button
                                                    type="button"
                                                    className={"mb-recording-button mb-retry-button"}
                                                    onClick={() => {
                                                        setPreparing(true)
                                                        setStarted(false)
                                                        clearMediaStream()
                                                        clearMediaBlob()
                                                        start()

                                                    }}
                                                    disabled={status === 'recording'}
                                                >
                                                    <Icon icon="reload" className={"icon"}/>
                                                    Repetă înregistrarea
                                                </button>

                                                <br/>

                                                <button
                                                    type="button"
                                                    className={"mb-recording-button mb-finish-button"}
                                                    onClick={() => uploadFile()}
                                                    disabled={status === 'recording' || loading}
                                                >
                                                    <Icon icon="ok-circle" className={"icon"}/>
                                                    Finalizează
                                                </button>
                                            </>
                                            :
                                            <>
                                                {
                                                    started ?
                                                        null
                                                        :
                                                        <button
                                                            type="button"
                                                            className={"mb-start-large-button double"}
                                                            onClick={() => {
                                                                setPreparing(false)
                                                                setStarted(true)
                                                            }
                                                            }
                                                            disabled={status === 'recording'}
                                                        >
                                                            <Icon icon="play" className={"icon"}/>
                                                            START
                                                        </button>
                                                }
                                            </>
                                    }
                                </>
                                :
                                null

            }
            </div>
        </div>
    </div>)
}

