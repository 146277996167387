import React from 'react'
import SettingCard from '../Profile/components/SettingCard'
import './Planning.scss'

import HeaderWhite from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'
import { Button } from 'rsuite'

import SVG_Tasks from '../../assets/PlanningSVGs/tasks-icon.svg'
import SVG_Budget from '../../assets/PlanningSVGs/budget-icon.svg'
import SVG_Gifts from '../../assets/PlanningSVGs/cadou-icon.svg'
import SVG_Seating_Chart from '../../assets/PlanningSVGs/plan-locatie-icon.svg'

class Planning extends React.Component {
	goToTasks = () => {
		this.props.history.push('/plan/tasks/' + this.props.match.params.id)
	}

	goToBuget = () => {
		this.props.history.push('/plan/buget/' + this.props.match.params.id)
	}

	goToGifts = () => {
		this.props.history.push('/plan/gifts/' + this.props.match.params.id)
	}

	goToSeatigChart= () => {
		this.props.history.push('/adminSeatingChart/' + this.props.match.params.id)
	}

	goBack = () => {
		this.props.history.push("/adminEventPage/" + this.props.match.params.id)
	}

	render() {
		return (
			<div className="Planning-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<div className="Title">Planifica evenimentul</div>

				<div className="Planning-contents">
					<SettingCard svg={SVG_Tasks} onClick={this.goToTasks} name="Task-uri" />

					<SettingCard svg={SVG_Budget} onClick={this.goToBuget} name="Buget" />

					<SettingCard svg={SVG_Gifts} onClick={this.goToGifts} name="Cadouri" />

					<SettingCard svg={SVG_Seating_Chart} onClick={this.goToSeatigChart} name="Plan mese" />

					<div>
						<Button style={{ margin: "20px 20px 20px 0px", color: "#1E1C59", border: "1px solid #1E1C59", borderRadius: "80px", width: "200px" }} onClick={this.goBack}>
							Inapoi
						</Button>
					</div>
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default Planning;