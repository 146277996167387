import React from 'react'
import { Button } from 'rsuite'
import './ResetPass.scss'
import { toast } from 'react-toastify'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import CustomInput from '../../../components/custom/CustomInput'

class ResetPass extends React.Component {
	state = {
		newPass1: "",
		newPass2: ""
	}

	componentDidMount = async () => {
		this.setState({
			newPass1: "",
			newPass2: ""
		})
	}

	setNewPass1 = (value) => {
		this.setState({ newPass1: value });
	}

	setNewPass2 = (value) => {
		this.setState({ newPass2: value });
	}

	checkNewPassword = async () => {
		//verificare daca parola noua este corecta
		const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

		if (!passRegex.test(this.state.newPass1)) {
			toast.error("Parola este invalida")
			return
		}

		//verificare daca parola noua este identica in ambele parti
		if (this.state.newPass1 !== this.state.newPass2) {
			toast.error("Parolele nu sunt identice")
			return
		}

		//trimite modificarile pe server
		//get code
		let obj = {
			email: this.props.email,
			sendEmail: false,
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'auth/forgot-password', obj)
			.then((response) => {
				let obj2 = {
					password: this.state.newPass1,
					passwordConfirmation: this.state.newPass2,
					code: response.data.code
				}

				axios.post(process.env.REACT_APP_DOMAIN + 'auth/reset-password', obj2)
					.then(_ => {
						toast.success("Parola schimbata")
					})
					.catch(_ => {
						toast.error("S-a produs o erorare va rog reincercati")
					})
			})
			.catch(_ => {
				toast.error("S-a produs o erorare va rog reincercati")
			})

		//reset state and close modal
		this.resetState()
	}

	resetState = () => {
		this.setState({
			newPass1: "",
			newPass2: ""
		})

		this.props.closeModal()
	}

	render() {
		const styles = {
			fontFamily: "sans-serif",
			textAlign: "center",
			marginTop: "20px",
			width: "90vw",
			height: "100%",
		};

		return (
			<div style={styles}>
				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal',
						closeButton: 'modal-close-button',
					}}
					center
					open={this.props.showModal}
					onClose={this.props.closeModal}
				>
					<div style={{marginTop: "20px"}}>
						<h4>Schimba parola</h4>

						<p>Parola noua trebuie sa contina cel putin 8 caractere, printre care o litera mare, o litera mica, o cifra</p>

						<CustomInput style={{ margin: "10px 0px" }} inputType={"password"} value={this.state.newPass1} onChange={this.setNewPass1} placeholder={'Parola noua'} border={true} />

						<CustomInput style={{ margin: "10px 0px" }} inputType={"password"} value={this.state.newPass2} onChange={this.setNewPass2} placeholder={'Confirmare parola noua'} border={true} />

						<Button onClick={this.checkNewPassword} color="green" style={{ backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%" }}>Confirm</Button>
					</div>
				</Modal>
			</div>
		)
	}
}

export default ResetPass