import React, {useState, useRef, useEffect} from 'react';
import '../MemoryBooth.scss'
import {Icon} from "rsuite";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {toast} from 'react-toastify'
import TemplateRenderLive from "../../MemoryBoothPhotoConfig/components/TemplateRenderLive/TemplateRenderLive";
import Loader from "react-spinners/DotLoader";
import {toJpeg} from 'html-to-image';
import Webcam from "react-webcam";
import {digiCamControlHost, mode} from "../../../const";

const videoWidth = 922
const videoHeight = 518
const startTimeout = 5

const videoConstraints = {
    width: 1280,
    height: 720
};

export default function PhotoScreenV2() {

    let history = useHistory();
    const webcamRef = React.useRef(null);

    const id = localStorage.getItem('id_eveniment')
    const testMode = localStorage.getItem('memoryBoothTest')

    const name = localStorage.getItem('name')
    const email = localStorage.getItem('email')
    const backgroundImageUrl = localStorage.getItem('memoryBoothBackgroundImage')
    const memoryBoothWedding = localStorage.getItem('memoryBoothWedding') ? JSON.parse(localStorage.getItem('memoryBoothWedding')) : false


    const [images, setImages] = useState([])

    const [requiredPhotos, setRequiredPhotos] = useState(100)
    const [templateDetails, setTemplateDetails] = useState({})
    const [previewPrint, setPreviewPrint] = useState(false)


    const printRef = React.useRef();
    const [loading, setLoading] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    const [started, setStarted] = useState(false)
    const [refresh, setRefresh] = useState(0)

    const [timer, setTimer] = useState(null);

    useEffect(() => {

        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });

        //initDslr()
        getTemplate()
        liveFocus()

        checkTimeLimit()

        /*
        const liveStampInterval = setInterval(() => {
            let date = new Date()
            setLiveStamp(date.toTimeString())

        }, 300);
        return () => {
            //fetch(digiCamControlHost + '?cmd=LiveViewWnd_Hide').catch((e) => {})
            clearInterval(liveStampInterval);
        };
         */

    }, [])

    useEffect(() => {

        let newTemplateDetails = {...templateDetails}

        if (images.length > 0) {

            for (let i = 0; i < newTemplateDetails.sections.length; i++) {
                if (newTemplateDetails.sections[i].type === 0) {
                    newTemplateDetails.sections[i].type = 1
                    newTemplateDetails.sections[i].value = images[images.length - 1]
                    newTemplateDetails.sections[i].base64 = true
                    break;
                }
            }

            setTemplateDetails(newTemplateDetails)
        }

        fixRemoteImages()

    }, [images])


    useEffect(() => {

        if (images.length === requiredPhotos) {
            setTimeout(getPrintImageTemplate(), 1500)
        }


    }, [templateDetails])

    useEffect(() => {
        if (timer === 1) {
            capture(images)
            setTimer(null)
        }

        // exit early when we reach 0
        if (!timer) {
            return
        }

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {

            setTimer(timer - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timer]);


    useEffect(() => {
        if (images.length < requiredPhotos && started) {
            setTimeout(() => {
                startCapture()
            }, 1000)

        }
    }, [images, started])

    const initDslr = async () => {
        //Force open live view
        try {
            setTimeout(() => {
                 fetch(digiCamControlHost + '?cmd=LiveViewWnd_Show').catch((e) => {})
            }, 500)

        } catch (e) {

        }
    }

    const liveFocus = async () => {
        //live focus
        try {
            fetch(digiCamControlHost + '?cmd=livefocus').catch((e) => {})
        } catch (e) {

        }
    }


    const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))

    const fixRemoteImages = async () => {

        if (!templateDetails) {
            return
        }

        let newTemplateDetails = {...templateDetails}

        if (newTemplateDetails && newTemplateDetails.sections) {

            for (let i = 0; i < newTemplateDetails.sections.length; i++) {

                if (newTemplateDetails.sections[i].type === 1 && !newTemplateDetails.sections[i].base64) {

                    newTemplateDetails.sections[i].base64 = true

                    await toDataURL(newTemplateDetails.sections[i].value)
                        .then(dataUrl => {
                            newTemplateDetails.sections[i].value = dataUrl
                        })

                }

            }

            for (let i = 0; i < newTemplateDetails.settings.length; i++) {
                if (newTemplateDetails.settings[i].type === 'image' && !newTemplateDetails.settings[i].base64) {

                    newTemplateDetails.settings[i].base64 = true

                    await toDataURL(newTemplateDetails.settings[i].value)
                        .then(dataUrl => {
                            newTemplateDetails.settings[i].value = dataUrl
                        })

                }

            }
            setTemplateDetails(newTemplateDetails)

        } else {
            console.log(' Nu am gasit tempalte')
        }

        setRefresh(refresh + 1)
    }

    const getTemplate = () => {

        if (memoryBoothWedding) {
            let template = memoryBoothWedding.memorybooth && memoryBoothWedding.memorybooth.photo && memoryBoothWedding.memorybooth.photo.templateDetails ? memoryBoothWedding.memorybooth.photo.templateDetails : {}

            template.live = true

            let nrOfRequiredPhotos = 0;
            template.sections.map((section, i) => {
                if (section.type === 0) {
                    nrOfRequiredPhotos++;
                }
            })

            setRequiredPhotos(nrOfRequiredPhotos)
            setTemplateDetails(template)
        }
    }

    const checkTimeLimit = async () => {
        if (testMode !== '1') {
            await axios.post(process.env.REACT_APP_DOMAIN + 'memorybooth/checkTimeLimit', {id: id})
                .then(response => {
                    if (response.data.status === 0) {

                        toast.error('Perioada maxima de 12 ore a fost depasita!')
                        history.push("/adminEventPage/" + id)
                    }
                })
        } else {
            toast.success('Modul de previzualizare este activ!')
        }
    }

    const capture = async () => {
        setShowLoading(true)
        let newPhoto = await toDataURL(digiCamControlHost + '?cmd=Capture')

        if(newPhoto.length > 500) {
            let newImages = [...images]
            newImages.push(newPhoto)
            setImages(newImages)
        } else {
            //Retry

            if (images.length < requiredPhotos && started) {
                setTimeout(() => {
                    startCapture()
                }, 1000)

            }
        }
        setShowLoading(false)
    }

    const getPrintImageTemplate = async () => {

        await timeout(1000)
        const element = printRef.current;

        await toJpeg(element, { cacheBust: false, quality: 0.8 })
            .then((dataUrl) => {
                setPreviewPrint(dataUrl)
                return dataUrl
            })
            .catch((err) => {
                console.log(err)
            })
    };

    const startCapture = async () => {
        setTimer(startTimeout)
    }

    const convertBase64ToBlob = async (base64Image, resize = true) => {

        if(resize) {
            base64Image = await resizedataURL(base64Image, 1296, 864)
        }

        // Split into two parts
        const parts = base64Image.split(';base64,');

        // Hold the content type
        const imageType = parts[0].split(':')[1];

        // Decode Base64 string
        const decodedData = window.atob(parts[1]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        return new Blob([uInt8Array], {type: imageType});
    }

    const resizedataURL = (datas, wantedWidth, wantedHeight) => {
        return new Promise(async function(resolve,reject){

            // We create an image to receive the Data URI
            var img = document.createElement('img');

            // When the event "onload" is triggered we can resize the image.
            img.onload = function()
            {
                // We create a canvas and get its context.
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');

                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth;
                canvas.height = wantedHeight;

                // We resize the image with the canvas method drawImage();
                ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

                var dataURI = canvas.toDataURL('image/jpeg', 0.8);

                // This is the return of the Promise
                resolve(dataURI);
            };

            // We put the Data URI in the image's src attribute
            img.src = datas;

        })
    }

    const finish = async () => {
        setLoading(true)

        if(memoryBoothWedding && memoryBoothWedding.memorybooth && memoryBoothWedding.memorybooth.photo && !memoryBoothWedding.memorybooth.photo.individualDisabled) {
            for (let i = 0; i < images.length; i++) {
                const data = new FormData();
                data.append('file', await convertBase64ToBlob(images[i]));
                console.log('end converting to blob')
                data.append('gif', 0)
                data.append('wedding', 1);
                data.append('id_eveniment', id);
                data.append('name', name);
                data.append('email', '')
                data.append('individual', 1)
                data.append('extension', 'jpg')


                let fetchConfig = {
                    header: {
                        'Content-Type': 'multipart/form-data'
                    }
                }

                //process.env.REACT_APP_DOMAIN
                await axios.post(process.env.REACT_APP_DOMAIN + 'memory-booth-files/upload-file', data, fetchConfig)
                    .then(response => {

                    })
                    .catch(err => {
                    })
            }
        }

        let base64PrintImage = previewPrint

        localStorage.removeItem('name')

        const data = new FormData();
        data.append('file', await convertBase64ToBlob(base64PrintImage, false));
        data.append('gif', 0)
        data.append('wedding', 1);
        data.append('id_eveniment', id);
        data.append('name', name);
        data.append('email', '')

        data.append('secondaryRecipient', localStorage.getItem('memoryBoothSendTo'))

        let fetchConfig = {
            header: {
                'Content-Type': 'multipart/form-data'
            }
        }

        //process.env.REACT_APP_DOMAIN
        await axios.post(process.env.REACT_APP_DOMAIN + 'memory-booth-files/upload-file', data, fetchConfig)
            .then(response => {
                setLoading(false)
                localStorage.removeItem('name')
                localStorage.setItem('memoryBoothPrintPhoto', response.data.url)
                history.push("/memorybooth/finishedPhoto/" + id + "/" + response.data.uid)
            })
            .catch(err => {
                setLoading(false)
                toast.error('A aparut o eroare!')
            })



    }

    const timeout = (delay) => {
        return new Promise( res => setTimeout(res, delay) );
    }

    return (<div className={"mb-recording-container"}>
        <img src={backgroundImageUrl}
             style={{position: 'absolute', width: '100%', height: '100%', zIndex: 5, top: 0, left: 0}}
             crossOrigin={"true"}/>


        <div style={{
            position: 'absolute',
            left: 0,
            zIndex: 1000,
            height: '100vh',
            padding: '1rem 0 0 1.5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            {
                images && images.map((image, i) => (
                    <>
                        <img src={image} style={{width: '180px', height: 'auto', marginBottom: '10px', borderRadius:'15px'}}/><br/>
                    </>
                ))
            }
        </div>

        <div className={"mb-bg-overlay"} style={{position: 'absolute', zIndex: 10}}>


            <div>


                {
                    images.length === requiredPhotos ?
                        <>
                            <div>
                                {previewPrint ?
                                    <img src={previewPrint} style={{height: videoHeight+'px', width: 'auto'}}/>
                                    :
                                    <div style={{
                                        height: '434px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <Loader color={'rgb(0,175,242)'}/>
                                    </div>
                                }
                            </div>

                            {/*
                        <TemplateRenderLive
                            reff={printRef}
                            key={images.length + '_' + refresh + '_' + 'small'}
                            templateDetails={templateDetails}
                            small={true}
                        /> */}
                        </>
                        :

                        <div key={1} className={"mb-video-container"}
                             style={{position:'relative',minHeight: videoHeight + 'px', minWidth: videoWidth, height: videoHeight + 'px'}}>

                            {
                                timer !== null ?
                                    <div className={"mb-recording-overlay"}>
                                        {
                                            timer > 1 &&
                                            <h1 className={"animate__animated animate__zoomIn animate__infinite"}>{timer - 1}</h1>
                                        }
                                    </div>
                                    : null
                            }

                            {
                                showLoading ?
                                    <div style={{
                                        height: videoHeight + 'px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: "absolute",
                                        zIndex: 10000,
                                        backgroundColor: "rgba(0,0,0,0.5)"
                                    }}>
                                        <Loader color={'rgb(0,175,242)'}/>
                                    </div>
                                    :null
                            }


                            <div style={{
                                height: videoHeight + 'px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Loader color={'rgb(0,175,242)'}/>
                            </div>

                            <div style={{position:'absolute', top: 0, left:0, width:'100%', height:'100%'}}>

                                        {/*<iframe src={digiCamControlHostLive} style={{width:'100%', height:'100%'}}></iframe>*/}
                                            <div style={{}}>
                                            <Webcam
                                                audio={false}
                                                height={videoHeight}controlhttps
                                                ref={webcamRef}
                                                screenshotFormat="image/jpeg"
                                                width={videoWidth}
                                                videoConstraints={videoConstraints}
                                                style={{position:'relative'}}
                                            />
                                            </div>
                                            {/*
                                            <img src={'http://127.0.0.1:5513/liveview.jpg?get=live&stamp='+liveStamp} style={{width:'100%', height:'100%'}}/>

                                            <img src={digiCamControlHostLive} style={{width:'100%', height:'100%'}}/>*/}
                                            {/*<Webcam
                                                audio={false}
                                                height={562}controlhttps
                                                ref={webcamRef}
                                                screenshotFormat="image/jpeg"
                                                width={922}
                                                videoConstraints={videoConstraints}
                                            />*/}


                            </div>


                        </div>
                }
            </div>


            <div className={"mb-buttons-container"}>

                {
                    images.length === requiredPhotos ?
                        <>


                            <button
                                type="button"
                                className={"mb-recording-button mb-stop-button"}
                                disabled={loading}
                                onClick={() => {
                                    localStorage.removeItem('name')
                                    localStorage.removeItem('email')
                                    history.push("/memorybooth/intro/" + id)
                                }
                                }>

                                <Icon icon="trash" className={"icon"}/>
                                Șterge fotografia
                            </button>

                            <button
                                type="button"
                                className={"mb-recording-button mb-retry-button"}
                                disabled={loading}
                                onClick={() => {
                                    //initDslr()
                                    getTemplate()
                                    setStarted(false)
                                    setImages([])
                                    setPreviewPrint(false)
                                }}
                            >
                                <Icon icon="reload" className={"icon"}/>
                                Repetă fotografia
                            </button>

                            <br/><br/>


                            {
                                loading ?
                                    <div style={{
                                        textAlign: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}><Loader color={'rgb(0,175,242)'}/></div> :

                                    <>

                                    <button
                                        type="button"
                                        className={"mb-recording-button mb-finish-button"}
                                        onClick={() => finish()}
                                        disabled={loading}
                                    >
                                        <Icon icon="ok-circle" className={"icon"}/>
                                        Finalizează
                                    </button>
                                        </>
                            }
                        </>
                        :
                        !started ?
                        <button
                            type="button"
                            className={"mb-start-large-button double"}
                            onClick={() => {
                                setStarted(true)
                            }
                            }
                        >
                            <Icon icon="camera" className={"icon"}/>
                            Start
                        </button>
                            :null
                }

            </div>


            <div style={{position:'absolute', top: '100vh'}}>

            {
                templateDetails &&
                <TemplateRenderLive
                    reff={printRef}
                    key={images.length + '_' + refresh}
                    templateDetails={templateDetails}
                />
            }
            </div>

        </div>


    </div>)
}

