import React from 'react'
import './AddSaveTheDate.scss'
import HeaderWhite from '../components/HeaderWhiteBack'
import { Uploader, Icon, Dropdown, Input, Button } from 'rsuite'
import Modal from "react-responsive-modal"

import NavbarColor from '../components/NavbarColor'
import axios from 'axios'
import { toast } from 'react-toastify'

import MicRecorder from 'mic-recorder-to-mp3';
import LoadingModal from './LoadingModal/LoadingModal'

import { CirclePicker } from 'react-color'
import CustomInput from '../components/custom/CustomInput'
import { BackAndConfirmButtons, ConfirmButton, LoadingScreen, PlayAudio, SelectInviteType, textarea_max_length } from '../components/Helper'
import { BsFillMicFill } from 'react-icons/bs';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

class AddSaveTheDate extends React.Component {
	state = {
		saveTheDateType: "",

		// eventType: "Nunta",
		eventId: -1,
		isUsingNotifications: false,
		isUsingNotificationsInitialState: false,

		showModalChangeType: false,

		city: "",
		description: "",
		fotoID: "",
		linkVideo: "",
		poza_saveTheDate: null,
		fontSize: "20px",
		fontColor: "#000",
		fontFamily: "Open Sans",
		fontsOptions: ["Open Sans", "Cursive", "Monospace", "Revert", "Fantasy", "Poppins"],

		isChangingColor: false,

		isUsingAudio: false,
		isRecording: false,
		blobURL: '',
		audioBlobData: null,
		isBlocked: false,

		loading: false,
		loadingData: true
	}

	componentDidMount = async () => {
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices.getUserMedia({ audio: true },
				() => {
					console.log('Permission Granted');
					this.setState({ isBlocked: false });
				},
				() => {
					console.log('Permission Denied');
					this.setState({ isBlocked: true })
				},
			);
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let weddingData = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)

		this.setState({
			// eventType: weddingData.data.eventType,//mereu "Nunta"
			eventId: weddingData.data.id,
			weddingData: weddingData.data,
			isUsingNotifications: weddingData.data.notifications,
			isUsingNotificationsInitialState: weddingData.data.notifications
		})

		this.setState({
			loadingData: false
		})
	}

	setSaveTheDateType = (type) => {
		this.setState({
			saveTheDateType: type
		})
	}

	start = () => {
		if (this.state.isBlocked) {
			toast.info("Microfonul este blocat, acordati permisiune pentru a-l folosii.")
		} else {
			Mp3Recorder
				.start()
				.then(() => {
					this.setState({ blobURL: "", isRecording: true, audioBlobData: null });
				}).catch((e) => console.error(e));
		}
	};

	stop = () => {
		Mp3Recorder
			.stop()
			.getMp3()
			.then(([buffer, blob]) => {
				// console.log(buffer);
				// console.log(blob);
				const blobURL = URL.createObjectURL(blob)
				// console.log(blobURL);
				this.setState({ blobURL, isRecording: false, audioBlobData: blob });
			}).catch((e) => console.log(e));
	};

	handleChange = (value, event) => {
		this.setState({
			[event.target.name]: value
		})
	}

	createSaveTheDate = async () => {
		if (this.state.saveTheDateType === "Personal" && this.state.poza_saveTheDate === null) {
			toast.error("Nu ati adaugat invitatia personala")
			return;
		}

		if (this.state.saveTheDateType === "Video" && this.state.linkVideo === "") {
			toast.error("Nu ati adaugat link")
			return;
		}

		if (this.state.saveTheDateType === "Custom" && (this.state.city === "" || this.state.description === "")) {
			toast.error("Nu ati completat toate campurile")
			return;
		}

		this.setState({
			loading: true
		})

		let obj = {
			user: localStorage.getItem('id'),
			event: this.state.eventId,
			saveTheDateType: this.state.saveTheDateType,
			prenumeMireasa: this.state.weddingData.prenumeMireasa,
			prenumeMire: this.state.weddingData.prenumeMire,
			dataEvent: this.state.weddingData.dataEvent,
			localitate: this.state.city,
			description: this.state.description,
			linkVideo: this.state.linkVideo,
			fontColor: this.state.fontColor,
			fontSize: this.state.fontSize,
			fontFamily: this.state.fontFamily,
			audioType: this.state.isUsingAudio ? "useAudio" : "none"
		}

		let formData = new FormData()
		formData.append("data", JSON.stringify(obj))

		if (this.state.poza_saveTheDate !== null) {
			formData.append("files.backgroundImage", this.state.poza_saveTheDate)
		}

		if (this.state.isUsingAudio && this.state.audioBlobData !== null) {
			formData.append("files.audio", this.state.audioBlobData)
		}

		let auth = {
			headers: {
				"Content-Type": `multipart/form-data;`,
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'save-the-dates', formData, auth)
			.then(async response => {
				toast.success("Save the date creat cu succes!")

				this.setState({
					loading: false
				})

				let obj2 = {
					saveTheDateInfo: true,
					notifications: this.state.isUsingNotifications
				}

				let auth0 = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					}
				}

				await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj2, auth0)

				this.props.history.push('/info/' + this.props.match.params.id)
			})
			.catch(err => {
				toast.error("Eroare")
			})
	}

	handlePoza = (fileList) => {
		if (fileList.length === 0) {
			this.setState({
				poza_saveTheDate: null
			})
			return
		}

		this.setState({
			poza_saveTheDate: fileList[0].blobFile
		})
	}

	changeSaveTheDateDescription = (value) => {
		if (this.state.description.length > textarea_max_length - 1 && value.length > textarea_max_length - 1) {
			return
		}

		this.setState({
			description: value
		})
	}

	openModalChangeType = () => {
		this.setState({
			showModalChangeType: true
		})
	}

	closeModalChangeType = () => {
		this.setState({
			showModalChangeType: false
		})
	}

	changeType = () => {
		this.setState({
			saveTheDateType: "",

			isUsingNotifications: this.state.isUsingNotificationsInitialState,

			city: "",
			description: "",
			fotoID: "",
			linkVideo: "",
			poza_saveTheDate: null,
			fontSize: "20px",
			fontColor: "#000",
			fontFamily: "Open Sans",

			isChangingColor: false,

			isRecording: false,
			blobURL: '',
			isUsingAudio: false,
			audioBlobData: null,

			showModalChangeType: false
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loadingData) {
			return <LoadingScreen />
		}

		let customInputStyle = {
			margin: "10px 0px",
			backgroundColor: "#E5F7FE",
			borderRadius: "10px"
		}

		let body = <div></div>

		if (this.state.saveTheDateType === "Personal") {
			body = <div>
				<div className="Upload-zone">
					<h3> Adauga fotografie cu invitatia personala </h3>
					{/* <p> Fisiere acceptate: .jpg, recomandam fotografii tip portret</p> */}
					<Uploader onChange={this.handlePoza} accept=".jpg" style={{ borderRadius: "5px" }} autoUpload={false} multiple={false} disabled={this.state.poza_saveTheDate !== null}>
						<div style={{ color: "#221d5d", width: "100%", height: "100%" }} id="text">Click aici pentru a uploada fotografie</div>
					</Uploader>
				</div>

				<div className="Section">
					<div className="Title">Doriti sa inregistrati un mesaj audio?</div>

					<div className="Options-Row">
						<div className={this.state.isUsingAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingAudio: true })} style={{ marginRight: "30px" }}>Da</div>
						<div className={!this.state.isUsingAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingAudio: false })}>Nu</div>
					</div>
				</div>

				{this.state.isUsingAudio && <div style={{ width: "100%", alignContent: "center", margin: "10px 0px" }}>
					<h4 style={{ textAlign: "center", marginLeft: "0px" }}> Inregistrare Audio </h4>

					<div className="Upload-Audio">
						{/* <audio src={this.state.blobURL} controls="controls" /> */}
						{this.state.blobURL && <PlayAudio link={this.state.blobURL} />}

						<div className="Buttons">
							{!this.state.isRecording && <Button size="lg" appearance="primary" style={{ marginRight: "10px" }} onClick={this.start} disabled={this.state.isRecording}>Inregistreaza</Button>}

							{this.state.isRecording && <BsFillMicFill color={"red"} style={{ margin: "0px 10px", alignSelf: "center" }} />}

							<Button size="lg" color="red" style={{ marginLeft: "10px" }} onClick={this.stop} disabled={!this.state.isRecording}>Stop</Button>
						</div>
					</div>
				</div>}
			</div>
		}

		if (this.state.saveTheDateType === "Video") {
			body = <div>
				<CustomInput value={this.state.linkVideo} onChange={value => this.setState({ linkVideo: value })} placeholder={"Link video save the date"}
					style={customInputStyle} icon={<Icon icon="text-height" />} />
			</div>
		}

		if (this.state.saveTheDateType === "Custom") {
			body = <div>
				<CustomInput value={this.state.city} onChange={value => this.setState({ city: value })} placeholder={"In ce oras este evenimentul?"}
					style={customInputStyle} icon={<Icon icon="map-marker" />} />

				<CustomInput value={this.state.description} onChange={this.changeSaveTheDateDescription} placeholder={`Informatiile evenimentului ${this.state.description.length}/${textarea_max_length}`}
					style={customInputStyle} componentClass={"textarea"} icon={<Icon icon="pencil" />} />

				<div style={{ fontSize: "18px" }}>Format text</div>
				<div className="Invite-Design">
					<div>
						<p>Dimensiune</p>

						<Input value={this.state.fontSize.split("p")[0]} onChange={value => this.setState({ fontSize: value + "px" })} placeholder={"Dimensiune"}
							style={{ backgroundColor: "#E5F7FE", borderRadius: "10px", width: "100px" }} icon={<Icon icon="text-height" />} type={"number"} />
					</div>

					<div className="color-container">
						<p>Culoare</p>

						<div className="color-choice">
							<div style={{ height: "35px", width: "35px", backgroundColor: this.state.fontColor, borderRadius: "50%" }} onClick={() => this.setState({ isChangingColor: true })}></div>

							{this.state.isChangingColor &&
								<Modal classNames={{
									overlay: 'modal-overlay',
									modal: 'modal-colors',
									closeButton: 'modal-close-button',
								}} center open={this.state.isChangingColor} onClose={() => this.setState({ isChangingColor: false })}>
									<div className="color-options">
										<CirclePicker color={this.state.fontColor} onChange={newColor => this.setState({ fontColor: newColor.hex, isChangingColor: false })} />

										<div className="basic-color">
											<div className="basic-color-white" onClick={() => this.setState({ fontColor: "#fff", isChangingColor: false })}></div>
											<div className="basic-color-black" onClick={() => this.setState({ fontColor: "#000", isChangingColor: false })}></div>
										</div>
									</div>
								</Modal>}
						</div>
					</div>

					<div style={{ textAlign: "center" }}>
						<p>Font</p>

						<Dropdown title={this.state.fontFamily} activeKey={this.state.fontFamily} className="dropdown_scroll" style={{ backgroundColor: "#E5F7FE", borderRadius: "10px" }}>
							{this.state.fontsOptions.map((option, index) => {
								return <Dropdown.Item key={index} eventKey={option} onClick={() => this.setState({ fontFamily: option })}>{option}</Dropdown.Item>
							})}
						</Dropdown>
					</div>
				</div>

				<p style={{ fontSize: this.state.fontSize, fontFamily: this.state.fontFamily, color: "" + this.state.fontColor, marginBottom: "20px" }}>Text final</p>

				<div className="Upload-zone">
					<p> Adauga Fotografie de Fundal </p>

					<Uploader onChange={this.handlePoza} accept=".jpg" style={{ borderRadius: "5px" }} autoUpload={false}>
						<div style={{ color: "#221d5d", width: "100%" }}>Click aici pentru a incarca fotografia</div>
					</Uploader>
				</div>

				<div className="Section">
					<div className="Title">Doriti sa inregistrati un mesaj audio?</div>

					<div className="Options-Row">
						<div className={this.state.isUsingAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingAudio: true })} style={{ marginRight: "30px" }}>Da</div>
						<div className={!this.state.isUsingAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingAudio: false })}>Nu</div>
					</div>
				</div>

				{this.state.isUsingAudio && <div style={{ width: "100%", alignContent: "center", margin: "10px 0px" }}>
					<h4 style={{ textAlign: "center" }}> Inregistrare Audio </h4>

					<div className="Upload-Audio">
						{/* <audio src={this.state.blobURL} controls="controls" /> */}
						{this.state.blobURL && <PlayAudio link={this.state.blobURL} />}

						<div className="Buttons">
							{!this.state.isRecording && <Button size="lg" appearance="primary" style={{ marginRight: "10px" }} onClick={this.start} disabled={this.state.isRecording}>Inregistreaza</Button>}

							{this.state.isRecording && <BsFillMicFill color={"red"} style={{ margin: "0px 10px", alignSelf: "center" }} />}

							<Button size="lg" color="red" style={{ marginLeft: "10px" }} onClick={this.stop} disabled={!this.state.isRecording}>Stop</Button>
						</div>
					</div>
				</div>}
			</div>
		}

		return (
			<div className="AddSaveTheDate-container">
				<HeaderWhite goBack={this.goBack} />

				<LoadingModal loading={this.state.loading} />

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.showModalChangeType} onClose={this.closeModalChangeType}>
					<div style={{ marginTop: "30px" }}>
						<div>Daca schimbati tipul de invitatie, pierdeti datele introduse in cadrul acesteia</div>

						<ConfirmButton text={"Confirm"} function={this.changeType} />
					</div>
				</Modal>

				<div className="AddSaveTheDate-contents">
					<h4> Creaza Save the Date </h4>

					<div className="input-zone">
						{/* {this.state.saveTheDateType === "" && <div>
							<div className="Input_Type_Options">
								<div className="Input_Type_Option" onClick={_ => this.setSaveTheDateType("Personal")}>Invitatie personala</div>

								<div className="Input_Type_Option" onClick={_ => this.setSaveTheDateType("Video")}>Incarca link</div>

								<div className="Input_Type_Option" onClick={_ => this.setSaveTheDateType("Custom")}>Invitatie personalizata</div>
							</div>

							<BackButton text={"Inapoi"} function={this.goBack} />
						</div>}

						{this.state.saveTheDateType !== "" && <div>
							<div onClick={this.openModalChangeType} className="ChangeChoiceButton">
								Alege alt tip de invitatie
							</div>
						</div>} */}

						<SelectInviteType inviteType={this.state.saveTheDateType} setInviteType={this.setSaveTheDateType}
							goBack={this.goBack} openModalChangeType={this.openModalChangeType} text={"save the date"} />

						{body}

						{this.state.saveTheDateType !== "" && <div>
							<div className="Section">
								<div className="Title">Doriti sa primiti notificari cand primiti raspuns?</div>

								<div className="Options-Row">
									<div className={this.state.isUsingNotifications ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingNotifications: true })} style={{ marginRight: "30px" }}>Da</div>
									<div className={!this.state.isUsingNotifications ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingNotifications: false })}>Nu</div>
								</div>
							</div>

							<BackAndConfirmButtons textBack={"Inapoi"} textConfirm={"Salveaza"} functionBack={this.goBack} functionConfirm={this.createSaveTheDate} />
						</div>}
					</div>
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default AddSaveTheDate;