import React from 'react'
import { Button, Icon } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'

import HeaderWhite from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'

import './Gifts.scss'

import AddGift from './Components/AddGift'
import ChangeGift from './Components/ChangeGift'
import ChoseBuyer from './Components/ChoseBuyer'
import { getUrlFromImage } from '../../components/Helper'

class Gifts extends React.Component {
	state = {
		isPublished: false,
		eventId: 0,
		gifts: [],
		choice: "Loading",
		giftInChange: 0,

		isChangingPublishStatus: false
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let eventData = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

		let info = await axios.get(process.env.REACT_APP_DOMAIN + 'gifts/list/byEvent/' + this.props.match.params.id, auth);
		// console.log(info);

		this.setState({
			gifts: info.data,
			choice: "SHOW",
			eventId: eventData.data.id,
			isPublished: eventData.data.areGiftsPublished
		})
	}

	changeStatus = async () => {
		if (this.state.isChangingPublishStatus) {
			toast.info("Se trimite, va rog asteptati")
			return
		}

		this.setState({
			isChangingPublishStatus: true
		})

		let newStatus = !this.state.isPublished

		let obj = {
			areGiftsPublished: newStatus
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
			.then(_ => {
				toast.success("Modificari trimise")
				this.setState({
					isPublished: newStatus
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("Eroare")
			})

		this.setState({
			isChangingPublishStatus: false
		})
	}

	saveGift = async (giftData, picture) => {
		//send new gift to server
		let formData = new FormData()
		formData.append("data", JSON.stringify(giftData))

		if (picture !== null) {
			formData.append("files.picture", picture)
		}

		let auth = {
			headers: {
				"Content-Type": `multipart/form-data;`,
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'gifts', formData, auth)
			.then(async response => {
				toast.success("Cadou salvat cu succes")

				// giftData.id = response.data.id
				// if (response.data.picture !== null) {
				// 	giftData.picture = response.data.picture.url
				// } else {
				// 	giftData.picture = null
				// }

				// var newGifts = [...this.state.gifts, giftData]

				let info = await axios.get(process.env.REACT_APP_DOMAIN + 'gifts/list/byEvent/' + this.props.match.params.id, auth);

				this.setState({
					gifts: info.data,
					choice: "SHOW"
				})
			})
			.catch(_ => {
				toast.error("Cadoul nu a putut fi salvat, va rog reincercati")

				this.setState({
					choice: "SHOW"
				})
			})
	}

	changeGift = async (giftData, picture) => {
		let formData = new FormData()
		formData.append("data", JSON.stringify(giftData))

		if (picture !== null) {
			formData.append("files.picture", picture)
		}

		let auth = {
			headers: {
				"Content-Type": `multipart/form-data;`,
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'gifts/' + this.state.gifts[this.state.giftInChange].id, formData, auth)
			.then(response => {
				toast.success("Cadou salvat cu succes")

				if (response.data.picture !== null) {
					giftData.picture = response.data.picture.url
				} else {
					giftData.picture = null
				}

				var newGifts = [...this.state.gifts]
				newGifts.splice(this.state.giftInChange, 1, giftData)

				this.setState({
					gifts: newGifts,
					choice: "SHOW"
				})
			});
	}

	setBoughtBy = async (user) => {
		if (user.id === undefined || user.id === null || user.id === -1) {
			toast.error("Acest participant nu are un cont")
			return
		}

		var gift = { ...this.state.gifts[this.state.giftInChange] }
		gift.boughtBy = user.prenume + " " + user.nume
		gift.idBoughtBy = user.id
		var newGifts = [...this.state.gifts]
		newGifts.splice(this.state.giftInChange, 1, gift)

		this.setState({
			gifts: newGifts,
			choice: "SHOW"
		})

		let obj = {
			boughtBy: user.prenume + " " + user.nume,
			idBoughtBy: "" + user.id
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'gifts/' + gift.id, obj, auth)
			.then(_ => {
				toast.success("Cadou atribuit")
			})
	}

	deleteBoughtBy = async () => {
		var gift = { ...this.state.gifts[this.state.giftInChange] }

		gift.boughtBy = ""
		gift.idBoughtBy = ""
		var newGifts = [...this.state.gifts]
		newGifts.splice(this.state.giftInChange, 1, gift)

		this.setState({
			gifts: newGifts
		})

		let obj = {
			boughtBy: "",
			idBoughtBy: ""
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'gifts/' + gift.id, obj, auth)
			.then(_ => {
				toast.success("Atribuire cadou anulata")
			})
	}

	deleteGift = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.delete(process.env.REACT_APP_DOMAIN + 'gifts/' + this.state.gifts[this.state.giftInChange].id, auth)
			.then(_ => {
				toast.success("Stergere finalizata")
			})

		var newGifts = [...this.state.gifts]
		newGifts.splice(this.state.giftInChange, 1)

		this.setState({
			gifts: newGifts,
			choice: "SHOW"
		})
	}

	openGiftLink = (link) => {
		if (link.startsWith("http")) {
			window.open(link)
		} else {
			let newLink = "https://" + link

			window.open(newLink)
		}
	}

	goToEditGift = (index) => {
		this.setState({ choice: "CHANGE", giftInChange: index })
	}

	goBack = () => {
		if (this.state.choice === "ADD" || this.state.choice === "CHANGE" || this.state.choice === "SELECT") {
			this.setState({
				choice: "SHOW"
			})

			return
		}

		this.props.history.go(-1)
	}

	render() {
		var body = <div>Loading Data...</div>

		if (this.state.choice === "SHOW") {
			body = <div className="Gifts-contents">
				<div className="Title">
					<div className='text'>Cadouri</div>

					<div className='add-gift-button'>
						<Icon icon="plus-circle" size="lg" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={() => this.setState({ choice: "ADD" })} />
						<div onClick={() => this.setState({ choice: "ADD" })}>Adauga cadou</div>
					</div>
				</div>

				<div className="Gifts">
					<div className="Status">
						<div className="Details">Status meniu cadouri: {this.state.isPublished ? "Vizibil" : "Neafisat"}</div>

						<Button loading={this.state.isChangingPublishStatus} className={!this.state.isPublished ? "Published" : "Unpublished"} onClick={this.changeStatus}>
							{!this.state.isPublished ? "Publica cadouri" : "Anuleaza publicare cadourilor"}
						</Button>
					</div>

					{this.state.gifts.map((gift, index) => {
						return <div key={index} className="Gift-Card">
							{gift.picture !== null && <div className="Picture" style={{
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundImage: `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(gift.picture)})`
							}} onClick={() => this.goToEditGift(index)}></div>}

							<div className="Edit-Gift" onClick={() => this.goToEditGift(index)}>
								Click aici pentru a edita cadoul
							</div>

							<div className="Title font-family-poppins-700-bold">
								{gift.title}
							</div>

							<div className={"Description"}>
								{gift.description}
							</div>

							{gift.link !== "" && <div className="Link" onClick={() => this.openGiftLink(gift.link)}>
								Link
							</div>}

							<div className="Text">
								{gift.boughtBy !== "" && <p>Va fi cumparat de: {gift.boughtBy}</p>}

								<p style={{ color: "#05AFF2" }} onClick={() => this.setState({ choice: "SELECT", giftInChange: index })} >Atribuie cadou {gift.boughtBy !== "" ? "altui participant" : ""}</p>
							</div>
						</div>
					})}
				</div>
			</div>
		}

		if (this.state.choice === "ADD") {
			body = <AddGift saveGift={this.saveGift} back={this.goBack} eventId={this.state.eventId} />
		}

		if (this.state.choice === "CHANGE") {
			body = <ChangeGift gift={this.state.gifts[this.state.giftInChange]} changeGift={this.changeGift} deleteGift={this.deleteGift} back={this.goBack} />
		}

		if (this.state.choice === "SELECT") {
			body = <ChoseBuyer gift={this.state.gifts[this.state.giftInChange]} eventId={this.props.match.params.id}
				setBoughtBy={this.setBoughtBy} deleteBoughtBy={this.deleteBoughtBy} back={this.goBack}
			/>
		}

		return (
			<div className="Gifts-container">
				<HeaderWhite shadow={true} goBack={this.goBack} />
				{body}
				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default Gifts;
