import React from 'react'
import './EditEvent.scss'
import HeaderWhite from '../components/HeaderWhiteBack'
import {Uploader, Button, SelectPicker, Icon, DatePicker, Dropdown, Checkbox} from 'rsuite'
import Modal from "react-responsive-modal";
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';

import NavbarColor from '../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import isBefore from 'date-fns/isBefore'
import CustomInput from '../components/custom/CustomInput';
import {BackAndConfirmButtons, EditPicture, getUrlFromImage, textarea_max_length} from '../components/Helper';
import Loader from "react-spinners/DotLoader";

let locatiiNunta = [{label: "Locatie petrecere", value: "Locatie petrecere"}, {
    label: "Locatie biserica",
    value: "Locatie biserica"
}, {label: "Locatie cununie", value: "Locatie cununie"}, {label: "Alta locatie", value: "Alta locatie"}]
let locatiiBotez = [{label: "Locatie petrecere", value: "Locatie petrecere"}, {
    label: "Locatie biserica",
    value: "Locatie biserica"
}, {label: "Alta locatie", value: "Alta locatie"}]
let locatiiPetrecere = [{label: "Locatie petrecere", value: "Locatie petrecere"}, {
    label: "Alta locatie",
    value: "Alta locatie"
}]

class EditEvent extends React.Component {
    state = {
        loading: false,
        showModal: false,
        prenumeMireasa: "",
        prenumeMire: "",
        dataEvent: "",
        eventType: "",
        eventId: "",
        eventDescription: "",
        backgroundImageLink: "",
        memoryboothWatermarkLink: "",
        backgroundImage: null,
        memoryboothWatermark: null,
        croppedImage: null,
        locationsTypes: [],

        codEvent: "",
        standardEventCode: "",

        locations: [],
        locationType: "",
        locationName: "",
        locationData: new Date(),
        locationDay: new Date(),
        locationAddress: "",

        memorybooth: {},
        questions: [],

        photosDisabled: false,
        individualPhotosDisabled: false,

        duration: 20,
        memoryBoothSpentTimeLimit: 720,

        devicePhotoCounter: false,
        deviceLoading: true,

        waitModal: false
    }

    async componentDidMount() {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

        if (info !== null) {

            let currentTimeSpent = info.data.currentTimeSpent

            let remainingTime = Math.round(((720 - currentTimeSpent) / 60) * 2) / 2


            this.setState({
                eventType: info.data.eventType,
                eventId: info.data.id,
                locationsTypes: info.data.eventType === "Nunta" ? locatiiNunta : info.data.eventType === "Botez" ? locatiiBotez : locatiiPetrecere,
                prenumeMireasa: info.data.prenumeMireasa,
                prenumeMire: info.data.prenumeMire,
                eventDescription: info.data.eventDescription,
                dataEvent: new Date(info.data.dataEvent),
                backgroundImageLink: info.data.backgroundImage !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(info.data.backgroundImage)}` : null,
                memoryboothWatermarkLink: info.data.memoryboothWatermark !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(info.data.memoryboothWatermark)}` : null,
                codEvent: info.data.id_eveniment,
                locations: info.data.locatii,
                duration: info.data.memorybooth_duration,
                memorybooth: info.data.memorybooth,
                memoryBoothSpentTimeLimit: info.data.memorybooth_spent_time_limit,
                remainingTime: remainingTime,
                questions: info.data.memorybooth && info.data.memorybooth.questions ? info.data.memorybooth.questions : [],
                standardEventCode: info.data.event ? info.data.event.id_eveniment : null,
                photosDisabled: info.data.memorybooth && info.data.memorybooth.photo && info.data.memorybooth.photo.templateDetails && info.data.memorybooth.photo.disabled ? true : false,
                individualPhotosDisabled: info.data.memorybooth && info.data.memorybooth.photo && info.data.memorybooth.photo.templateDetails && info.data.memorybooth.photo.individualDisabled ? true : false
            })
        }

        axios.get('http://localhost:8000/')
            .then(response => {
                this.setState({
                    devicePhotoCounter: parseInt(response.data.data.photosCounter),
                    deviceLoading: false
                })
            })
            .catch(err => {
                this.setState({
                    deviceLoading: false
                })
            })
    }

    resetDevicePhotoCounter = async () => {
        axios.get('http://localhost:8000/resetCounter')
            .then(response => {
                this.setState({
                    devicePhotoCounter: 0,
                })
            })
            .catch(err => {
            })
    }

    selectLocationType = (value) => {
        this.setState({
            locationType: value
        })
    }

    changeLocationName = (value) => {
        this.setState({
            locationName: value
        })
    }

    changeLocationDate = (value) => {
        this.setState({
            locationData: value
        })
    }

    changeLocationDay = (value) => {
        this.setState({
            locationDay: value
        })
    }

    changeEventDescription = (value) => {
        if (this.state.eventDescription.length > textarea_max_length - 1 && value.length > textarea_max_length - 1) {
            return
        }

        this.setState({
            eventDescription: value
        })
    }

    setLocationAddress = (value) => {
        this.setState({
            locationAddress: value.description
        })
    }

    addLocation = () => {
        let {locationAddress, locationData, locationDay, locationName} = this.state;

        if (this.state.locationType === "") {
            toast.error("Adaugati tipul sau numele locatiei")
            return
        }

        if (this.state.locationType === "Alta locatie" && locationName === "") {
            toast.error("Adaugati numele locatiei ")
            return
        }

        if (locationName === "") {
            locationName = this.state.locationType
        }

        if (locationData === "") {
            toast.error("Adaugati ora evenimentului ")
            return
        }

        if (locationDay === "") {
            toast.error("Adaugati ziua evenimentului ")
            return
        }

        if (locationAddress === "") {
            toast.error("Adaugati adresa evenimentului")
            return
        }

        let current = [...this.state.locations]

        geocodeByAddress(locationAddress)
            .then(results => {
                let objects = Object.keys(results[0].geometry.viewport)
                let pinx = parseFloat(results[0].geometry.viewport[objects[0]].g.toFixed(3))
                let piny = parseFloat(results[0].geometry.viewport[objects[1]].g.toFixed(3))

                current.push({
                    address: locationAddress,
                    pinx: pinx,
                    piny: piny,
                    time: locationData,
                    day: locationDay,
                    name: locationName
                })

                this.setState({
                    locations: current,
                    locationName: "",
                    locationAddress: "",
                    locationType: "",
                    locationData: new Date(),
                    showModal: false
                })
            })
    }

    removeLocation = (index) => {
        let newLocations = [...this.state.locations]

        newLocations.splice(index, 1)

        this.setState({
            locations: newLocations
        })
    }

    openModal = () => {
        this.setState({
            showModal: true
        })
    }

    closeModal = () => {
        this.setState({
            locationName: "",
            locationAddress: "",
            locationType: "",
            locationData: new Date(),
            showModal: false
        })
    }

    handleError = err => {
        console.error(err)
    }

    handleChange = (value, event) => {
        this.setState({
            [event.target.name]: value
        })
    }

    onChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    handlePoza = (fileList) => {
        if (fileList.length === 0) {
            this.setState({
                backgroundImage: null
            })
            return
        }

        if (fileList[0].blobFile.size > 7000000) {
            toast.error("Fisier prea mare, maxim 7MB")
        }

        this.setState({
            backgroundImage: [fileList[0].blobFile]
        })
    }

    handlePozaWatermark = (fileList) => {
        if (fileList.length === 0) {
            this.setState({
                memoryboothWatermark: null
            })
            return
        }

        if (fileList[0].blobFile.size > 7000000) {
            toast.error("Fisier prea mare, maxim 7MB")
        }

        this.setState({
            memoryboothWatermark: [fileList[0].blobFile]
        })
    }

    saveCroppedImage = (croppedImg) => {
        this.setState({
            croppedImage: croppedImg
        })
    }

    saveEvent = async () => {
        if (this.state.eventType === "" || this.state.prenumeMire === "" || this.state.dataEvent === "" || this.state.eventDescription === "") {
            toast.error("Nu ati completat toate campurile")
            return;
        }

        if (this.state.eventType === "Nunta" && this.state.prenumeMireasa === "") {
            toast.error("Nu ati completat toate campurile")
            return;
        }

        this.setState({
            waitModal: true
        })

        let memorybooth = this.state.memorybooth ? this.state.memorybooth : {}
        memorybooth.questions = this.state.questions

        if (memorybooth.photo) {
            memorybooth.photo.disabled = this.state.photosDisabled
            memorybooth.photo.individualDisabled = this.state.individualPhotosDisabled
        }

        let obj = {
            prenumeMireasa: this.state.eventType === "Nunta" ? this.state.prenumeMireasa : "Mireasa",
            prenumeMire: this.state.prenumeMire,
            dataEvent: this.state.dataEvent,
            eventDescription: this.state.eventDescription,
            locatii: this.state.locations,
            memorybooth_duration: this.state.duration,
            memorybooth: memorybooth,
            memorybooth_spent_time_limit: this.state.memoryBoothSpentTimeLimit
        }

        let auth = {
            headers: {
                "Content-Type": `multipart/form-data;`,
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        if (this.state.standardEventCode && this.state.standardEventCode.length > 0) {

            let eventCode = false

            //Check standard event
            await axios.post(process.env.REACT_APP_DOMAIN + 'getEvent', {id_eveniment: this.state.standardEventCode})
                .then(response => {
                    if (response.data && response.data.id) {
                        eventCode = response.data.id
                    }
                })
                .catch(err => {

                })

            if (eventCode) {
                obj.event = eventCode
            } else {
                toast.error('Codul evenimentului nu este valid!')
                this.setState({
                    waitModal: false
                })
                return;
            }

        }

        let formData = new FormData()
        formData.append("data", JSON.stringify(obj))

        if (this.state.backgroundImage !== null) {
            formData.append("files.backgroundImage", this.state.backgroundImage[0])
        }

        if (this.state.memoryboothWatermark !== null) {
            formData.append("files.memoryboothWatermark", this.state.memoryboothWatermark[0])
        }

        //if (this.state.backgroundImage !== null && this.state.croppedImage !== null) {
        //	formData.append("files.backgroundImage", this.state.croppedImage)
        //}

        axios.put(process.env.REACT_APP_DOMAIN + 'weddings/update/' + this.state.eventId, formData, auth)
            .then(response => {
                toast.success("Informațiile au fost actualizate!")
                this.props.history.push('/adminEventPage/' + this.props.match.params.id)
            })
            .catch(err => {
                toast.error("Eroare")
                this.setState({
                    waitModal: false
                })
            })
    }

    goBack = () => {
        this.props.history.go(-1)
    }

    handleStartDate = (value) => {
        this.setState({
            dataEvent: value.toISOString()
        })
    }

    addQuestion = () => {

        let newQuestions = [...this.state.questions]

        newQuestions.push("")

        this.setState({
            questions: newQuestions
        })

    }

    editQuestion = (index, value) => {

        let newQuestions = [...this.state.questions]

        newQuestions[index] = value

        this.setState({
            questions: newQuestions
        })

    }

    deleteQuestion = (index) => {

        let newQuestions = [...this.state.questions]

        delete (newQuestions[index])

        newQuestions = newQuestions.filter(function () {
            return true;
        });

        this.setState({
            questions: newQuestions
        })

    }


    render() {
        let locations = <div></div>

        if (this.state.locations.length > 0) {
            let columnStyle = {width: "30%"}
            locations = <div className="location">
                <div className="header">
                    <div style={columnStyle}>Nume Locatie</div>

                    <div style={columnStyle}>Adresa</div>

                    <div style={columnStyle}>Data</div>

                    <div>Sterge</div>
                </div>

                {this.state.locations.map((element, index) => {
                    let addr = element.address.split(',')[0]
                    let date = null;
                    let hour = null;

                    if (typeof element.time === "string") {
                        date = element.day
                        if (date === undefined) {
                            date = element.time.split("T")[0].split("-")
                        } else {
                            date = date.split("T")[0].split("-")
                        }

                        hour = element.time.split("T")[1].split(":")
                    } else {
                        date = element.day
                        if (date === undefined) {
                            date = new Date(element.time)
                            date = element.time.split("T")[0].split("-")
                        } else {
                            date = new Date(date)
                            date = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
                        }

                        hour = new Date(element.time)
                        hour = [hour.getHours(), hour.getMinutes()]
                    }

                    return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
                        <div style={columnStyle}> {element.name}</div>
                        <div style={columnStyle}> {addr.toString()} </div>
                        <div
                            style={columnStyle}> {date[2] + "-" + date[1] + "-" + date[0] + " " + hour[0].toString() + ":" + hour[1].toString()} </div>
                        <Icon style={{color: "red", fontSize: "30px"}} icon="times-circle"
                              onClick={() => this.removeLocation(index)}/>
                    </div>
                })}
            </div>
        }

        let customInputStyle = {
            margin: "10px 0px",
            backgroundColor: "#E5F7FE",
            borderRadius: "10px"
        }

        return (
            <div className="EditEvent-container">
                <HeaderWhite goBack={this.goBack}/>

                <div className="EditEvent-contents">
                    <h4 id="title"> Setari generale </h4>

                    <div className="input-zone">
                        {
                            this.state.eventType === "MemoryBooth" ?

                                <CustomInput value={this.state.prenumeMire}
                                             onChange={(value) => this.onChange(value, "prenumeMire")}
                                             style={customInputStyle}
                                             placeholder={"Nume eveniment"}
                                             icon={<Icon icon="user"/>}/>
                                :
                                <CustomInput value={this.state.prenumeMire}
                                             onChange={(value) => this.onChange(value, "prenumeMire")}
                                             style={customInputStyle}
                                             placeholder={this.state.eventType === "Nunta" ? "Prenume Mire" : this.state.eventType === "Botez" ? "Nume Copil" : "Nume Petrecere"}
                                             icon={<Icon icon="user"/>}/>
                        }

                        <div className="AddHours-date-picker">
                            <p>Va rog alegeti data evenimentului:</p>

                            <DatePicker
                                value={new Date(this.state.dataEvent)}
                                style={{width: "100%", borderRadius: "5px", backgroundColor: "#E5F7FE"}}
                                placement="bottomStart"
                                disabledDate={date => isBefore(date, new Date(new Date().setDate(new Date().getDate() - 1)))}
                                defaultOpen={false}
                                oneTap onSelect={this.handleStartDate}
                                appearance="subtle"
                                placeholder="Data eveniment"
                                locale={{
                                    sunday: 'Sa',
                                    monday: 'Lu',
                                    tuesday: 'Ma',
                                    wednesday: 'Mi',
                                    thursday: 'Jo',
                                    friday: 'Vi',
                                    saturday: 'Sa',
                                    ok: 'OK',
                                    today: 'Azi',
                                    yesterday: 'Ieri',
                                    hours: 'Ore',
                                    minutes: 'Minute',
                                    seconds: 'Secunde'
                                }}/>
                        </div>

                        <CustomInput value={this.state.standardEventCode}
                                     onChange={(value) => this.setState({
                                         standardEventCode: value
                                     })}
                                     style={{...customInputStyle, marginTop: '20px'}}
                                     placeholder={"Cod eveniment"}
                        />

                        <CustomInput value={this.state.eventDescription} onChange={this.changeEventDescription}
                                     style={{height: "200px"}}
                                     placeholder={`Descriere Eveniment ${this.state.eventDescription.length}/${textarea_max_length}`}
                                     componentClass={"textarea"} icon={<Icon icon="pencil"/>}/>


                        <div style={{display: 'flex', flexDirection: 'row'}}>

                            <div style={{textAlign: 'left'}}>
                                <label>Durata maximă inregistrare</label>
                                <Dropdown title={this.state.duration + ' secunde'} activeKey={this.state.duration}
                                          className="dropdown_scroll"
                                          style={{marginBottom: "20px", borderRadius: "5px", width: "100%"}}>

                                    <Dropdown.Item
                                        key={0}
                                        eventKey={10}
                                        onClick={() => this.setState({duration: 10})}>10</Dropdown.Item>

                                    <Dropdown.Item
                                        key={1}
                                        eventKey={15}
                                        onClick={() => this.setState({duration: 15})}>15</Dropdown.Item>

                                    <Dropdown.Item
                                        key={2}
                                        eventKey={20}
                                        onClick={() => this.setState({duration: 20})}>20</Dropdown.Item>

                                    <Dropdown.Item
                                        key={3}
                                        eventKey={25}
                                        onClick={() => this.setState({duration: 25})}>25</Dropdown.Item>

                                    <Dropdown.Item
                                        key={4}
                                        eventKey={30}
                                        onClick={() => this.setState({duration: 30})}>30</Dropdown.Item>

                                </Dropdown>
                            </div>
                            <div style={{textAlign: 'left'}}>
                                <label>Perioada functionare MemoryBooth</label>
                                <Dropdown
                                    title={(this.state.memoryBoothSpentTimeLimit > 0 ? this.state.memoryBoothSpentTimeLimit : 720) / 60 + ' ore'}
                                    activeKey={this.state.memoryBoothSpentTimeLimit}
                                    className="dropdown_scroll"
                                    style={{marginBottom: "20px", borderRadius: "5px", width: "100%"}}>

                                    <Dropdown.Item
                                        key={0}
                                        eventKey={60}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 60})}>1
                                        ora</Dropdown.Item>

                                    <Dropdown.Item
                                        key={1}
                                        eventKey={120}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 120})}>2
                                        ore</Dropdown.Item>

                                    <Dropdown.Item
                                        key={2}
                                        eventKey={180}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 180})}>3
                                        ore</Dropdown.Item>

                                    <Dropdown.Item
                                        key={3}
                                        eventKey={240}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 240})}>4
                                        ore</Dropdown.Item>

                                    <Dropdown.Item
                                        key={4}
                                        eventKey={300}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 300})}>5
                                        ore</Dropdown.Item>

                                    <Dropdown.Item
                                        key={5}
                                        eventKey={360}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 360})}>6
                                        ore</Dropdown.Item>

                                    <Dropdown.Item
                                        key={6}
                                        eventKey={420}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 420})}>7
                                        ore</Dropdown.Item>

                                    <Dropdown.Item
                                        key={7}
                                        eventKey={480}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 480})}>8
                                        ore</Dropdown.Item>

                                    <Dropdown.Item
                                        key={8}
                                        eventKey={540}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 540})}>9
                                        ore</Dropdown.Item>

                                    <Dropdown.Item
                                        key={9}
                                        eventKey={600}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 600})}>10
                                        ore</Dropdown.Item>

                                    <Dropdown.Item
                                        key={10}
                                        eventKey={660}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 660})}>11
                                        ore</Dropdown.Item>

                                    <Dropdown.Item
                                        key={11}
                                        eventKey={720}
                                        onClick={() => this.setState({memoryBoothSpentTimeLimit: 720})}>12
                                        ore</Dropdown.Item>
                                </Dropdown>
                            </div>

                            <div style={{textAlign: 'left'}}>
                                <label>Timp ramas</label>

                                <div style={{
                                    fontSize: '14px',
                                    lineHeight: '1.42857143',
                                    color: '#8e8e93',
                                    fontWeight: '400',
                                    marginTop: '8px'
                                }}>{this.state.remainingTime > 0 ? this.state.remainingTime : '0'} ore
                                </div>

                            </div>
                        </div>

                        {
                            this.state.memorybooth && this.state.memorybooth.photo && this.state.memorybooth.photo.templateDetails ?
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', flex:1}}>
                                        <div className="checkbox-content">
                                            <Checkbox onChange={() => {
                                                this.setState({
                                                    photosDisabled: !this.state.photosDisabled
                                                })
                                            }}
                                                      checked={this.state.photosDisabled}/>

                                            <p>Oprește printarea fotografiilor</p>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', flex:1}}>
                                        <div className="checkbox-content">
                                            <Checkbox onChange={() => {
                                                this.setState({
                                                    individualPhotosDisabled: !this.state.individualPhotosDisabled
                                                })
                                            }}
                                                      checked={this.state.individualPhotosDisabled}/>

                                            <p>Oprește fotografii individuale</p>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                        {
                            !this.state.deviceLoading && this.state.devicePhotoCounter !== false ?
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div style={{textAlign: 'left'}}><br/>
                                        Fotografii printate: {this.state.devicePhotoCounter} <br/>
                                        <button type={"button"} onClick={() => this.resetDevicePhotoCounter()}>
                                            Resetare
                                        </button>
                                    </div>
                                </div>
                                :
                                !this.state.deviceLoading && this.state.devicePhotoCounter === false ?
                                    <div style={{textAlign: 'left'}}><br/>Nu a fost indentificat dispozitivul!</div>
                                    : null

                        }

                        <p>Imagine de fundal</p>
                        {this.state.backgroundImageLink !== null
                            ? <img style={{padding: "5px", width: "300px", height: "300px", alignSelf: "center"}}
                                   src={this.state.backgroundImageLink} alt="" crossOrigin={"true"}/>
                            : <div>Nicio imagine adaugata</div>
                        }

                        <div style={customInputStyle}>
                            <Uploader onChange={this.handlePoza} accept=".jpg" style={{borderRadius: "5px"}}
                                      autoUpload={false} multiple={false}
                                      disabled={this.state.backgroundImage !== null}>
                                <div style={{color: "#221d5d", width: "100%", height: "100%"}} id="text">Click aici
                                    pentru a incarca alta fotografie de fundal
                                </div>
                            </Uploader>
                        </div>

                        <p>Watermark (Format .png)</p>
                        {this.state.memoryboothWatermarkLink !== null
                            ? <img style={{padding: "5px", width: "300px", height: "300px", alignSelf: "center"}}
                                   src={this.state.memoryboothWatermarkLink} alt="" crossOrigin={"true"}/>
                            : <div>Nicio imagine adaugata</div>
                        }

                        <div style={customInputStyle}>
                            <Uploader onChange={this.handlePozaWatermark} accept=".png" style={{borderRadius: "5px"}}
                                      autoUpload={false} multiple={false}
                                      disabled={this.state.memoryboothWatermark !== null}>
                                <div style={{color: "#221d5d", width: "100%", height: "100%"}} id="text">Click aici
                                    pentru a incarca alta imagine
                                </div>
                            </Uploader>
                        </div>

                        <div className={"questions-cont"}>
                            <p>Intrebari</p>

                            <div className='add-question' onClick={() => this.addQuestion()}>
                                <Icon icon="plus-circle" size="lg" style={{color: "#05AFF2", marginRight: "10px"}}/>
                                <div>Adauga intrebare</div>
                            </div>

                            {
                                this.state.questions && this.state.questions.map((question, i) => (
                                    <div className={"question-cont"} key={"q_" + i}>
                                        <div className={"title"}>
                                            <CustomInput value={this.state.questions[i]}
                                                         onChange={(value) => this.editQuestion(i, value)}
                                                         style={customInputStyle}
                                                         placeholder={"Intrebarea nr. " + (i + 1)}
                                                         icon={<Icon icon="pencil"/>}/>
                                        </div>

                                        <div className={"delete"} onClick={() => this.deleteQuestion(i)}>
                                            <div className={"button"}>Sterge</div>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>


                        {this.state.eventType !== "" && <div className="Locations" style={{margin: "10px 0px"}}>
                            <Modal classNames={{
                                overlay: 'modal-overlay',
                                modal: 'modal-add-location',
                                closeButton: 'modal-close-button'
                            }} open={this.state.showModal} onClose={this.closeModal}>
                                <div style={{margin: "10px 0px"}}>
                                    <div style={{marginTop: "20px", fontWeight: "bold"}}>Va rugam adaugati locatiile in
                                        ordine cronologica
                                    </div>

                                    <SelectPicker
                                        size="lg"
                                        onSelect={this.selectLocationType}
                                        value={this.state.locationType !== "Alta locatie" ? this.state.locationType : "Alta locatie"}
                                        searchable={false}
                                        appearance="subtle"
                                        placement="bottom"
                                        id="job-select"
                                        data={this.state.locationsTypes}
                                        style={{
                                            width: "100%",
                                            marginTop: "20px",
                                            borderRadius: "5px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                        placeholder="Selecteaza raspuns"
                                    >
                                    </SelectPicker>
                                </div>

                                <div style={{marginBottom: "10px"}}>
                                    {this.state.locationType === "Alta locatie"
                                        ? <CustomInput style={{
                                            height: "40px",
                                            width: "100%",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                                       value={this.state.locationName}
                                                       onChange={this.changeLocationName} placeholder={"Nume Locatie"}/>
                                        : null
                                    }
                                </div>

                                <div style={{margin: "10px 0px"}}>
                                    <DatePicker
                                        appearance="subtle"
                                        style={{
                                            width: "100%",
                                            borderRadius: "5px",
                                            height: "40px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                        ranges={[]}
                                        block
                                        locale={{
                                            sunday: 'Sa',
                                            monday: 'Lu',
                                            tuesday: 'Ma',
                                            wednesday: 'Mi',
                                            thursday: 'Jo',
                                            friday: 'Vi',
                                            saturday: 'Sa',
                                            ok: 'OK',
                                            today: 'Azi',
                                            yesterday: 'Ieri',
                                            hours: 'Ore',
                                            minutes: 'Minute',
                                            seconds: 'Secunde'
                                        }}
                                        placeholder="Ora eveniment"
                                        size="lg"
                                        onSelect={this.changeLocationDay}
                                        value={new Date(this.state.locationDay)}
                                        placement="bottomEnd"
                                    />
                                </div>

                                <div style={{marginBottom: "10px"}}>
                                    <DatePicker
                                        appearance="subtle"
                                        style={{
                                            width: "100%",
                                            borderRadius: "5px",
                                            height: "40px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                        ranges={[]}
                                        block
                                        hideMinutes={minute => minute % 5 !== 0}
                                        locale={{
                                            sunday: 'Sa',
                                            monday: 'Lu',
                                            tuesday: 'Ma',
                                            wednesday: 'Mi',
                                            thursday: 'Jo',
                                            friday: 'Vi',
                                            saturday: 'Sa',
                                            ok: 'OK',
                                            today: 'Azi',
                                            yesterday: 'Ieri',
                                            hours: 'Ore',
                                            minutes: 'Minute',
                                            seconds: 'Secunde'
                                        }}
                                        placeholder="Ora eveniment"
                                        size="lg"
                                        onSelect={this.changeLocationDate}
                                        value={new Date(this.state.locationData)}
                                        placement="bottomEnd"
                                        format="HH:mm"
                                    />
                                </div>

                                <div style={{width: "100%", marginBottom: "20px"}} id='fromGoogleAutocomplete'>
                                    <GooglePlacesAutocomplete
                                        currentLocation={false}
                                        placeholder={"Adresa"}
                                        onSelect={value => this.setLocationAddress(value)}
                                        autocompletionRequest={{
                                            componentRestrictions: {
                                                country: 'ro'
                                            }
                                        }}
                                        inputStyle={{
                                            width: "100%",
                                            padding: "5px",
                                            backgroundColor: "#E5F7FE",
                                            border: "0px",
                                            borderRadius: "5px",
                                            height: "40px"
                                        }}
                                    />
                                </div>

                                <BackAndConfirmButtons textBack={"Inapoi"} textConfirm={"Salveaza"}
                                                       functionBack={this.closeModal}
                                                       functionConfirm={this.addLocation}/>
                            </Modal>

                            {locations}
                        </div>}

                        <div className="Footer">
                            <Button onClick={this.goBack} className="back-button" style={{color: "#1E1C59"}}>
                                Inapoi
                            </Button>

                            <Button onClick={this.saveEvent} className="add-button"
                                    style={{backgroundColor: "#00afcc", color: "white"}}>
                                Salveaza
                            </Button>
                        </div>
                    </div>

                    <Modal classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal',
                        closeButton: 'modal-close-button',
                    }} open={this.state.waitModal} onClose={() => {
                    }} onEscKeyDown={() => {
                    }} onOverlayClick={() => {
                    }}>
                        <div>
                            <div style={{margin:"auto"}}>
                                <Loader color={'rgb(0,175,242)'}/>
                            </div>
                        </div>
                    </Modal>
                </div>

                <NavbarColor history={this.props.history}/>
            </div>
        )
    }
}

export default EditEvent;
