import React, {useState, useEffect} from 'react';
import './MemoryBooth.scss'
import CustomInput from "../../components/custom/CustomInput";
import {Icon} from "rsuite";
import {Link} from "react-router-dom";
import {useParams} from 'react-router'
import {useHistory} from "react-router-dom";
import {toast} from 'react-toastify'
import axios from "axios";
import HeaderWhite from "../../components/HeaderWhite";

export default function MemoryBoothPreviewFile() {

    const {id} = useParams();

    const [data, setData] = useState(false)


    const getFile = async () => {

        let info = await axios.get(process.env.REACT_APP_DOMAIN + 'memory-booth-files/get/previewFile/' + id);

        setData(info.data)

    }

    useEffect(() => {
        getFile()

        const intervalId = setInterval(() => {
            getFile()
        }, 5000)

        return () => clearInterval(intervalId);

    }, [])

    const getOs = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }


    return (<div>
        <div>

            <HeaderWhite shadow={true}/>
            <br/>
            {
                data ?
                data.needsProcessing && getOs() === 'iOS' ?
                    <div style={{marginTop:'2rem'}}>Testimonialul se proceseaza. <br/>Va rugam reveniti in cateva minute.</div>
                    :
                    data.url.includes('.gif') || data.url.includes('.png') || data.url.includes('.jpg') ?
                        <>
                        <img src={data.url} style={{width: '100%', maxWidth: '1000px', height: 'auto'}}
                             crossOrigin={"true"}/>
                            <div style={{textAlign:'center'}}>Pentru a descărca fotografia, vă rugăm să țineți apăsat pe aceasta.</div>
                        </>
                        :
                        <video
                            src={data.url}
                            width={'100%'}
                            style={{maxWidth: '900px', backgroundColor: '#000'}}
                            crossOrigin={"true"}
                            height={700}
                            controls={true}
                        />
                    :
                    <div style={{marginTop:'2rem'}}>Se incarca ...</div>
            }

        </div>

    </div>)
}

