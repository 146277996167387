import React from 'react';
import './TemplatePicker.scss'
import {photoTemplates} from "../../../../components/memorybooth/phototemplates";
import {BackButton, ConfirmButton} from "../../../../components/Helper";

export default function TemplatePicker({changeTemplate, selectedTemplate, resetTemplate}) {


    return (

            selectedTemplate > 0 ?
            <div>

                <BackButton
                    function={resetTemplate}
                    text={"Schimba sablonul"}
                />

            </div>
            :
            <>
                <h3>Alege sablonul</h3>
                <div className={"select-template-cont"}>
                    {
                        photoTemplates.map((template, i) => (
                            <div
                                className={"item " + (selectedTemplate === (i + 1) ? 'selected' : '')}
                                key={i}
                                onClick={() => changeTemplate((i + 1))}
                            >
                                <img
                                    src={"/images/memorybooth/phototemplates/" + (i + 1) + "/preview.jpg"}/>

                                <div className={"selected-mark"}>
                                    Selectat
                                </div>
                            </div>
                        ))
                    }
                </div>
            </>

    )
}

