import React from 'react'
import './AdminEventPage.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'

import MenuAdmin from './components/Menu/MenuAdmin'
import axios from 'axios'
import {toast} from 'react-toastify'

class AdminEventPage extends React.Component {
    state = {
        current_event: null,
        loading: true,
        hasNewResponses: false,
        activePage: "General",
        std_modal: false,
        inv_modal: false,
        general_modal: false
    }

    componentDidMount() {
        let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

        if (isAndroid) {
            let viewHeight = window.innerHeight
            let viewport = document.querySelector("meta[name=viewport]");
            viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
        }

        window.scrollTo(0, 0);

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
            .then(async response => {

                localStorage.setItem('memoryBoothTitle', response.data.prenumeMire);
                localStorage.setItem('memoryBoothDate', response.data.dataEvent);
                localStorage.setItem('memoryBoothDuration', response.data.memorybooth_duration);
                localStorage.setItem('memoryBoothData', JSON.stringify(response.data.memorybooth));

                if (response.data.event) {
                    localStorage.setItem('memoryBoothStandardEvent',
                        JSON.stringify({
                            'id': response.data.event.id,
                            'id_eveniment': response.data.event.id_eveniment,
                            'tip_eveniment': response.data.event.tip_eveniment,
                            'teachers': response.data.event.teachers
                        }))
                } else {
                    localStorage.removeItem('memoryBoothStandardEvent')
                }

                localStorage.setItem('memoryBoothBackgroundImage',
                    response.data.backgroundImage ? process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + response.data.backgroundImage.url
                        :
                        '/images/bg-memory-booth-transparent.jpg')


                this.setState({
                    current_event: response.data,
                    loading: false,
                })
            })
            .catch(err => {
                toast.error("A aparut o eroare in cautarea evenimentului, reincercati")
            })
    }

    moveTo = (where, active) => {
        if (where === "plan")
            this.props.history.push('/plan/' + this.props.match.params.id)

        if (where === "info")
            this.props.history.push('/info/' + this.props.match.params.id)

        if (where === "guests")
            this.props.history.push('/guests/' + this.props.match.params.id)

        if (where === "memorybooth")
            this.props.history.push('/memorybooth/main/' + this.props.match.params.id)

        if (where === "exports")
            this.props.history.push('/exports/' + this.props.match.params.id)

        if (where === "contact_us")
            this.props.history.push('/contact_us')

        if (where === "package")
            this.props.history.push('/package/' + this.props.match.params.id)

        if (where === "editEvent")
            this.props.history.push('/editEvent/' + this.props.match.params.id)

    }

    render() {
        if (this.state.loading) {
            return <p>Loading...</p>
        }

        let toRender = null;
        if (this.state.activePage === "General")
            toRender = <MenuAdmin moveTo={this.moveTo} id={this.props.match.params.id} event={this.state.current_event}
                                  seating={(this.props.location.state !== undefined) ? this.props.location.state.seating : null}
                                  hasNewResponses={this.state.hasNewResponses}/>

        return (
            <div className="AdminEventPage-container">
                <HeaderWhite shadow={true} goBack={() => this.props.history.push('/')}/>

                <div className="AdminEventPage-contents">
                    {toRender}
                </div>

                <NavbarColor history={this.props.history}/>
            </div>
        )
    }
}

export default AdminEventPage;