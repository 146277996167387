import React from "react";

export default function LiveStreamPlayer({stream, videoWidth, videoHeight}) {
    let videoPreviewRef = React.useRef();

    React.useEffect(() => {
        if (videoPreviewRef.current && stream) {
            videoPreviewRef.current.srcObject = stream;
        }
    }, [stream]);

    if (!stream) {
        console.log('No live stream')
        return null;
    }

    return  <video ref={videoPreviewRef} width={videoWidth} height={videoHeight} autoPlay/>
}
