import React from 'react'
import SectionRender from '../sectionRender'
import './template17.scss'

export default function template17Content(templateDetails = false) {

    return (
        <div className={"main-content"} >

            <div className={"background"}>
                <img src={templateDetails.settings[1].value} crossOrigin={"true"}/>
            </div>

            <div className={"photo-template-17 " + (templateDetails.live ? 'live' : '')}>

                <div className={"col-left"}>
                    <div className={"row-1"}>
                        <div className={"c-left"} style={{borderColor: templateDetails.settings[0].value}}>
                            <SectionRender
                                section={templateDetails.sections[0]}
                                preview={!templateDetails.live}
                            />
                        </div>
                    </div>

                    <div className={"row-2"}>
                        <div className={"c-left"} style={{borderColor: templateDetails.settings[0].value}}>
                            <SectionRender
                                section={templateDetails.sections[1]}
                                preview={!templateDetails.live}
                            />
                        </div>
                        <div className={"c-right"} style={{borderColor: templateDetails.settings[0].value}}>
                            <SectionRender
                                section={templateDetails.sections[2]}
                                preview={!templateDetails.live}
                            />
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}
