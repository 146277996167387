import React from 'react'
import { toast } from 'react-toastify'
import { Uploader } from 'rsuite'
import CustomInput from '../../../components/custom/CustomInput'
import { BackAndConfirmButtons } from '../../../components/Helper'
import './Gift.scss'

class AddGift extends React.Component {
	state = {
		title: "",
		description: "",
		link: "",
		picture: null,

		isSaving: false
	}

	changeData = (key, value) => {
		this.setState({
			[key]: value
		})
	}

	addPicture = (fileList) => {
		if (fileList.length === 0) {
			this.setState({
				picture: null
			})
			return
		}

		this.setState({
			picture: [fileList[0].blobFile]
		})
	}

	addGift = async () => {
		if (this.state.title === "") {
			toast.error("Trebuie sa introduceti un titlu");
			return
		}

		this.setState({
			isSaving: true
		})

		var gift = {
			event: this.props.eventId,
			title: this.state.title,
			description: this.state.description,
			link: this.state.link,
			boughtBy: "",
			idBoughtBy: ""
		}

		let picture = this.state.picture !== null ? this.state.picture[0] : null

		this.props.saveGift(gift, picture)
	}

	render() {
		var boldStyle = {
			fontWeight: "bold",
			fontSize: 20
		}

		var inputStyle = {
			marginTop: 10,
			marginBottom: 10,
			textAlign: 'center',
			width: '90%',
			maxWidth: "var(--container-max-width)",
			alignSelf: 'center',
			backgroundColor: "#E5F7FE",
			borderRadius: "10px"
		}

		return (
			<div className="Gift-contents">
				<p style={boldStyle}>Adauga Cadou</p>

				<CustomInput style={inputStyle} value={this.state.title} onChange={(value) => this.changeData("title", value)} placeholder={"Titlu"} />

				<CustomInput style={inputStyle} value={this.state.description} onChange={(value) => this.changeData("description", value)} placeholder={"Descriere"} />

				<CustomInput style={inputStyle} value={this.state.link} onChange={(value) => this.changeData("link", value)} placeholder={"Link"} />

				<Uploader onChange={this.addPicture} accept=".jpg" style={{ borderRadius: "5px", margin: "10px 0px" }} autoUpload={false} multiple={false} disabled={this.state.picture !== null}>
					<div style={{ color: "#221d5d", width: "100%", height: "100%" }} id="text">Click aici pentru a incarca fotografie</div>
				</Uploader>

				<BackAndConfirmButtons loading={this.state.isSaving} textBack={"Inapoi"} textConfirm={"Salveaza"} functionBack={this.props.back} functionConfirm={this.addGift} />
			</div>
		)
	}
}

export default AddGift;