import React from 'react'
import { Button } from "rsuite"
import "./Helper.scss"

import { MdZoomIn, MdZoomOut } from 'react-icons/md';

import SVG_INVITE_CUSTOM from '../assets/HelperSVG/invitatie-custom-icon.svg'
import SVG_INVITE_PERSONAL from '../assets/HelperSVG/invitatie-personala-icon.svg'
import SVG_INVITE_VIDEO from '../assets/HelperSVG/invitatie-video-icon.svg'

import Modal from 'react-responsive-modal'
// import {
// 	WhatsappShareButton,
// 	EmailShareButton
// } from "react-share";
// import whatsapp from '../assets/icons/whatsapp.svg'
// import messenger from '../assets/icons/messenger.svg'
// import gmail from '../assets/icons/gmail.svg'
// import CustomInput from './custom/CustomInput';
// import { MdPlayCircleOutline, MdStop } from 'react-icons/md';




export let textarea_max_length = 250

//param: image
export function getUrlFromImage(image) {
	if (image === null || image === undefined) return ""

	if (image.formats === null || image.formats === undefined) return image.url

	if (image.formats.medium !== undefined) return image.formats.medium.url

	if (image.formats.small !== undefined) return image.formats.small.url

	return image.url
}

//params: onClick function, button text
export class BackButton extends React.Component {
	render() {
		return <div className="bottom-buttons">
			<Button onClick={this.props.function} className="back-button" style={{ ...this.props.style, color: "#1E1C59" }}>
				{this.props.text}
			</Button>
		</div>
	}
}

//params: onClick function, button text
export class ConfirmButton extends React.Component {
	render() {
		return <div className="bottom-buttons">
			<Button onClick={this.props.function} className="add-button" style={{ ...this.props.style, backgroundColor: "#00afcc", color: "white" }}>
				{this.props.text}
			</Button>
		</div>
	}
}

//params: onClick function and text for back and confirm buttons
export class BackAndConfirmButtons extends React.Component {
	render() {
		// let hideConfirmButton = false;
		// if (this.props.hideConfirm !== null) {
		// 	hideConfirmButton = this.props.hideConfirm
		// }

		return <div className="bottom-buttons">
			<Button onClick={this.props.functionBack} className="back-button" style={{ ...this.props.styleBack, color: "#1E1C59" }}>
				{this.props.textBack}
			</Button>

			{/* {!hideConfirmButton &&  */}
			<Button loading={this.props.loading} onClick={this.props.functionConfirm} className="add-button" style={{ ...this.props.styleConfirm, backgroundColor: "#00afcc", color: "white" }}>
				{this.props.textConfirm}
			</Button>
			{/* // } */}
		</div>
	}
}

export class RedButton extends React.Component {
	render() {
		return <div className="bottom-buttons" style={{ margin: "0px auto" }}>
			<Button onClick={this.props.function} className="red-button" style={{ ...this.props.style, backgroundColor: "#F26157", color: "white" }}>
				{this.props.text}
			</Button>
		</div>
	}
}

export class LoadingScreen extends React.Component {
	render() {
		return <div>Loading data</div>
	}
}

// //params: link to be shared
// export class ShareModal extends React.Component {
// 	openFb = () => {
// 		window.open('fb-messenger://share?link=' + encodeURIComponent(process.env.REACT_APP_PAGE + "events/" + this.props.link + "/" + this.props.linkCategory) + '&app_id=' + encodeURIComponent("1437009096459536"));
// 	}

// 	render() {
// 		let size = "70px"
// 		let url = process.env.REACT_APP_PAGE + "events/" + this.props.link + "/" + this.props.linkCategory

// 		return (
// 			<Modal classNames={{
// 				overlay: 'modal-overlay',
// 				modal: 'helper-modal',
// 				closeButton: 'modal-close-button',
// 			}} open={this.props.showModal} onClose={this.props.closeModal} center>
// 				<div className="modal-content">
// 					<div style={{ marginTop: "10vh" }}>
// 						<div style={{ textAlign: "center", marginBottom: "40px" }}>Share la {this.props.link}</div>

// 						<div className="icons">
// 							<WhatsappShareButton url={url} >
// 								<img src={whatsapp} alt="" style={{ height: size, width: size }} />
// 							</WhatsappShareButton>

// 							<img onClick={this.openFb} src={messenger} alt="" style={{ height: size, width: size }} />

// 							<EmailShareButton url={url}>
// 								<img src={gmail} alt="" style={{ height: size, width: size }} />
// 							</EmailShareButton>
// 						</div>
// 					</div>
// 				</div>
// 			</Modal>
// 		)
// 	}
// }

// //params: questions, answer, changeAnswers function
// export class CustomQuestions extends React.Component {
// 	selectQuestionAnswer = (index, value) => {
// 		let newAnswers = [...this.props.answers]

// 		if (newAnswers[index] === value || newAnswers[index] === value.value) {
// 			newAnswers[index] = ""
// 		} else {
// 			newAnswers[index] = value
// 			if (value.value !== undefined) {
// 				newAnswers[index] = value.value
// 			}
// 		}

// 		this.props.changeAnswers(newAnswers)
// 	}

// 	render() {
// 		return <div>
// 			{this.props.questions.map((question, index) => {
// 				return <div key={index} style={{ margin: "20px 0px", textAlign: "left" }}>
// 					<h5>{question.title}</h5>
// 					{/* raspuns liber */}
// 					{question.type === 0 &&
// 						<CustomInput value={this.props.answers[index]} onChange={value => this.selectQuestionAnswer(index, value)} placeholder={"Raspuns liber"} />
// 					}

// 					{/* raspuns cu variante */}
// 					{question.type === 1 &&
// 						<CheckTree
// 							defaultExpandAll
// 							renderTreeIcon={null}
// 							cascade={true}
// 							data={question.options}
// 							onSelect={value => this.selectQuestionAnswer(index, value)}
// 							value={[this.props.answers[index]]}
// 						/>
// 					}
// 				</div>
// 			})}
// 		</div>
// 	}
// }

//params: link to audio to play
export class PlayAudio extends React.Component {
	render() {
		return <audio controls id={"audioMP3"} style={this.props.style}>
			<source src={this.props.link} type={"audio/mp3"} />
		</audio>
	}
}

//params: link to video to play
export class PlayVideo extends React.Component {
	render() {
		return <></>
	}
}

export class ShowPackageData extends React.Component {
	state = {
		title: this.props.packageData.package ? this.props.packageData.package.details.title : "Fara",
		limit: this.props.packageData.package ? this.props.packageData.package.details.limit : 5,
		totalCount: this.props.packageData.totalResponses,

		showModal: false
	}

	componentDidMount = () => {
		if (this.state.limit < this.state.totalCount) {
			this.setState({
				showModal: true
			})
		}
	}

	goToPacakge = () => {
		this.props.history.push('/package/' + this.props.eventId)
	}

	closeModal = () => {
		this.setState({
			showModal: false
		})
	}

	render() {
		return <Modal classNames={{
			overlay: 'modal-overlay',
			modal: 'helper-modal',
			closeButton: 'modal-close-button',
		}} open={this.state.showModal} onClose={this.closeModal} center>
			<div className="package-info">
				<div>Limita de raspunsuri a fost atinsa, primite {this.state.totalCount}, limita {this.state.limit}</div>

				<div>Pentru a vedea se restul raspunsurilor achizitionati urmatorul pachet</div>

				<Button onClick={this.goToPacakge} className="button">
					Catre pachete
				</Button>
			</div>
		</Modal>
	}
}

//params: cards (required)
//cards: [{data (required), color (required), onClick event (optional), svgIcon (optional)}, {...}]
export class DataCards extends React.Component {
	render() {
		return <div className="data-cards">
			{this.props.cards.map((card, index) => {
				return <div key={index} className="card" onClick={card.onClickEvent} style={{ color: card.color, border: `1px solid ${card.color}` }}>
					{card.data}
					{card.svgIcon !== undefined && <div style={{ display: "flex", marginLeft: "10px" }}><img src={card.svgIcon} alt="card" style={{ height: "20px" }} /></div>}
				</div>
			})}
		</div>
	}
}
/*
Example
<DataCards cards={[
	{
		data: `Buget Total: ${this.state.budget} ${usedCurrency}`,
		color: "#19B899",
		onClickEvent: this.modalChangeBudget,
		svgIcon: SVG_Budget_Edit
	},
	{
		data: `Total dar: ${this.state.totalGiftMoney} ${usedCurrency}`,
		color: "#731DD8"
	},
	{
		data: `Cheltuieli totale: ${this.state.totalSpending} ${usedCurrency}`,
		color: "#F26157"
	},
	{
		data: `Buget ramas: ${this.state.budget - this.state.totalSpending + this.state.totalGiftMoney} ${usedCurrency}`,
		color: "#E3B505"
	}
	]} />
*/

//params: columnNames (required, used in header), onRowClick (optional), dataArray (required), dataToTakeFromArray (required)
//columnNames: [{name: "" (required), width: "" (optional)}, {...}, ...]
//dataArray: [{...}, ...] - get data from here
//dataToTakeFromArray: ["title", "name", ...] - one element for each column
export class DataTable extends React.Component {
	render() {
		return <div className="data-table">
			<div className="header">
				{this.props.columnNames.map((data, index) => {
					return <div key={index} style={data.style}>{data.name}</div>
				})}
			</div>

			{this.props.dataArray.map((data, index) => {
				return <div key={index} onClick={() => this.props.onRowClick(index)} className={index % 2 === 0 ? "even-line" : "uneven-line"}>
					{this.props.dataToTakeFromArray.map((row, jIndex) => {
						return <div key={jIndex} style={this.props.columnNames[jIndex].style}>
							{data[row]}
						</div>
					})}
				</div>
			})}
		</div>
	}
}
/*
<DataTable
	columnNames={[{ name: "Cheltuieli", style: { width: "75%" } }, { name: "Cost", style: { width: "25%", textAlign: "right" } }]}
	onRowClick={this.modalChangeExpense}
	dataArray={this.state.expenses}
	dataToTakeFromArray={["title", "cost"]}
/>
*/

export class SelectInviteType extends React.Component {
	render() {
		return <div>
			{this.props.inviteType === ""
				? <div>
					<div className="Input_Type_Options">
						<div className="Input_Type_Option" onClick={_ => this.props.setInviteType("Personal")}>
							<div className='Circle'><img src={SVG_INVITE_PERSONAL} alt="Personal" style={{ height: "80px" }} /></div>
							<div>Incarca {this.props.text} creata</div>
						</div>

						<div className="Input_Type_Option" onClick={_ => this.props.setInviteType("Video")}>
							<div className='Circle'><img src={SVG_INVITE_VIDEO} alt="Video" style={{ height: "80px" }} /></div>
							<div>Incarca {this.props.text} video creata</div>
						</div>

						<div className="Input_Type_Option" onClick={_ => this.props.setInviteType("Custom")}>
							<div className='Circle'><img src={SVG_INVITE_CUSTOM} alt="Custom" style={{ height: "80px" }} /></div>
							<div>Creeaza {this.props.text} acum</div>
						</div>
					</div>

					<BackButton text={"Inapoi"} function={this.props.goBack} />
				</div>
				: <div>
					<div onClick={this.props.openModalChangeType} className="ChangeChoiceButton">
						Alege alt tip de invitatie
					</div>
				</div>}
		</div>
	}
}

/*
Params:
  imageLink - uploaded image link
  saveCroppedImage - function to save edited picture
*/
export class EditPicture extends React.Component {
	state = {
		scale: 1,
		src: this.props.imageLink,
		crop: {
			unit: '%',
			width: 30,
			aspect: 16 / 9
		},
		croppedImageBlob: null,
		croppedImageURL: null
	}

	onCropChange = (crop, percentCrop) => {
		this.setState({ crop });
	};

	onImageLoaded = (image) => {
		this.state.imageRef = image;
	};

	onCropComplete = (crop) => {
		this.makeClientCrop(crop);
	};

	async makeClientCrop(crop) {
		if (this.state.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
				this.state.imageRef,
				crop,
				'newFile.jpeg'
			);
			this.setState({ croppedImageUrl });
		}
	}

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement('canvas');
		const pixelRatio = window.devicePixelRatio;
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');

		canvas.width = crop.width * pixelRatio * scaleX;
		canvas.height = crop.height * pixelRatio * scaleY;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob(
				(blob) => {
					if (!blob) {
						//reject(new Error('Canvas is empty'));
						console.error('Canvas is empty');
						return;
					}

					this.props.saveCroppedImage(blob)
					blob.name = fileName;
					window.URL.revokeObjectURL(this.fileUrl);
					this.fileUrl = window.URL.createObjectURL(blob);
					resolve(this.fileUrl);
				},
				'image/jpeg',
				1
			);
		});
	}

	render() {
		const { crop, croppedImageUrl, src } = this.state;
		// console.log(croppedImageUrl);

		return <div className='Edit-picture-container'>


			<div>Modifica imaginea incarcata</div>

			{croppedImageUrl && (
				<img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
			)}
		</div>
	}
}

//const strings
export const usedCurrency = "lei";
export const maxScreenWidth = "700px";
