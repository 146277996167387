import React from 'react'
import { Icon, Checkbox } from 'rsuite';
import './Signup.scss'
import { IoMdArrowRoundBack } from 'react-icons/io';
import Fade from 'react-reveal/Fade';
// import { DatePicker } from 'rsuite';
import axios from 'axios';
import { Button } from 'rsuite';
import { toast } from 'react-toastify';
import TermsModal from './Modals/terms'
import ConfidModal from './Modals/confid'
import CustomInput from '../components/custom/CustomInput';

class Signup extends React.Component {
	state = {
		username: "",
		name: "",
		surname: "",
		email: "",
		password1: "",
		password2: "",
		showTermsModal: false,
		showConfidModal: false,
		checkedTerms: false,
		checkedConfid: false,

		pass1Valid: false,
		pass2Valid: false
	}

	toggleTermsModal = () => {
		let current = this.state.showTermsModal;
		this.setState({
			showTermsModal: !current
		})
	}

	toggleConfidModal = () => {
		let current = this.state.showConfidModal;

		this.setState({
			showConfidModal: !current
		})
	}

	displayErrors = (data) => {
		for (var key in data) {
			if (data.hasOwnProperty(key)) {
				toast.error(data[key][0]);
			}
		}
	}

	onChange = (value, key) => {
		if (key.startsWith("password")) {
			var isValid = true

			const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

			if (!passRegex.test(value)) {
				isValid = false
			}

			if (key.indexOf("1") !== -1) {
				this.setState({
					pass1Valid: isValid
				})
			} else {
				this.setState({
					pass2Valid: isValid
				})
			}
		}

		this.setState({
			[key]: value
		})
	}

	changeCheckedTermsState = () => {
		let state = this.state.checkedTerms

		this.setState({
			checkedTerms: !state
		})
	}

	changeCheckedConfidState = () => {
		let state = this.state.checkedConfid

		this.setState({
			checkedConfid: !state
		})
	}

	changeContext = () => {
		if (this.state.email === "" || this.state.password1 === "" || this.state.password2 === "" ||
			this.state.username === "") {
			toast.error("Nu ati completat toate campurile");
			return;
		}

		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!emailRegex.test(this.state.email.toLowerCase())) {
			toast.error("Adresa de email este invalida")

			if (this.state.email.indexOf(' ') >= 0) {
				toast.error("Adresa de email contine space")
			}

			return;
		}

		const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

		if (!passRegex.test(this.state.password1)) {
			toast.error("Parola este invalida")
			return
		}

		if (this.state.password1 !== this.state.password2) {
			toast.error("Parolele nu sunt identice")
			return;
		}

		if (this.state.name === "" || this.state.surname === "") {
			toast.error("Completati campurile nume, prenume. Vor fi folosite ca sa nu le introduceti la fiecare eveniment");
			return;
		}

		if (!this.state.checkedConfid || !this.state.checkedTerms) {
			toast.error("Nu ati luat la cunostinta termenii si conditiile / politica de confidentialitate")
			return;
		}

		let obj = {
			username: this.state.username + "|" + this.state.email.toLowerCase(),
			first_name: this.state.surname,
			last_name: this.state.name,
			email: this.state.email.toLowerCase(),
			password: this.state.password1,
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'auth/local/register', obj)
			.then((response) => {
				localStorage.setItem('id', response.data.user.id)

				toast.success("Contul a fost realizat cu succes!")

				//send mail confirmation request
				axios.post(process.env.REACT_APP_DOMAIN + 'auth/send-email-confirmation', { email: this.state.email.toLowerCase() })
					.then(_ => {
						toast.success("Te rog sa confirmi adresa de email")
					})

				this.props.history.push('/login');
			})
			.catch(err => {
				toast.error("Mail sau Username sunt luate")
			})
	}

	render() {
		return (
			<div className="Signup-container">
				<Fade cascade>
					<TermsModal toggleModal={this.toggleTermsModal} showModal={this.state.showTermsModal} />

					<ConfidModal toggleModal={this.toggleConfidModal} showModal={this.state.showConfidModal} />

					<div className="Signup-one">
						<IoMdArrowRoundBack onClick={() => this.props.history.go(-1)} style={{ fontSize: "20px" }} />

						<p> Introdu datele tale de identificare </p>
					</div>

					<div className="Signup-two">
						<CustomInput value={this.state.username} onChange={(value) => this.onChange(value, "username")} placeholder={"Username"} icon={<Icon icon="user" />} />

						<p>Numele si prenumele vor fi folosite pt participarea la evenimente. Pot fi schimbate din profil</p>

						<CustomInput value={this.state.name} onChange={(value) => this.onChange(value, "name")} placeholder={"Nume"} icon={<Icon icon="user" />} />

						<CustomInput value={this.state.surname} onChange={(value) => this.onChange(value, "surname")} placeholder={"Prenume"} icon={<Icon icon="user" />} />

						<CustomInput value={this.state.email} onChange={(value) => this.onChange(value, "email")} placeholder={"Adresa de email"} icon={<Icon icon="envelope" />}
							regex={/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/} />

						<p>Parola trebuie sa contina cel putin 8 caractere, printre care o litera mare, o litera mica, o cifra</p>

						<CustomInput value={this.state.password1} onChange={(value) => this.onChange(value, "password1")} placeholder={"Parola cont"} icon={<Icon icon="key" />}
							inputType={"password"} regex={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/} />

						<CustomInput value={this.state.password2} onChange={(value) => this.onChange(value, "password2")} placeholder={"Confirmare parola cont"} icon={<Icon icon="key" />}
							inputType={"password"} regex={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/} />

						{this.state.pass1Valid && this.state.pass2Valid && this.state.password1 !== this.state.password2 && <div style={{ color: "red" }}>
							Parolele nu sunt identice
						</div>}

						<div className="checkbox-content">
							<Checkbox onChange={this.changeCheckedTermsState} checked={this.state.checkedTerms} />

							<p> Sunt de acord cu <span onClick={this.toggleTermsModal} style={{ textDecoration: "underline" }}>Termenii si Conditiile</span> </p>
						</div>

						<div className="checkbox-content" >
							<Checkbox onChange={this.changeCheckedConfidState} checked={this.state.checkedConfid} />

							<p> Sunt de acord cu <span onClick={this.toggleConfidModal} style={{ textDecoration: "underline" }}> Politica de Confidentialitate</span> </p>
						</div>
					</div>

					<div className="Signup-three">
						<Button onClick={this.changeContext} className="Continue-button"> Continua </Button>

						<p id="disclaimer"> Prin apasarea acestui buton, confirmati ca ati luat la cunostinta termenii si conditiile ai LYRA NAPKIN S.R.L. </p>
					</div>
				</Fade>
			</div>
		)
	}
}

export default Signup;
