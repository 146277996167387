import React from 'react'
import './TemplateSettingsEdit.scss'
import {photoTemplatesSectionTypes} from "../../../../components/memorybooth/phototemplates";
import ImageUploadComponent from "../ImageUploadComponent";
export default function TemplateSettingsEdit({setting, updateValue, weddingId}) {


    return (<div className={"settings-edit-item"}>

            <div className={"title"}>{setting.title}</div>

            <div className={"content"}>

                {
                    setting.type === 'color' ?
                        <input type={"color"} value={setting.value} onChange={(e) => updateValue(setting.id, e.target.value)} />
                        :null
                }

                {
                    setting.type === 'text' ?
                        <input type={"text"} value={setting.value} onChange={(e) => updateValue(setting.id, e.target.value)} />
                        :null
                }

                {
                    setting.type === 'integer' ?
                        <div className={"field-integer"}>
                            <div className={"minus"} onClick={() => updateValue(setting.id, (parseInt(setting.value)-1) > -1 ? parseInt(setting.value)-1 : 0)}>-</div>
                            <div className={"current-value"}>{setting.value}</div>
                            <div className={"plus"} onClick={() => updateValue(setting.id, parseInt(setting.value)+1)}>+</div>
                        </div>
                        :null
                }

                {
                    setting.type === 'image' ?
                        <ImageUploadComponent
                            saveImageUrl={(url) => updateValue(setting.id, url)}
                            weddingId={weddingId}
                        />
                        :null
                }

            </div>

        </div>
    )
}