import Modal from "react-responsive-modal";
import React, {useEffect, useState} from "react";
import './SendToModal.scss'
import {toast} from "react-toastify";
import CustomInput from "../../../../components/custom/CustomInput";
import {Icon} from "rsuite";
import axios from "axios";

export default function SendToModal ({show, setShow, memoryBoothStandardEvent, setSendTo}) {


    const [type, setType] = useState(0)
    const [search, setSearch] = useState('')
    const [selectedEmail, setSelectedEmail] = useState('')
    const [selectedName, setSelectedName] = useState('')

    const [participants, setParticipants] = useState([])
    const [loadingParticipants, setLoadingParticipants] = useState(true)

    const [results, setResults] = useState([])

    useEffect(() => {
        axios.post(process.env.REACT_APP_DOMAIN + 'getPublicPaidParticipants', {id_eveniment: memoryBoothStandardEvent.id_eveniment})
            .then(response => {
                    setParticipants(response.data)
                    setLoadingParticipants(false)
            })
            .catch(err => {
            })
    }, [])

    useEffect(() => {

        if(search.length>0) {

            if (type === 1) {
                let res = memoryBoothStandardEvent.teachers.filter(o => o.name.toLowerCase().includes(search.toLowerCase()))
                setResults(res)
            }

            if (type === 0) {
                let res = participants.filter(o => o.name.toLowerCase().includes(search.toLowerCase()))
                setResults(res)
            }

        } else {
            if (type === 1) {
                setResults(memoryBoothStandardEvent.teachers)
            }

            if (type === 0) {
                setResults(participants)
            }
        }

    }, [search])

    useEffect(() => {
        if(type === 0) {
            setResults(participants)
        }
    }, [participants])

    const customInputStyle = {
        margin: "15px 0px 20px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    return (
        <Modal
            classNames={{
                overlay: 'modal-overlay',
                modal: 'modal',
                closeButton: 'modal-close-button',
            }}
            autoFocus={false}
            focusTrapped={false}
            open={show}
            onClose={() => setShow(false)}
            onEscKeyDown={() => {}}
            onOverlayClick={() => setShow(false)}>
            <div className={"SendToModal"}>


                <h4 style={{marginBottom: '25px'}}>Cui doresti sa trimiti ?</h4>
                <div className={"selector"}>
                    <div className={"item " + (type===0 ? 'active' : '')} onClick={() => {
                        setType(0)
                        setSearch('')
                        setResults(participants)
                        setSelectedEmail('')
                        setSelectedName('')
                    }}>
                        Coleg
                    </div>
                    <div className={"item " + (type===1 ? 'active' : '')} onClick={() => {
                        setType(1)
                        setSearch('')
                        setResults(memoryBoothStandardEvent.teachers)
                        setSelectedEmail('')
                        setSelectedName('')
                    }}>
                        Profesor
                    </div>
                </div>

                <CustomInput value={search}
                             onChange={setSearch}
                             style={customInputStyle}
                             placeholder={"Cautare ..."}
                             icon={<Icon icon="search" className={"search-icon"} />} />

                {
                    type === 0 ?
                        <div className={"users-cont"}>

                            {
                                loadingParticipants ?
                                    <div>Se incarca ...</div>
                                    :
                                    results && results.map((participant, i) => (
                                        <div key={i}
                                             className={"item " + (participant.email === selectedEmail ? 'active' : '')}
                                             onClick={() => {
                                                 setSelectedEmail(participant.email)
                                                 setSelectedName(participant.name)
                                             }}>
                                            {participant.name}
                                        </div>
                                    ))
                            }

                        </div>
                        : null
                }

                {
                    type === 1 ?
                        <div className={"users-cont"}>
                            {
                                results && results.map((teacher,i) => (
                                    <div key={i} className={"item " + (teacher.email === selectedEmail ? 'active' : '')}
                                         onClick={() => {
                                             setSelectedEmail(teacher.email)
                                             setSelectedName(teacher.name)
                                         }}>
                                        {teacher.name}
                                    </div>
                                ))
                            }
                        </div>
                        : null
                }

                <div className={"footer"}>
                <button
                    type={"button"}
                    className={"confirm-button"}
                    disabled={selectedEmail.length === 0}
                    onClick={() => {
                        setSendTo(selectedEmail, selectedName)
                        setShow(false)
                    }}
                >
                    Confirma
                </button>
                </div>

            </div>
        </Modal>
    )
}