import React from 'react'
import './AddEvent.scss'
import HeaderWhiteBack from '../components/HeaderWhiteBack'
import { Uploader, Button, SelectPicker, Icon, DatePicker } from 'rsuite'
import Modal from "react-responsive-modal";
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';

import NavbarColor from '../components/NavbarColor'
import axios from 'axios'
import { toast } from 'react-toastify'
import isBefore from 'date-fns/isBefore'
import CustomInput from '../components/custom/CustomInput';
import { BackAndConfirmButtons, EditPicture, textarea_max_length } from '../components/Helper';

import SVG_Nunta from '../assets/EventsTypes/nunta-icon.svg'
import SVG_Botez from '../assets/EventsTypes/botez-icon.svg'
import SVG_Petrecere from '../assets/EventsTypes/petrecere-icon.svg'
import HeaderWhite from "../components/HeaderWhite";

let locatiiNunta = [{ label: "Locatie petrecere", value: "Locatie petrecere" }, { label: "Locatie biserica", value: "Locatie biserica" }, { label: "Locatie cununie", value: "Locatie cununie" }, { label: "Alta locatie", value: "Alta locatie" }]
let locatiiBotez = [{ label: "Locatie petrecere", value: "Locatie petrecere" }, { label: "Locatie biserica", value: "Locatie biserica" }, { label: "Alta locatie", value: "Alta locatie" }]
let locatiiPetrecere = [{ label: "Locatie petrecere", value: "Locatie petrecere" }, { label: "Alta locatie", value: "Alta locatie" }]

class AddEvent extends React.Component {
	state = {
		loading: false,
		showModal: false,
		prenumeMireasa: "",
		prenumeMire: "",
		numeCopil: "",
		numePetrecere: "",
		dataEvent: new Date(),
		eventType: "",
		eventDescription: "",
		backgroundImage: null,
		croppedImage: null,
		locationsTypes: [],

		locations: [],
		locationType: "",
		locationName: "",
		locationData: new Date(),
		locationDay: new Date(),
		locationAddress: "",
		base64ImageLink: "",

		waitModal: false
	}

	handleJobSelect = (value) => {
		let locationsTypes = locatiiNunta

		if (value === "Botez") {
			locationsTypes = locatiiBotez
		}

		if (value === "Petrecere") {
			locationsTypes = locatiiPetrecere
		}

		this.setState({
			eventType: value,
			locationsTypes: locationsTypes
		})
	}

	selectLocationType = (value) => {
		this.setState({
			locationType: value
		})
	}

	changeLocationName = (value) => {
		this.setState({
			locationName: value
		})
	}

	changeLocationDate = (value) => {
		this.setState({
			locationData: value
		})
	}

	changeLocationDay = (value) => {
		this.setState({
			locationDay: value
		})
	}

	changeEventDescription = (value) => {
		if (this.state.eventDescription.length > textarea_max_length - 1 && value.length > textarea_max_length - 1) {
			return
		}

		this.setState({
			eventDescription: value
		})
	}

	setLocationAddress = (value) => {
		this.setState({
			locationAddress: value.description
		})
	}

	addLocation = () => {
		let { locationAddress, locationData, locationDay, locationName } = this.state;

		if (this.state.locationType === "") {
			toast.error("Adaugati tipul sau numele locatiei")
			return
		}

		if (this.state.locationType === "Alta locatie" && locationName === "") {
			toast.error("Adaugati numele locatiei ")
			return
		}

		if (locationName === "") {
			locationName = this.state.locationType
		}

		if (locationData === "") {
			toast.error("Adaugati ora evenimentului ")
			return
		}

		if (locationDay === "") {
			toast.error("Adaugati ziua evenimentului ")
			return
		}

		if (locationAddress === "") {
			toast.error("Adaugati adresa evenimentului")
			return
		}

		let current = [...this.state.locations]

		geocodeByAddress(locationAddress)
			.then(results => {
				let objects = Object.keys(results[0].geometry.viewport)
				let pinx = parseFloat(results[0].geometry.viewport[objects[0]].g.toFixed(3))
				let piny = parseFloat(results[0].geometry.viewport[objects[1]].g.toFixed(3))

				current.push({
					address: locationAddress,
					pinx: pinx,
					piny: piny,
					time: locationData,
					day: locationDay,
					name: locationName
				})

				this.setState({
					locations: current,
					locationName: "",
					locationAddress: "",
					locationType: "",
					locationData: new Date(),
					showModal: false
				})
			})
	}

	removeLocation = (index) => {
		let newLocations = [...this.state.locations]

		newLocations.splice(index, 1)

		this.setState({
			locations: newLocations
		})
	}

	openModal = () => {
		this.setState({
			showModal: true
		})
	}

	closeModal = () => {
		this.setState({
			locationName: "",
			locationAddress: "",
			locationType: "",
			locationData: new Date(),
			showModal: false
		})
	}

	handleError = err => {
		console.error(err)
	}

	onChange = (value, key) => {
		this.setState({
			[key]: value
		})
	}

	handlePoza = (fileList) => {
		if (fileList.length === 0) {
			this.setState({
				backgroundImage: null
			})
			return
		}

		this.setState({
			backgroundImage: [fileList[0].blobFile]
		})
	}

	saveCroppedImage = (croppedImg) => {
		this.setState({
			croppedImage: croppedImg
		})
	}

	moveToMarketplace = () => {
		this.props.history.push('/marketplace');
	}

	createEvent = async () => {
		if (this.state.eventType === "" || this.state.dataEvent === "" || this.state.eventDescription === "") {
			toast.error("Nu ati completat toate campurile")
			return;
		}

		if (this.state.eventType === "Nunta") {
			if (this.state.prenumeMireasa === "" || this.state.prenumeMire === "") {
				toast.error("Nu ati completat toate campurile")
				return;
			}
		}

		if (this.state.eventType === "Botez") {
			if (this.state.numeCopil === "") {
				toast.error("Nu ati completat toate campurile")
				return;
			}
		}

		if (this.state.eventType === "Petrecere") {
			if (this.state.numePetrecere === "") {
				toast.error("Nu ati completat toate campurile")
				return;
			}
		}

		this.setState({
			waitModal: true
		})

		let customQuestions = [
			{
				type: 1,
				title: "Ce meniu doriti?",
				options: ["Normal", "Vegetarian", "Vegan"]
			},
			{
				type: 1,
				title: "Ce meniu doreste insotitorul?(Daca veniti insotit)",
				options: ["Normal", "Vegetarian", "Vegan"]
			},
			{
				type: 1,
				title: "Aveti nevoie de cazare?",
				options: ["Da", "Nu"]
			},
			{
				type: 0,
				title: "Doriti cu cineva anume la masa?",
				options: []
			},
			{
				type: 0,
				title: "Nu doriti cu cineva anume la masa?",
				options: []
			},
		]

		let numeM = this.state.prenumeMire

		if (this.state.eventType === "Botez") {
			numeM = this.state.numeCopil
		}

		if (this.state.eventType === "Petrecere" || this.state.eventType === "MemoryBooth") {
			numeM = this.state.numePetrecere
			customQuestions = []
		}

		let obj = {
			user: localStorage.getItem('id'),
			eventType: this.state.eventType,
			prenumeMireasa: this.state.eventType === "Nunta" ? this.state.prenumeMireasa : "Mireasa",
			prenumeMire: numeM,
			dataEvent: this.state.dataEvent,
			eventDescription: this.state.eventDescription,
			locatii: this.state.locations,
			generalInfo: true,
			budget: 0,
			questions: customQuestions
		}

		let formData = new FormData()
		formData.append("data", JSON.stringify(obj))

		// if (this.state.backgroundImage !== null) {
		// 	formData.append("files.backgroundImage", this.state.backgroundImage[0])
		// }

		if (this.state.backgroundImage !== null && this.state.croppedImage !== null) {
			formData.append("files.backgroundImage", this.state.croppedImage)
		}

		let auth = {
			headers: {
				"Content-Type": `multipart/form-data;`,
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}


		axios.post(process.env.REACT_APP_DOMAIN + 'weddings', formData, auth)
			.then(async response => {
				toast.success("Event creat cu succes!")


				this.props.history.push('/viewEvents/' + localStorage.getItem('id'))
			})
			.catch(err => {
				toast.error("Eroare")
				this.setState({
					waitModal: false
				})
			})
	}

	handleStartDate = (value) => {
		this.setState({
			dataEvent: value.toISOString()
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.eventType === "") {
			let svgHeight = "120px"

			return (
				<div className="AddEvent-container font-family-poppins-300">
					<HeaderWhite />

					<div className="AddEvent-contents">
						<h4 className="Title"> Creeaza eveniment </h4>

						<div className="Input_Center">
							<div className="Input_Type_Options">
								<div className="Input_Type_Option" onClick={_ => this.handleJobSelect("MemoryBooth")}>
									<div className='Circle'><img src={SVG_Petrecere} alt="MemoryBooth" style={{ height: svgHeight }} /></div>
									<div>MemoryBooth</div>
								</div>
							</div>
						</div>
					</div>

					<NavbarColor history={this.props.history} active={"Add"} />
				</div>
			)
		}

		let locations = <div></div>

		if (this.state.locations.length > 0) {
			let columnStyle = { width: "30%" }
			locations = <div className="location">
				<div className="header">
					<div style={columnStyle}>Nume Locatie</div>

					<div style={columnStyle}>Adresa</div>

					<div style={columnStyle}>Data</div>

					<div>Sterge</div>
				</div>

				{this.state.locations.map((element, index) => {
					let addr = element.address.split(',')[0]
					let date = null;
					let hour = null;

					if (typeof element.time === "string") {
						date = element.day
						if (date === undefined) {
							date = element.time.split("T")[0].split("-")
						} else {
							date = date.split("T")[0].split("-")
						}

						hour = element.time.split("T")[1].split(":")
					} else {
						date = element.day
						if (date === undefined) {
							date = new Date(element.time)
							date = element.time.split("T")[0].split("-")
						} else {
							date = new Date(date)
							date = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
						}

						hour = new Date(element.time)
						hour = [hour.getHours(), hour.getMinutes()]
					}

					return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
						<div style={columnStyle}> {element.name}</div>
						<div style={columnStyle}> {addr.toString()} </div>
						<div style={columnStyle}> {date[2] + "-" + date[1] + "-" + date[0] + " " + hour[0].toString() + ":" + hour[1].toString()} </div>
						<Icon style={{ color: "red", fontSize: "30px" }} icon="times-circle" onClick={() => this.removeLocation(index)} />
					</div>
				})}
			</div>
		}

		let customInputStyle = {
			margin: "10px 0px",
			backgroundColor: "#E5F7FE",
			borderRadius: "10px"
		}

		return (
			<div className="AddEvent-container font-family-poppins-300">
				<HeaderWhite />

				<div className="AddEvent-contents">
					<h4 className="Title"> Creeaza eveniment </h4>

					<div className="input-zone">
						{/* <InputGroup className="AddJob-Container" inside style={{ backgroundColor: "#E5F7FE" }}>
							<InputGroup.Addon className="AddJob-Icon">
								<Icon icon="suitcase" />
							</InputGroup.Addon>

							<SelectPicker
								onSelect={this.handleJobSelect}
								searchable={false}
								id="job-select"
								value={this.state.eventType}
								appearance="subtle"
								data={[{ label: "Nunta", value: "Nunta" }, { label: "Botez", value: "Botez" }, { label: "Petrecere", value: "Petrecere" }]}
								style={{ width: "100%", paddingLeft: "25px", borderRadius: "5px", fontWeight: "700" }}
								placeholder="Selecteaza tipul de eveniment" >
							</SelectPicker>
						</InputGroup> */}
						{/* <div style={{ display: "flex", flexDirection: "row" }}>
							<p style={{ color: "blue", marginRight: "5px" }} onClick={() => { this.setState({ eventType: "" }) }}>
								Click aici
							</p>
							pentru a schimba tipul evenimentului
						</div> */}

						{this.state.eventType === "Nunta" &&
							<CustomInput value={this.state.prenumeMireasa} onChange={(value) => this.onChange(value, "prenumeMireasa")} style={customInputStyle} placeholder={"Prenume Mireasa"} icon={<Icon icon="user" />} />
						}

						{this.state.eventType === "Nunta" &&
							<CustomInput value={this.state.prenumeMire} onChange={(value) => this.onChange(value, "prenumeMire")} style={customInputStyle} placeholder={"Prenume Mire"} icon={<Icon icon="user" />} />
						}

						{this.state.eventType === "Botez" &&
							<CustomInput value={this.state.numeCopil} onChange={(value) => this.onChange(value, "numeCopil")} style={customInputStyle} placeholder={"Nume Copil"} icon={<Icon icon="user" />} />
						}

						{this.state.eventType === "Petrecere" &&
							<CustomInput value={this.state.numePetrecere} onChange={(value) => this.onChange(value, "numePetrecere")} style={customInputStyle} placeholder={"Nume Petrecere"} icon={<Icon icon="user" />} />
						}

						{this.state.eventType === "MemoryBooth" &&
						<CustomInput value={this.state.numePetrecere} onChange={(value) => this.onChange(value, "numePetrecere")} style={customInputStyle} placeholder={"Nume eveniment"} icon={<Icon icon="user" />} />
						}

						{this.state.eventType !== "" &&
							<div className="AddHours-date-picker">
								<p style={{ textAlign: "left" }}>Va rog alegeti data evenimentului: </p>

								<DatePicker ranges={[]} value={new Date(this.state.dataEvent)} style={{ width: "100%", borderRadius: "5px", backgroundColor: "#E5F7FE" }}
									placement="bottomStart" disabledDate={date => isBefore(date, new Date(new Date().setDate(new Date().getDate() - 1)))} defaultOpen={false} oneTap onSelect={this.handleStartDate} appearance="subtle" placeholder="Data eveniment"
									locale={{
										sunday: 'Sa',
										monday: 'Lu',
										tuesday: 'Ma',
										wednesday: 'Mi',
										thursday: 'Jo',
										friday: 'Vi',
										saturday: 'Sa',
										ok: 'OK',
										today: 'Azi',
										yesterday: 'Ieri',
										hours: 'Ore',
										minutes: 'Minute',
										seconds: 'Secunde'
									}}
								/>
							</div>}

						{this.state.eventType !== "" &&
							<CustomInput value={this.state.eventDescription} onChange={this.changeEventDescription} icon={<Icon icon="pencil" />}
								placeholder={`Descriere Eveniment ${this.state.eventDescription.length}/${textarea_max_length}`} componentClass={"textarea"} />}

						{this.state.eventType !== "" && <div style={{ margin: "10px 0px" }}>
							<Uploader onChange={this.handlePoza} accept=".jpg" style={{ borderRadius: "5px" }} autoUpload={false} multiple={false} disabled={this.state.backgroundImage !== null}>
								<div style={{ color: "#221d5d", width: "100%", height: "100%" }} id="text">Click aici pentru a incarca fotografia de fundal</div>
							</Uploader>
						</div>}

						{this.state.backgroundImage !== null && <EditPicture imageLink={URL.createObjectURL(this.state.backgroundImage[0])} saveCroppedImage={this.saveCroppedImage} />}

						{this.state.eventType !== "" && <div className="Locations" style={{ margin: "10px 0px" }}>
							<Modal classNames={{
								overlay: 'modal-overlay',
								modal: 'modal-add-location',
								closeButton: 'modal-close-button'
							}} open={this.state.showModal} onClose={this.closeModal}>
								<div style={{ margin: "10px 0px" }}>
									<div style={{ marginTop: "20px", fontWeight: "bold" }}>Va rugam adaugati locatiile in ordine cronologica</div>

									<SelectPicker
										size="lg"
										onSelect={this.selectLocationType}
										value={this.state.locationType !== "Alta locatie" ? this.state.locationType : "Alta locatie"}
										searchable={false}
										appearance="subtle"
										placement="bottom"
										id="job-select"
										data={this.state.locationsTypes}
										style={{ width: "100%", marginTop: "20px", borderRadius: "5px", backgroundColor: "#E5F7FE" }}
										placeholder="Selecteaza locatie"
									>
									</SelectPicker>
								</div>

								<div style={{ margin: "10px 0px" }}>
									{this.state.locationType === "Alta locatie"
										? <CustomInput style={{ height: "40px", width: "100%", borderRadius: "5px", padding: "5px", backgroundColor: "#E5F7FE" }}
											value={this.state.locationName} onChange={this.changeLocationName} placeholder={"Nume Locatie"} />
										: null
									}
								</div>

								<div style={{ margin: "10px 0px" }}>
									<DatePicker
										appearance="subtle"
										style={{ width: "100%", borderRadius: "5px", height: "40px", backgroundColor: "#E5F7FE" }}
										ranges={[]}
										block
										hideMinutes={minute => minute % 5 !== 0}
										locale={{
											sunday: 'Sa',
											monday: 'Lu',
											tuesday: 'Ma',
											wednesday: 'Mi',
											thursday: 'Jo',
											friday: 'Vi',
											saturday: 'Sa',
											ok: 'OK',
											today: 'Azi',
											yesterday: 'Ieri',
											hours: 'Ore',
											minutes: 'Minute',
											seconds: 'Secunde'
										}}
										placeholder="Ora eveniment"
										size="lg"
										onSelect={this.changeLocationDay}
										value={new Date(this.state.locationDay)}
										placement="bottomEnd"
									/>
								</div>

								<div style={{ margin: "10px 0px" }}>
									<DatePicker
										appearance="subtle"
										style={{ width: "100%", borderRadius: "5px", height: "40px", backgroundColor: "#E5F7FE" }}
										ranges={[]}
										block
										hideMinutes={minute => minute % 5 !== 0}
										locale={{
											sunday: 'Sa',
											monday: 'Lu',
											tuesday: 'Ma',
											wednesday: 'Mi',
											thursday: 'Jo',
											friday: 'Vi',
											saturday: 'Sa',
											ok: 'OK',
											today: 'Azi',
											yesterday: 'Ieri',
											hours: 'Ore',
											minutes: 'Minute',
											seconds: 'Secunde'
										}}
										placeholder="Ora eveniment"
										size="lg"
										onSelect={this.changeLocationDate}
										value={new Date(this.state.locationData)}
										placement="bottomEnd" format="HH:mm"
									/>
								</div>

								<div style={{ width: "100%", margin: "10px 0px" }} id='fromGoogleAutocomplete'>
									<GooglePlacesAutocomplete
										currentLocation={false}
										placeholder={"Adresa"}
										onSelect={value => this.setLocationAddress(value)}
										autocompletionRequest={{
											componentRestrictions: {
												country: 'ro'
											}
										}}
										inputStyle={{
											width: "100%",
											padding: "5px",
											backgroundColor: "#E5F7FE",
											border: "0px",
											borderRadius: "5px",
											height: "40px"
										}}
									/>
								</div>

								<BackAndConfirmButtons textBack={"Inapoi"} textConfirm={"Salveaza"} functionBack={this.closeModal} functionConfirm={this.addLocation} />
							</Modal>

							{locations}
						</div>}

						{this.state.eventType !== "" &&
							<BackAndConfirmButtons textBack={"Inapoi"} textConfirm={"Confirm"} functionBack={() => { this.setState({ eventType: "" }) }} functionConfirm={this.createEvent} />
						}
					</div>
				</div>

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.waitModal} onClose={() => { }} onEscKeyDown={() => { }} onOverlayClick={() => { }}>
					<div>
						<p>Please wait</p>
					</div>
				</Modal>

				<NavbarColor history={this.props.history} active={"Add"} />
			</div>
		)
	}
}

export default AddEvent;
