import React from 'react'
import { toast } from 'react-toastify'
import { Uploader } from 'rsuite'
import CustomInput from '../../../components/custom/CustomInput'
import { BackAndConfirmButtons, RedButton } from '../../../components/Helper'
import './Gift.scss'

class ChangeGift extends React.Component {
	state = {
		title: this.props.gift.title,
		description: this.props.gift.description,
		link: this.props.gift.link,
		picture: null,
		pictureURL: this.props.gift.picture,
		boughtBy: this.props.gift.boughtBy,

		confirmDelete: false
	}

	changeData = (key, value) => {
		if (key === "title" && value.length > 30) {
			toast.error("Maxim 50 caractere la titlu")
			return
		}

		this.setState({
			[key]: value
		})
	}

	addPicture = (fileList) => {
		if (fileList.length === 0) {
			this.setState({
				picture: null
			})
			return
		}

		this.setState({
			picture: [fileList[0].blobFile]
		})
	}

	changeGift = async () => {
		if (this.state.title === "") {
			toast.error("Trebuie sa introduceti un titlu");
			return
		}

		var gift = {
			_id: this.props.gift._id,
			title: this.state.title,
			description: this.state.description,
			link: this.state.link,
			boughtBy: this.state.boughtBy
		}

		let picture = this.state.picture !== null ? this.state.picture[0] : null

		this.props.changeGift(gift, picture)
	}

	render() {
		var boldStyle = {
			fontWeight: "bold",
			fontSize: 20
		}

		var inputStyle = {
			marginTop: 10,
			marginBottom: 10,
			textAlign: 'center',
			width: '90%',
			maxWidth: "var(--container-max-width)",
			alignSelf: 'center',
			backgroundColor: "#E5F7FE",
			borderRadius: "10px"
		}

		return (
			<div className="Gift-contents">
				<p style={boldStyle}>Modifica detalii cadou</p>

				<CustomInput style={inputStyle} value={this.state.title} onChange={(value) => this.changeData("title", value)} placeholder={"Titlu"} />

				<CustomInput style={inputStyle} value={this.state.description} onChange={(value) => this.changeData("description", value)} placeholder={"Descriere"} />

				<CustomInput style={inputStyle} value={this.state.link} onChange={(value) => this.changeData("link", value)} placeholder={"Link"} />

				{this.state.pictureURL !== null && <div>
					<p style={boldStyle}>Poza:</p>
					<img style={{ padding: "5px", width: "300px", height: "300px", alignSelf: "center" }} src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.pictureURL.url}`} alt="Imagine Cadou" />
				</div>}

				<Uploader onChange={this.addPicture} accept=".jpg" style={{ borderRadius: "5px", margin: "10px 0px" }} autoUpload={false} multiple={false} disabled={this.state.picture !== null}>
					<div style={{ color: "#221d5d", width: "100%", height: "100%" }} id="text">{this.state.pictureURL !== undefined ? "Click aici pentru a incarca fotografia" : "Adauga poza"}</div>
				</Uploader>

				<div className="Buttons-column">
					<RedButton style={{marginBottom: "10px"}} text={"Sterge"} function={() => this.setState({ confirmDelete: true })} />

					{this.state.confirmDelete && <RedButton function={this.props.deleteGift} text={"Confirma stergerea"} />}
				</div>

				<BackAndConfirmButtons textBack={"Inapoi"} textConfirm={"Salveaza"} functionBack={this.props.back} functionConfirm={this.changeGift} />
			</div>
		)
	}
}

export default ChangeGift;