import React, {useState, useEffect} from 'react'
import './Administrators.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import {Uploader, Button, SelectPicker, Icon, DatePicker} from 'rsuite'
import Modal from "react-responsive-modal";
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';

import NavbarColor from '../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import isBefore from 'date-fns/isBefore'
import CustomInput from '../../components/custom/CustomInput';
import {BackAndConfirmButtons, ConfirmButton, getUrlFromImage, textarea_max_length} from '../../components/Helper';
import {useHistory, useParams} from "react-router-dom";


export default function Administrators() {

    let history = useHistory()
    let {id} = useParams()

    const [administrators, setAdministrators] = useState(null)
    const [loading, setLoading] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteUserId, setDeleteUserId] = useState(0)
    const [deleteUserName, setDeleteUserName] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        getAdministrators()
    }, [])


    const getAdministrators = async () => {

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            id_eveniment: id
        }

        let info = await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/administrators/list', payload, auth);

        if (info.data && info.data.length > 0) {
            setAdministrators(info.data)
        }

    }

    const sendInvite = async () => {

        if (email.length > 0) {

            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            let payload = {
                id_eveniment: id,
                email: email.toLowerCase()
            }

            let info = await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/administrators/invite', payload, auth)
                .then(response => {
                    if (response.data.status === 1) {
                        setAddModal(false)
                        setEmail('')
                        toast.success('Invitatia a fost trimisa!')
                    }

                    if (response.data.status === 2) {
                        toast.error('O invitate a fost deja trimisa pe aceasta adresa de email!')
                    }

                })
        }
    }

    const deleteAdmin = async() => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            id_eveniment: id,
            user_id: deleteUserId
        }

        let info = await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/administrators/delete', payload, auth)
            .then(response => {
                toast.success('Contul a fost eliminat!')
                setDeleteModal(false)
                getAdministrators()
            })
    }

    const goBack = () => {
        history.goBack()
    }

    const customInputStyle = {
        margin: "25px 0px 25px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px",
    }

    const widthStyle = {
        width: '33%',
        overflow: "hidden",
        // whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        borderRadius: "0px",
        textAlign: "center"
    }

    return (
        <div className="Administrators-container">
            <HeaderWhite goBack={goBack}/>

            <div className={"Administrators-contents"}>


                <div className='add-item-button'>
                    <Icon icon="plus-circle" size="lg" style={{color: "#05AFF2", marginRight: "10px"}}
                          onClick={() => setAddModal(true)}/>
                    <div onClick={() => setAddModal(true)}>Adauga organizator</div>
                </div>

                <div className="data-table">
                    <div className="header">

                        <div style={widthStyle}> Nume</div>

                        <div style={widthStyle}> Email</div>

                        <div style={widthStyle}> Elimina</div>
                    </div>

                    {
                        administrators && administrators.map((administrator, i) => (
                            <div className={i % 2 === 0 ? "even-line" : "uneven-line"} key={i}>
                                <div style={{...widthStyle, fontWeight: "bold"}} onClick={() => {
                                }}> {administrator.first_name} {administrator.last_name} </div>

                                <div style={widthStyle}> {administrator.email} </div>

                                <div style={widthStyle}>
                                    <div
                                        className={"btn-remove-access"}
                                        onClick={() => {
                                        setDeleteUserId(administrator.id)
                                        setDeleteUserName(administrator.first_name + ' ' + administrator.last_name)
                                        setDeleteModal(true)
                                    }}>
                                        Elimina
                                    </div>
                                </div>
                            </div>
                        ))
                    }


                </div>

            </div>
            <NavbarColor history={history}/>

            <Modal classNames={{
                overlay: 'modal-overlay',
                modal: 'modal-response',
                closeButton: 'modal-close-button',
            }} open={addModal} onClose={() => setAddModal(false)}>

                <div className={"administrators-modal-inner"}>
                    <h5>Invita organizator</h5>

                    <CustomInput
                        value={email}
                        onChange={setEmail}
                        style={customInputStyle}
                        placeholder={"Adresa de email"}
                        icon={<Icon icon="at"/>}
                    />

                    <ConfirmButton
                        function={() => sendInvite()}
                        text={"Trimite invitatia"}
                    />

                </div>
            </Modal>


            <Modal classNames={{
                overlay: 'modal-overlay',
                modal: 'modal-response',
                closeButton: 'modal-close-button',
            }} open={deleteModal} onClose={() => setDeleteModal(false)}>

                <div className={"administrators-modal-inner"}>
                    <h5>Elimina organizator</h5>

                    <p style={{marginTop:'20px', marginBottom:'20px'}}>Esti sigur ca doresti sa opresti accesul la acest eveniment pentru {deleteUserName} ?</p>


                    <ConfirmButton
                        function={() => deleteAdmin()}
                        text={"Opreste accesul"}
                    />

                </div>
            </Modal>

        </div>
    )

}
