import React from 'react'
import { Uploader, Button } from 'rsuite'
import './AddMenu.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'
import { toast } from 'react-toastify'
import axios from 'axios'

class AddMenu extends React.Component {
	state = {
		eventId: "",

		isSaving: false,

		pdfMeniu: null
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWeddingId/' + this.props.match.params.id, auth)
			.then(response => {
				this.setState({
					eventId: response.data
				})
			})
			.catch(err => {
				localStorage.clear()
				toast.error("Informatiile de autentificare au expirat, va rog reautentificativa")
				this.props.history.push('/login');
			})
	}

	savePDF = (fileList) => {
		if (fileList.length === 0) {
			this.setState({
				pdfMeniu: null
			})
			return
		}

		this.setState({
			pdfMeniu: fileList[0].blobFile
		})
	}

	savePDFToServer = async () => {
		if (this.state.pdfMeniu === null) {
			toast.error("Nu ati incarcat pdf")
			return;
		}

		this.setState({
			isSaving: true
		})

		let formData = new FormData()
		formData.append("data", JSON.stringify({}))

		if (this.state.pdfMeniu !== null) {
			formData.append("files.menuFile", this.state.pdfMeniu)
		}

		let auth = {
			headers: {
				"Content-Type": `multipart/form-data;`,
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, formData, auth)
			.then(response => {
				toast.success("Meniu incarcat!")
				this.props.history.push('/adminEventPage/' + this.props.match.params.id)
			})
			.catch(err => {
				toast.error("Eroare")
			})

		this.setState({
			isSaving: false
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		return <div className="AddMenu-container">
			<HeaderWhite goBack={this.goBack} />

			<div className="AddMenu-contents">
				<p className="title"> Adaugati meniu </p>

				<Uploader onChange={this.savePDF} accept=".pdf" className="Uploader" autoUpload={false} multiple={false}>
					<div style={{ color: "#221d5d", width: "100%" }} id="text">Click pentru a incarca un pdf cu meniu</div>
				</Uploader>

				<div className="Buttons">
					<Button style={{ margin: "20px 20px 20px 0px", color: "#1E1C59", border: "1px solid #1E1C59", borderRadius: "80px", width: "200px" }} onClick={this.goBack}>
						Inapoi
					</Button>

					<Button loading={this.state.isSaving} style={{ margin: "20px 0px", backgroundColor: "#05AFF2", color: "white", borderRadius: "80px", width: "200px" }} onClick={this.savePDFToServer}>
						Salveaza meniul
					</Button>
				</div>
			</div>

			<NavbarColor history={this.props.history} />
		</div>
	}
}

export default AddMenu;