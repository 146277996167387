import React from 'react'
import SectionRender from '../sectionRender'
import './template11.scss'

export default function template11Content(templateDetails = false) {

    return (
        <div className={"main-content"} >

            <div className={"background"}>
                <img src={templateDetails.settings[1].value} crossOrigin={"true"}/>
            </div>

            <div className={"photo-template-11 " + (templateDetails.live ? 'live' : '')}>

                <div className={"row-1"}>
                    <div className={"inner"}>
                    <div className={"col-first"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[1]}
                            preview={!templateDetails.live}
                        />
                    </div>
                    <div className={"col-second"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[2]}
                            preview={!templateDetails.live}
                        />
                    </div>
                    </div>
                </div>

                <div className={"row-2"}>
                    <div className={"col-left"} style={{borderColor: templateDetails.settings[0].value}}>
                        <SectionRender
                            section={templateDetails.sections[0]}
                            preview={!templateDetails.live}
                        />
                    </div>
                    <div className={"col-right"}>
                        <div className={"title"} style={{fontSize: (templateDetails.live ? templateDetails.settings[3].value*3.2 : templateDetails.settings[3].value)  +'px', color: templateDetails.settings[4].value}}>{templateDetails.settings[2].value}</div>
                        <div className={"subtitle"} style={{fontSize: (templateDetails.live ? templateDetails.settings[6].value*3.2 : templateDetails.settings[6].value)+'px', color: templateDetails.settings[7].value}}>{templateDetails.settings[5].value}</div>
                    </div>
                </div>


            </div>

        </div>
    )
}