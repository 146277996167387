import React, {useState, useEffect} from 'react';
import './PaymentConfirm.scss'
import { useHistory } from "react-router-dom";
import HeaderWhite from "../../components/HeaderWhiteBack";
import axios from "axios";
import {toast} from "react-toastify";


export default function PaymentConfirm() {

    const params = new URLSearchParams(window.location.search) // id=123
    let orderId = params.get('orderId') // 123

    let history = useHistory();

    const [loading, setLoading] = useState(true)
    const [transactionStatus, setTransactionStatus] = useState(false)


    useEffect(() => {

        getTransaction()

    }, [])

    const getTransaction = async () => {

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.get(process.env.REACT_APP_DOMAIN + 'transactions/getStatus/' + orderId , auth)
            .then(response => {
                setTransactionStatus(response.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                toast.error("Eroare")
                console.log(err)
            })


    }

    const customInputStyle = {
        margin: "15px 0px 10px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px"
    }

    return( <div className="PaymentConfirm-container">
        <HeaderWhite shadow={true} goBack={() => {
            history.push("/viewEvents")
        }} />

        <div className={"PaymentConfirm-contents"}>

            {
                loading ?
                    <div>Se incarca...</div>
                    :
                    <>
                        {
                            transactionStatus === 'confirmed' ?
                                <>
                                    <h1 style={{color:'green'}}>Tranzactie confirmata!</h1>
                                </>

                                :
                                (transactionStatus === 'confirmed_pending' || transactionStatus === 'paid_pending' || transactionStatus === 'paid') ?
                                    <>
                                        <h1 style={{color:'green',marginBottom:'30px'}}>Tranzactie in procesare!</h1>
                                        Veti primi un email de confirmare cand tranzactia va fi aprobata!</>
                                    :
                                    transactionStatus === 'credit' ?
                                        <>
                                            <h1 style={{color:'green',marginBottom:'30px'}}>Tranzactie in creditata!</h1>
                                        </>
                                        :
                                    <>
                                        <h1 style={{color:'red'}}>Tranzactie anulata!</h1>
                                    </>
                        }


                    </>
            }



        </div>

    </div>)
}

