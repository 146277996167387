import React from 'react'
import { toast } from 'react-toastify'
import { Button, DatePicker, Dropdown } from 'rsuite'
import CustomInput from '../../../components/custom/CustomInput'
import { BackAndConfirmButtons, textarea_max_length, usedCurrency } from '../../../components/Helper'
import './ChangeTask.scss'

class ChangeTask extends React.Component {
	state = {
		title: this.props.task.title,
		description: this.props.task.description !== null ? this.props.task.description : "",
		data: new Date(this.props.task.dueDate),
		price: this.props.task.cost,
		advance: this.props.task.advance,

		confirmDelete: false,
		categories: ["Planificare", "Muzica & Divertisment", "Foto & Video", "Ceremonie", "Flori & Decoratiuni", "Bijuterii", "Invitatii"],
		category: this.props.task.categorie
	}

	changeData = (key, value) => {
		if ((key === "price" || key === "advance") && "" + Number(value) === "NaN") {
			return
		}

		this.setState({
			[key]: value
		})
	}

	changeDescription = (value) => {
		if (this.state.description.length > textarea_max_length - 1 && value.length > textarea_max_length - 1) {
			return
		}

		this.setState({
			description: value
		})
	}

	changeTask = () => {
		if (this.state.title === "") {
			toast.error("Trebuie sa introduci un titlu");
			return
		}

		if (this.state.category === "") {
			toast.error("Trebuie sa alegiti categoria");
			return
		}

		if (this.state.data === "") {
			toast.error("Trebuie sa alegi data");
			return
		}

		if (this.state.price === "") {
			toast.error("Trebuie sa introduci un pret");
			return
		}

		var task = {
			title: this.state.title,
			description: this.state.description,
			dueDate: this.state.data,
			cost: this.state.price,
			advance: this.state.advance,
			done: false,
			eventID: this.props.task.eventID,
			id: this.props.task.id,
			categorie: this.state.category
		}

		this.props.changeTask(task)
	}

	render() {
		var boldStyle = {
			fontWeight: "bold",
			fontSize: 20
		}

		var inputStyle = {
			textAlign: 'center',
			width: '100%',
			maxWidth: "var(--container-max-width)",
			alignSelf: 'center',
			backgroundColor: "#E5F7FE",
			borderRadius: "10px"
		}

		return (
			<div className="ChangeTask-contents">
				<p style={boldStyle}>Modifica Task</p>

				<CustomInput style={inputStyle} value={this.state.title} onChange={(value) => this.changeData("title", value)} placeholder={"Tilu"} />

				<p style={boldStyle}>Categorie</p>
				<Dropdown title={this.state.category === "" ? "Alege categorie" : this.state.category} activeKey={this.state.category}
					className="dropdown_scroll" style={{ marginBottom: "20px", borderRadius: "5px", width: "100%" }}>
					{this.state.categories.map((category, index) => {
						return <Dropdown.Item key={index} eventKey={category} onClick={() => this.setState({ category: category })}>{category}</Dropdown.Item>
					})}
				</Dropdown>

				<p style={boldStyle}>Data Limita:</p>
				<DatePicker
					value={this.state.data}
					style={{ maxWidth: "450px", marginBottom: "20px" }}
					onChange={(value) => this.changeData("data", value)}
					oneTap={true}
					format="DD-MM-YYYY"
					locale={{
						sunday: 'Dum',
						monday: 'Lun',
						tuesday: 'Mar',
						wednesday: 'Mie',
						thursday: 'Joi',
						friday: 'Vin',
						saturday: 'Sam',
						ok: 'OK',
						today: 'Azi',
						yesterday: 'Ieri',
						hours: 'Ore',
						minutes: 'Minute',
						seconds: 'Secunde'
					}}
				/>

				<CustomInput style={inputStyle} rows={3} componentClass={"textarea"} value={this.state.description} onChange={this.changeDescription}
					placeholder={`Descriere ${this.state.description.length}/${textarea_max_length}`} />

				<CustomInput type="number" style={inputStyle} value={this.state.price} onChange={(value) => this.changeData("price", value)} placeholder={"Cost (" + usedCurrency + ")"} />

				<CustomInput type="number" style={inputStyle} value={this.state.advance} onChange={(value) => this.changeData("advance", value)} placeholder={"Avans (" + usedCurrency + ")"} />

				<BackAndConfirmButtons loading={this.state.isLoadingRequest} textBack={"Inapoi"} textConfirm={"Salveaza"} functionBack={this.props.back} functionConfirm={this.changeTask} />

				<div className="ChangeTask-buttons-column">
					<Button style={{marginBottom: "20px"}} color="red" onClick={() => this.setState({ confirmDelete: true })}>Delete</Button>

					{this.state.confirmDelete && <Button color="red" onClick={() => this.props.deleteTask()}>Confirm Delete</Button>}
				</div>
			</div>
		)
	}
}

export default ChangeTask;