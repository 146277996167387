import React from 'react'
import './stylesheets/MenuBlock.scss'
import { Icon } from 'rsuite'

import SVG_Planificari from '../../../../../assets/EventPageSVGs/planificari-icon.svg'
import SVG_Participanti from '../../../../../assets/EventPageSVGs/participanti-icon.svg'
import SVG_Informatii from '../../../../../assets/EventPageSVGs/informatii-icon.svg'
import SVG_MemoryBooth from '../../../../../assets/EventPageSVGs/memory-booth-icon.svg'
import SVG_Exports from '../../../../../assets/EventPageSVGs/export-icon.svg'
import SVG_Contact from '../../../../../assets/EventPageSVGs/contact-icon.svg'
import SVG_Packet from '../../../../../assets/EventPageSVGs/pachete-icon.svg'

class MenuBlock extends React.Component {
	state = {
		status: false,
		loading: true,
		who: "",
	}

	async componentDidMount() {
		if (this.props.id === '1') {
			this.setState({
				who: "editEvent",
				loading: false
			})
		} else if (this.props.id === '2') {
			this.setState({
				who: "package",
				loading: false
			})
		} else if (this.props.id === '3') {
			this.setState({
				who: "guests",
				loading: false
			})
		} else if (this.props.id === '4') {
			this.setState({
				who: "memorybooth",
				loading: false
			})
		}
	}

	render() {
		let svgHeight = { height: "40px" }

		return (
			<div onClick={() => this.props.moveTo(this.state.who, this.state.status)} id={this.state.status !== true ? null : "activeBlock"} className="MenuBlock-container" style={{ marginTop: this.props.id === '5' ? "70px" : "0px" }}>
				<div className="MenuBlock-content">
					<div className="left">
						{this.props.id === '1' && <img src={SVG_Informatii} alt="Informatii" style={svgHeight} />}

						{this.props.id === '2' && <img src={SVG_Planificari} alt="Planificari" style={svgHeight} />}

						{this.props.id === '3' && <img src={SVG_Participanti} alt="Participanti" style={svgHeight} />}

						{this.props.id === '4' && <img src={SVG_MemoryBooth} alt="Participanti" style={svgHeight} />}

						{this.props.id === '5' && <img src={SVG_Exports} alt="Participanti" style={svgHeight} />}

						{this.props.id === '6' && <img src={SVG_Contact} alt="Participanti" style={svgHeight} />}

						{this.props.id === '7' && <img src={SVG_Packet} alt="Participanti" style={svgHeight} />}
					</div>

					<div className="middle">
						<h5 id="title"> {this.props.title} </h5>
					</div>

					<div className="right">
						<Icon icon="angle-right" size="2x" />

						{this.props.id === '3' && this.props.hasNewResponses && <div className='redBlinkingText'>Nou</div>}
					</div>
				</div>
			</div>
		)
	}
}

export default MenuBlock;