import React, {useEffect, useState} from "react";

export default function Timer({
                                  startRecording,
                                  stopRecording,
                                  setPreparing,
                                  preparing,
                                  status,
                                  startTimout,
                                  maxDuration,
                                  startAtSecond = 0
                              }) {

    const [timer, setTimer] = useState(startTimout);
    const [duration, setDuration] = useState(null)

    useEffect(() => {

        if (timer === 0) {
            setPreparing(false)
            setTimer(null)
        }

        if(timer === startAtSecond) {
            startRecording()
        }

        // exit early when we reach 0
        if (!timer) {
            setDuration(maxDuration)
            return
        }

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {

            setTimer(timer - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timer]);

    useEffect(() => {

        if (status === 'stopped') {
            setDuration(null)
            return
        }

        if (duration === 0) {
            stopRecording()
            setDuration(null)
        }

        // exit early when we reach 0
        if (!duration) return;

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {

            setDuration(duration - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [duration]);

    useState(() => {
        if (preparing) {
            setTimer(startTimout)
        }
    }, [preparing])

    const showDurationTimer = () => {

        if (duration !== null) {
            if (duration < 10) {
                return '00:0' + duration
            } else {
                return '00:' + duration
            }
        } else {
            return ''
        }
    }


    return (
        <>
            {
                timer !== null ?
                    <div className={"mb-recording-overlay"}>
                            {
                                timer > 1 ?
                                    <h1 className={"animate__animated animate__zoomIn animate__infinite"}>{timer - 1}</h1>
                                    :
                                    <h1 className={"animate__animated animate__zoomIn "}>Start</h1>
                            }
                    </div>
                    : null
            }

            {
                duration !== null && duration < 5 ?
                    <div className={"mb-recording-duration"}>
                        <h1 className={"animate__animated animate__zoomIn animate__infinite"} style={{color:'red'}}>{showDurationTimer()}</h1>
                    </div>
                    :
                    <div className={"mb-recording-duration"}>
                        <h1>{showDurationTimer()}</h1>
                    </div>
            }
        </>
    );
}

