import React from 'react'
import { Button, Dropdown, Icon } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'
import Modal from "react-responsive-modal";

import HeaderWhite from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'

import './TasksPage.scss'
import Task from './components/Task'
import AddTask from './components/AddTask'
import ChangeTask from './components/ChangeTask'
import BasicTask from './components/BasicTask'
// import ModalTasks from './components/ModalTasks'
import { usedCurrency } from '../../components/Helper';

let allTypes = "Toate"

class TasksPage extends React.Component {
	state = {
		eventId: 0,
		dataEvent: new Date(),
		tasks: [],
		disp_tasks: [],
		doneTasks: 0,
		todoTask: 0,

		categories: [allTypes, "Planificare", "Muzica & Divertisment", "Foto & Video", "Ceremonie", "Flori & Decoratiuni", "Bijuterii", "Invitatii"],
		intervals: [allTypes, "Cu mai mult de 1 an înainte de eveniment", "10-12 luni înainte de eveniment", "7-9 luni înainte de eveniment", "4-6 luni înainte de eveniment",
			"2-3 luni înainte de eveniment", "cu o  luna înainte", "cu 2 saptamani înainte", "cu o saptamana înainte", "ziua nunții"],
		statuses: [allTypes, "Terminate", "In desfasurare"],

		categoryChoice: allTypes,
		intervalChoice: allTypes,
		statusChoice: allTypes,

		choice: "Loading",

		taskInWork: 0,

		showModalCategories: false,
		showModalIntervals: false,
		showModalStatus: false,

		showModalTask: false,
	}

	componentDidMount = async () => {
		// get tasks from server
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let eventData = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

		let info = await axios.get(process.env.REACT_APP_DOMAIN + 'tasks/list/byEvent/' + this.props.match.params.id, auth);

		if (info.data.length === 0) {
			this.setState({
				choice: "BASIC"
			})
		} else {
			// set tasks in state
			let newTasks = info.data.map((elem, index) => {
				elem.index = index

				return elem
			})

			for (let i = 0; i < newTasks.length; i++) {
				for (let j = i + 1; j < newTasks.length; j++) {
					if (newTasks[i].dueDate > newTasks[j].dueDate) {
						let aux = newTasks[i]
						newTasks[i] = newTasks[j]
						newTasks[j] = aux
					}
				}

				newTasks[i].index = i
			}

			this.setState({
				tasks: newTasks,
				disp_tasks: newTasks,
				choice: "SHOW"
			})

			// get amount of task done or in TODO
			this.resetViews(newTasks)

			//check if user comes from budget to edit task
			if (localStorage.getItem('taskToEditId') !== null) {
				let taskToEdit = Number(localStorage.getItem('taskToEditId'))
				let neededIndex = -1
				localStorage.removeItem('taskToEditId')

				info.data.map((elem, index) => {
					if (elem.id === taskToEdit) {
						neededIndex = index
					}

					return null
				})

				if (neededIndex !== -1) {
					this.setState({
						choice: "MODIF",
						taskInWork: neededIndex
					})
				}
			}
		}

		this.setState({
			eventId: eventData.data.id,
			dataEvent: eventData.data.dataEvent
		})
	}

	changeData = (key, value) => {
		this.setState({
			[key]: value
		})
	}

	checkButton = async (value, index) => {
		var newTasks = this.state.tasks
		newTasks[index].done = value

		// this.resetViews(newTasks)
		this.setDispTask(0, newTasks)

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		//send changed data to server
		await axios.put(process.env.REACT_APP_DOMAIN + 'tasks/' + this.state.tasks[index].id, { done: value }, auth);
	}

	changeCategoryChoice = (choice) => {
		this.setState({
			categoryChoice: choice,
			showModalCategories: false
		})

		this.setDispTask(1, choice)
	}

	changeIntervalChoice = (choice) => {
		this.setState({
			intervalChoice: choice,
			showModalIntervals: false
		})

		this.setDispTask(2, choice)
	}

	changeStatusChoice = (choice) => {
		this.setState({
			statusChoice: choice,
			showModalStatus: false
		})

		this.setDispTask(3, choice)
	}

	checkDate = (taskDate, interval) => {
		let event_date = new Date(this.state.dataEvent)
		let task_date = new Date(taskDate)

		if (interval === "Cu mai mult de 1 an înainte de eveniment") {
			event_date.setFullYear(event_date.getFullYear() - 1)
		}

		if (interval === "10-12 luni înainte de eveniment") {
			event_date.setMonth(event_date.getMonth() - 10)
		}

		if (interval === "7-9 luni înainte de eveniment") {
			event_date.setMonth(event_date.getMonth() - 7)
		}

		if (interval === "4-6 luni înainte de eveniment") {
			event_date.setMonth(event_date.getMonth() - 4)
		}

		if (interval === "2-3 luni înainte de eveniment") {
			event_date.setMonth(event_date.getMonth() - 2)
		}

		if (interval === "cu o  luna înainte") {
			event_date.setMonth(event_date.getMonth() - 1)
		}

		if (interval === "cu 2 saptamani înainte") {
			event_date.setDate(event_date.getDate() - 14)
		}

		if (interval === "cu o saptamana înainte") {
			event_date.setDate(event_date.getDate() - 7)
		}

		if (interval === "ziua nunții") {
			event_date.setDate(event_date.getDate() - 0)
		}

		return event_date.getTime() === task_date.getTime()
	}

	setDispTask = (step, choice) => {
		let allTasks = this.state.tasks

		if (step === 0) {
			allTasks = choice
		}

		let categoryChoice = this.state.categoryChoice
		if (step === 1) {
			categoryChoice = choice
		}

		let firstFilter = allTasks.filter(elem => categoryChoice === allTypes || elem.categorie === categoryChoice)

		let intervalChoice = this.state.intervalChoice
		if (step === 2) {
			intervalChoice = choice
		}

		let secondFilter = firstFilter.filter(elem => intervalChoice === allTypes || this.checkDate(elem.dueDate, intervalChoice))

		let statusChoice = this.state.statusChoice
		if (step === 3) {
			statusChoice = choice
		}

		let thirdFilter = secondFilter.filter(elem => statusChoice === allTypes || (elem.done && statusChoice === "Terminate") || (!elem.done && statusChoice === "In desfasurare"))

		this.setState({
			disp_tasks: thirdFilter
		})

		this.resetViews(allTasks)
	}

	modifTask = (index) => {
		this.setState({
			choice: "MODIF",
			showModalTask: false
		})
	}

	saveTask = async (task) => {
		//send new task to server
		var obj = {
			event: this.state.eventId,
			title: task.title,
			description: task.alteDetalii,
			cost: task.cost,
			advance: task.advance,
			done: false,
			dueDate: task.data,
			categorie: task.category
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'tasks', obj, auth)
			.then(response => {
				toast.success("Task salvat cu succes")

				obj.id = response.data.id
				obj.index = this.state.tasks.length

				var newTasks = [...this.state.tasks, obj]

				// this.resetViews(newTasks)
				this.setDispTask(0, newTasks)
			});
	}

	changeTask = async (task) => {
		task.index = this.state.taskInWork
		var newTasks = this.state.tasks
		newTasks.splice(this.state.taskInWork, 1, task)
		// this.resetViews(newTasks)
		this.setDispTask(0, newTasks)

		let obj = {
			title: task.title,
			description: task.description,
			cost: task.cost,
			advance: task.advance,
			dueDate: task.dueDate,
			categorie: task.categorie
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'tasks/' + task.id, obj, auth)
			.then(_ => {
				toast.success("Modificari efectuate cu succes")
			})
	}

	deleteTask = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.delete(process.env.REACT_APP_DOMAIN + 'tasks/' + this.state.tasks[this.state.taskInWork].id, auth)
			.then(_ => {
				toast.success("Stergere finalizata")
			})

		let newTasks = this.state.tasks
		newTasks.splice(this.state.taskInWork, 1)

		// this.resetViews(newTasks)
		this.setDispTask(0, newTasks)
	}

	resetViews = (tasks) => {
		var doneT = 0
		var todoT = 0

		for (var i = 0; i < tasks.length; i++) {
			if (tasks[i].done) {
				doneT++
			} else {
				todoT++
			}
		}

		this.setState({
			tasks: tasks,
			choice: "SHOW",
			doneTasks: doneT,
			todoTask: todoT,
		})
	}

	goBack = () => {
		if (this.state.choice === "ADD" || this.state.choice === "MODIF") {
			this.setState({
				choice: "SHOW"
			})

			return
		}

		this.props.history.go(-1)
	}

	exitBasicTasks = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let info = await axios.get(process.env.REACT_APP_DOMAIN + 'tasks/list/byEvent/' + this.props.match.params.id, auth);

		let newTasks = info.data.map((elem, index) => {
			elem.index = index

			return elem
		})

		this.setState({
			choice: "SHOW",
			tasks: newTasks,
			disp_tasks: newTasks
		})

		this.resetViews(info.data)
	}

	closeModalCateories = () => {
		this.setState({
			showModalCategories: false,
		})
	}

	closeModalIntervals = () => {
		this.setState({
			showModalIntervals: false,
		})
	}

	closeModalStatus = () => {
		this.setState({
			showModalStatus: false,
		})
	}

	render() {
		var body = <div>Loading...</div>

		if (this.state.choice === "ADD") {
			body = <AddTask saveTask={this.saveTask} back={this.goBack} />
		}

		if (this.state.choice === "MODIF") {
			body = <ChangeTask task={this.state.tasks[this.state.taskInWork]} changeTask={this.changeTask} back={this.goBack} deleteTask={this.deleteTask} />
		}

		if (this.state.choice === "BASIC") {
			body = <BasicTask event_id={this.state.eventId} event_id_eveniment={this.props.match.params.id} exitBasicTasks={this.exitBasicTasks} history={this.props.history} />
		}

		if (this.state.choice === "SHOW") {
			body = <div className="TasksPage-contents">
				<div className="Top-data">
					<div className="data">
						Completate: {this.state.doneTasks}/{this.state.tasks.length} task-uri, {this.state.tasks.length === 0 ? 0 : Math.round((this.state.doneTasks / this.state.tasks.length) * 100)}%
					</div>
				</div>

				<div className='Add-task'>
					<Icon icon="plus-circle" size="lg" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={() => this.setState({ choice: "ADD" })} />
					<div onClick={() => this.setState({ choice: "ADD" })}>Adauga task</div>
				</div>

				{/* <div>
						<Button color="violet" appearance="ghost" onClick={() => this.setState({ choice: "ADD" })}>Adauga Task</Button>
					</div> */}
				<div className="TasksPage-Tasks">
					<div className="Filters">
						<Dropdown title={"Categorie: " + this.state.categoryChoice} activeKey={this.state.categoryChoice} className="dropdown_scroll">
							{this.state.categories.map((option, index) => {
								return <Dropdown.Item key={index} eventKey={option} onClick={() => this.changeCategoryChoice(option)}>{option}</Dropdown.Item>
							})}
						</Dropdown>

						<Dropdown title={"Interval: " + this.state.intervalChoice} activeKey={this.state.intervalChoice} className="dropdown_scroll">
							{this.state.intervals.map((option, index) => {
								return <Dropdown.Item key={index} eventKey={option} onClick={() => this.changeIntervalChoice(option)}>{option}</Dropdown.Item>
							})}
						</Dropdown>

						<Dropdown title={"Status: " + this.state.statusChoice} activeKey={this.state.statusChoice} className="dropdown_scroll">
							{this.state.statuses.map((option, index) => {
								return <Dropdown.Item key={index} eventKey={option} onClick={() => this.changeStatusChoice(option)}>{option}</Dropdown.Item>
							})}
						</Dropdown>
					</div>

					{this.state.disp_tasks.map((task, index) => {
						return <Task
							key={index}
							task={task}
							checkChange={(value) => this.checkButton(!task.done, task.index)}
							onClick={() => { this.setState({ showModalTask: true, taskInWork: task.index }) }}
						// onClick={() => this.modifTask(task.index)}
						/>
					})}
				</div>

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal-tasks',
					closeButton: 'modal-close-button',
				}} center open={this.state.showModalTask} onClose={() => { this.setState({ showModalTask: false }) }}>
					{this.state.disp_tasks.length > 0 && <div>
						<div className="title">{this.state.tasks[this.state.taskInWork].title}</div>

						<div className="description">{this.state.tasks[this.state.taskInWork].description}</div>

						{this.state.tasks[this.state.taskInWork].cost > 0 && <div className="category">Cost: {this.state.tasks[this.state.taskInWork].cost} {usedCurrency}</div>}

						{this.state.tasks[this.state.taskInWork].advance > 0 && <div className="category">Avans: {this.state.tasks[this.state.taskInWork].advance} {usedCurrency}</div>}

						<div className="category">Categorie: {this.state.tasks[this.state.taskInWork].categorie}</div>

						<div className="button" style={{ marginBottom: "20px" }} onClick={() => { this.modifTask(this.state.taskInWork) }} >
							Editeaza
						</div>

						<div className="button" onClick={() => { this.modifTask(this.state.taskInWork) }} >
							Adauga cost
						</div>
					</div>}
				</Modal>
			</div>
		}

		return (
			<div className="TasksPage-container">
				<HeaderWhite shadow={true} goBack={this.goBack} />

				{body}

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default TasksPage;