import React from 'react'
import HeaderWhite from '../../components/HeaderWhiteBack';
import NavbarColor from '../../components/NavbarColor';
import './Package.scss'
import axios from 'axios'
import {BackAndConfirmButtons, BackButton, LoadingScreen, usedCurrency} from '../../components/Helper';
import {toast} from 'react-toastify';
import TermsModal from "../../SignUp/Modals/terms2";
import Modal from "react-responsive-modal";
import {Button, Icon} from "rsuite";
import CustomInput from "../../components/custom/CustomInput";

const participants_category = "Invitati"
const participants_extra_category = "Invitati extra"
const facerecog_category = "Face recog"
const facerecog_extra_category = "Face recog extra"
const memorybooth_category = "Memory booth"


const memoryBoothPackagesTitles = [
	"Video + GIF + Poze",
	"Video + GIF + Poze + Printare"
]

class Package extends React.Component {
	state = {
		participants_packages: [],
		participants_extra_packages: [],
		facerecog_packages: [],
		facerecog_extra_packages: [],
		memorybooth_packages: [],

		participants_package_owned: null,
		facerecog_package_owned: 0,
		memorybooth_package_owned: 0,

		participants_package_selected: null,
		participants_extra_packages_selected: [],
		facerecog_package_selected: 0,
		facerecog_extra_packages_selected: [],
		memorybooth_package_selected: 0,
		weddingId: 0,
		netopiaDetails: false,
		showTermsModal: false,
		weddingPackages: {},
		showCodeModal: false,
		code: '',

		loading: true
	}

	constructor() {
		super();
		this.netopiaForm = React.createRef();
	}

	componentDidMount = async () => {
		this.init()
	}

	init = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let payload = {
			id_eveniment: this.props.match.params.id
		}

		//get packages data
		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/packages/getAvailablePackages', payload, auth)
			.then(response => {
				let participants_packages_new = []

				response.data.wedding_participants.map(elem => {
					participants_packages_new.push(elem)

					return null
				})

				this.setState({
					participants_packages: participants_packages_new,
					participants_extra_packages: response.data.wedding_participants_extra,
					weddingPackages: response.data.packages,
				})
			})

		//get owned packages data
		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
			.then(response => {
				if (response.data.packages !== null) {
					let packagesData = response.data.packages

					let participants_package = null
					if (packagesData.wedding_participants !== undefined) {
						participants_package = packagesData.wedding_participants.package
					}

					let facerecog_package = null
					if (packagesData.wedding_facerecog !== undefined) {
						facerecog_package = packagesData.wedding_facerecog.package
					}

					let memorybooth_package = null
					if (packagesData.wedding_memorybooth !== undefined) {
						memorybooth_package = packagesData.wedding_memorybooth.package
					}

					this.setState({
						participants_package_owned: participants_package,
						facerecog_package_owned: facerecog_package,
						memorybooth_package_owned: memorybooth_package,

						participants_package_selected: participants_package,
						facerecog_package_selected: facerecog_package,
						memorybooth_package_selected: memorybooth_package,
					})
				}

				this.setState({
					weddingId: response.data.id
				})
			})

		this.setState({
			loading: false
		})
	}

	changeSelected = (category, optionIndex) => {
		if (category === participants_category) {
			if (this.state.participants_package_selected !== optionIndex) {
				if (optionIndex === 0 && this.state.participants_package_owned > 0) {
					//toast.info("Nu va puteti intoarce la pachetul ");
				} else {

					this.setState({
						participants_package_selected: optionIndex
					})
				}
			}

			return
		}

	}

	showPackageOptions = (packageData, category, currentlyActive, currentlySelected, packageType) => {
		return <div className="Category">
			<div className="Title">Pachete</div>

			<div className="Options">
				{packageData.map((option, index) => {
					return <div key={index}
								className={index === currentlyActive ? "active-option" : index === currentlySelected ? "selected-option" : "basic-option"}
								onClick={() => {

									if(index === currentlySelected) {
										this.changeSelected(category, currentlyActive)
										return
									}

									if (index > (currentlyActive ? currentlyActive : -1)) {
										// if(index === 1 || index === 0) {
										this.changeSelected(category, index)
										// } else {
										// this.initPayment(packageType, index, this.state.weddingId)
										// }
									} else {
										toast.error('Pachetul poate fi înlocuit doar cu unul superior!')
									}

								}}
					>
						<div style={{fontWeight: "bold"}}>
							{memoryBoothPackagesTitles[index]}
						</div>
						<div>Pret: {option.price > 0 ?

							option.oldPrice ?
								<>
									<span style={{textDecoration:'line-through'}}>{option.oldPrice} Euro</span>
									<span style={{paddingLeft:'10px', color:'#FC4F4F', fontWeight:'600'}}>{option.price} Euro</span>
								</>
								:
								option.price + ' Euro'

							: <span style={{color:'#FC4F4F', fontWeight:'600' }} className={"text-stroke-white"}>Gratuit</span>
						}</div>
					</div>
				})}

			</div>

		</div>
	}

	showPackageChoice = (types = false) => {
		let choices = []

		if (this.state.participants_package_owned !== this.state.participants_package_selected) {
			let choice = {
				category: participants_category,
				title: 'Maxim ' + this.state.participants_packages[this.state.participants_package_selected].limit,
				price: this.state.participants_packages[this.state.participants_package_selected].price,
				type: "wedding_participants",
				nr: this.state.participants_package_selected
			}

			if(types) {
				if(types.includes(choice.type)) {
					choices.push(choice)
				}
			} else {
				choices.push(choice)
			}
		}

		if (this.state.facerecog_package_owned !== this.state.facerecog_package_selected) {
			let choice = {
				category: facerecog_category,
				title: 'Maxim ' + this.state.facerecog_packages[this.state.facerecog_package_selected].limit,
				price: this.state.facerecog_packages[this.state.facerecog_package_selected].price,
				type: "wedding_facerecog",
				nr: this.state.facerecog_package_selected
			}

			if(types) {
				if(types.includes(choice.type)) {
					choices.push(choice)
				}
			} else {
				choices.push(choice)
			}
		}

		if (this.state.participants_extra_packages_selected) {

			this.state.participants_extra_packages_selected.map((newExtraPackage, j) => {
				let choice = {
					category: participants_extra_category,
					title: 'Extra +' + this.state.participants_extra_packages_selected[j].limit,
					price: this.state.participants_extra_packages_selected[j].price,
					type: "wedding_participants_extra",
					nr: this.state.participants_extra_packages_selected[j].nr
				}

				if(types) {
					if(types.includes(choice.type)) {
						choices.push(choice)
					}
				} else {
					choices.push(choice)
				}
			})

		}

		if (this.state.facerecog_extra_packages_selected) {

			this.state.facerecog_extra_packages_selected.map((newExtraPackage, j) => {
				let choice = {
					category: facerecog_extra_category,
					title: 'Extra +' + this.state.facerecog_extra_packages_selected[j].limit,
					price: this.state.facerecog_extra_packages_selected[j].price,
					type: "wedding_facerecog_extra",
					nr: this.state.facerecog_extra_packages_selected[j].nr
				}

				if(types) {
					if(types.includes(choice.type)) {
						choices.push(choice)
					}
				} else {
					choices.push(choice)
				}
			})

		}

		// if (this.state.memorybooth_package_owned !== this.state.memorybooth_package_selected) {
		// 	let choice = {
		// 		category: memorybooth_category,
		// 		title: this.state.memorybooth_packages[this.state.memorybooth_package_selected].title,
		// 		price: this.state.memorybooth_packages[this.state.memorybooth_package_selected].price
		// 	}

		// 	choices.push(choice)
		// }

		let total = 0;

		if (choices.length === 0) {
			return <div></div>
		} else {
			return <div style={{marginTop: "20px", margin: "10px auto"}}>
				<div style={{fontWeight: "bold"}}>Alegeri:</div>

				{choices.map((elem, index) => {
					total += elem.price

					return <div key={index}>
						Pachet ales: {memoryBoothPackagesTitles[elem.nr]}, pret: {elem.price} Euro
					</div>
				})}

				{
					!types ?
						<>
							<div style={{color: "#05AFF2", fontWeight: "bold"}}>Total: {total} Euro</div>
							<div>Preturile se calculeaza la cursul valutar 1 Euro = 4.97 lei</div>
							<BackAndConfirmButtons textBack={"Sterge"} textConfirm={"Plateste"}
												   functionBack={this.revertChoices}
												   functionConfirm={() => this.initPayment(choices, this.state.weddingId)}/>
						</>
						: null
				}
			</div>
		}
	}

	// savePackages = async () => {
	// 	let packages = []

	// 	if (this.state.participants_package_owned !== this.state.participants_package_selected) {
	// 		let choice = {
	// 			type: "wedding_participants",
	// 			number: this.state.participants_package_selected
	// 		}

	// 		packages.push(choice)
	// 	}

	// 	if (this.state.facerecog_package_owned !== this.state.facerecog_package_selected) {
	// 		let choice = {
	// 			type: "wedding_facerecog",
	// 			number: this.state.facerecog_package_selected
	// 		}

	// 		packages.push(choice)
	// 	}

	// 	if (this.state.memorybooth_package_owned !== this.state.memorybooth_package_selected) {
	// 		let choice = {
	// 			type: "wedding_memorybooth",
	// 			number: this.state.memorybooth_package_selected
	// 		}

	// 		packages.push(choice)
	// 	}

	// 	let obj = {
	// 		id_eveniment: this.props.match.params.id,
	// 		packages: packages
	// 	}

	// 	let auth = {
	// 		headers: {
	// 			'Authorization': 'Bearer ' + localStorage.getItem('jwt')
	// 		}
	// 	}

	// 	await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/packages/buy', obj, auth)
	// 		.then(response => {
	// 			// console.log(response);
	// 			toast.success("Modificari efectuate cu succes")

	// 			this.setState({
	// 				participants_package_owned: this.state.participants_package_selected,
	// 				facerecog_package_owned: this.state.facerecog_package_selected,
	// 				memorybooth_package_owned: this.state.memorybooth_package_selected
	// 			})
	// 		})
	// 		.catch(err => {
	// 			console.log(err);
	// 			toast.error("Erorare la trimitere date, va rog reincercati")
	// 		})
	// }

	revertChoices = () => {
		this.setState({
			participants_package_selected: this.state.participants_package_owned,
			participants_extra_packages_selected: [],
			facerecog_package_selected: this.state.facerecog_package_owned,
			memorybooth_package_selected: this.state.memorybooth_package_owned
		})
	}

	toggleTermsModal = () => {
		let current = this.state.showTermsModal;
		this.setState({
			showTermsModal: !current
		})
	}

	initPayment = async (choices, weddingId) => {
		this.setState({
			netopiaDetails: false
		}, async () => {
			let selectedPackages = []

			choices.map(elem => {
				selectedPackages.push({
					type: elem.type,
					nr: elem.nr
				})

				return null
			})

			let payload = {
				packages: selectedPackages,
				weddingId
			}

			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			// get packages data
			await axios.post(process.env.REACT_APP_DOMAIN + 'transactions/create', payload, auth)
				.then(response => {
					if (response.data.data) {
						this.setState({
							netopiaDetails: response.data
						})
					}
				})
		})
	}

	goBack = () => {
		this.props.history.push("/adminEventPage/" + this.props.match.params.id)
	}

	claimCode = async () => {

		if(this.state.code.length > 0) {

			let payload = {
				code: this.state.code,
				id_eveniment: this.props.match.params.id
			}

			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			// assign package by code
			await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/claimCodePackage', payload, auth)
				.then(response => {
					if(response.data.status === 1) {
						toast.success('Pachetul a fost revendicat!')
						this.setState({showCodeModal: false})
					} else {
						toast.success('Codul nu este valid!')
					}
					this.init()
				})
		}


	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.netopiaDetails !== this.state.netopiaDetails && this.state.netopiaDetails !== false) {
			document.getElementById('netopiaForm').submit();
			this.netopiaForm.dispatchEvent(new Event("submit", {cancelable: true, bubbles: true}))
		}
	}

	render() {
		if (this.state.loading) {
			return <LoadingScreen/>
		}

		return <div className="Package-container">
			<HeaderWhite shadow={true} goBack={this.goBack}/>

			<div className="Package-contents">
				<div className={"claim-code-container"}>
					<div>Ai un cod promoțional ?</div>
					<div>
						<Button
							onClick={() => this.setState({showCodeModal: true})}
							color="green" style={{ backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%" }}>Revendică</Button>
					</div>
				</div>

				{this.showPackageOptions(this.state.participants_packages, participants_category, this.state.participants_package_owned, this.state.participants_package_selected, 'wedding_participants')}

				{this.showPackageChoice()}

				<div onClick={this.toggleTermsModal} style={{textDecoration: "underline", margin: 'auto'}}>Termenii si
					Conditiile
				</div>
				<img src={"/images/netopia.jpeg"} style={{width: '80%', margin: 'auto'}}/>

				<BackButton text={"Inapoi"} function={this.goBack}/>

				<form id={"netopiaForm"} method={"post"} ref={(ref) => {
					this.netopiaForm = ref;
				}} action={this.state.netopiaDetails ? this.state.netopiaDetails.redirectUrl : ''}>
					{
						this.state.netopiaDetails !== false ?
							<>
								<input type={"hidden"} name={"env_key"} value={this.state.netopiaDetails.envKey}/>
								<input type={"hidden"} name={"data"} value={this.state.netopiaDetails.data}/>
							</>
							: null
					}
				</form>
			</div>

			<TermsModal toggleModal={this.toggleTermsModal} showModal={this.state.showTermsModal}/>


			<Modal
				classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}}
				center
				open={this.state.showCodeModal}
				onClose={() => this.setState({showCodeModal: false})}
			>
				<div style={{ marginTop: "20px" }}>
					<h4>Introduceți codul</h4>

					<CustomInput
						value={this.state.code}
						onChange={(value) => this.setState({code: value})}
						placeholder={"Cod"}
					/>

					<Button
						onClick={() => this.claimCode()}
						color="green" style={{ backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%" }}>Revendică</Button>
				</div>
			</Modal>

			<NavbarColor history={this.props.history}/>
		</div>
	}
}

export default Package;