import React from 'react'
import { Icon } from 'rsuite'
import './CheckPresence.scss'
import HeaderWhite from '../../components/HeaderWhiteBack'
import axios from 'axios'
import { toast } from 'react-toastify'
import CustomInput from '../../components/custom/CustomInput'
import { BackButton, ShowPackageData } from '../../components/Helper'
import NavbarColor from '../../components/NavbarColor'

class CheckPresence extends React.Component {
	state = {
		positions: [],
		participantsData: [],
		displayData: [],

		currentSearch: "",
		
		totalParticipants: 0,
		prezentParticipants: 0,

		packageData: {},

		isSendingData: false,
		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let event_info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

		let seating = event_info.data.seatingChart

		let positions = []
		if (seating !== null) {
			positions = seating.positions
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
			.then(async response => {
				//take all data
				let data = response.data.responses.map((invitee, index) => {
					let ans = { ...invitee.response }
					ans.responseIndex = index
					ans.id = invitee.id
					ans.user = invitee.user
					ans.is_checker = invitee.is_checker

					return ans
				})

				if (data.length === 0) {
					this.setState({
						positions: [],
						participantsData: [],
						displayData: [],
						packageData: response.data,
						loading: false
					})

					toast.info("Niciun raspuns in acest moment")

					return
				}

				//get only tables from data
				let tablePositions = positions.filter(elem => elem.type === "roundTable" || elem.type === "squareTable" || elem.type === "rectangleTable")

				//set tables for participants from server data
				for (var i = 0; i < tablePositions.length; i++) {
					let peopleAtTable = tablePositions[i].peopleAtTable

					for (var j = 0; j < peopleAtTable.length; j++) {
						if (data[peopleAtTable[j].responseIndex] !== undefined) {
							data[peopleAtTable[j].responseIndex].table = i
						}
					}
				}

				//take only data from people who will be participating
				let participants = data.filter(ans => ans.answer === "yes")

				let total = 0
				let prezent = 0

				//set total seats needed
				for (i = 0; i < participants.length; i++) {
					participants[i].seatsNeeded = 1 + (participants[i].insotit ? 1 : 0) + (participants[i].cuCopii ? Number(participants[i].nr_copii) : 0)
					participants[i].participantIndex = i

					if (participants[i].is_present === undefined) {
						participants[i].is_present = false
					}

					total += participants[i].seatsNeeded

					if (participants[i].is_present) {
						prezent += participants[i].seatsNeeded
					}
				}

				this.setState({
					positions: tablePositions,
					participantsData: participants,
					displayData: participants,
					packageData: response.data,
					totalParticipants: total,
					prezentParticipants: prezent,
					loading: false
				})
			})
	}

	onChange = (value, data = null) => {
		let participantsData = this.state.participantsData

		if (data !== null) {
			participantsData = data
		}

		let filteredByName = participantsData.filter(element => {
			return element.nume.toLocaleLowerCase().indexOf(value) !== -1 ||
				element.prenume.toLocaleLowerCase().indexOf(value) !== -1
		})

		this.setState({
			currentSearch: value,
			displayData: filteredByName
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading === true) {
			return <p> Loading.. </p>
		}

		let widthStyle = { width: '20%', textAlign: "center" }
		let isBigScreen = true

		if (window.innerWidth < 700) {
			isBigScreen = false
			widthStyle = { width: '50%', textAlign: "center" }
		}

		return (
			<div className="CheckPresence-container">
				<HeaderWhite goBack={this.goBack} />

				<div className="CheckPresence-contents">
					<div style={{ fontSize: "30px", fontWeight: "bold" }}>Verifica Prezenta</div>

					<div className='Attendance'>
						Invitati prezenti: {this.state.prezentParticipants} / {this.state.totalParticipants} persoane
						{/* {this.state.totalParticipants > 0 ? this.state.prezentParticipants / this.state.totalParticipants : 0}% */}
					</div>

					<CustomInput style={{ backgroundColor: "#E5F7FE" }} value={this.state.currentSearch} onChange={value => this.onChange(value, null)} placeholder={"Numele invitatului"} icon={<Icon icon="search" />} />

					<div className="data-table" style={{ width: "100%" }}>
						<div className="header">
							<div style={widthStyle}>Nume Participant</div>
							{isBigScreen && <div style={widthStyle}>Total Persoane</div>}
							{isBigScreen && <div style={widthStyle}>Masa</div>}
							<div style={widthStyle}>Prezenta</div>
						</div>

						{this.state.displayData.map((element, index) => {
							return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
								<div style={widthStyle}> {element.prenume} {element.nume} </div>
								{isBigScreen && <div style={widthStyle}> {element.seatsNeeded} </div>}
								{isBigScreen && <div style={widthStyle}> {element.table === undefined ? "Nu este asezat" : element.table + 1}</div>}
								<div style={widthStyle}> {element.is_present ? "Prezent" : "Absent"} </div>
							</div>
						})}
					</div>

					<BackButton function={this.goBack} text={"Inapoi"} />

					<ShowPackageData packageData={this.state.packageData} history={this.props.history} eventId={this.props.match.params.id} />
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default CheckPresence;