import React from 'react'
import Modal from 'react-responsive-modal'
import { Divider, Button } from 'rsuite'
import './style.scss'

const confid = (props) => {
	return (
		<Modal classNames={{
			overlay: 'modal-overlay',
			modal: 'terms-modal',
			closeButton: 'modal-close-button',
		}} focusTrapped={false} open={props.showModal} onClose={props.toggleModal} center>

			<h5>Politica de confidentialitate</h5>
			<p> Ultima modificare: 20 August 2021 </p>
			<Divider />

			{/* <h5> Părţile contractante </h5>
			<p> La Ality.ro si pe aplicatia “Ality”, acordam o importanță foarte mare protejării și confidențialității datelor. Scopul acestei politici de confidențialitate este de a face Ality.ro si aplicatia “Ality”” sigură și potrivită pentru toată lumea. Politica noastră de confidențialitate se aplică utilizării de către dvs. a serviciilor Ality.ro si a aplicatiei “Ality” și descrie tipul de informații pe care le procesăm, modul în care sunt procesate și de ce sunt procesate.
				<br /><br />
				Ality.ro si aplicatia “Ality”” este deținut de către compania Lyra Napkin S.R.L. In cadrul aplicației oferim servicii de management și automatizare al participanților la evenimente. Ca urmare a acestui fapt, procesăm datele personale, ca intermediar, în numele clientului nostru, compania organizatoare a evenimentului.
			</p>

			<h5> <br /><br />1. Definiții </h5>
			<p> 1.1. "Controlor" înseamnă o entitate care determină scopurile și mijloacele de prelucrare a datelor cu caracter personal.
				<br /><br />
				1.2. "Client" înseamnă o persoană sau o entitate care finalizeaza procesul de inregistrare pe Ality.ro si pe aplicatia “Ality” pentru a utiliza Serviciile.
				<br /><br />
				1.3. "Legile privind protecția datelor" înseamnă toate legile și regulamentele privind protecția datelor și confidențialitatea din UE, SEE și statele membre ale acestora, Elveția și Regatul Unit, aplicabile procesării datelor cu caracter personal.
				<br /><br />
				1.4. "GDPR" înseamnă Regulamentul (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și libera circulație a acestor date și de abrogare a Directivei 95 / 46 / CE (Regulamentul general privind protecția datelor).
				<br /><br />
				1.5. "Date personale" înseamnă orice informație referitoare la o persoană fizică identificată sau identificabilă.
				<br /><br />
				1.6. "Procesare" înseamnă orice operațiune sau set de operațiuni care se efectuează asupra datelor cu caracter personal, cu sau fără mijloace automate, cum ar fi colectarea, înregistrarea, organizarea, stocarea, adaptarea sau modificarea, recuperarea, consultarea, utilizarea, dezvăluirea prin transmitere, diseminarea sau punerea la dispoziție în alt mod, alinierea sau combinarea, blocarea, ștergerea sau distrugerea. "Procesul", "Procesele" și "Procesul" vor fi interpretate în mod corespunzător.
				<br /><br />
				1.7. "Procesor" înseamnă o persoană fizică sau juridică, o autoritate publică, o agenție sau orice alt organism care prelucrează date cu caracter personal în numele controlorului.
				<br /><br />
				1.8. "Servicii" înseamnă orice produs sau serviciu furnizat de Ality.ro si pe aplicatia “Ality” în conformitate cu Termenii și condițiile Ality.ro si pe aplicatia “Ality” (Terms of Service)
				<br /><br />
				1.9. "Participant" înseamnă o persoană ce participă la evenimentele Clientului prin intermediul Serviciilor noastre.
				<br /><br />
				1.10. "Noi", "noi" și "nostru" se referă la Ality.ro si la aplicatia “Ality””.
			</p>

			<h5> <br /><br />2. Consimțământul </h5>
			<p> Prin accesarea serviciilor noastre, acceptați Politica noastră de Confidențialitate, Termenii și Condițiile de Utilizare și sunteți de acord cu colectarea, stocarea, utilizarea și dezvăluirea informațiilor dvs. personale așa cum este descris în această politică de confidențialitate. În plus, prin utilizarea serviciilor noastre, acceptați politicile și practicile descrise în această Politică de Confidențialitate. De fiecare dată când vizitați site-ul nostru sau folosiți Serviciul și oricând ne furnizați în mod voluntar sau involuntar informații, sunteți de acord cu colectarea, utilizarea și dezvăluirea informațiilor pe care le furnizați și sunteți de acord să primiți e-mailuri sau sa fiti contactati prin intermediul aplicatiilor de mesagerie sau telefonic, așa cum este descris în această Politică de Confidențialitate. Indiferent dacă vă înregistrați sau nu vă creați un cont, această Politică de confidențialitate se aplică tuturor utilizatorilor site-ului și serviciilor. </p>

			<h5> <br /><br />3. Ce informații procesăm </h5>
			<p> Ality.ro si aplicatia “Ality” colectează atât datele personale cât și datele non-personale despre dvs. și va procesa aceste date pe durata Serviciilor, așa cum este descris în Termenii și Condițiile de Utilizare:
				<br /><br />
				3.1. Date personale:
				<br /><br />
				Informații despre clienți: datele de identificare și de contact (nume, numar de telefon, email, nume de utilizator), informații despre organizație (numele, adresa, locația geografică, zona de responsabilitate, codul TVA), adresele IP de pe care se acceseaza serviciile noastre.
				<br /><br />
				Informații despre participanți: datele de identificare și de contact (numele, ocupația sau alte informații demografice, adresa, datele de contact, inclusiv adresa de e-mail), interesele personale sau preferințele (inclusiv istoricul achizițiilor, informații despre profil); Informații IT (adrese IP, date de utilizare, cookie-uri, date de navigație pe paginile noastre online, date despre locație, date despre browser).
				<br /><br />
				În orice moment, puteți să vă conectați și să modificați sau eliminati datele personale de identificare. Puteți refuza furnizarea de date cu caracter personal serviciilor, însă unele dintre datele personale pe care vi le solicităm să le furnizați, de exemplu, adresa de e-mail,numele,prenumele, sunt obligatorii pentru utilizarea serviciului. Dacă refuzați să le furnizați, ne rezervam dreptul de a nu presta serviciul.
				<br /><br />
				3.2. Date non-personale:
				<br /><br />
				Informații privind browserul și dispozitivul: locația, tipul dispozitivului, tipul de browser sau sistemul de operare, ora și data accesului, activitatea de navigare, rezoluția ecranului, programele de completare și versiunea serviciilor pe care le utilizați , și alte informații care nu vă identifică personal.
				<br /><br />
				Informații din utilizarea de către dvs. a serviciilor și a informațiilor din alte surse: este posibil să primim informații despre cum și când utilizați serviciile, să le stocăm în fișierele jurnal sau în alte tipuri de fișiere asociate contului dvs. și să le conectăm la alte informații pe care le colectăm despre dvs. De asemenea, putem obține informații despre dvs. sau abonații dvs. din surse terțe, cum ar fi bazele de date publice, platformele sociale media, furnizorii de date terță parte și partenerii noștri de marketing în comun. Aceste informații pot include, de exemplu, ora, data, browserul folosit, informațiile demografice, informațiile despre dispozitiv (cum ar fi tipul dispozitivului, tipul de browser sau sistemul de operare, adresele IP) despre folosirea site-urilor de social media, informații despre afișarea paginilor și rezultatele căutării și legăturile), acțiunile pe care le-ați luat, datele de utilizare anonime, paginile de trimitere / ieșire și adresele URL, preferințele pe care le trimiteți și preferințele generate pe baza datelor trimise și numărului de clicuri.
			</p>

			<h5> <br /><br />4. Scopurile procesării </h5>
			<p>
				4.1. Ality.ro si aplicatia “Ality” utilizează informațiile colectate pentru funcționarea Serviciilor, pentru a menține calitatea Serviciului, pentru a furniza statistici generale privind utilizarea Serviciului, pentru a promova și analiza Serviciile, pentru a oferi suport clienților și a trimite informații despre Servicii, pentru a proteja drepturile și siguranța clienților, a abonaților, a terților și a noastră, să trimită conținut informativ sau promoțional personalizat și să furnizeze sugestii în funcție de preferințele de marketing, să asigure respectarea termeniilor și condițiilor și să îndeplinească cerințele legale, să verifice identitatea și informațiile furnizate, plăți, pentru a înțelege datele demografice, interesele și nevoile clienților și alte tendințe în rândul utilizatorilor și pentru alte scopuri de afaceri.
				<br /><br />
				4.2. Putem combina datele personale cu alte informații pe care le procesăm pentru a personaliza conținutul informațional și pentru a spori capacitatea noastră de a furniza servicii în funcție de preferințele, restricțiile de marketing, de publicitate, de direcționare sau de a vă dezvolta și furniza produse și funcții relevante si adaptate la nevoile dvs de utilizare.
			</p>

			<h5> <br /><br />5. Cum colectăm informațiile dvs. </h5>
			<p>
				5.1. Datele furnizate de clienții noștri: Clienții pot importa datele personale ale serviciilor pe care le-au colectat de la participanții lor sau de la alte persoane. Nu avem o relație directă cu participanții la evenimentele clienților sau cu alte persoane decât cu clienții noștri. Prelucrarea datelor noastre personale se bazează pe faptul că persoana vizată acordă consimțământul în mod liber și clienții sunt responsabili pentru a ne asigura că au permisele necesare pentru a procesa date personale despre abonați sau alte persoane.
				<br /><br />
				5.2. Tehnologii de urmărire: în legătură cu performanța serviciilor Ality.ro si pe aplicatia “Ality” utilizează:
				<br /><br />
				Cookie-urile: cookie-urile sunt fișiere de date pe care un site web le transferă pe hard disk-ul computerului pentru a fi ținute în evidență. Majoritatea browserelor web sunt setate să accepte modulele cookie, dar puteți schimba acest lucru în setările browserului. Cu toate acestea, dacă nu acceptați cookie-urile, exista posibilitatea ca unele functionalitati sa nu manifeste comportament corect sau coerent in contextul utilizarii Ality.ro si a aplicatiei “Ality””. Utilizăm cookie-uri pentru a îmbunătăți experiența dvs.pe Ality.ro si pe aplicatia “Ality”” și colectând aceste date, putem stoca informații astfel încât să nu trebuie să le reintroduceți de fiecare dată când vizitați Ality.ro sau aplicatia “Ality”. De asemenea, le utilizăm pentru a monitoriza valorile vizitatorilor către Ality.ro si a aplicatiei “Ality”, cum ar fi vizualizările de pagină, timpul petrecut pe site, numărul de vizitatori etc.
				<br /><br />
				Semnalizatoare web: includem gif-uri cu un singur pixel în e-mailurile pe care le trimitem, ceea ce ne permite să colectăm informații despre momentul deschiderii e-mailului și a adresei dvs. IP, a browserului sau a tipului de client de e-mail și alte detalii similare. Utilizăm datele de pe aceste semnalizatoare web pentru a crea rapoarte despre modul în care au fost receptate comunicările automate trimise de Sistem, pentru a captura timpul petrecut în serviciile, paginile vizitate și performanța Serviciului.
				<br /><br />
				Identificatori unici și tehnologii de urmărire similare: este posibil să folosim alte tehnologii de urmărire pentru a administra Serviciile, pentru a vă urmări mișcările în jurul Serviciilor, pentru a analiza tendințele, pentru a urmări comportamentul, pentru a difuza anunțuri direcționate și pentru a aduna informații demografice, pentru a măsura performanța Serviciilor și pentru a îmbunătăți caracteristicile noastre pentru anumite segmente de clienți.
				<br /><br />
				5.3. Monitorizarea datelor din log-uri: este posibil să colectăm date din log-uri ori de câte ori accesați Serviciul nostru. Aceste date ar putea include adresa IP, tipul și versiunea de browser, paginile pe care le accesați pe Ality.ro si pe aplicatia “Ality” și alte statistici de utilizare. Când accesați Ality.ro si aplicatia “Ality” cu un dispozitiv mobil, datele din jurnal pot include tipul dispozitivului, adresa IP a dispozitivului dvs., sistemul de operare și alte caracteristici.
				<br /><br />
				5.4. Servicii terțe: folosim servicii ale unor terțe părți, cum ar fi Twispay reprezentata de firma Capital Financial Services S.A, Google Analytics și Facebook, care colectează și analizează date din log-uri pentru a ne ajuta să îmbunătățim Ality.ro si aplicatia “Ality” și, ocazional, să le folosim pentru publicitate online. Partenerii noștri terță parte pot utiliza cookie-uri sau alte tehnologii de urmărire pentru a vă oferi publicitate pe alte site-uri în funcție de activitățile și interesele dvs. de navigare. Puteți găsi mai multe informații despre aceste practici, inclusiv cum să renunțați la publicitatea vizată aici: http://www.aboutads.info/choices/.
			</p>

			<h5> <br /><br />6. Transferarea către companii terțe </h5>
			<p>
				6.1. Prin acceptarea prevederilor prezentului act va manifestati acordul asupra posibilitatii vanzarii împrumutarii sau închirii datelor dvs. personale unei terțe părți, respectiv ne rezervam dreptul să împărtășim informațiile colectate în scopurile descrise în această politică de confidențialitate cu terțe părți care ajută Ality.ro si aplicatia “Ality” să furnizeze, să îmbunătățească, să promoveze sau să sprijine serviciile sale sau care ne ajută în operațiunile noastre comerciale și ne oferă asistență în livrarea Serviciilor noastre sau furnizarea de servicii pentru noi, cum ar fi procesoare de plăți, servicii de găzduire, servicii de analiză, servicii de livrare a conținutului, parteneri de publicitate, etc. De asemenea, putem împărtăși informații cu terțe părți, dacă acest lucru este cerut de lege.
				<br /><br />
				6.2. Prin acceptarea prevederilor prezentului act va manifestati acordul asupra posibilitatii de a divulga si de a utiliza date non-personale pentru orice scop, deoarece aceste informații nu pot fi folosite pentru a vă identifica pe dvs. sau pe altă persoană.
			</p>

			<h5> <br /><br />7. Comunicații prin e-mail și oprirea </h5>
			<p>
				7.1. Ne rezervam dreptul de a comunica prin e-mail informatii legate de utilizarea de către dvs. a Ality.ro si pe aplicatia “Ality”. Pe baza datelor personale pe care ni le furnizați, asumam dreptul să comunicăm cu dvs. ca răspuns la solicitările dvs. pentru a furniza Serviciile solicitate și pentru a vă administra contul. De asemenea, putem utiliza datele dvs. personale pentru a vă trimite actualizări și alte comunicări in scop promoțional. Fiecare e-mail va conține un link in vederea dezabonarii de la newsletter-ul si anunturile noastre. Ne asumam drreptul de a trimite mesaje importante cu privire la chestiuni administrative, actualizări, dispute și probleme legate de serviciul clienți care sunt necesare pentru a furniza Serviciile.
				<br /><br />
				7.2. Dacă un Participant nu mai dorește să fie contactat de unul dintre clienții noștri, acesta se va poate dezabona direct din buletinul informativ al clienților sau poate contacta direct Clientul pentru a actualiza sau șterge datele. Dacă un Participant ne contactează, îl vom referi la Clientul respectiv. Vom păstra informațiile personale pe care le procesăm în numele clienților noștri atât timp cât este necesar pentru a furniza serviciile noastre sau pentru a ne conforma obligațiilor legale. Clientul este complet răspunzător pentru a asigura tot suportul necesar Participanților în ce privește datele personale ale acestora.
			</p>

			<h5> <br /><br />8. Siguranța informațiilor dvs. </h5>
			<p>
				8.1. Ality.ro si aplicatia “Ality” ia măsuri de precauție rezonabile, măsuri de securitate tehnice și organizaționale pentru a asigura un nivel de siguranță adecvat riscului, respectă cele mai bune practici din domeniu pentru a vă proteja datele personale de orice încălcare neautorizată sau ilegală a securității care duce la distrugerea accidentală sau ilegală, modificarea, dezvăluirea neautorizată sau accesul la datele cu caracter personal și păstrarea securității și confidențialității datelor. Cu toate acestea, aceste măsuri nu garantează ca nu vor exista cazuri in care informațiile dvs. nu vor fi accesate, dezvăluite, modificate sau distruse prin încălcarea acestor măsuri de precauție. (Aici trebuie ceva formulat legat de atacuri cibernetice) Prin utilizarea Serviciului nostru, declarati pe propria raspundere faptul că înțelegeți și sunteți de acord să vă asumați aceste riscuri.
				<br /><br />
				8.2. Clientul este responsabil de revizuirea informațiilor puse la dispoziție de Ality.ro si de aplicatia “Ality” în legătură cu securitatea datelor sale și de determinarea independentă a faptului dacă serviciile oferite îndeplinesc cerințele și obligațiile legale ce trebuie asigurate de către Client în conformitate cu legile privind protecția datelor. De asemenea, Clientul este responsabil pentru utilizarea securizată a Serviciilor, inclusiv securizarea datelor de autentificare a contului, protejarea securității datelor personale în timpul tranzitului către și de la Servicii și luarea oricăror măsuri corespunzătoare pentru criptarea sau backupul securizat a datelor personale utilizate în cadrul Serviciilor.
			</p>


			<h5> <br /><br />9. Protejarea datelor Participantiilor </h5>
			<p>
				9.1. Ne rezervăm dreptul de a monitoriza constant listele, conținutul și datele dvs. de răspuns pentru a ne asigura că respectă Termenii de utilizare.
				<br /><br />
				9.2. Centrul de date unde sunt stocate datele Serviciului este localizat în Europa – Germania, Frankfurt, în Uniunea Europeană, și este administrat de către o companie specializată in oferirea acestor servicii. Aceasta companie se asigură că funcționarea Serviciilor și securitatea datele sunt permanent asigurate în așa fel încât să fiți siguri că datele abonaților dvs. sunt în siguranță cu noi. Compania ce stocheaza datele Serviciului nu are dreptul de a citi sau folosi datele personale gazduite pe serverele sale.
			</p>

			<h5> <br /><br />10. Legături către alte site-uri web</h5>
			<p>
				Ca parte a Serviciului, putem furniza link-uri sau compatibilitate cu alte site-uri sau aplicații. Cu toate acestea, nu suntem responsabili pentru practicile de confidențialitate utilizate de aceste site-uri web sau pentru informațiile sau conținutul pe care le conțin. Această politică de confidențialitate se aplică numai informațiilor colectate de noi prin intermediul Serviciului. Prin urmare, această politică de confidențialitate nu se aplică utilizării de către dvs. a unui site web al unei terțe părți accesat prin intermediul Serviciului nostru. În măsura în care accesați sau utilizați servicii terte, conectate cu serviciile noastre, atunci politica de confidențialitate a acelui alt site sau aplicație se va aplica accesului sau utilizării site-ului sau aplicației respective. Îi încurajăm pe utilizatorii noștri să citească declarațiile de confidențialitate ale altor site-uri web înainte de a le folosi.
			</p>

			<h5> <br /><br />11. Acordul de prelucrare a datelor</h5>
			<p>
				Am creat contractul nostru de prelucrare a datelor pentru a acoperi cerințele GDPR în ceea ce privește marketingul prin e-mail prin telefon.
			</p>

			<h5> <br /><br />12. Varsta consimtamantului</h5>
			<p>
				Prin utilizarea Serviciului, declarați pe proprie raspundere că aveți cel puțin 14 ani împliniți.
			</p>

			<h5> <br /><br />13. Fuziune sau achiziție</h5>
			<p>
				În cazul în care efectuăm o tranzacție de afaceri, cum ar fi o fuziune, o achiziție de către o altă companie sau o vânzare a tuturor sau a unei părți din activele noastre, datele dvs. personale pot fi printre activele transferate. Recunoașteți și consimțiți că astfel de transferuri pot apărea și sunt permise de această Politică de confidențialitate. Astfel, orice cumpărător al activelor noastre poate continua să proceseze datele dvs. personale așa cum este stabilit în această politică de confidențialitate
			</p>

			<h5> <br /><br />14. Schimbări</h5>
			<p>
				Ality.ro si aplicatia “Ality” își rezervă dreptul de a modifica oricând această politică de confidențialitate. In cazul modificarii Politicii de confidentialitate, toate modificările vor fi mentionate atat pe pagina noastra oficiala ality.ro printr-un comunicat, cat si pe newsletter-ul oferit. Astfel, garantam transparenta catre dvs. despre ce informatii se colecteaza, de modul în care sunt folosite și în ce circumstanțe pot fi dezvaluite. Toate modificările sunt eficiente imediat după înregistrare și vă sugerăm să verificați periodic această politică de confidențialitate dacă sunteți preocupat de modul în care sunt utilizate informațiile dvs.
			</p>

			<h5> <br /><br />15. Contactați Ality</h5>
			<p>
				Aveti dreptul sa ne solicitati partajarea tuturor datelor personale pe care le deținem despre dumneavoastra. Solicitarile se fac doar in format electronic pe adresa de email: contact@ality.ro. Va rugăm să ne acordați un termen de solutionare de 30 de zile calendaristice de la solicitare. Aveți dreptul legal de a accesa, vizualiza aceste informații, să le corectați, modificați, ștergeți sau actualizați
				<br /><br />
				Oricând aveți întrebări privind această politică de confidențialitate sau practicile acestui site, doriți să vă retrageți consimțământul pentru colectarea continuă, doriți să obiectați împotriva utilizarii datelor dvs. personale sau doriti sa adresati întrebări suplimentare, vă rugăm să ne contactați în orice moment printr-un e-mail trimis la adresa contact@ality.ro.
			</p> */}

			<h5> Părţile contractante </h5>
			<p> La Ality.ro si pe aplicatia “Ality”, acordam o importanță foarte mare protejării și confidențialității datelor. Scopul acestei politici de confidențialitate este de a face Ality.ro si aplicatia “Ality”” sigură și potrivită pentru toată lumea. Politica noastră de confidențialitate se aplică utilizării de către dvs. a serviciilor Ality.ro si a aplicatiei “Ality” și descrie tipul de informații pe care le procesăm, modul în care sunt procesate și de ce sunt procesate.
				<br /><br />
				Ality.ro si aplicatia “Ality”” este deținut de către compania Lyra Napkin S.R.L. In cadrul aplicației oferim servicii de management și automatizare al participanților la evenimente. Ca urmare a acestui fapt, procesăm datele personale, ca intermediar, în numele clientului nostru, compania organizatoare a evenimentului.
			</p>

			<h5> <br /><br />1. Definiții </h5>
			<p> 1.1. "Controlor" înseamnă o entitate care determină scopurile și mijloacele de prelucrare a datelor cu caracter personal.
				<br /><br />
				1.2. "Client" înseamnă o persoană sau o entitate care finalizeaza procesul de inregistrare pe Ality.ro si pe aplicatia “Ality” pentru a utiliza Serviciile.
				<br /><br />
				1.3. "Legile privind protecția datelor" înseamnă toate legile și regulamentele privind protecția datelor și confidențialitatea din UE, SEE și statele membre ale acestora, Elveția și Regatul Unit, aplicabile procesării datelor cu caracter personal.
				<br /><br />
				1.4. "GDPR" înseamnă Regulamentul (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și libera circulație a acestor date și de abrogare a Directivei 95 / 46 / CE (Regulamentul general privind protecția datelor).
				<br /><br />
				1.5. "Date personale" înseamnă orice informație referitoare la o persoană fizică identificată sau identificabilă.
				<br /><br />
				1.6. "Procesare" înseamnă orice operațiune sau set de operațiuni care se efectuează asupra datelor cu caracter personal, cu sau fără mijloace automate, cum ar fi colectarea, înregistrarea, organizarea, stocarea, adaptarea sau modificarea, recuperarea, consultarea, utilizarea, dezvăluirea prin transmitere, diseminarea sau punerea la dispoziție în alt mod, alinierea sau combinarea, blocarea, ștergerea sau distrugerea. "Procesul", "Procesele" și "Procesul" vor fi interpretate în mod corespunzător.
				<br /><br />
				1.7. "Procesor" înseamnă o persoană fizică sau juridică, o autoritate publică, o agenție sau orice alt organism care prelucrează date cu caracter personal în numele controlorului.
				<br /><br />
				1.8. "Servicii" înseamnă orice produs sau serviciu furnizat de Ality.ro si pe aplicatia “Ality” în conformitate cu Termenii și condițiile Ality.ro si pe aplicatia “Ality” (Terms of Service)
				<br /><br />
				1.9. "Participant" înseamnă o persoană ce participă la evenimentele Clientului prin intermediul Serviciilor noastre.
				<br /><br />
				1.10. "Noi", "noi" și "nostru" se referă la Ality.ro si la aplicatia “Ality””.
			</p>

			<h5> <br /><br />2. Consimțământul </h5>
			<p> Prin accesarea serviciilor noastre, acceptați Politica noastră de Confidențialitate, Termenii și Condițiile de Utilizare și sunteți de acord cu colectarea, stocarea, utilizarea și dezvăluirea informațiilor dvs. personale așa cum este descris în această politică de confidențialitate. În plus, prin utilizarea serviciilor noastre, acceptați politicile și practicile descrise în această Politică de Confidențialitate. De fiecare dată când vizitați site-ul nostru sau folosiți Serviciul și oricând ne furnizați în mod voluntar sau involuntar informații, sunteți de acord cu colectarea, utilizarea și dezvăluirea informațiilor pe care le furnizați și sunteți de acord să primiți e-mailuri sau sa fiti contactati prin intermediul aplicatiilor de mesagerie sau telefonic, așa cum este descris în această Politică de Confidențialitate. Indiferent dacă vă înregistrați sau nu vă creați un cont, această Politică de confidențialitate se aplică tuturor utilizatorilor site-ului și serviciilor. </p>

			<h5> <br /><br />3. Ce informații procesăm </h5>
			<p> Ality.ro si aplicatia “Ality” colectează atât datele personale cât și datele non-personale despre dvs. și va procesa aceste date pe durata Serviciilor, așa cum este descris în Termenii și Condițiile de Utilizare:
				<br /><br />
				3.1. Date personale:
				<br /><br />
				Informații despre clienți: datele de identificare și de contact (nume, numar de telefon, email, nume de utilizator), informații despre organizație (numele, adresa, locația geografică, zona de responsabilitate, codul TVA), adresele IP de pe care se acceseaza serviciile noastre.
				<br /><br />
				Informații despre participanți: datele de identificare și de contact (numele, ocupația sau alte informații demografice, adresa, datele de contact, inclusiv adresa de e-mail), interesele personale sau preferințele (inclusiv istoricul achizițiilor, informații despre profil); Informații IT (adrese IP, date de utilizare, cookie-uri, date de navigație pe paginile noastre online, date despre locație, date despre browser).
				<br /><br />
				În orice moment, puteți să vă conectați și să modificați sau eliminati datele personale de identificare. Puteți refuza furnizarea de date cu caracter personal serviciilor, însă unele dintre datele personale pe care vi le solicităm să le furnizați, de exemplu, adresa de e-mail,numele,prenumele, sunt obligatorii pentru utilizarea serviciului. Dacă refuzați să le furnizați, ne rezervam dreptul de a nu presta serviciul.
				<br /><br />
				3.2. Date non-personale:
				<br /><br />
				Informații privind browserul și dispozitivul: locația, tipul dispozitivului, tipul de browser sau sistemul de operare, ora și data accesului, activitatea de navigare, rezoluția ecranului, programele de completare și versiunea serviciilor pe care le utilizați , și alte informații care nu vă identifică personal.
				<br /><br />
				Informații din utilizarea de către dvs. a serviciilor și a informațiilor din alte surse: este posibil să primim informații despre cum și când utilizați serviciile, să le stocăm în fișierele jurnal sau în alte tipuri de fișiere asociate contului dvs. și să le conectăm la alte informații pe care le colectăm despre dvs. De asemenea, putem obține informații despre dvs. sau abonații dvs. din surse terțe, cum ar fi bazele de date publice, platformele sociale media, furnizorii de date terță parte și partenerii noștri de marketing în comun. Aceste informații pot include, de exemplu, ora, data, browserul folosit, informațiile demografice, informațiile despre dispozitiv (cum ar fi tipul dispozitivului, tipul de browser sau sistemul de operare, adresele IP) despre folosirea site-urilor de social media, informații despre afișarea paginilor și rezultatele căutării și legăturile), acțiunile pe care le-ați luat, datele de utilizare anonime, paginile de trimitere / ieșire și adresele URL, preferințele pe care le trimiteți și preferințele generate pe baza datelor trimise și numărului de clicuri.
			</p>

			<h5> <br /><br />4. Scopurile procesării </h5>
			<p>
				4.1. Ality.ro si aplicatia “Ality” utilizează informațiile colectate pentru funcționarea Serviciilor, pentru a menține calitatea Serviciului, pentru a furniza statistici generale privind utilizarea Serviciului, pentru a promova și analiza Serviciile, pentru a oferi suport clienților și a trimite informații despre Servicii, pentru a proteja drepturile și siguranța clienților, a abonaților, a terților și a noastră, să trimită conținut informativ sau promoțional personalizat și să furnizeze sugestii în funcție de preferințele de marketing, să asigure respectarea termeniilor și condițiilor și să îndeplinească cerințele legale, să verifice identitatea și informațiile furnizate, plăți, pentru a înțelege datele demografice, interesele și nevoile clienților și alte tendințe în rândul utilizatorilor și pentru alte scopuri de afaceri.
				<br /><br />
				4.2. Putem combina datele personale cu alte informații pe care le procesăm pentru a personaliza conținutul informațional și pentru a spori capacitatea noastră de a furniza servicii în funcție de preferințele, restricțiile de marketing, de publicitate, de direcționare sau de a vă dezvolta și furniza produse și funcții relevante si adaptate la nevoile dvs de utilizare.
			</p>

			<h5> <br /><br />5. Cum colectăm informațiile dvs. </h5>
			<p>
				5.1. Datele furnizate de clienții noștri: Clienții pot importa datele personale ale serviciilor pe care le-au colectat de la participanții lor sau de la alte persoane. Nu avem o relație directă cu participanții la evenimentele clienților sau cu alte persoane decât cu clienții noștri. Prelucrarea datelor noastre personale se bazează pe faptul că persoana vizată acordă consimțământul în mod liber și clienții sunt responsabili pentru a ne asigura că au permisele necesare pentru a procesa date personale despre abonați sau alte persoane.
				<br /><br />
				5.2. Tehnologii de urmărire: în legătură cu performanța serviciilor Ality.ro si pe aplicatia “Ality” utilizează:
				<br /><br />
				Cookie-urile: cookie-urile sunt fișiere de date pe care un site web le transferă pe hard disk-ul computerului pentru a fi ținute în evidență. Majoritatea browserelor web sunt setate să accepte modulele cookie, dar puteți schimba acest lucru în setările browserului. Cu toate acestea, dacă nu acceptați cookie-urile, exista posibilitatea ca unele functionalitati sa nu manifeste comportament corect sau coerent in contextul utilizarii Ality.ro si a aplicatiei “Ality””. Utilizăm cookie-uri pentru a îmbunătăți experiența dvs.pe Ality.ro si pe aplicatia “Ality”” și colectând aceste date, putem stoca informații astfel încât să nu trebuie să le reintroduceți de fiecare dată când vizitați Ality.ro sau aplicatia “Ality”. De asemenea, le utilizăm pentru a monitoriza valorile vizitatorilor către Ality.ro si a aplicatiei “Ality”, cum ar fi vizualizările de pagină, timpul petrecut pe site, numărul de vizitatori etc.
				<br /><br />
				Semnalizatoare web: includem gif-uri cu un singur pixel în e-mailurile pe care le trimitem, ceea ce ne permite să colectăm informații despre momentul deschiderii e-mailului și a adresei dvs. IP, a browserului sau a tipului de client de e-mail și alte detalii similare. Utilizăm datele de pe aceste semnalizatoare web pentru a crea rapoarte despre modul în care au fost receptate comunicările automate trimise de Sistem, pentru a captura timpul petrecut în serviciile, paginile vizitate și performanța Serviciului.
				<br /><br />
				Identificatori unici și tehnologii de urmărire similare: este posibil să folosim alte tehnologii de urmărire pentru a administra Serviciile, pentru a vă urmări mișcările în jurul Serviciilor, pentru a analiza tendințele, pentru a urmări comportamentul, pentru a difuza anunțuri direcționate și pentru a aduna informații demografice, pentru a măsura performanța Serviciilor și pentru a îmbunătăți caracteristicile noastre pentru anumite segmente de clienți.
				<br /><br />
				5.3. Monitorizarea datelor din log-uri: este posibil să colectăm date din log-uri ori de câte ori accesați Serviciul nostru. Aceste date ar putea include adresa IP, tipul și versiunea de browser, paginile pe care le accesați pe Ality.ro si pe aplicatia “Ality” și alte statistici de utilizare. Când accesați Ality.ro si aplicatia “Ality” cu un dispozitiv mobil, datele din jurnal pot include tipul dispozitivului, adresa IP a dispozitivului dvs., sistemul de operare și alte caracteristici.
				<br /><br />
				5.4. Servicii terțe: folosim servicii ale unor terțe părți, cum ar fi Twispay reprezentata de firma Capital Financial Services S.A, Google Analytics și Facebook, care colectează și analizează date din log-uri pentru a ne ajuta să îmbunătățim Ality.ro si aplicatia “Ality” și, ocazional, să le folosim pentru publicitate online. Partenerii noștri terță parte pot utiliza cookie-uri sau alte tehnologii de urmărire pentru a vă oferi publicitate pe alte site-uri în funcție de activitățile și interesele dvs. de navigare. Puteți găsi mai multe informații despre aceste practici, inclusiv cum să renunțați la publicitatea vizată aici: http://www.aboutads.info/choices/.
			</p>

			<h5> <br /><br />6. Transferarea către companii terțe </h5>
			<p>
				6.1. Prin acceptarea prevederilor prezentului act va manifestati acordul asupra posibilitatii vanzarii împrumutarii sau închirii datelor dvs. personale unei terțe părți, respectiv ne rezervam dreptul să împărtășim informațiile colectate în scopurile descrise în această politică de confidențialitate cu terțe părți care ajută Ality.ro si aplicatia “Ality” să furnizeze, să îmbunătățească, să promoveze sau să sprijine serviciile sale sau care ne ajută în operațiunile noastre comerciale și ne oferă asistență în livrarea Serviciilor noastre sau furnizarea de servicii pentru noi, cum ar fi procesoare de plăți, servicii de găzduire, servicii de analiză, servicii de livrare a conținutului, parteneri de publicitate, etc. De asemenea, putem împărtăși informații cu terțe părți, dacă acest lucru este cerut de lege.
				<br /><br />
				6.2. Prin acceptarea prevederilor prezentului act va manifestati acordul asupra posibilitatii de a divulga si de a utiliza date non-personale pentru orice scop, deoarece aceste informații nu pot fi folosite pentru a vă identifica pe dvs. sau pe altă persoană.
			</p>

			<h5> <br /><br />7. Comunicații prin e-mail și oprirea </h5>
			<p>
				7.1. Ne rezervam dreptul de a comunica prin e-mail informatii legate de utilizarea de către dvs. a Ality.ro si pe aplicatia “Ality”. Pe baza datelor personale pe care ni le furnizați, asumam dreptul să comunicăm cu dvs. ca răspuns la solicitările dvs. pentru a furniza Serviciile solicitate și pentru a vă administra contul. De asemenea, putem utiliza datele dvs. personale pentru a vă trimite actualizări și alte comunicări in scop promoțional. Fiecare e-mail va conține un link in vederea dezabonarii de la newsletter-ul si anunturile noastre. Ne asumam drreptul de a trimite mesaje importante cu privire la chestiuni administrative, actualizări, dispute și probleme legate de serviciul clienți care sunt necesare pentru a furniza Serviciile.
				<br /><br />
				7.2. Dacă un Participant nu mai dorește să fie contactat de unul dintre clienții noștri, acesta se va poate dezabona direct din buletinul informativ al clienților sau poate contacta direct Clientul pentru a actualiza sau șterge datele. Dacă un Participant ne contactează, îl vom referi la Clientul respectiv. Vom păstra informațiile personale pe care le procesăm în numele clienților noștri atât timp cât este necesar pentru a furniza serviciile noastre sau pentru a ne conforma obligațiilor legale. Clientul este complet răspunzător pentru a asigura tot suportul necesar Participanților în ce privește datele personale ale acestora.
			</p>

			<h5> <br /><br />8. Siguranța informațiilor dvs. </h5>
			<p>
				8.1. Ality.ro si aplicatia “Ality” ia măsuri de precauție rezonabile, măsuri de securitate tehnice și organizaționale pentru a asigura un nivel de siguranță adecvat riscului, respectă cele mai bune practici din domeniu pentru a vă proteja datele personale de orice încălcare neautorizată sau ilegală a securității care duce la distrugerea accidentală sau ilegală, modificarea, dezvăluirea neautorizată sau accesul la datele cu caracter personal și păstrarea securității și confidențialității datelor. Cu toate acestea, aceste măsuri nu garantează ca nu vor exista cazuri in care informațiile dvs. nu vor fi accesate, dezvăluite, modificate sau distruse prin încălcarea acestor măsuri de precauție. (Aici trebuie ceva formulat legat de atacuri cibernetice) Prin utilizarea Serviciului nostru, declarati pe propria raspundere faptul că înțelegeți și sunteți de acord să vă asumați aceste riscuri.
				<br /><br />
				8.2. Clientul este responsabil de revizuirea informațiilor puse la dispoziție de Ality.ro si de aplicatia “Ality” în legătură cu securitatea datelor sale și de determinarea independentă a faptului dacă serviciile oferite îndeplinesc cerințele și obligațiile legale ce trebuie asigurate de către Client în conformitate cu legile privind protecția datelor. De asemenea, Clientul este responsabil pentru utilizarea securizată a Serviciilor, inclusiv securizarea datelor de autentificare a contului, protejarea securității datelor personale în timpul tranzitului către și de la Servicii și luarea oricăror măsuri corespunzătoare pentru criptarea sau backupul securizat a datelor personale utilizate în cadrul Serviciilor.
			</p>


			<h5> <br /><br />9. Protejarea datelor Participantiilor </h5>
			<p>
				9.1. Ne rezervăm dreptul de a monitoriza constant listele, conținutul și datele dvs. de răspuns pentru a ne asigura că respectă Termenii de utilizare.
				<br /><br />
				9.2. Centrul de date unde sunt stocate datele Serviciului este localizat în Europa – Germania, Frankfurt, în Uniunea Europeană, și este administrat de către o companie specializată in oferirea acestor servicii. Aceasta companie se asigură că funcționarea Serviciilor și securitatea datele sunt permanent asigurate în așa fel încât să fiți siguri că datele abonaților dvs. sunt în siguranță cu noi. Compania ce stocheaza datele Serviciului nu are dreptul de a citi sau folosi datele personale gazduite pe serverele sale.
			</p>

			<h5> <br /><br />10. Legături către alte site-uri web</h5>
			<p>
				Ca parte a Serviciului, putem furniza link-uri sau compatibilitate cu alte site-uri sau aplicații. Cu toate acestea, nu suntem responsabili pentru practicile de confidențialitate utilizate de aceste site-uri web sau pentru informațiile sau conținutul pe care le conțin. Această politică de confidențialitate se aplică numai informațiilor colectate de noi prin intermediul Serviciului. Prin urmare, această politică de confidențialitate nu se aplică utilizării de către dvs. a unui site web al unei terțe părți accesat prin intermediul Serviciului nostru. În măsura în care accesați sau utilizați servicii terte, conectate cu serviciile noastre, atunci politica de confidențialitate a acelui alt site sau aplicație se va aplica accesului sau utilizării site-ului sau aplicației respective. Îi încurajăm pe utilizatorii noștri să citească declarațiile de confidențialitate ale altor site-uri web înainte de a le folosi.
			</p>

			<h5> <br /><br />11. Acordul de prelucrare a datelor</h5>
			<p>
				Am creat contractul nostru de prelucrare a datelor pentru a acoperi cerințele GDPR în ceea ce privește marketingul prin e-mail prin telefon.
			</p>

			<h5> <br /><br />12. Varsta consimtamantului</h5>
			<p>
				Prin utilizarea Serviciului, declarați pe proprie raspundere că aveți cel puțin 14 ani împliniți.
			</p>

			<h5> <br /><br />13. Fuziune sau achiziție</h5>
			<p>
				În cazul în care efectuăm o tranzacție de afaceri, cum ar fi o fuziune, o achiziție de către o altă companie sau o vânzare a tuturor sau a unei părți din activele noastre, datele dvs. personale pot fi printre activele transferate. Recunoașteți și consimțiți că astfel de transferuri pot apărea și sunt permise de această Politică de confidențialitate. Astfel, orice cumpărător al activelor noastre poate continua să proceseze datele dvs. personale așa cum este stabilit în această politică de confidențialitate
			</p>

			<h5> <br /><br />14. Schimbări</h5>
			<p>
				Ality.ro si aplicatia “Ality” își rezervă dreptul de a modifica oricând această politică de confidențialitate. In cazul modificarii Politicii de confidentialitate, toate modificările vor fi mentionate atat pe pagina noastra oficiala ality.ro printr-un comunicat, cat si pe newsletter-ul oferit. Astfel, garantam transparenta catre dvs. despre ce informatii se colecteaza, de modul în care sunt folosite și în ce circumstanțe pot fi dezvaluite. Toate modificările sunt eficiente imediat după înregistrare și vă sugerăm să verificați periodic această politică de confidențialitate dacă sunteți preocupat de modul în care sunt utilizate informațiile dvs.
			</p>

			<h5> <br /><br />15. Contactați Ality</h5>
			<p>
				Aveti dreptul sa ne solicitati partajarea tuturor datelor personale pe care le deținem despre dumneavoastra. Solicitarile se fac doar in format electronic pe adresa de email: contact@ality.ro. Va rugăm să ne acordați un termen de solutionare de 30 de zile calendaristice de la solicitare. Aveți dreptul legal de a accesa, vizualiza aceste informații, să le corectați, modificați, ștergeți sau actualizați
				<br /><br />
				Oricând aveți întrebări privind această politică de confidențialitate sau practicile acestui site, doriți să vă retrageți consimțământul pentru colectarea continuă, doriți să obiectați împotriva utilizarii datelor dvs. personale sau doriti sa adresati întrebări suplimentare, vă rugăm să ne contactați în orice moment printr-un e-mail trimis la adresa contact@ality.ro.
			</p>

			<br /><br />
			<h2>Politica Confidentialitate Face Recognition</h2>

			<br /><br />
			<h3>1. INTRODUCERE</h3>
			<p>Prezenta Politică de confidențialitate respectă dispozițiile Regulamentului (UE) 2016/679 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (denumit în continuare „GDPR”) și prevederile legislației în domeniu, Legii nr. 190/2018 privind măsuri de punere în aplicare a GDPR</p>
			<br /><br />
			<p>Această Politică de confidențialitate conține informații importante și își propune să explice într-un mod simplu și transparent ce tip de date cu caracter personal colectăm despre dvs., cum le prelucrăm și drepturile dvs. cu privire la utilizarea informațiilor dvs. personale.</p>
			<br /><br />
			<div>Politica privind confidențialitatea datelor cu caracter personal vizează modalitatea prin care  LYRA NAPKIN SRL, în calitate de operator de date cu caracter personal, prelucrează datele cu caracter personal, respectiv:
				<ul>
					<li>scopurile pentru care colectăm și utilizăm datele dumneavoastră cu caracter personal</li>
					<br /><br />
					<li>temeiurile de prelucrare ale acestor date</li>
					<br /><br />
					<li>categoriile de date cu caracter personal pe care le colectăm de la dumneavoastră și pe care le prelucrăm</li>
					<br /><br />
					<li>durata procesului de prelucrare și de stocare a acestor date</li>
					<br /><br />
					<li>drepturile dumneavoastră în calitate de persoane vizate</li>
					<br /><br />
					<li>persoanele către care este posibil să dezvăluim datele dumneavoastră cu caracter personal</li>
				</ul>
			</div>
			<br /><br />
			<p>LYRA NAPKIN SRL este doar un furnizor de tehnologie (Furnizor) pentru oferirea de către un Terț autorizat de Furnizor (Terț autorizat) să utilizeze site-ul/aplicația în vederea oferirii, prin intermediul site-ului/aplicației Ality, a unor Servicii de recunoaștere facială prin intermediul căreia sunt identificate și trimise utilizatorilor fotografiile unde au fost etichetați la diferite evenimente (denumite în continuare Servicii).</p>
			<br /><br />
			<p>Relația juridică directă se naște între Utilizatorul care folosește Serviciile și Terțul autorizat care oferă Serviciile. Terțul autorizat poate fi organizatorul de evenimente sau orice persoană care angajează serviciile unui fotograf pentru fotografierea Utilizatorilor la evenimentul respectiv, și care o relație contractuală cu Furnizorul care îi permite Terțului autorizat să ofere Serviciile prin intermediul aplicației Ality.</p>

			<br /><br />
			<h3>2. CINE SUNTEM NOI</h3>
			<div>LYRA NAPKIN SRL (denumită în continuare „controlorul”, “operator asociat”, “furnizor” sau „ALITY”), companie cu răspundere limitată, înregistrată în România, având numărul de înregistrare  J03/741/2018, cod fiscal 39173082, înregistrată în Câmpulung Muscel, jud. Argeș, str Carpați, nr 60, cam 2, în calitate de operator asociat, prelucrează datele dvs. personale atunci când utilizați:
				<ul>
					<li>site-ul nostru de la www.Ality.ro</li>
					<br /><br />
					<li>aplicația pentru smartphone-uri Ality</li>
					<br /><br />
					<li>oricare dintre serviciile la care poți avea acces pe platforma ALITY (produsele și serviciile noastre</li>
				</ul>
			</div>
			<br /><br />
			<p>După cum am menționat mai sus, suntem un furnizor de tehnologie (Furnizor) pentru oferirea de către un Terț autorizat de Furnizor (Terț autorizat) să utilizeze site-ul/aplicația în vederea oferirii, prin intermediul site-ului/aplicației Ality, a unor Servicii de recunoaștere facială prin intermediul căreia sunt identificate și trimise utilizatorilor fotografiile unde au fost etichetați la diferite evenimente. Astfel, vom colecta, procesa, prelucra, stoca date cu caracter personal al Utiliatorului site-ului/aplicației în asociere cu Terțul autorizat care va oferi Serviciile în mod direct către Utilizatori.</p>
			<br /><br />
			<p>Trebuie să aveți vârsta de cel puțin 16 ani pentru a încheia acest acord.</p>
			<br /><br />
			<p>Prin accesarea și utilizarea platformei ALITY și dobândirea calității de Utilizator, se presupune că ați citit Termenii și condițiile Site-ului și ale prezentei Politici de confidențialitate și de protecție a datelor cu caracter personal și că vă dați consimțământul pentru a procesa informațiile dvs. personale necesare pentru ca aceste servicii să fie furnizate.</p>
			<br /><br />
			<p>Pentru evitarea oricăror îndoieli, acceptând această politică de confidențialitate, recunoașteți că Terțul autorizat  oferă prin intermediul platformei ALITY un serviciu care vă ajută sa primiți pozele cu dvs. de la un eveniment prin recunoașterea facială. Datele tale biometrice reprezintă un model matematic al caracteristicilor tale faciale și sunt criptate.</p>
			<br /><br />
			<p>Citiți cu atenție această politică pentru a înțelege practicile noastre de date și modul în care le tratăm. Dacă nu sunteți de acord cu oricare dintre aceste practici, nu accesați serviciile furnizate de această platformă.</p>
			<br /><br />
			<p>Dacă după citirea acestui acord în întregul său nu sunteți încă sigur sau aveți întrebări, nu ezitați să ne contactați la adresa de email contact@ality.ro</p>
			<br /><br />
			<p>Terțul asociat care vă oferă în concret Serviciile prin intermediul site-ului este menționat în cadrul contului dumneavoastră, la secțiunea ... și care va prelucra colecta, procesa, prelucra, stoca date cu caracter personal al Utiliatorului în scopul oferirii Serviciilor.</p>

			<br /><br />
			<h3>3. PRINCIPII DE PROCESARE</h3>
			<p>Protejarea și respectarea vieții private este una dintre preocupările noastre constante.</p>
			<br /><br />
			<p>Prelucrarea datelor dumneavoastră cu caracter personal se va face într-un mod legal, corect și transparent.</p>
			<br /><br />
			<p>Scopul pentru care colectăm datele dvs. personale sunt specificate, explicite și legitime, iar datele dvs. personale nu vor fi prelucrate în continuare într-o manieră care poate fi incompatibilă cu aceste scopuri.</p>
			<br /><br />
			<p>Vom colecta datele dvs. personale într-o manieră adecvată; datele personale colectate sunt relevante și limitate la informațiile necesare în scopul prelucrării, exacte și, dacă este necesar, actualizate.</p>
			<br /><br />
			<p>Ne-am angajat să luăm toate măsurile necesare pentru a asigura ștergerea sau corectarea datelor incorecte.</p>
			<br /><br />
			<p>Datele cu caracter personal vor fi păstrate pentru o perioadă nu mai mare decât cea în care datele cu caracter personal sunt prelucrate, cu excepția circumstanțelor impuse de lege, în cazul în care păstrarea poate fi necesară și ulterior.</p>
			<br /><br />
			<p>Datele cu caracter personal vor fi păstrate confidențiale; stocarea acestora se va face într-o manieră care să asigure securitatea necesară.</p>
			<br /><br />
			<p>Datele cu caracter personal vor fi partajate terților numai dacă sunt necesare în scopul furnizării de servicii în baza unor acorduri.</p>
			<br /><br />
			<p>Persoanele în cauză au dreptul de a solicita accesul la datele cu caracter personal, rectificarea și ștergerea, impedimentul sau restricționarea procesării datelor și dreptul la portabilitatea datelor.</p>
			<br /><br />
			<p>Nu comercializăm date cu caracter personal în scopuri comerciale și nu folosim cookie-uri.</p>

			<br /><br />
			<h3>4. TIP DE DATE PERSONALE PROCESATE</h3>
			<div>Este posibil să prelucrăm date personale precum:
				<ul>
					<li>Detalii de contact (nume, prenume, adresă de e-mail, număr de telefon, adresă poștală)</li>
					<br /><br />
					<li>Șablon biometric (față)</li>
					<br /><br />
					<li>Logare cont (nume de utilizator și parolă)</li>
					<br /><br />
					<li>Fotografii, selfie al utilizatorului</li>
					<br /><br />
					<li>Detalii privind locația - rezultând din denumirea evenimentului la care fotografia a fost realizată</li>
					<br /><br />
					<li>Informații tehnice, cum ar fi adresa protocolului internet (IP) utilizat pentru conectarea computerului la internet, tipul și versiunea browserului, informațiile de conectare, setarea fusului orar, sistemul de operare și platforma, tipul de dispozitiv pe care îl utilizați sau numărul de telefon mobil utilizat de dispozitiv, informațiile despre rețeaua mobilă, sistemul dvs. de operare mobil, tipul de browser mobil pe care îl utilizați și așa mai departe</li>
				</ul>
			</div>

			<br /><br />
			<h3>5. SCOPUL PROCESULUI</h3>
			<div>Scopul nostru principal în colectarea informațiilor personale este să vă oferim o experiență sigură, eficientă și personalizată. Folosim informații personale pentru a crea, dezvolta, opera, livra și îmbunătăți serviciile noastre, în conformitate cu Termenii și condițiile și pentru a crea contul de utilizator al platformei ALITY, pentru a vă identifica ca utilizator al unei platforme și a vă oferi acces la diferitele caracteristici și servicii disponibile pentru dvs. ca utilizator înregistrat:
				<ul>
					<li>Verificarea identității în vederea creării Contului de utilizator</li>
					<br></br>
					<li>Oferirea Serviciilor de către Terțul autorizat  prin intermediul aplicației Ality de recunoaștere facială</li>
					<br></br>
					<li>Detectarea, investigarea, prevenirea sau înlăturarea încălcărilor acordurilor dvs. cu noi</li>
					<br></br>
					<li>Pentru a respecta cerințele legale și de reglementare</li>
				</ul>
			</div>

			<br /><br />
			<h3>6. BAZĂ JURIDICĂ PENTRU PROCESARE</h3>
			<div>ALITY prelucrează informațiile dvs. personale pe bazele prezentate mai jos:
				<ul>
					<li>Respectând acordul cu dvs. - Avem nevoie de anumite informații personale pentru a furniza serviciile noastre și nu le putem furniza fără aceste informații</li>
					<br></br>
					<li>Obligații legale - În unele cazuri, avem responsabilitatea legală de a colecta și stoca informațiile dvs. personale</li>
					<br></br>
					<li>Interese legitime - Uneori colectăm și folosim informațiile dvs. personale, deoarece avem un motiv legitim de a le avea și acest lucru este rezonabil atunci când este echilibrat împotriva dreptului dvs. la confidențialitate</li>
					<br></br>
					<li>Consimțământ - acceptând această politică de confidențialitate, ne acordați acordul dvs. specific asupra prelucrării datelor efectuate în scopurile detaliate mai sus</li>
					<br></br>
					<li>Consimțământul dumneavoastră prealabil, liber, specific, informat, în baza art. 6 alin. (1) lit. a) din GDPR</li>
				</ul>
			</div>

			<br /><br />
			<h3>7. DURATA procesului de prelucrare și de stocare a acestor date</h3>
			<div>În funcție de tipul de date colectate și de momentul prelucrării acestora, ALITY stochează datele pentru următoarele durate
				<ul>
					<li>Nivelul 1 (Aceste date se stochează timp de 7 zile): adresa de email ale utilizatorului, selfie-ul Utilizatorului necesar pentru înregistrare, numele evenimentului la care selfie-ul a fost făcut</li>
					<br></br>
					<li>Nivelul 2 - Aceste date se stochează cât timp algoritmul de recunoaștere facială rulează</li>
					<br></br>
					<li>Fotografiile realizate de fotografi la eveniment sunt stocate în cloud timp de 7 zile</li>
				</ul>
			</div>
			<br /><br />
			<p>Datele cu caracter personal vor fi păstrate pentru o perioadă nu mai mare decât cea în care datele cu caracter personal sunt prelucrate, cu excepția circumstanțelor impuse de lege, în cazul în care păstrarea poate fi necesară și ulterior</p>

			<br /><br />
			<h3>8. Este posibil să dezvăluim și informațiile dvs. personale către terți:</h3>
			<div>
				<ul>
					<li>În cazul în care solicitați sau ne dați permisiunea de a face acest lucru</li>
					<br></br>
					<li>Persoanelor care pot demonstra că au autoritatea legală să acționeze în numele tău</li>
					<br></br>
					<li>Dacă este interesul nostru legitim să facem acest lucru pentru a gestiona, extinde sau dezvolta activitatea comercială. Dacă ALITY (sau o parte substanțială a activelor sale) este achiziționată de un terț, caz în care datele cu caracter personal deținute de ALITY vor fi unul dintre activele transferate</li>
					<br></br>
					<li>Dacă avem obligația de a dezvălui datele dvs. cu caracter personal pentru a respecta o obligație legală, orice solicitare legală din partea autorităților guvernamentale sau executive și, după cum este necesar, pentru a îndeplini anumite cerințe de securitate națională sau de aplicare a legii sau pentru a preveni anumite activități ilegale</li>
					<br></br>
					<li>Pentru a răspunde oricărei revendicări, pentru a ne proteja drepturile sau a unei terțe părți, pentru a proteja siguranța oricărei persoane sau pentru a preveni orice activitate ilegală</li>
					<br></br>
					<li>Pentru a proteja drepturile, proprietatea sau siguranța ALITY, a angajaților săi, a utilizatorilor platformei ALITY sau a altor persoane</li>
					<br></br>
					<li>Terțului autorizat - în calitate de operator asociat</li>
				</ul>
			</div>

			<br /><br />
			<h3>9. SECURITATEA DATELOR PERSONALE</h3>
			<p>Folosim măsuri adecvate pentru a menține confidențialitatea și securitatea datelor dvs. personale și pentru a preveni accesul, utilizarea, dezvăluirea, modificarea sau distrugerea neautorizate</p>
			<br /><br />
			<p>Vă rugăm să rețineți că aceste garanții nu se aplică informațiilor pe care alegeți să le distribuiți în domeniul public, cum ar fi rețelele sociale deținute de terți</p>
			<br /><br />
			<p>Datele dvs. personale vor fi prelucrate de personalul sau agenții noștri autorizați numai în măsura în care li se cere să știe, în funcție de scopurile specifice pentru care au fost colectate datele dvs. personale</p>
			<br /><br />
			<p>Stocăm datele dvs. personale în medii de operare care folosesc măsuri rezonabile de securitate pentru a preveni accesul neautorizat. Respectăm standarde rezonabile pentru protecția datelor cu caracter personal</p>
			<br /><br />
			<div>Informațiile personale pe care le furnizați la crearea unui cont pe platforma ALITY sunt criptate și păstrate în conturile cloud ALITY în AWS Amazon și Azure. Acestea au propriile lor Politici de confidențialitate în ceea ce privește protecția datelor cu caracter personal pe care le puteți găsi aici:
				<ul>
					<li>https://aws.amazon.com/privacy/</li>
					<br></br>
					<li>https://azure.microsoft.com/en-in/overview/trusted-cloud/privacy/</li>
					<br></br>
					<li>https://azure.microsoft.com/es-es/blog/protecting-privacy-in-microsoft-azure-gdpr-azure-policy-updates/</li>
				</ul>
			</div>
			<br /><br />
			<p>Prin exprimarea consimțământului pentru prelucrarea datelor cu caracter personal conform prezentei Politici aferente platformei ALITY, CONFIRMAȚI CĂ SUNTEȚI DE ACORD și CUNOAȘTEȚI  POLITICA DE CONFIDENȚIALITATE aferentă AMAZON ȘI AZURE</p>
			<br /><br />
			<p>Este important pentru dvs. să jucați un rol în menținerea securității și securității datelor dvs. personale. Sunteți responsabil pentru păstrarea confidențialității pentru orice utilizare a contului</p>
			<br /><br />
			<p>Dacă sunteți la curent cu orice utilizare neautorizată a contului dvs. sau orice altă încălcare a securității cu privire la datele cu caracter personal pe care le-ați furnizat către ALITY, vă dați acordul să notificați imediat ALITY</p>

			<br /><br />
			<h3>10. POSIBILE RISCURI PENTRU FURNIZAREA DATELOR BIOMETRICE</h3>
			<div>Chiar dacă luăm toate măsurile pentru securizarea tuturor datelor cu caracter personal furnizate de utilizatori, există totuși anumite riscuri, în special atunci când vine vorba de date sensibile, cum ar fi datele biometrice. Deoarece dorim ca utilizatorii noștri să fie informați pe deplin atunci când vine vorba de datele lor personale, în cele ce urmează menționăm, de exemplu, câteva dintre aceste riscuri:
				<ul>
					<li>Nu este exclus furtul de identitate indiferent de tehnologia utilizată</li>
					<br></br>
					<li>Publicarea acestor date în cazul în care serverele sunt hackate și utilizarea datelor în scopuri neautorizate (de exemplu, marketing, statistici etc.), pierderea acestor date, distrugerea lor, modificarea, dezvăluirea sau colectarea ilegală</li>
					<br></br>
					<li>Acces la alte date cu caracter personal care pot rezulta din datele biometrice, cum ar fi: rasă, sex, etnie, vârstă etc</li>
				</ul>
			</div>
			<br /><br />
			<p>Cu toate acestea, vă rugăm să rețineți că am implementat metode de securizare a datelor cu caracter personal pe care le colectăm, în special datele sensibile prin criptarea și stocarea acestora într-o formă în care aceste informații nu pot fi utilizate pentru a reda date biometrice în forma sa inițială</p>

			<br /><br />
			<h3>11. TRANSFERURI DE DATE PERSONALE</h3>
			<p>Păstrarea și prelucrarea informațiilor dvs. personale, așa cum s-a descris mai sus, poate necesita transferul unei astfel de date și / sau depozitarea către o destinație din afara țării de reședință către țările din Uniunea Europeană („UE”) sau Spațiul Economic European („SEE”), de exemplu, furnizorii de servicii</p>
			<br /><br />
			<p>Înainte de a transfera datele dvs., vom lua măsurile necesare pentru a ne asigura că informațiile dvs. personale vor beneficia de o protecție adecvată, în conformitate cu legile de confidențialitate relevante și politicile interne ale ALITY</p>
			<br /><br />
			<p>Înscriindu-vă ca Utilizator al platformei ALITY deținută de ALITY, vă dați în mod expres și fără echivoc consimțământul pentru transferul și stocarea datelor dvs. personale pe serverele din afara țării dvs. de reședință, inclusiv SUA, care pot avea legi de protecție a datelor diferite de cele din tara ta</p>

			<br /><br />
			<h3>12. DREPTURILE UTILIZATORULUI în calitate de PERSOANĂ VIZATĂ</h3>
			<div>
				<ul>
					<li>Dreptul de a fi informat, acces (persoana vizată are dreptul să obțină de la ALITY o confirmare a faptului că datele cu caracter personal care îl privesc sunt sau nu prelucrate și, în caz afirmativ, are dreptul să acceseze datele). Acest drept poate fi limitat sau refuzat, motivul refuzului sau al limitării fiind comunicat persoanei în cauză</li>
					<br></br>
					<li>Dreptul de rectificare (persoana vizată are dreptul de a obține, fără întârziere nejustificată, rectificarea datelor cu caracter personal inexacte care îl privesc). Ținând cont de scopurile pentru care au fost prelucrate datele, acesta are dreptul să obțină completarea datelor cu caracter personal incomplete, inclusiv prin furnizarea unei declarații suplimentare</li>
					<br></br>
					<li>Dreptul la ștergerea datelor (în situațiile în care (1) datele nu mai sunt necesare pentru îndeplinirea scopurilor, (2) consimțământul a fost retras și nu există niciun alt temei legal pentru prelucrare, (3) persoana se opune prelucrarea și nu există motive legitime (4) datele cu caracter personal au fost prelucrate în mod ilegal, persoana are dreptul să obțină ștergerea datelor referitoare la aceasta fără întârzieri nejustificate). Acest drept poate fi limitat sau refuzat, motivul refuzului sau al limitării fiind comunicat persoanei în cauză</li>
					<br></br>
					<li>Dreptul la restricționarea procesării - persoana vizată are dreptul să restricționeze prelucrarea în următoarele situații
						<p>(i) contesta exactitatea datelor pentru o perioadă care permite operatorului să verifice exactitatea datelor</p>
						<p>(ii) prelucrarea este ilegală și persoana în cauză se opune ștergerii datelor cu caracter personal, dar în schimb solicită restricționarea utilizării acestora</p>
						<p>(iii) nu sunt necesare date personale pentru prelucrare, dar persoana vizată le solicită instituirea, exercitarea sau apărarea unui drept în instanță</p>
						<p>(iv) persoana vizată opusă prelucrării, pentru perioada de timp pentru a verifica dacă interesele legitime ale operatorului prevalează asupra drepturilor persoanei</p>
					</li>
					<br></br>
					<li>Utilizatorul are dreptul de a se opune oricând prelucrării datelor în scop de marketing direct, de a nu fi supus procesului automat de luare a deciziilor, inclusiv de profilare</li>
					<br></br>
					<li>Dreptul la portabilitatea datelor - persoana vizată are dreptul de a primi datele cu caracter personal pe care le consideră și pe care le-a furnizat într-un formular structurat, utilizat în mod obișnuit și care poate fi citit și are dreptul să solicite ALITY să transmită aceste date către o altă persoană operator, fără obstacole din partea ALITY, dacă sunt îndeplinite cumulativ următoarele condiții:
						<p>(i) prelucrarea se bazează pe consimțământ sau contract și</p>
						<p>(ii) prelucrarea se realizează prin mijloace automate, în special dacă acest lucru este fezabil din punct de vedere tehnic</p>
					</li>
					<br></br>
					<li>Dreptul de a depune o reclamație la Terțul autorizat sau Furnizor - persoana vizată poate depune o plângere dacă nu este nemulțumit de prelucrarea datelor sale personale sau de modul de a răspunde la solicitarea sa</li>
					<br></br>
					<li>Dreptul de a depune o reclamație la Autoritatea de Supraveghere - persoana vizată are dreptul să depună o plângere la Autoritatea Națională de Supraveghere pentru Prelucrarea Datelor cu Caracter Personal dacă nu este nemulțumit de prelucrarea datelor sale personale
						<p>Nume: Autoritatea Națională pentru Supravegherea Prelucrării Datelor cu Caracter Personal</p>
						<p>Adresa: B-dul G-ral. Gheorghe Magheru 28-30, Sector 1, cod poștal 010336, București, România</p>
						<p>Telefon: +40.318.059.211 sau +40.318.059.212</p>
						<p>Fax: +40.318.059.602</p>
						<p>E-mail: anspdcp@dataprotection.ro</p>
					</li>
					<br></br>
					<li>Dreptul de a se adresa justiției - persoana vizată are dreptul de a apela la instanțele competente dacă nu este nemulțumit de prelucrarea datelor sale personale</li>
				</ul>
			</div>
			<br /><br />
			<p>Persoana vizată se bucură de aceste drepturi, indiferent de temeiul legal al procesării datelor sale</p>

			<br /><br />
			<h3>13. DIVERSE</h3>
			<p>Este posibil ca site-ul nostru să conțină linkuri către și de pe site-urile rețelelor partenere, agenților de publicitate și afiliaților noștri. Dacă accesați un link către oricare dintre aceste site-uri web, vă rugăm să rețineți că aceste site-uri web au propriile lor politici de confidențialitate și că nu putem fi responsabili pentru aceste politici. Vă rugăm să verificați politicile de confidențialitate ale fiecărui site web înainte de a trimite date personale</p>

			<br /><br />
			<h3>14. MODIFICĂRI LA ACEASTA POLITICĂ</h3>
			<p>Dacă aducem modificări modului în care gestionăm informațiile dvs. personale, vom actualiza această politică de confidențialitate. Ne rezervăm dreptul de a face schimbări în practicile și politicile noastre în orice moment. Vă rugăm să consultați site-ul nostru în mod regulat pentru a vedea actualizări sau modificări ale politicii noastre de confidențialitate. Toate versiunile anterioare ale acestei politici de confidențialitate vor fi disponibile pe site-ul nostru web</p>
			<br /><br />
			<p>SUBSEMNATUL/SUBSEMNATA, DECLAR ÎN MOD EXPRES CĂ AM FOST INFORMAT/Ă DE CĂTRE LYRA NAPKIN S.R.L ASUPRA CONDIȚIILOR DETALIATE PRIVIND GESTIUNEA DATELOR CU CARACTER PERSONAL DE CĂTRE  LYRA NAPKIN S.R.L  ÎMPREUNĂ CU OPERATORUL ASOCIAT - TERȚUL AUTORIZAT, PRIN INTERMEDIUL PREZENTEI POLITICI DE CONFIDENȚIALITATE ȘI DE PROTECȚIE A DATELOR CU CARACTER PERSONAL ȘI PRIN INTERMEDIUL TERMENILOR ȘI CONDIȚIILOR DE UTILIZARE A SITE-ULUI ALITY,
				ȘI,
				SUBSEMNATUL/SUBSEMNATA, DECLAR ÎN MOD EXPRES CĂ SUNT DE ACORD CU CU COLECTAREA, PRELUCRAREA, PROCESAREA, TRANSFERUL DATELOR CU CARACTER PERSONAL ALE SUBSEMNATULUI/SUBSEMNATEI CONFORM PREZENTEI POLITICI DE CONFIDENȚIALITATE ȘI DE PROTECȚIE A DATELOR CU CARACTER PERSONAL
				{/* :   DA _ / NU_ */}
			</p>

			<Button onClick={props.toggleModal} appearance="primary" > Am luat la cunostinta </Button>

		</Modal>
	)
}

export default confid;