import React from 'react'
import SettingCard from '../Profile/components/SettingCard'
import './GuestInfoPage.scss'

import HeaderWhite from '../../components/HeaderWhiteBack'
import NavbarColor from '../../components/NavbarColor'
import { Button } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'

import SVG_Invite_Answers from '../../assets/GuestInfoSVG/invite-answers-icon.svg'
import SVG_Hostess from '../../assets/GuestInfoSVG/hostess-icon.svg'
import SVG_Check_Attendance from '../../assets/GuestInfoSVG/check-attendance-icon.svg'
import SVG_Seat_Participants from '../../assets/GuestInfoSVG/seat-participants-icon.svg'
import SVG_Gift_Money from '../../assets/GuestInfoSVG/cadou-dar-icon.svg'

import { LoadingScreen } from '../../components/Helper'

class GuestInfoPage extends React.Component {
	state = {
		eventType: "",
		hasNewResponses: false,
		hasNewSaveTheDateResponses: false,

		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
			.then(async response => {
				let hasNewResponses = false
				let hasNewSaveTheDateResponses = false

				let inviteResponses = await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
				if (inviteResponses.data.totalResponses > response.data.count_seen_invite_responses) {
					hasNewResponses = true
				}
				
				if (response.data.eventType === "Nunta") {	
					let saveThedateResponses = await axios.get(process.env.REACT_APP_DOMAIN + 'response-save-the-dates/list/byEvent/' + this.props.match.params.id, auth)

					if (saveThedateResponses.data.totalResponses > response.data.count_seen_save_the_date_responses) {
						hasNewSaveTheDateResponses = true
					}
				}

				this.setState({
					eventType: response.data.eventType,
					hasNewResponses: hasNewResponses,
					hasNewSaveTheDateResponses: hasNewSaveTheDateResponses,
					loading: false
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("A aparut o eroare, va rog reincercati")
				this.props.history.push("/viewEvents/" + localStorage.getItem("id"))
			})
	}

	goToResponesSaveTheDate = () => {
		this.props.history.push('/raspunsuriSaveTheDate/' + this.props.match.params.id)
	}

	goToResponsesInvite = () => {
		this.props.history.push('/raspunsuriInvitati/' + this.props.match.params.id)
	}

	goToChooseHostess = () => {
		this.props.history.push('/hostessMap/' + this.props.match.params.id)
	}

	goToCheckPresence = () => {
		this.props.history.push('/checkPresence/' + this.props.match.params.id)
	}

	goToGiftMoney = () => {
		this.props.history.push('/dar/' + this.props.match.params.id)
	}

	goToSeatParticipants = () => {
		this.props.history.push('/seatParticipants/' + this.props.match.params.id)
	}

	goBack = () => {
		this.props.history.push("/adminEventPage/" + this.props.match.params.id)
	}

	render() {
		if (this.state.loading) {
			return <LoadingScreen />
		}

		return (
			<div className="GuestInfoPage-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<div className="Title">Informatii Participanti</div>

				<div className="GuestInfoPage-contents">
					{this.state.eventType === "Nunta" && <SettingCard svg={SVG_Invite_Answers} onClick={this.goToResponesSaveTheDate} name={"Raspunsuri save the date"} hasRedDot={this.state.hasNewSaveTheDateResponses} />}

					<SettingCard svg={SVG_Invite_Answers} onClick={this.goToResponsesInvite} name={"Raspunsuri invitatie"} hasRedDot={this.state.hasNewResponses} />

					<SettingCard svg={SVG_Hostess} onClick={this.goToChooseHostess} name="Alege hostess" />

					<SettingCard svg={SVG_Check_Attendance} onClick={this.goToCheckPresence} name="Verifica prezenta" />

					<SettingCard svg={SVG_Seat_Participants} onClick={this.goToSeatParticipants} name="Aseaza participanti la mese" />

					{this.state.eventType !== "Petrecere" && <SettingCard svg={SVG_Gift_Money} onClick={this.goToGiftMoney} name="Dar" />}

					<div>
						<Button style={{ margin: "20px 20px 20px 0px", color: "#1E1C59", border: "1px solid #1E1C59", borderRadius: "80px", width: "200px" }} onClick={this.goBack}>
							Inapoi
						</Button>
					</div>
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default GuestInfoPage;